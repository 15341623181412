import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getRealEstates, getStats } from '../../api/User';
import { STATS_FILTER_AKTUELLEWOCHE, STATS_FILTER_GESAMT, STATS_FILTER_LETZTE3WOCHEN } from '../../constants';
import { parseDate } from '../../util/datetimeParser';
import moment from 'moment';
import 'moment/locale/de';

export const useFetchStats = (page) => {
  const user = useSelector((state) => state.user);
  const [realEstates, setRealEstates] = useState(null);
  const [besichtigungenData, setBesichtigungenData] = useState(null);
  const [verschickteAngeboteData, setVerschickteAngeboteData] = useState(null);
  const [inseratKlicksData, setInseratKlicksData] = useState(null);

  const lineChartDatasetSettings = {
    fill: true,
    borderColor: page === 'Statistiken' ? '#213363' : 'rgba(255,255,255,1)',
    backgroundColor: 'rgba(255,255,255, 0.3)',
    pointRadius: 6,
    pointBackgroundColor: page === 'Statistiken' ? '#213363' : 'rgba(255,255,255,1)',
    legendColor: '#fff',
    showLine: true,
  };

  const fetchRealEstates = async () => {
    try {
      const realEstates = await getRealEstates();
      setRealEstates(realEstates);
      return realEstates;
    } catch (e) {
      return [];
    }
  };

  const getData = async (dummyData = false) => {
    if (!dummyData) {
      try {
        const realEstates = await fetchRealEstates();

        let foundImmo = realEstates.find((immo) => {
          // Check if catalogue_number already contains tenant_id (xxx/xxxxxx), if not build string with tenant_id
          if (user?.userRealtyActive?.catalogue_number.includes('/')) {
            return immo?.externalId === user?.userRealtyActive?.catalogue_number;
          } else {
            return immo?.externalId === `${user?.userRealtyActive?.tenant_id}/${user?.userRealtyActive?.catalogue_number}`;
          }
        });

        if (foundImmo) {
          try {
            const stats = await getStats(foundImmo?.id);
            setupStats(stats, ['clicks', 'offers', 'visits']);
          } catch (e) {
            resetData();
          }
        } else {
          resetData();
        }
      } catch (e) {
        resetData();
      }
    } else {
      const stats = {
        'Aktuelle Woche': {
          startDate: '2024-03-25T00:00:00+00:00',
          endDate: '2024-03-31T00:00:00+00:00',
          groups: [
            {
              displayName: 'Klicks auf Inserat',
              key: 'clicks',
              types: [
                {
                  displayName: 'sreal.at',
                  key: 'sreal',
                  values: [],
                  sum: 0,
                },
                {
                  displayName: 'my-sreal.at',
                  key: 'mysreal',
                  values: [],
                  sum: 0,
                },
                {
                  displayName: 'willhaben Wien',
                  key: 'wh-w',
                  values: [],
                  sum: 0,
                },
                {
                  displayName: 'willhaben Steiermark',
                  key: 'wh-s',
                  values: [],
                  sum: 0,
                },
                {
                  displayName: 'willhaben Oberösterreich',
                  key: 'wh-o',
                  values: [],
                  sum: 0,
                },
                {
                  displayName: 'willhaben Kärnten',
                  key: 'wh-k',
                  values: [],
                  sum: 0,
                },
                {
                  displayName: 'willhaben Tirol',
                  key: 'wh-t',
                  values: [],
                  sum: 0,
                },
                {
                  displayName: 'willhaben Niederösterreich',
                  key: 'wh-n',
                  values: [],
                  sum: 0,
                },
                {
                  displayName: 'willhaben Burgenland',
                  key: 'wh-b',
                  values: [],
                  sum: 0,
                },
                {
                  displayName: 'willhaben Salzburg',
                  key: 'wh-sbg',
                  values: [],
                  sum: 0,
                },
                {
                  displayName: 'willhaben Kommerz',
                  key: 'wh-kom',
                  values: [],
                  sum: 0,
                },
                {
                  displayName: 'FindMyHome',
                  key: 'fmh',
                  values: [],
                  sum: 0,
                },
                {
                  displayName: 'Immoscout24',
                  key: 'isc',
                  values: [],
                  sum: 0,
                },
                {
                  displayName: 'immo.tt',
                  key: 'itt',
                  values: [],
                  sum: 0,
                },
                {
                  displayName: 'wohnnet.at Wien',
                  key: 'wnet-w',
                  values: [],
                  sum: 0,
                },
                {
                  displayName: 'wohnnet.at Stiermark',
                  key: 'wnet-s',
                  values: [],
                  sum: 0,
                },
                {
                  displayName: 'wohnnet.at Tirol',
                  key: 'wnet-t',
                  values: [],
                  sum: 0,
                },
                {
                  displayName: 'wohnnet.at Vorarlberg',
                  key: 'wnet-v',
                  values: [],
                  sum: 0,
                },
                {
                  displayName: 'wohnnet.at OÖ',
                  key: 'wnet-o',
                  values: [],
                  sum: 0,
                },
                {
                  displayName: 'wohnnet.at NÖ',
                  key: 'wnet-n',
                  values: [],
                  sum: 0,
                },
                {
                  displayName: 'wohnnet.at Salzburg',
                  key: 'wnet-sbg',
                  values: [],
                  sum: 0,
                },
                {
                  displayName: 'wohnnet.at St. Veit',
                  key: 'wnet-sv',
                  values: [],
                  sum: 0,
                },
                {
                  displayName: 'wohnnet.at Kärnten',
                  key: 'wnet-k',
                  values: [],
                  sum: 0,
                },
                {
                  displayName: 'ländleimmo.at',
                  key: 'lli',
                  values: [],
                  sum: 0,
                },
              ],
              sum: 0,
            },
            {
              displayName: 'Verschickte Angebote',
              key: 'offers',
              types: [
                {
                  displayName: 'JustImmo',
                  key: 'ji',
                  values: [],
                  sum: 0,
                },
              ],
              sum: 0,
            },
            {
              displayName: 'Besichtigungen',
              key: 'visits',
              types: [
                {
                  displayName: 'JustImmo',
                  key: 'ji',
                  values: [],
                  sum: 0,
                },
              ],
              sum: 0,
            },
          ],
        },
        Gesamtlaufzeit: {
          startDate: '2023-07-15T00:00:00+00:00',
          endDate: '2024-03-20T00:00:00+00:00',
          groups: [
            {
              displayName: 'Klicks auf Inserat',
              key: 'clicks',
              types: [
                {
                  displayName: 'sreal.at',
                  key: 'sreal',
                  values: [
                    {
                      date: '2023-09-23T00:00:00+00:00',
                      value: 1,
                    },
                    {
                      date: '2023-09-24T00:00:00+00:00',
                      value: 1,
                    },
                    {
                      date: '2023-09-25T00:00:00+00:00',
                      value: 1,
                    },
                    {
                      date: '2023-11-03T00:00:00+00:00',
                      value: 2,
                    },
                    {
                      date: '2023-11-04T00:00:00+00:00',
                      value: 2,
                    },
                    {
                      date: '2023-11-05T00:00:00+00:00',
                      value: 3,
                    },
                    {
                      date: '2023-11-12T00:00:00+00:00',
                      value: 1,
                    },
                    {
                      date: '2023-11-13T00:00:00+00:00',
                      value: 1,
                    },
                    {
                      date: '2023-11-14T00:00:00+00:00',
                      value: 1,
                    },
                    {
                      date: '2023-11-19T00:00:00+00:00',
                      value: 2,
                    },
                    {
                      date: '2023-11-21T00:00:00+00:00',
                      value: 1,
                    },
                    {
                      date: '2023-11-23T00:00:00+00:00',
                      value: 3,
                    },
                    {
                      date: '2023-11-24T00:00:00+00:00',
                      value: 1,
                    },
                    {
                      date: '2023-11-29T00:00:00+00:00',
                      value: 1,
                    },
                    {
                      date: '2023-11-30T00:00:00+00:00',
                      value: 1,
                    },
                    {
                      date: '2023-12-02T00:00:00+00:00',
                      value: 1,
                    },
                    {
                      date: '2023-12-04T00:00:00+00:00',
                      value: 1,
                    },
                    {
                      date: '2023-12-06T00:00:00+00:00',
                      value: 1,
                    },
                    {
                      date: '2023-12-09T00:00:00+00:00',
                      value: 1,
                    },
                    {
                      date: '2023-12-12T00:00:00+00:00',
                      value: 1,
                    },
                    {
                      date: '2023-12-13T00:00:00+00:00',
                      value: 1,
                    },
                    {
                      date: '2023-12-15T00:00:00+00:00',
                      value: 1,
                    },
                    {
                      date: '2023-12-17T00:00:00+00:00',
                      value: 3,
                    },
                    {
                      date: '2023-12-20T00:00:00+00:00',
                      value: 1,
                    },
                  ],
                  sum: 33,
                },
                {
                  displayName: 'my-sreal.at',
                  key: 'mysreal',
                  values: [
                    {
                      date: '2023-09-25T00:00:00+00:00',
                      value: 2,
                    },
                    {
                      date: '2023-11-03T00:00:00+00:00',
                      value: 1,
                    },
                    {
                      date: '2023-11-13T00:00:00+00:00',
                      value: 1,
                    },
                    {
                      date: '2023-11-23T00:00:00+00:00',
                      value: 3,
                    },
                    {
                      date: '2023-11-24T00:00:00+00:00',
                      value: 1,
                    },
                    {
                      date: '2023-11-28T00:00:00+00:00',
                      value: 1,
                    },
                    {
                      date: '2023-12-15T00:00:00+00:00',
                      value: 1,
                    },
                  ],
                  sum: 10,
                },
                {
                  displayName: 'willhaben Wien',
                  key: 'wh-w',
                  values: [],
                  sum: 0,
                },
                {
                  displayName: 'willhaben Steiermark',
                  key: 'wh-s',
                  values: [],
                  sum: 0,
                },
                {
                  displayName: 'willhaben Oberösterreich',
                  key: 'wh-o',
                  values: [],
                  sum: 0,
                },
                {
                  displayName: 'willhaben Kärnten',
                  key: 'wh-k',
                  values: [],
                  sum: 0,
                },
                {
                  displayName: 'willhaben Tirol',
                  key: 'wh-t',
                  values: [],
                  sum: 0,
                },
                {
                  displayName: 'willhaben Niederösterreich',
                  key: 'wh-n',
                  values: [
                    {
                      date: '2023-11-03T00:00:00+00:00',
                      value: 70,
                    },
                    {
                      date: '2023-11-04T00:00:00+00:00',
                      value: 75,
                    },
                    {
                      date: '2023-11-05T00:00:00+00:00',
                      value: 59,
                    },
                    {
                      date: '2023-11-06T00:00:00+00:00',
                      value: 81,
                    },
                    {
                      date: '2023-11-07T00:00:00+00:00',
                      value: 58,
                    },
                    {
                      date: '2023-11-09T00:00:00+00:00',
                      value: 127,
                    },
                    {
                      date: '2023-11-11T00:00:00+00:00',
                      value: 45,
                    },
                    {
                      date: '2023-11-12T00:00:00+00:00',
                      value: 61,
                    },
                    {
                      date: '2023-11-13T00:00:00+00:00',
                      value: 69,
                    },
                    {
                      date: '2023-11-14T00:00:00+00:00',
                      value: 37,
                    },
                  ],
                  sum: 682,
                },
                {
                  displayName: 'willhaben Burgenland',
                  key: 'wh-b',
                  values: [],
                  sum: 0,
                },
                {
                  displayName: 'willhaben Salzburg',
                  key: 'wh-sbg',
                  values: [],
                  sum: 0,
                },
                {
                  displayName: 'willhaben Kommerz',
                  key: 'wh-kom',
                  values: [],
                  sum: 0,
                },
                {
                  displayName: 'FindMyHome',
                  key: 'fmh',
                  values: [],
                  sum: 0,
                },
                {
                  displayName: 'Immoscout24',
                  key: 'isc',
                  values: [],
                  sum: 0,
                },
                {
                  displayName: 'immo.tt',
                  key: 'itt',
                  values: [],
                  sum: 0,
                },
                {
                  displayName: 'wohnnet.at Wien',
                  key: 'wnet-w',
                  values: [],
                  sum: 0,
                },
                {
                  displayName: 'wohnnet.at Stiermark',
                  key: 'wnet-s',
                  values: [],
                  sum: 0,
                },
                {
                  displayName: 'wohnnet.at Tirol',
                  key: 'wnet-t',
                  values: [],
                  sum: 0,
                },
                {
                  displayName: 'wohnnet.at Vorarlberg',
                  key: 'wnet-v',
                  values: [],
                  sum: 0,
                },
                {
                  displayName: 'wohnnet.at OÖ',
                  key: 'wnet-o',
                  values: [],
                  sum: 0,
                },
                {
                  displayName: 'wohnnet.at NÖ',
                  key: 'wnet-n',
                  values: [],
                  sum: 0,
                },
                {
                  displayName: 'wohnnet.at Salzburg',
                  key: 'wnet-sbg',
                  values: [],
                  sum: 0,
                },
                {
                  displayName: 'wohnnet.at St. Veit',
                  key: 'wnet-sv',
                  values: [],
                  sum: 0,
                },
                {
                  displayName: 'wohnnet.at Kärnten',
                  key: 'wnet-k',
                  values: [],
                  sum: 0,
                },
                {
                  displayName: 'ländleimmo.at',
                  key: 'lli',
                  values: [],
                  sum: 0,
                },
              ],
              sum: 725,
            },
            {
              displayName: 'Verschickte Angebote',
              key: 'offers',
              types: [
                {
                  displayName: 'JustImmo',
                  key: 'ji',
                  values: [
                    {
                      date: '2023-07-15T00:00:00+00:00',
                      value: 2,
                    },
                    {
                      date: '2023-07-18T00:00:00+00:00',
                      value: 2,
                    },
                    {
                      date: '2023-07-20T00:00:00+00:00',
                      value: 1,
                    },
                    {
                      date: '2023-08-07T00:00:00+00:00',
                      value: 1,
                    },
                    {
                      date: '2023-09-18T00:00:00+00:00',
                      value: 1,
                    },
                    {
                      date: '2023-10-16T00:00:00+00:00',
                      value: 1,
                    },
                    {
                      date: '2023-10-18T00:00:00+00:00',
                      value: 2,
                    },
                    {
                      date: '2023-11-23T00:00:00+00:00',
                      value: 1,
                    },
                    {
                      date: '2024-03-19T00:00:00+00:00',
                      value: 1,
                    },
                  ],
                  sum: 12,
                },
              ],
              sum: 12,
            },
            {
              displayName: 'Besichtigungen',
              key: 'visits',
              types: [
                {
                  displayName: 'JustImmo',
                  key: 'ji',
                  values: [
                    {
                      date: '2023-07-18T00:00:00+00:00',
                      value: 1,
                    },
                    {
                      date: '2023-07-21T00:00:00+00:00',
                      value: 1,
                    },
                    {
                      date: '2023-10-26T00:00:00+00:00',
                      value: 1,
                    },
                    {
                      date: '2023-11-10T00:00:00+00:00',
                      value: 1,
                    },
                    {
                      date: '2024-03-20T00:00:00+00:00',
                      value: 1,
                    },
                  ],
                  sum: 5,
                },
              ],
              sum: 5,
            },
          ],
        },
        'Letzte 3 Wochen': {
          startDate: '2024-03-19T00:00:00+00:00',
          endDate: '2024-03-20T00:00:00+00:00',
          groups: [
            {
              displayName: 'Klicks auf Inserat',
              key: 'clicks',
              types: [
                {
                  displayName: 'sreal.at',
                  key: 'sreal',
                  values: [],
                  sum: 0,
                },
                {
                  displayName: 'my-sreal.at',
                  key: 'mysreal',
                  values: [],
                  sum: 0,
                },
                {
                  displayName: 'willhaben Wien',
                  key: 'wh-w',
                  values: [],
                  sum: 0,
                },
                {
                  displayName: 'willhaben Steiermark',
                  key: 'wh-s',
                  values: [],
                  sum: 0,
                },
                {
                  displayName: 'willhaben Oberösterreich',
                  key: 'wh-o',
                  values: [],
                  sum: 0,
                },
                {
                  displayName: 'willhaben Kärnten',
                  key: 'wh-k',
                  values: [],
                  sum: 0,
                },
                {
                  displayName: 'willhaben Tirol',
                  key: 'wh-t',
                  values: [],
                  sum: 0,
                },
                {
                  displayName: 'willhaben Niederösterreich',
                  key: 'wh-n',
                  values: [],
                  sum: 0,
                },
                {
                  displayName: 'willhaben Burgenland',
                  key: 'wh-b',
                  values: [],
                  sum: 0,
                },
                {
                  displayName: 'willhaben Salzburg',
                  key: 'wh-sbg',
                  values: [],
                  sum: 0,
                },
                {
                  displayName: 'willhaben Kommerz',
                  key: 'wh-kom',
                  values: [],
                  sum: 0,
                },
                {
                  displayName: 'FindMyHome',
                  key: 'fmh',
                  values: [],
                  sum: 0,
                },
                {
                  displayName: 'Immoscout24',
                  key: 'isc',
                  values: [],
                  sum: 0,
                },
                {
                  displayName: 'immo.tt',
                  key: 'itt',
                  values: [],
                  sum: 0,
                },
                {
                  displayName: 'wohnnet.at Wien',
                  key: 'wnet-w',
                  values: [],
                  sum: 0,
                },
                {
                  displayName: 'wohnnet.at Stiermark',
                  key: 'wnet-s',
                  values: [],
                  sum: 0,
                },
                {
                  displayName: 'wohnnet.at Tirol',
                  key: 'wnet-t',
                  values: [],
                  sum: 0,
                },
                {
                  displayName: 'wohnnet.at Vorarlberg',
                  key: 'wnet-v',
                  values: [],
                  sum: 0,
                },
                {
                  displayName: 'wohnnet.at OÖ',
                  key: 'wnet-o',
                  values: [],
                  sum: 0,
                },
                {
                  displayName: 'wohnnet.at NÖ',
                  key: 'wnet-n',
                  values: [],
                  sum: 0,
                },
                {
                  displayName: 'wohnnet.at Salzburg',
                  key: 'wnet-sbg',
                  values: [],
                  sum: 0,
                },
                {
                  displayName: 'wohnnet.at St. Veit',
                  key: 'wnet-sv',
                  values: [],
                  sum: 0,
                },
                {
                  displayName: 'wohnnet.at Kärnten',
                  key: 'wnet-k',
                  values: [],
                  sum: 0,
                },
                {
                  displayName: 'ländleimmo.at',
                  key: 'lli',
                  values: [],
                  sum: 0,
                },
              ],
              sum: 0,
            },
            {
              displayName: 'Verschickte Angebote',
              key: 'offers',
              types: [
                {
                  displayName: 'JustImmo',
                  key: 'ji',
                  values: [
                    {
                      date: '2024-03-19T00:00:00+00:00',
                      value: 1,
                    },
                  ],
                  sum: 1,
                },
              ],
              sum: 1,
            },
            {
              displayName: 'Besichtigungen',
              key: 'visits',
              types: [
                {
                  displayName: 'JustImmo',
                  key: 'ji',
                  values: [
                    {
                      date: '2024-03-20T00:00:00+00:00',
                      value: 1,
                    },
                  ],
                  sum: 1,
                },
              ],
              sum: 1,
            },
          ],
        },
      };
      setupStats(stats, ['clicks', 'offers', 'visits']);
    }
  };

  const setupStats = (stats, chartTypes) => {
    chartTypes.forEach((chartType) => {
      let data = {
        type: 'line',
        count: {
          [STATS_FILTER_AKTUELLEWOCHE.key]: stats[STATS_FILTER_AKTUELLEWOCHE.label].groups.find((g) => g.key === chartType)?.sum || 0,
          [STATS_FILTER_GESAMT.key]: stats[STATS_FILTER_GESAMT.label].groups.find((g) => g.key === chartType)?.sum || 0,
          [STATS_FILTER_LETZTE3WOCHEN.key]: stats[STATS_FILTER_LETZTE3WOCHEN.label].groups.find((g) => g.key === chartType)?.sum || 0,
        },
        data: {
          [STATS_FILTER_AKTUELLEWOCHE.key]: displayData(stats[STATS_FILTER_AKTUELLEWOCHE.label].groups.find((g) => g.key === chartType)),
          [STATS_FILTER_GESAMT.key]: displayData(stats[STATS_FILTER_GESAMT.label].groups.find((g) => g.key === chartType)),
          [STATS_FILTER_LETZTE3WOCHEN.key]: displayData(stats[STATS_FILTER_LETZTE3WOCHEN.label].groups.find((g) => g.key === chartType)),
        },
        legend: createLegend(stats, chartType),
      };
      if (chartType === 'clicks') {
        setInseratKlicksData(data);
      } else if (chartType === 'visits') {
        setBesichtigungenData(data);
      } else if (chartType === 'offers') {
        setVerschickteAngeboteData(data);
      }
    });
  };

  const displayData = (stats) => {
    let possibleDates = {};
    stats.types?.forEach((type) => {
      if (type.values?.length > 0) {
        type.values.forEach((value) => {
          if (!possibleDates[value.date]) {
            possibleDates[value.date] = value.value;
          } else {
            possibleDates[value.date] += value.value;
          }
        });
      }
    });

    return {
      labels: generateDateLabels(possibleDates),
      datasets: [
        {
          data: Object.values(possibleDates),
          ...lineChartDatasetSettings,
        },
      ],
    };
  };

  const generateDateLabels = (possibleDates) => {
    const datesArray = Object.keys(possibleDates).map((date) => moment(date));
    const sortedDates = datesArray.sort((a, b) => a - b);

    return sortedDates.map((date) => date.locale('de').format('DD. MMM'));
  };

  const createLegend = (stats, chartType) => {
    let legend = [];
    Object.entries(stats).forEach(([key, value]) => {
      value.groups.forEach((group) => {
        if (group.key === chartType) {
          group.types.forEach((type) => {
            if (!legend.find((l) => l.source === type.displayName)) legend.push({ source: type.displayName, [chartType]: {} });
          });
        }
      });
    });

    Object.entries(stats).forEach(([key, value]) => {
      if (key === STATS_FILTER_AKTUELLEWOCHE.label) {
        value.groups.forEach((group) => {
          if (group.key === chartType) {
            group.types.forEach((type) => {
              if (legend.find((l) => l.source === type.displayName)) {
                legend.find((l) => l.source === type.displayName)[chartType][STATS_FILTER_AKTUELLEWOCHE.key] = type.sum;
              }
            });
          }
        });
      } else if (key === STATS_FILTER_GESAMT.label) {
        value.groups.forEach((group) => {
          if (group.key === chartType) {
            group.types.forEach((type) => {
              if (legend.find((l) => l.source === type.displayName)) {
                legend.find((l) => l.source === type.displayName)[chartType][STATS_FILTER_GESAMT.key] = type.sum;
              }
            });
          }
        });
      } else if (key === STATS_FILTER_LETZTE3WOCHEN.label) {
        value.groups.forEach((group) => {
          if (group.key === chartType) {
            group.types.forEach((type) => {
              if (legend.find((l) => l.source === type.displayName)) {
                legend.find((l) => l.source === type.displayName)[chartType][STATS_FILTER_LETZTE3WOCHEN.key] = type.sum;
              }
            });
          }
        });
      }
    });

    if (chartType === 'clicks') {
      // Group willhaben together
      legend.push({
        source: 'willhaben',
        [chartType]: {
          [STATS_FILTER_AKTUELLEWOCHE.key]: 0,
          [STATS_FILTER_GESAMT.key]: 0,
          [STATS_FILTER_LETZTE3WOCHEN.key]: 0,
        },
      });

      legend.forEach((l) => {
        if (l.source.includes('willhaben') && l.source !== 'willhaben') {
          legend.find((l) => l.source === 'willhaben')[chartType][STATS_FILTER_AKTUELLEWOCHE.key] += l[chartType][STATS_FILTER_AKTUELLEWOCHE.key];
          legend.find((l) => l.source === 'willhaben')[chartType][STATS_FILTER_GESAMT.key] += l[chartType][STATS_FILTER_GESAMT.key];
          legend.find((l) => l.source === 'willhaben')[chartType][STATS_FILTER_LETZTE3WOCHEN.key] += l[chartType][STATS_FILTER_LETZTE3WOCHEN.key];
        }
      });

      legend = legend.filter((l) => !(l.source.includes('willhaben') && l.source !== 'willhaben'));

      legend = legend.filter((l) => !Object.values(l?.clicks)?.every((val) => val === 0));
    }
    return legend;
  };

  const resetData = () => {
    setBesichtigungenData([]);
    setVerschickteAngeboteData([]);
    setInseratKlicksData([]);
  };

  return {
    getData,
    fetchRealEstates,
    inseratKlicksData,
    verschickteAngeboteData,
    besichtigungenData,
  };
};
