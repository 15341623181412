import { useDispatch } from 'react-redux';
import IconChatHerz from '../Icons/IconChatHerz';
import Card from './Card';
import PageDialogEmpfehlung from '../Dialogs/PageDialogEmpfehlung';
import { useEffect, useState } from 'react';

function RecommendationCard({ cssClasses }) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const handleSetOpen = () => {
    let val = !open;
    setOpen(val);

    // if pageDialog closed - set cookie
    if (val === false) {
      localStorage.setItem('mysreal-recommendation-already-opened', true);
    }
  };

  useEffect(() => {
    if (
      !localStorage.getItem('mysreal-recommendation-already-opened') ||
      localStorage.getItem('mysreal-recommendation-already-opened') === false
    ) {
      setOpen(true);
    }
  }, []);

  cssClasses += ' recommendation-card';

  return (
    <>
      <Card cardStyle={'customContent'} cssClasses={cssClasses}>
        <div className="card__content">
          <h2>Weiterempfehlung</h2>
          <p>
            Jetzt s REAL weiterempfehlen und profitieren. Bei erfolgreicher Empfehlung erhalten Sie
            einen Wunschgutschein.
          </p>
          <div className="d-flex w-100 pt-100rem align-items-end justify-content-between">
            <span className="textlink nobreak" onClick={handleSetOpen}>
              s REAL empfehlen
            </span>
            <IconChatHerz color="#fff" cssClasses={'pl-200rem'} />
          </div>
        </div>
      </Card>
      <PageDialogEmpfehlung open={open} handleClose={handleSetOpen} />
    </>
  );
}

export default RecommendationCard;
