function IconKAnEinemOrt({ color, cssClasses }) {
  return (
    <svg
      className={cssClasses}
      xmlns="http://www.w3.org/2000/svg"
      width="27.301"
      height="36.402"
      viewBox="0 0 27.301 36.402"
    >
      <path
        id="Pfad_8404"
        data-name="Pfad 8404"
        d="M573.894,2748.336H548.109a.758.758,0,0,0-.758.758v34.886a.756.756,0,0,0,.394.666.764.764,0,0,0,.775-.029L561,2776.542l12.478,8.076a.76.76,0,0,0,.775.029.759.759,0,0,0,.4-.666v-34.886A.76.76,0,0,0,573.894,2748.336Zm-4.066,12.718-4.185,3.486,1.4,5.606a.757.757,0,0,1-1.176.8L561,2767.468l-4.869,3.477a.758.758,0,0,1-1.177-.8l1.4-5.606-4.185-3.486a.759.759,0,0,1,.487-1.342h5.566l2.078-4.849a.758.758,0,0,1,1.394,0l2.078,4.849h5.568a.759.759,0,0,1,.484,1.342Z"
        transform="translate(-547.352 -2748.336)"
        fill={color}
      />
    </svg>
  );
}

export default IconKAnEinemOrt;
