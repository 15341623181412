function IconVKTransparent({ color, cssClasses }) {
  return (
    <svg
      className={cssClasses}
      id="Gruppe_13379"
      data-name="Gruppe 13379"
      xmlns="http://www.w3.org/2000/svg"
      width="48.717"
      height="28.418"
      viewBox="0 0 48.717 28.418"
    >
      <path
        id="Pfad_8445"
        data-name="Pfad 8445"
        d="M268.347,410.059a7.1,7.1,0,1,0-7.1-7.1A7.114,7.114,0,0,0,268.347,410.059Zm-4.06-8.119a1.014,1.014,0,0,1,1.015,1.015,3.045,3.045,0,1,0,3.045-3.045,1.015,1.015,0,1,1,0-2.03,5.075,5.075,0,1,1-5.075,5.075A1.014,1.014,0,0,1,264.288,401.939Z"
        transform="translate(-243.988 -388.746)"
        fill={color}
      />
      <path
        id="Pfad_8446"
        data-name="Pfad 8446"
        d="M252.963,407.186c.438.554,10.908,13.582,24.139,13.582s23.7-13.028,24.139-13.582a1.018,1.018,0,0,0,0-1.258c-.44-.556-10.908-13.578-24.139-13.578s-23.7,13.021-24.139,13.578A1.007,1.007,0,0,0,252.963,407.186Zm24.139-9.762a9.134,9.134,0,1,1-9.134,9.134A9.143,9.143,0,0,1,277.1,397.425Z"
        transform="translate(-252.742 -392.35)"
        fill={color}
      />
    </svg>
  );
}

export default IconVKTransparent;
