function IconBarchartWithRoof({ color, cssClasses }) {
  return (
    <svg
      className={cssClasses}
      xmlns="http://www.w3.org/2000/svg"
      width="107.576"
      height="81.204"
      viewBox="0 0 107.576 81.204"
    >
      <g data-name="Gruppe 10157" transform="translate(2 2.772)">
        <path
          data-name="Pfad 5"
          d="M22.492,13.893l-.168-15.5L37.771-1.68M89.322,4.794,69.555-8.327M.512,37.5,69.555-8.327M70.9,10.822H59.4V68.1H70.9ZM47.89,26.1H36.384V68.1H47.89ZM24.877,41.369H13.371V68.1H24.877ZM1.862,56.646H-9.644V68.1H1.862ZM-13.481,68.1H74.742"
          transform="translate(13.481 8.327)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
        />
      </g>
    </svg>
  );
}

export default IconBarchartWithRoof;
