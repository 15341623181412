import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ACTIVITY_BESICHTIGUNG_ID,
  ACTIVITY_EMAIL_ID,
  MENU_KAUF,
  MENU_VERKAUF,
  ACTIVITY_OBJEKTSTATUSAENDERUNG_VERMITTELT_VERKAUFT_ID,
  ACTIVITY_OBJEKTSTATUSAENDERUNG_VERMITTELT_VERMIETET_ID,
  AKTUELLES_FILTER_BESICHTIGUNGEN,
  AKTUELLES_FILTER_WEITERE,
  AKTUELLES_FILTER_DOKUMENTE,
  PHASE1_VERKAUF,
} from '../../constants';
import { useFetchSearchprofiles } from '../searchprofiles/useFetchSearchprofiles';
import { setUnreadBesichtigungen, setUnreadWeitere } from '../../reducers/notifications';

export const useHandleUserRealtyActive = (loadData = true) => {
  const user = useSelector((state) => state.user);
  const app = useSelector((state) => state.app);

  const dispatch = useDispatch();

  const [besichtigungen, setBesichtigungen] = useState([]);
  const [besichtigungenLoaded, setBesichtigungenLoaded] = useState(false);

  const [weitere, setWeitere] = useState([]);
  const [weitereLoaded, setWeitereLoaded] = useState(false);

  const [files, setFiles] = useState([]);
  const [filesLoaded, setFilesLoaded] = useState(false);

  const { getSearchprofilesMenu } = useFetchSearchprofiles();

  useEffect(() => {
    loadAllRealtyData();
  }, []);

  function loadAllRealtyData() {
    if(loadData) {
      getBesichtigungen();
      getWeitere();
      getDocuments();
    }
  }

  function getBesichtigungen() {
	if(app.menuId === PHASE1_VERKAUF) {
		setBesichtigungenLoaded(true);
		return;
	}

    // Abgeber: check besichtigungen of userRealtyActive
    if (app.menuType === MENU_VERKAUF) {
      if (user.userRealtyActive?.id && user.userRealtyActive?.id > -1) {
        if(user.userRealtyActive.activities?.length > 0) {
          let activities = renderBesichtigungActivities(user.userRealtyActive.activities).filter((a) => a !== undefined);
          setBesichtigungen(activities);
        }
        setBesichtigungenLoaded(true);
      }
      else {
        setBesichtigungenLoaded(true);
      }
    } else if (app.menuType === MENU_KAUF) {
      const kaeuferImmos = getKaeuferRealties();

      if (kaeuferImmos.length > 0) {
        let activities = kaeuferImmos.map((immo) => {
            return renderBesichtigungActivities(immo.activities, immo);
          })
          .filter((a) => a !== undefined)
          .flat();

        setBesichtigungen(activities);
        setBesichtigungenLoaded(true);
      }
    }
  }

  function renderBesichtigungActivities(activities, immo) {
    if (!activities || activities.length === 0) return [];

    let activitiesB = activities
      .flat()
      .filter((a) => a.id == ACTIVITY_BESICHTIGUNG_ID)
      .map((a) => {
        let text = '';
        if (a.value?.Comment) {
          text = a.value.Comment;
        } else if (a.value?.Offertext) {
          text = a.value.Offertext;
        }

        return {
          ...a,
          immo: immo,
          text: text,
          filter_id: AKTUELLES_FILTER_BESICHTIGUNGEN
        };
      });

    return activitiesB;
  }

  /**
   * get searchprofile hits and kaufanbot list (saved in searchprofilesMenu)
   * @returns Array
   */
  const getKaeuferRealties = () => {
    let kaeuferImmos = [];
    let menuSP = getSearchprofilesMenu();

    if (menuSP) {
      kaeuferImmos = menuSP
        .filter((m) => m.immos && m.immos.length > 0 && m.label === 'Alle')
        .map((m) => m.immos)
        .flat();
    }
    return kaeuferImmos;
  };

  /**
   * get all activities except besichtigungen, vermittelt + email anbot
   * @returns Array
   */
  function getWeitere() {
	if(app.menuId === PHASE1_VERKAUF) {
		setWeitereLoaded(true);
		return;
	}

    if (app.menuType === MENU_KAUF) {
      setWeitereLoaded(true);
    } else if(app.menuType === MENU_VERKAUF) {
      if (user.userRealtyActive?.id && user.userRealtyActive?.id > -1) {
        if(user.userRealtyActive.activities?.length > 0) {
          let activities = user.userRealtyActive.activities
          .flat()
          .filter(
            (a) =>
            a.id != ACTIVITY_BESICHTIGUNG_ID &&
            a.id != ACTIVITY_OBJEKTSTATUSAENDERUNG_VERMITTELT_VERKAUFT_ID &&
            a.id != ACTIVITY_OBJEKTSTATUSAENDERUNG_VERMITTELT_VERMIETET_ID &&
            a.id != ACTIVITY_EMAIL_ID,
            )
            .map((a) => {
              let text = '';
              if (a.value?.Comment) {
                text = a.value.Comment;
              } else if (a.value?.Offertext) {
                text = a.value.Offertext;
              }
              
              return {
                ...a,
                text: text,
                filter_id: AKTUELLES_FILTER_WEITERE
              };
          });
            
          setWeitere(activities);
        }
      }
      
      setWeitereLoaded(true);
    }
  }

  /**
   * set private documents of current realty
   */
  function getDocuments() {
	if(app.menuId === PHASE1_VERKAUF) {
		setFilesLoaded(true);
		return;
	}

    if (app.menuType === MENU_KAUF) {
      let kaeuferPhase3Immo = getKaeuferImmoPhase3();
      if (kaeuferPhase3Immo && kaeuferPhase3Immo.id) {
        let filesArray = kaeuferPhase3Immo.documentsPrivate.map((file) => {
          return {
            ...file,
            filter_id: AKTUELLES_FILTER_DOKUMENTE,
            dateFrom: file.uploaded_at
          };
        });
        setFiles(filesArray);
      }
    } else {
      if (user.userRealtyActive?.id && user.userRealtyActive?.id > -1 && user.userRealtyActive.documentsPrivate?.length > 0) {
        let filesArray = user.userRealtyActive.documentsPrivate.map((file) => {
          return {
            ...file,
            filter_id: AKTUELLES_FILTER_DOKUMENTE,
            dateFrom: file.uploaded_at
          };
        });

        setFiles(filesArray);
      }
    }

    setFilesLoaded(true);
  }

  /**
   * get käufer realty from offers that has phase 3
   * @returns Object|null
   */
  function getKaeuferImmoPhase3() {
    let kaeuferPhase3Immo = null;
    const kaeuferImmos = getKaeuferRealties();

    if (kaeuferImmos && kaeuferImmos.length > 0) {
      let kaeuferImmosIds = kaeuferImmos.map((k) => k.id);
      let phase3Immo = user.userObject?.realtyPhases?.find((p) => kaeuferImmosIds?.includes(p?.realtyId) && p?.phase === 3);

      if (phase3Immo?.realtyId) {
        kaeuferPhase3Immo = kaeuferImmos.find((immo) => immo.id === phase3Immo.realtyId);
      }
    }
    return kaeuferPhase3Immo;
  }

  function reloadData() {
    setBesichtigungenLoaded(false);
    setWeitereLoaded(false);
    setFilesLoaded(false);

    setTimeout(() => {
      loadAllRealtyData();
    }, 200);
  }


  /* get count of all besichtigungen unread */
  function getBesichtigungenUnreadCount(idsArray = null) {
    if(idsArray === null) {
      idsArray = user.userObject?.realtyActivitiesSeen?.split(';');
    }
    const besichtigungenSeen = idsArray.filter((b) => b.includes('b-'));
    const besUnread = besichtigungen.length - besichtigungenSeen.length;
    dispatch(setUnreadBesichtigungen(besUnread)); // count
    return besUnread;
  }

  /* get count of all besichtigungen unread */
  function getWeitereUnreadCount(idsArray = null) {
    if(idsArray === null) {
      idsArray = user.userObject?.realtyActivitiesSeen?.split(';');
    }
    const weitereSeen = idsArray.filter((w) => w.includes('w-'));
    const weitereUnread = weitere.length - weitereSeen.length;
    dispatch(setUnreadWeitere(weitereUnread)); // count
    return weitereUnread;
  }

  useEffect(() => {
    if (!besichtigungenLoaded && !weitereLoaded && !filesLoaded) {
      loadAllRealtyData();
    }
  }, [besichtigungenLoaded, weitereLoaded, filesLoaded]);

  return {
    besichtigungen,
    besichtigungenLoaded,
    weitere,
    weitereLoaded,
    files,
    filesLoaded,
    getKaeuferRealties,
    getKaeuferImmoPhase3,
    reloadData,
    getBesichtigungenUnreadCount,
    getWeitereUnreadCount
  };
};
