import { useNavigate } from 'react-router-dom';

// ICONS
import IconArrowRight from '../Icons/IconArrowRight';
import IconArrowRight2 from '../Icons/IconArrowRight2';

import { useSelector } from 'react-redux';
import { useHandleScreenResize } from '../../hooks/app/useHandleScreenResize';
import { useFetchNews } from '../../hooks/news/useFetchNews';
import NewsCards from '../Cards/NewsCards';

function NewsTeaserCards({ cssClasses }) {
  let navigate = useNavigate();
  const erste_darkblue = '#1E3163';
  const app = useSelector((state) => state.app);
  const { news } = useFetchNews();

  const navigateToNews = () => {
    navigate(app.root + '/news');
  };

  if (!cssClasses) cssClasses = '';

  const { isMobile } = useHandleScreenResize();

  return (
    news?.length > 0 && (
      <div className={cssClasses}>
        {/* News Headline with Link mobile+desktop */}
        <div className={`${!isMobile ? 'w-desktop--center pt-0' : ''}`}>
          <h2 className="mb-200rem mt-0">News</h2>
        </div>

        {!isMobile ? (
          <NewsCards news={news.slice(0, 6)} carousel={true} />
        ) : (
          <>
            <NewsCards news={news.slice(0, 6)} />
            <span onClick={navigateToNews} className="textlink w-100 d-flex flex-col align-items-center p-100rem mb-100rem">
              Alle News anzeigen
            </span>
          </>
        )}
      </div>
    )
  );
}

export default NewsTeaserCards;
