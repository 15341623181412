import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { JUSTIMMO_IMMOBILIENART_GRUNDSTUECK, JUSTIMMO_IMMOBILIENART_HAUS, JUSTIMMO_IMMOBILIENART_WOHNUNG, WOHNWERTLIGHT_SITEINDEX_RESULT } from '../../constants';
import { getKaufpreis, getMainArea } from '../../util/immoObjectParser';
import { bitteWaehlenOption } from './util/formOptions';

// ICONS
import IconClose from '../Icons/IconClose';

import {
  setEstateTypeSPForm,
  setExteriorSurfaceSPForm,
  setFederalStateSPForm,
  setLivingAreaFromSPForm,
  setLivingAreaToSPForm,
  setRoomsFromSPForm,
  setSiteAreaFromSPForm,
  setSiteAreaToSPForm,
  setPhoneNumberPrefixSPForm,
  setPhoneSPForm,
} from '../../reducers/searchProfileForm';

import {
  setDisplayedDistrictNameContactForm,
  setPostalCodeContactForm,
  setReasonContactForm,
  setStateContactForm,
  setTypeContactForm,
  setPhoneNumberPrefixContactForm,
  setPhoneNumberContactForm,
} from '../../reducers/contactInformation';

import { setEstateTypeSearchForm, setFeaturesSearchForm, setRealtySubTypeOptions, setSortingSearchForm, setSubEstateTypeSearchForm } from '../../reducers/searchForm';

import { setWunschimmobilieKaufanbotForm } from '../../reducers/kaufAnbote';
import { setMarktinfoResultActive, setUserRealtyActive, setWohnwertResultsActive } from '../../reducers/user';

import { setDistrictFinanzForm, setFederalStateFinanzForm } from '../../reducers/finanzierungRechner';

import { setMarktinfoLightTown } from '../../reducers/marktinfoLight';

import { setSiteIndexWohnwertForm, setWohnwertLightDistrict, setWohnwertLightFederalState, setWohnwertLightRealEstateType, setWohnwertLightTypedPostCode } from '../../reducers/wohnwertLight';

import { setPhoneNumberPrefixRegisterForm, setPhoneNumberRegisterForm } from '../../reducers/registerForm';

import { setPhoneNumberPrefixObjectRequest, setPhoneObjectRequest } from '../../reducers/objectRequest';

import { Chip, FormControl, ListSubheader } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { getRealtySubTypes } from '../../api/Sreal';
import { setChangedParsedPhoneNumberPrefix, setChangedParsedPhoneNumberPrefixCheck } from '../../reducers/registerFormChangePhoneNumber';

function FormSelect({
  label,
  id,
  showBitteWaehlen = false,
  placeholder,
  options,
  disabled,
  value = bitteWaehlenOption,
  hasLockIcon,
  formType,
  required,
  error,
  errorTextWhite,
  cssClasses,
  multiple = false,
}) {
  const [showResetIcon, setShowResetIcon] = useState(false);
  const [open, setOpen] = useState(false);
  const openRef = useRef(open);
  if (!cssClasses) cssClasses = '';
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentSearchProfileForm = useSelector((state) => state.searchProfileForm);
  const selectRef = useRef(null);

  let currentSearchForm = useSelector((state) => state.searchForm);
  let app = useSelector((state) => state.app);

  const parseHTML = require('html-react-parser');

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    openRef.current = open;
  }, [open]);

  useEffect(() => {
    const handleScroll = () => {
      if (openRef.current) handleClose();
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (!hasLockIcon && !disabled && value !== bitteWaehlenOption && id !== 'inputSorting' && Object.keys(value).length > 0) {
      setShowResetIcon(true);
    }
  }, []);

  useEffect(() => {
    if (Object.keys(value).length > 0 && value !== bitteWaehlenOption) {
      setShowResetIcon(true);
    } else {
      setShowResetIcon(false);
    }
  }, [value]);

  useEffect(() => {
    if (formType === 'searchProfileForm') {
      if (id === 'inputExteriorSurface') {
        const enabledExteriorSurfaceOptions = [];
        if (currentSearchProfileForm.requestBody.balcony.enabled) {
          enabledExteriorSurfaceOptions.push(options.find((o) => o.id === 'balcony'));
        }
        if (currentSearchProfileForm.requestBody.garden.enabled) {
          enabledExteriorSurfaceOptions.push(options.find((o) => o.id === 'garden'));
        }
        if (currentSearchProfileForm.requestBody.terrace.enabled) {
          enabledExteriorSurfaceOptions.push(options.find((o) => o.id === 'terrace'));
        }
        if (currentSearchProfileForm.requestBody.covered_balcony.enabled) {
          enabledExteriorSurfaceOptions.push(options.find((o) => o.id === 'covered_balcony'));
        }
        handleChange(enabledExteriorSurfaceOptions);
      }
    }
  }, [
    currentSearchProfileForm.requestBody.balcony.enabled,
    currentSearchProfileForm.requestBody.garden.enabled,
    currentSearchProfileForm.requestBody.terrace.enabled,
    currentSearchProfileForm.requestBody.covered_balcony.enabled,
  ]);

  const handleReset = () => {
    if (multiple) {
      handleChange([]);
    } else {
      handleChange('');
    }
  };

  const handleDelete = (val) => {
    let filteredValue = value.filter((v) => v.id !== val.id);
    handleChange(filteredValue);
  };

  const handleChange = async (val) => {
    if (formType === 'searchProfileForm') {
      switch (id) {
        case 'inputFederalState':
          dispatch(setFederalStateSPForm(val));
          break;
        case 'inputEstateType':
          dispatch(setEstateTypeSPForm(val));
          // switch area types according to estate type
          let areaFrom = currentSearchProfileForm.requestBody.living_area.from || currentSearchProfileForm.requestBody.site_area.from;
          let areaTo = currentSearchProfileForm.requestBody.living_area.to || currentSearchProfileForm.requestBody.site_area.to;
          if (val?.id === JUSTIMMO_IMMOBILIENART_WOHNUNG || val?.id === JUSTIMMO_IMMOBILIENART_HAUS) {
            dispatch(setLivingAreaFromSPForm(areaFrom));
            dispatch(setLivingAreaToSPForm(areaTo));

            dispatch(setSiteAreaFromSPForm(''));
            dispatch(setSiteAreaToSPForm(''));
          } else if (val?.id === JUSTIMMO_IMMOBILIENART_GRUNDSTUECK) {
            dispatch(setSiteAreaFromSPForm(areaFrom));
            dispatch(setSiteAreaToSPForm(areaTo));

            dispatch(setLivingAreaFromSPForm(''));
            dispatch(setLivingAreaToSPForm(''));
            dispatch(setRoomsFromSPForm(''));
            dispatch(setExteriorSurfaceSPForm([]));
          }
          break;
        // case 'inputFeatures':
        //   dispatch(setFeaturesSPForm(val));
        //   break;
        case 'inputExteriorSurface':
          dispatch(setExteriorSurfaceSPForm(val));
          break;
        case 'selectPhoneNumberPrefix':
          dispatch(setPhoneNumberPrefixSPForm(val));
          dispatch(setPhoneSPForm({ ...val, text: val.text?.substring(0, val.text.indexOf(' ')) }));
          break;
        default:
          break;
      }
    } else if (formType === 'searchForm') {
      switch (id) {
        case 'inputEstateType':
          dispatch(setEstateTypeSearchForm(val));

          if (val.length > 0) {
            const subTypes = await getRealtySubTypes(val.map((val) => val.id));

            if (subTypes.length > 0) {
              dispatch(setRealtySubTypeOptions(subTypes));

              // check if subtype still valid
              if (currentSearchForm.requestBody.subEstateType.length > 0) {
                const newSubtypes = currentSearchForm.requestBody.subEstateType
                  .map((sub) => {
                    return subTypes.find((option) => sub.id === option.id);
                  })
                  .filter((newSub) => newSub !== undefined);

                dispatch(setSubEstateTypeSearchForm(newSubtypes));
              }
            } else {
              dispatch(setRealtySubTypeOptions([{ id: -1, text: 'Keine Ergebnisse gefunden' }]));
              dispatch(setSubEstateTypeSearchForm([]));
            }
          } else {
            dispatch(setRealtySubTypeOptions([]));
            dispatch(setSubEstateTypeSearchForm([]));
          }
          break;
        case 'inputSubEstateType':
          dispatch(setSubEstateTypeSearchForm(val));
          break;
        case 'inputFeatures':
          dispatch(setFeaturesSearchForm(val));
          break;
        case 'inputSorting':
          dispatch(setSortingSearchForm(val));
          break;
        default:
          break;
      }
    } else if (formType === 'contactInformation') {
      switch (id) {
        case 'inputReason':
          dispatch(setReasonContactForm(val));
          break;
        case 'inputState':
          dispatch(setStateContactForm(val));
          dispatch(setDisplayedDistrictNameContactForm(''));
          dispatch(setPostalCodeContactForm(''));
          break;
        case 'inputType':
          dispatch(setTypeContactForm(val));
          break;
        case 'selectPhoneNumberPrefix':
          dispatch(setPhoneNumberPrefixContactForm(val));
          dispatch(setPhoneNumberContactForm({ ...val, text: val.text?.substring(0, val.text.indexOf(' ')) }));
          break;
        default:
          break;
      }
    } else if (formType === 'userRealties') {
      switch (id) {
        case 'userRealties':
          const immo = val.immo;

          dispatch(setUserRealtyActive(val.immo));

          if (immo.url) {
            navigate(app.root + '/immobilie/' + immo.url);
          }
          break;
        default:
          break;
      }
    } else if (formType === 'finanzierungsCalc') {
      switch (id) {
        case 'inputFederalState':
          dispatch(setFederalStateFinanzForm(val));
          dispatch(setDistrictFinanzForm(bitteWaehlenOption));
          break;
        case 'inputDistrict':
          dispatch(setDistrictFinanzForm(val));
          break;
        default:
          break;
      }
    } else if (formType === 'marktinfoLight') {
      switch (id) {
        case 'inputTown':
          dispatch(setMarktinfoLightTown(val));
          break;
        case 'previous_calculations':
          dispatch(setMarktinfoResultActive(val));
          break;
        default:
          break;
      }
    } else if (formType === 'wohnwertLight') {
      switch (id) {
        case 'inputFederalState':
          dispatch(setWohnwertLightFederalState(val));
          dispatch(setWohnwertLightDistrict({}));
          dispatch(setWohnwertLightTypedPostCode(''));
          break;
        case 'realEstateType':
          dispatch(setWohnwertLightRealEstateType(val));
          break;
        case 'previous_calculations':
          dispatch(setSiteIndexWohnwertForm(WOHNWERTLIGHT_SITEINDEX_RESULT));
          dispatch(setWohnwertResultsActive(val));
          break;
        default:
          break;
      }
    } else if (formType === 'wunschimmobilie') {
      switch (id) {
        case 'inputWunschimmobilie':
          if (val.immo) {
            let newWunschImmo = val.immo;
            let newWunschImmoArea = getMainArea(newWunschImmo);
            let newWunschImmoAddressLine = newWunschImmo.address.zip + ' ' + newWunschImmo.address.city;

            if (newWunschImmoArea) {
              newWunschImmoAddressLine += ', ' + newWunschImmoArea;
            }

            if (newWunschImmo.rooms > 0) {
              newWunschImmoAddressLine += ', ' + newWunschImmo.rooms?.toString().replace('.', ',') + ' Zimmer';
            }

            let wunschImmo = {
              id: newWunschImmo.id,
              addressLine: parseHTML(newWunschImmoAddressLine),
              kaufpreis: getKaufpreis(newWunschImmo),
              url: newWunschImmo.url,
              title: newWunschImmo.title,
            };

            let newVal = {
              ...val,
              wunschImmo: wunschImmo,
            };

            dispatch(setWunschimmobilieKaufanbotForm(newVal));
          } else {
            dispatch(setWunschimmobilieKaufanbotForm({}));
          }
          break;
        default:
          break;
      }
    } else if (formType === 'registerForm') {
      switch (id) {
        case 'selectPhoneNumberPrefix':
          dispatch(setPhoneNumberPrefixRegisterForm(val));
          dispatch(setPhoneNumberRegisterForm({ ...val, text: val.text.substring(0, val.text.indexOf(' ')) }));
          break;
        default:
          break;
      }
    } else if (formType === 'registerFormChangePhoneNumber') {
      switch (id) {
        case 'selectPhoneNumberPrefix':
          dispatch(setChangedParsedPhoneNumberPrefix(val));
          break;
        case 'selectPhoneNumberPrefixCheck':
          dispatch(setChangedParsedPhoneNumberPrefixCheck(val));
          break;
        default:
          break;
      }
    } else if (formType === 'objectRequestForm') {
      switch (id) {
        case 'selectPhoneNumberPrefix':
          dispatch(setPhoneNumberPrefixObjectRequest(val));
          dispatch(setPhoneObjectRequest({ ...val, text: val.text?.substring(0, val.text.indexOf(' ')) }));
          break;
        default:
          break;
      }
    }
  };

  let selectOptions = options;
  if (showBitteWaehlen) {
    if (typeof showBitteWaehlen === 'object') {
      selectOptions = [showBitteWaehlen, ...options];
    } else {
      selectOptions = [bitteWaehlenOption, ...options];
    }
  }

  if (!placeholder) placeholder = label;

  return (
    <div className={`${cssClasses} form__field`}>
      <InputLabel htmlFor={id}>
        {label} {!!required ? '*' : ''}
      </InputLabel>

      <div className={`select-wrapper ${hasLockIcon && disabled ? 'locked' : ''}`}>
        <Select
          ref={selectRef}
          className={`select 
            ${multiple ? 'select-multiple' : ''} 
            ${multiple && value.length > 0 ? 'select-multiple--withChips' : ''}
            ${showResetIcon ? 'formfield--withreseticon' : ''}`}
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
          name={id}
          id={id}
          multiple={multiple}
          onChange={(e) => handleChange(e.target.value)}
          renderValue={(selected) => {
            if (multiple && selected && selected.length > 0) {
              return selected.map((option) => {
                return (
                  <Chip
                    key={option.id}
                    label={option.text}
                    variant="outlined"
                    deleteIcon={
                      <span onMouseDown={(event) => event.stopPropagation()} className="select-multiple__deleteicon">
                        <IconClose color="#3679ec" />
                      </span>
                    }
                    onDelete={(e) => handleDelete(option)}
                  />
                );
              });
            } else if (selected && selected.text) {
              return selected.text;
            }
            return placeholder;
          }}
          value={value}
          disabled={disabled}
          displayEmpty={true}
          inputProps={{ MenuProps: { disableScrollLock: true } }}
        >
          {selectOptions.map((option, index) => {
            if (option.optgroup) {
              return <ListSubheader key={index}>{option.text}</ListSubheader>;
            } else {
              return (
                <MenuItem value={option} key={option.id + '-' + index} className={multiple && value.length > 0 && value.find((v) => v.id === option.id) && 'Mui-selected'}>
                  {id === 'selectPhoneNumberPrefix' ? (
                    // Custom styling um die Vorwahlen schöner darzustellen
                    <div className="select__phoneprefix">
                      <span>{option.text.match(/\+\d+/)[0]}</span>
                      <span>{option.text.match(/\(.*\)/)[0]}</span>
                    </div>
                  ) : (
                    option.text
                  )}
                </MenuItem>
              );
            }
          })}
        </Select>

        {showResetIcon && (
          <span className="form__field--reset" onClick={handleReset}>
            <IconClose color={'#313a45'} />
          </span>
        )}
      </div>

      {error && <p className={!errorTextWhite ? 'form__fielderror' : 'text-white'}>{error}</p>}
    </div>
  );
}

export default FormSelect;
