function IconEmailSent({ cssClasses, color }) {
  return (
    <svg
      className={cssClasses}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="112"
      height="134"
      viewBox="0 0 112 134"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            data-name="Rechteck 3858"
            width="112"
            height="134"
            transform="translate(0)"
            fill="none"
          />
        </clipPath>
      </defs>
      <g data-name="Gruppe 11288" transform="translate(0)" clipPath="url(#clip-path)">
        <path
          data-name="Pfad 7954"
          d="M110.365,11.974,57.736,53.52a2.806,2.806,0,0,1-3.466,0L1.635,11.974A11.116,11.116,0,0,0,0,17.72v50.4a11.215,11.215,0,0,0,11.2,11.2h89.6A11.215,11.215,0,0,0,112,68.12V17.72a11.116,11.116,0,0,0-1.635-5.746"
          transform="translate(0 55.08)"
          fill={color}
        />
        <path
          data-name="Pfad 7955"
          d="M101.647,12.518A11.009,11.009,0,0,0,96.131,11H6.531a11.009,11.009,0,0,0-5.516,1.518L51.331,52.238Z"
          transform="translate(4.669 50.6)"
          fill={color}
        />
        <path
          data-name="Pfad 7956"
          d="M30.519,52.382a2.824,2.824,0,0,0,.913.6,2.78,2.78,0,0,0,2.134,0,2.824,2.824,0,0,0,.913-.6l25.2-25.2a2.8,2.8,0,1,0-3.959-3.959L35.3,43.641V2.8a2.8,2.8,0,1,0-5.6,0V43.641L9.278,23.223a2.8,2.8,0,1,0-3.959,3.959Z"
          transform="translate(20.699)"
          fill={color}
        />
      </g>
    </svg>
  );
}

export default IconEmailSent;
