import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import ImmoCard from '../Cards/ImmoCard';
import ImmoFeatures from '../Immo/ImmoFeatures';
import Card from './Card';

// ICONS
import IconHouse from '../../components/Icons/IconHouse';

// API

// STORE

import { useHandleScreenResize } from '../../hooks/app/useHandleScreenResize';
import { getKaufpreis } from '../../util/immoObjectParser';

function ImmoCardAbgeber({ retailObject, cssClasses }) {
  cssClasses = ' immo-card immoabgeber-card';

  const [kaufpreis, setKaufpreis] = useState('');
  const [coverImage, setCoverImage] = useState(null);

  useEffect(() => {
    let preis = getKaufpreis(retailObject);
    setKaufpreis(preis);

    if (retailObject.imagesPublic[0]?.url) {
      setCoverImage(retailObject.imagesPublic[0].url);
    }
  }, []);

  const { isMobile } = useHandleScreenResize();

  if (retailObject) {
    if (isMobile) {
      return <ImmoCard isAbgeber={true} retailObject={retailObject} />;
    } else {
      return (
        <Card cardStyle={'topImage2Cols'} cssClasses={cssClasses + ' immo-card__2col bg-white with-shadow'} imagePath={coverImage} icon={<IconHouse color="#fff" />}>
          <div className="immo-card__headline">
            <h3>{retailObject.title}</h3>
            <p>
              {retailObject.address.zip} {retailObject.address.city}
            </p>
          </div>

          <div className="immo-card__bottom w-100 mt-200rem mb-200rem">
            <ImmoFeatures immo={retailObject} showKaufpreis={true} />
          </div>

          <Link className="mt-200rem w-fit-content" to={`../immobilie/${retailObject.url}`} title={retailObject.title}>
            <button className="button--blue">Immobiliendetails anzeigen</button>
          </Link>
        </Card>
      );
    }
  } else {
    return null;
  }
}

export default ImmoCardAbgeber;
