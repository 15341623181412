import IconSearchHouse from '../../Icons/IconSearchHouse';

import { setPageDialogOpenSPForm, setResetFieldsSPForm } from '../../../reducers/searchProfileForm';
import { setResetFormValidator } from '../../../reducers/formValidators';
import { useDispatch } from 'react-redux';

function SearchProfileCreated() {
  const dispatch = useDispatch();

  const closeAction = () => {
    dispatch(setPageDialogOpenSPForm(false));
    dispatch(setResetFieldsSPForm());
    dispatch(setResetFormValidator());
  };

  return (
    <>
      <div className="d-flex w-100 justify-content-center pt-40 pb-40">
        <IconSearchHouse color="#1E3163" />
      </div>

      <h2>Ihr Suchprofil wurde erfolgreich erstellt.</h2>
      <p>
        Ihr Suchprofil wurde erfolgreich erstellt und befindet sich nun in Bearbeitung. Eine
        Immobilienexpert:in schaltet Sie in Kürze frei.
      </p>

      <div className="button-panel pt-100rem">
        <button className="button button--blue-outline" onClick={closeAction}>
          Schließen
        </button>
      </div>
    </>
  );
}

export default SearchProfileCreated;
