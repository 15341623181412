import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { getRealtyDetails, getRealtyPOICategories } from '../../api/JustImmo';
import { IMMO_STATE_INAKTIV } from '../../constants';
import { getDefaultImmoObject, parseImmo, parseInfrastructureList } from '../../util/immoObjectParser';
import { useHandleScreenResize } from '../app/useHandleScreenResize';
import { useHandleUserRealtyActive } from '../user/useHandleUserRealtyActive';
import { useHandleFavorite } from './useHandleFavorite';
import axios from 'axios';

//Util
const getIdsFromUrl = (url, app) => {
  let parsedURL = url.pathname.split('/').filter((part) => part !== '');
  let immoId = -1;
  let token = '';
  let trackingUrl = '';
  let tenantIdIndex;
  const UUID_REGEX = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/;
  const UUIDIndex = parsedURL.findIndex((element) => UUID_REGEX.test(element));
  const urlHasTrackingId = UUIDIndex !== -1 && UUIDIndex < parsedURL.length - 1;

  if (urlHasTrackingId) {
    token = parsedURL[UUIDIndex];
    trackingUrl = parsedURL[UUIDIndex + 1];
    immoId = parsedURL[UUIDIndex - 1];
    tenantIdIndex = UUIDIndex - 3;
  } else {
    if (UUIDIndex !== -1) {
      token = parsedURL[UUIDIndex];
      immoId = parsedURL[UUIDIndex - 1];
      tenantIdIndex = UUIDIndex - 3;
    } else {
      immoId = parsedURL[parsedURL.length - 1];
      tenantIdIndex = parsedURL.length - 3;
    }
  }

  let tenantId = -1;
  if (tenantIdIndex >= 0 && parsedURL[tenantIdIndex]) {
    let urlIds = parsedURL[tenantIdIndex];
    if (urlIds.split('-') && urlIds.split('-').length > 0) {
      tenantId = urlIds.split('-')[0];
    }
  }

  // Check if ids are valid
  if (parseInt(immoId) <= 0 || isNaN(immoId) || parseInt(tenantId) <= 0 || isNaN(tenantId)) {
    window.location = app.root + '/404';
  }

  return { immoId, tenantId, token, trackingUrl };
};

export const useFetchImmo = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const user = useSelector((state) => state.user);
  const app = useSelector((state) => state.app);
  const [immo, setImmo] = useState(getDefaultImmoObject());
  const [showFullDesc, setShowFullDesc] = useState(false);
  const [showPrivate, setShowPrivate] = useState(false);
  const [showFullAddress, setShowFullAddress] = useState(false);
  const { immoId, tenantId, token, trackingUrl } = getIdsFromUrl(location, app);

  const { reloadFavsWithRealtyDetails } = useHandleFavorite();
  const { getKaeuferRealties } = useHandleUserRealtyActive();

  const { isMobile } = useHandleScreenResize();

  useEffect(() => {
    if (location.pathname.includes('immobilie')) {
      // check if this immo belongs to user
      let isPrivate = checkShowPrivate(immoId);
      if (isPrivate) {
        setShowPrivate(true);
      }

      getAndSetImmoDetails();
      reloadFavsWithRealtyDetails();

      if (!isMobile) {
        setShowFullDesc(true);
      }
    }
  }, [location.pathname]);

  /**
   * checks if user has access rights to this immo
   * @returns Boolean
   */
  const checkShowPrivate = (immoId) => {
    let isPrivate = false;
    if (user.isLoggedIn) {
      let realtyFound = false;
      if (user.userRealties.length > 0) {
        realtyFound = user.userRealties.find((realty) => realty.id === parseInt(immoId));
      }

      const kaeuferImmos = getKaeuferRealties();
      if (!realtyFound && kaeuferImmos?.length > 0) {
        realtyFound = kaeuferImmos.find((realty) => realty.id === parseInt(immoId));
      }

      if (realtyFound) {
        isPrivate = true;
      }
    }

    return isPrivate;
  };

  const getAndSetImmoDetails = async () => {
    try {
      const immo = parseImmo(await getRealtyDetails(immoId, tenantId, token));
      setImmo(immo);

      if (trackingUrl) {
        const decodedTrackingUrl = window.atob(trackingUrl);
        fetch(decodedTrackingUrl, { mode: 'no-cors' });
      }

      if (token) {
        setShowPrivate(true);
      }
      if (immo.address.geo_coordinates.latitude && immo.address.geo_coordinates.longitude && (checkShowPrivate(immoId) || token)) {
        setShowFullAddress(true);
      }

      if (immo.status === IMMO_STATE_INAKTIV) {
        window.location = app.root + '/404';
      }

      // get POIs and update immo if found
      try {
        const poiCategories = await getRealtyPOICategories(immoId, tenantId);
        setImmo({
          ...immo,
          infrastructureList: parseInfrastructureList(poiCategories),
        });
      } catch (e) {
        // TODO handle error
        console.error('e', e);
      }
    } catch (e) {
      window.location = app.root + '/404';
    }
  };

  return {
    immo,
    showFullDesc,
    setShowFullDesc,
    showPrivate,
    setShowPrivate,
    showFullAddress,
    token,
  };
};
