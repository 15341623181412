import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import FormRadio from '../../FormFields/FormRadio';
import FormInput from '../../FormFields/FormInput';
import FormCheckbox from '../../FormFields/FormCheckbox';
import FormSelectPhoneNumber from '../../FormFields/customFields/FormSelectPhoneNumber';
import LoadingSpinner from '../../LoadingSpinner';
import ButtonForwardAction from '../../Buttons/ButtonForwardAction';

import { genderOptions } from '../../FormFields/util/formOptions';
import { fetchErrorText, searchProfileNameAlreadyExistsErrorText, tooManySearchProfilesErrorText } from '../../FormFields/util/formTexts';

import { confirmPassword, validateFields } from '../../FormFields/util/validateFields';

import { SEARCHPROFILE_SITEINDEX_EMAIL, SEARCHPROFILE_SITEINDEX_START } from '../../../constants';

// STORE
import { setFormIsLoading } from '../../../reducers/app';
import { setPrivacyPolicyReadSPForm, setSiteIndexSPForm } from '../../../reducers/searchProfileForm';

// API
import { createSearchProfile } from '../../../api/JustImmo';

import {
  setEmailSPForm,
  setFirstNameSPForm,
  setGenderSPForm,
  setLastNameSPForm,
  setPhoneToUserPhoneSPForm,
  setPhoneNumberPrefixSPForm,
  setParsedPhoneNumberSPForm,
  setTitlePostSPForm,
  setTitlePreSPForm,
} from '../../../reducers/searchProfileForm';

import FormTextDsgvo from '../../FormFields/util/formTextDsgvo';
import { handleScrollToErrorPageDialog, findPhonePrefix } from '../../../util/generalUtils';
import { useFetchSearchprofiles } from '../../../hooks/searchprofiles/useFetchSearchprofiles';
import { combineReduxPhoneParts } from '../../../util/phoneNumberParser';

function UserData({ formType, id }) {
  const searchProfileForm = useSelector((state) => state.searchProfileForm);
  const currentFormValidators = useSelector((state) => state.formValidators);
  const user = useSelector((state) => state.user);
  let app = useSelector((state) => state.app);

  const dispatch = useDispatch();
  const { getCriteriaFromRequestbody } = useFetchSearchprofiles();

  useEffect(() => {
    if (user.isLoggedIn && user.userObject) {
      const foundPrefix = findPhonePrefix(user.userObject.phoneNumber);
      // fill data
      dispatch(setGenderSPForm(user.userObject.gender));
      dispatch(setTitlePreSPForm(user.userObject.titleBefore));
      dispatch(setTitlePostSPForm(user.userObject.titleAfter));
      dispatch(setFirstNameSPForm(user.userObject.firstName));
      dispatch(setLastNameSPForm(user.userObject.lastName));
      dispatch(setEmailSPForm(user.userObject.userName));
      dispatch(setPhoneNumberPrefixSPForm(foundPrefix));
      dispatch(setParsedPhoneNumberSPForm(user.userObject.phoneNumber.substring(foundPrefix.text.substring(0, foundPrefix.text.indexOf(' ')).length)));
      dispatch(setPhoneToUserPhoneSPForm(user.userObject.phoneNumber));
      dispatch(setPrivacyPolicyReadSPForm(true));
    }
  }, []);

  const handleBack = () => {
    dispatch(setSiteIndexSPForm(SEARCHPROFILE_SITEINDEX_START));
  };

  const [error, setError] = useState('');

  const forwardAction = async () => {
    setError('');

    if (app.formIsLoading) {
      return;
    }

    let validationResult = validateFields(
      {
        gender: searchProfileForm.gender,
        lastName: searchProfileForm.lastName,
        email: searchProfileForm.email,
        phone: combineReduxPhoneParts(searchProfileForm.phoneNumberPrefix, searchProfileForm.parsedPhoneNumber),
        privacyPolicy: searchProfileForm.privacyPolicyRead,
      },
      dispatch,
    );

    let validationResultReg = true;
    let validationResultPW = true;
    if (!user.isLoggedIn) {
      validationResultReg = validateFields(
        {
          register: searchProfileForm.wantsToRegister,
          password: searchProfileForm.password,
          passwordConfirm: searchProfileForm.passwordConfirm,
        },
        dispatch,
      );

      validationResultPW = confirmPassword(searchProfileForm.password, searchProfileForm.passwordConfirm, dispatch);
    }

    if (validationResult && validationResultReg && validationResultPW) {
      const newSearchProfileRequestBody = {
        register: {
          gender: searchProfileForm.gender,
          email: searchProfileForm.email,
          firstName: searchProfileForm.firstName,
          lastName: searchProfileForm.lastName,
          password: searchProfileForm.password,
          phoneNumber: searchProfileForm.phone,
          titleBefore: searchProfileForm.titlePre,
          titleAfter: searchProfileForm.titlePost,
          privacyAggrement: searchProfileForm.privacyPolicyRead,
        },
        state: searchProfileForm.state,
        criteria: getCriteriaFromRequestbody(searchProfileForm.requestBody),
        createUser: searchProfileForm.wantsToRegister,
      };
      dispatch(setFormIsLoading(true));

      try {
        await createSearchProfile(searchProfileForm.name, newSearchProfileRequestBody);

        dispatch(setFormIsLoading(false));
        dispatch(setSiteIndexSPForm(SEARCHPROFILE_SITEINDEX_EMAIL));
      } catch (e) {
        const data = e.response?.data;
        dispatch(setFormIsLoading(false));

        if (data === 'This user already has 3 active search profiles') {
          setError(tooManySearchProfilesErrorText);
        } else if (data === 'A search profile with this name already exists for this user') {
          setError(searchProfileNameAlreadyExistsErrorText);
        } else if (data === 'Duplicate Username') {
          let errmsg = 'Die Mailadresse ist bereits vergeben.';
          setError(errmsg);
        } else {
          setError(fetchErrorText);
        }

        handleScrollToErrorPageDialog(formType, '#' + id);
      }
    }
  };

  return (
    <>
      <h2>Persönliche Daten</h2>

      <div className="d-flex justify-content-between">
        <h3 className="form__title">Kontakt</h3>
        <span className="font-90">* Pflichtfelder</span>
      </div>

      <FormRadio options={genderOptions} id={'gender'} error={currentFormValidators.genderError} selectedValue={searchProfileForm.gender} formType={formType} />

      <div className="form__row form__row--double">
        <FormInput id={'inputTitlePre'} label={'Titel (vorgestellt)'} value={searchProfileForm.titlePre} formType={formType} />

        <FormInput id={'inputTitlePost'} label={'Titel (nachgestellt)'} value={searchProfileForm.titlePost} formType={formType} />
      </div>

      <FormInput id={'inputFirstName'} label={'Vorname'} value={searchProfileForm.firstName} error={currentFormValidators.firstNameError} formType={formType} required={true} />

      <FormInput
        id={'inputLastName'}
        label={'Nachname'}
        value={searchProfileForm.lastName}
        error={currentFormValidators.lastNameError}
        required={true}
        disabled={user.isLoggedIn ? true : false}
        hasLockIcon={user.isLoggedIn ? true : false}
        formType={formType}
      />

      <FormInput
        type={'email'}
        id={'inputEmail'}
        label={'E-Mail-Adresse'}
        value={searchProfileForm.email}
        error={currentFormValidators.emailError}
        disabled={user.isLoggedIn ? true : false}
        hasLockIcon={user.isLoggedIn ? true : false}
        required={true}
        formType={formType}
      />

      <FormSelectPhoneNumber formType={formType} form={searchProfileForm} />

      {!user.isLoggedIn && (
        <>
          <h3 className="form__title pt-100rem">Zustimmungen</h3>
          <FormCheckbox id="privacyPolicy" label={<FormTextDsgvo />} required={true} value={searchProfileForm.privacyPolicyRead} error={currentFormValidators.privacyPolicyError} formType={formType} />
        </>
      )}

      {!user.isLoggedIn && (
        <>
          <FormCheckbox
            id="register"
            label={'Jetzt direkt für das sREAL Kundenportal registrieren und von den vielen Vorteilen profitieren.'}
            value={searchProfileForm.wantsToRegister}
            error={currentFormValidators.registerError}
            formType={formType}
            required={true}
          />

          {searchProfileForm.wantsToRegister && (
            <>
              <FormInput type={'password'} id={'inputPassword'} label={'Passwort'} value={searchProfileForm.password} error={currentFormValidators.passwordError} required={true} formType={formType} />
              <span className="form--info">Mindestens 8 Zeichen, 1 Klein- und Großbuchstabe, 1 Ziffer und 1 Sonderzeichen</span>

              <FormInput
                type={'password'}
                id={'inputPasswordConfirm'}
                label={'Passwort wiederholen'}
                value={searchProfileForm.passwordConfirm}
                error={currentFormValidators.passwordError || currentFormValidators.passwordConfirmError}
                required={true}
                formType={formType}
              />
            </>
          )}
        </>
      )}

      <LoadingSpinner />

      <div className="button-panel pt-100rem">
        <button className="button button--blue-outline" onClick={handleBack}>
          Zurück
        </button>
        <ButtonForwardAction formType={formType} buttonText="Suchprofil erstellen" forwardAction={forwardAction} buttonStyle="blue" />
      </div>
      {error && (
        <p id={formType + '-error'} className="form__fielderror">
          {error}
        </p>
      )}
    </>
  );
}

export default UserData;
