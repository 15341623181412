import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  ICONLISTELEMENT_ALLINONE_LABEL,
  ICONLISTELEMENT_BASIC_LABEL,
  ICONLISTELEMENT_DIGITALPERSÖNLICH_LABEL,
  ICONLISTELEMENT_KANEINEMORT_LABEL,
  ICONLISTELEMENT_KBESTIMMO_LABEL,
  ICONLISTELEMENT_KMARKTANALYSE_LABEL,
  ICONLISTELEMENT_MEHRWERTPLUS_LABEL,
  ICONLISTELEMENT_MEHRWERT_LABEL,
  ICONLISTELEMENT_SREALIMMOBLOG_LABEL,
  ICONLISTELEMENT_SREALMAGAZIN_LABEL,
  ICONLISTELEMENT_SREALNEWSLETTER_LABEL,
  ICONLISTELEMENT_TRANSPARENT_LABEL,
  ICONLISTELEMENT_VKIMMODRIVE_LABEL,
  ICONLISTELEMENT_VKINTERESSENTEN_LABEL,
  ICONLISTELEMENT_VKTRANSPARENT_LABEL,
} from '../../constants';
//*Icons
import IconListElement from '../IconList/IconListElement';
import IconKAnEinemOrt from '../Icons/IconKAnEinemOrt';
import IconKBesteImmoVorab from '../Icons/IconKBesteImmoVorab';
import IconKMarktanalyse from '../Icons/IconKMarktanalyse';
import IconVKImmoDrive from '../Icons/IconVKImmoDrive';
import IconVKInteressenten from '../Icons/IconVKInteressenten';
import IconVKTransparent from '../Icons/IconVKTransparent';
import IconVorteilAnEinemOrt from '../Icons/IconVorteilAnEinemOrt';
import IconVorteilDigitalPersoenlich from '../Icons/IconVorteilDigitalPersoenlich';
import IconVorteilMehrwert from '../Icons/IconVorteilMehrwert';
import IconVorteilOnlinePlattform from '../Icons/IconVorteilOnlinePlattform';
import IconVorteilTransparentesPortal from '../Icons/IconVorteilTransparentesPortal';
import IconVorteilTransparenzEchtzeit from '../Icons/IconVorteilTransparenzEchtzeit';

import { useHandleScreenResize } from '../../hooks/app/useHandleScreenResize';
import IconCalendar from '../Icons/IconCalendar';
import IconHandDocument from '../Icons/IconHandDocument';
import IconPeopleConnect from '../Icons/IconPeopleConnect';

function IconListElementVorteilBlock({ label }) {
  const navigate = useNavigate();
  const app = useSelector((state) => state.app);

  const { isMobile } = useHandleScreenResize();

  switch (label) {
    case ICONLISTELEMENT_BASIC_LABEL:
      return (
        <IconListElement
          blueCircleDesktop={!isMobile}
          icon={<IconVorteilOnlinePlattform />}
        >
          <h3>Die Online-Plattform für Ihre Immobilie</h3>
          <p className='iconlist__teaser'>
            Ob Sie Ihre Immobilie verkaufen möchten oder auf der Suche nach Eigentum sind: auf
            my-sreal.at wickeln wir beides mit Ihnen ab. Einfach, transparent und digital.
          </p>
        </IconListElement>
      );
    case ICONLISTELEMENT_MEHRWERT_LABEL:
      return (
        <IconListElement
          blueCircleDesktop={!isMobile}
          icon={<IconVorteilTransparentesPortal />}
        >
          <h3>Transparentes Online-Portal mit Mehrwert</h3>
          <p className='iconlist__teaser'>
            Berechnen Sie in my-sreal.at Ihren Wohnwert und analysieren Sie den Immobilienmarkt. Bei
            einem Verkauf erhalten Sie alle Statistiken zu Klickzahlen, Aufrufen und Besichtigungen
            Ihrer Immobilie in Echtzeit.
          </p>
        </IconListElement>
      );
    case ICONLISTELEMENT_ALLINONE_LABEL:
      return (
        <IconListElement
          blueCircleDesktop={!isMobile}
          icon={<IconVorteilAnEinemOrt />}
        >
          <h3>Vom ersten Interesse bis zum Kaufvertrag alles an einem Ort</h3>
          <p className='iconlist__teaser'>
            my-sreal.at ist der zentrale Ort, an dem Sie den Verkauf oder Kauf von Immobilien
            abwickeln können: vom Chat mit Makler:innen über die Dokumentenbibliothek bis zum
            digitalen Vertragsabschluss.
          </p>
        </IconListElement>
      );
    case ICONLISTELEMENT_DIGITALPERSÖNLICH_LABEL:
      return (
        <IconListElement
          icon={<IconVorteilDigitalPersoenlich cssClasses="iconlist-element__imagebanner-left" />}
          cssClasses="iconlist-element--highlightborder"
        >
          <h3>Digital und persönlich</h3>
          <div className='iconlist__teaser'>
            <p className="d-flex w-100 pt-150rem pb-150rem m-0">
              Nutzen Sie den Vorteil eines digitalen Immobilienportals und die Erfahrung von
              Immobilienexpert:innen an einem Ort.
            </p>
          </div>
        </IconListElement>
      );
    case ICONLISTELEMENT_MEHRWERTPLUS_LABEL:
      return (
        <IconListElement
          icon={<IconVorteilMehrwert />}
          cssClasses="iconlist-element--highlightborder"
        >
          <h3>Mehrwert. Und das mehrfach!</h3>
          <div className='iconlist__teaser'>
            <p className="d-flex w-100 pt-150rem pb-150rem m-0">
              Berechnen Sie den Wert von Immobilien in wenigen Minuten und erhalten Sie umfangreiche
              Marktanalysen.
            </p>
          </div>
        </IconListElement>
      );
    case ICONLISTELEMENT_TRANSPARENT_LABEL:
      return (
        <IconListElement
          icon={<IconVorteilTransparenzEchtzeit cssClasses="iconlist-element__imagebanner-right" />}
          cssClasses="iconlist-element--highlightborder"
        >
          <h3>Transparenz in Echtzeit</h3>
          <div className='iconlist__teaser'>
            <p className="d-flex w-100 pt-150rem pb-150rem m-0">
              Beim Verkauf einer Immobilie erhalten Sie alle Statistiken zu Klickzahlen, Aufrufen und
              Besichtigungen in Echtzeit.
            </p>
          </div>
        </IconListElement>
      );
    case ICONLISTELEMENT_KBESTIMMO_LABEL:
      return (
        <IconListElement
          blueCircleDesktop={!isMobile}
          icon={<IconKBesteImmoVorab />}
        >
          <h3>Die besten Immobilien vorab</h3>
          <div className='iconlist__teaser'>
            <p className='iconlist__teaser'>
              Als Vormerkkund:in erhalten Sie in my-sreal.at neue Immobilien vor allen anderen
              Interessent:innen angeboten. Organisieren Sie übersichtlich Ihre Suchprofile und aktualisieren Sie bei Bedarf Ihre Wünsche.
            </p>
          </div>
        </IconListElement>
      );
    case ICONLISTELEMENT_KMARKTANALYSE_LABEL:
      return (
        <IconListElement
          blueCircleDesktop={!isMobile}
          icon={<IconKMarktanalyse />}
        >
          <h3>Hochwertige Marktanalysen für Interessent:innen</h3>
          <div className='iconlist__teaser'>
            <p className='iconlist__teaser'>
              Marktanalysen geben Ihnen umfassende Infos zu für Sie interessanten Bezirken oder Orten.
              Die Marktanalyse verrät Ihnen, wie es um Gastronomie, Bildung und Gesundheitsbetreuung steht, damit Sie sich bestens auskennen.
            </p>
          </div>
        </IconListElement>
      );
    case ICONLISTELEMENT_KANEINEMORT_LABEL:
      return (
        <IconListElement
          blueCircleDesktop={!isMobile}
          icon={<IconKAnEinemOrt />}
        >
          <h3>Von der Suche bis zum Kaufvertrag alles an einem Ort</h3>
          <p className='iconlist__teaser'>
            my-sreal.at ist der zentrale Ort für Ihren Immobilienkauf. Von der Angebotsübersicht Ihrer Immo-Angebote über die Dokumentenbibliothek <span className="no-break">immo-drive</span> bis zum digitalen Kaufvertrag finden Sie hier
            alles, das Sie im Kaufprozess benötigen.
          </p>
        </IconListElement>
      );
    case ICONLISTELEMENT_VKINTERESSENTEN_LABEL:
      return (
        <IconListElement
          blueCircleDesktop={!isMobile}
          icon={<IconVKInteressenten />}
        >
          <h3>Tausende Interessent:innen erreichen</h3>
          <p className='iconlist__teaser'>
            Über my-sreal.at erreichen Sie mit Ihrer Immobilie exklusiven Zugang zu tausenden
            Interessent:innen, die österreichweit bei s REAL vorgemerkt sind.
          </p>
        </IconListElement>
      );
    case ICONLISTELEMENT_VKTRANSPARENT_LABEL:
      return (
        <IconListElement
          blueCircleDesktop={!isMobile}
          icon={<IconVKTransparent />}
        >
          <h3>Transparenter Verkaufsprozess</h3>
          <p className='iconlist__teaser'>
            Sie erhalten Statistiken zu Klickzahlen, erfahren, wie oft Ihre Immobilie angeboten
            wurde und sehen alle Vermarktungsaktivitäten direkt in my-sreal.at.
          </p>
        </IconListElement>
      );
    case ICONLISTELEMENT_VKIMMODRIVE_LABEL:
      return (
        <IconListElement
          blueCircleDesktop={!isMobile}
          icon={<IconVKImmoDrive />}
        >
          <h3>Alle Dokumente in immo-drive</h3>
          <p className='iconlist__teaser'>
            Sammeln und sehen Sie alle Dokumente zu Ihrer Immobilie in immo-drive ein – der
            Dokumentenablage direkt in my-sreal.at.
          </p>
        </IconListElement>
      );
    case ICONLISTELEMENT_SREALNEWSLETTER_LABEL:
      return (
        <IconListElement blueCircleBoth icon={<IconPeopleConnect />}>
          <h3>s REAL Newsletter</h3>
          <div className="d-flex flex-col iconlist__teaser">
            <p className="pb-100rem">
              Unsere Newsletterfamilie ist gewachsen! Jetzt anmelden und keine Neuheiten verpassen.
            </p>

            <a href="https://www.sreal.at/de/newsletter" className="mt-100rem" target="_blank" rel="noreferrer" title="s REAL Newsletter abonnieren">
              s REAL Newsletter abonnieren
            </a>
          </div>
        </IconListElement>
      );
    case ICONLISTELEMENT_SREALMAGAZIN_LABEL:
      return (
        <IconListElement blueCircleBoth icon={<IconCalendar />}>
          <h3>s REAL Wohnen Magazin</h3>
          <div className="d-flex flex-col iconlist__teaser">
            <p className="pb-100rem">Blättern Sie durch die neue s REAL Magazin-Ausgabe.</p>
            <a href="https://www.sreal.at/de/magazin" className="mt-100rem" target="_blank" rel="noreferrer" title="Magazin anzeigen">
              Magazin anzeigen
            </a>
          </div>
        </IconListElement>
      );
    case ICONLISTELEMENT_SREALIMMOBLOG_LABEL:
      return (
        <IconListElement blueCircleBoth icon={<IconHandDocument />}>
          <h3>s REAL Immoblog</h3>
          <div className="d-flex flex-col iconlist__teaser">
            <p className="pb-100rem">
              Sie interessieren sich für die Immobilienwelt? Hier finden Sie wissenswerte
              Informationen rund um Immobilien und Wohnen.
            </p>
            <a href="https://www.sreal.at/de/immoblog" className="mt-100rem" target="_blank" rel="noreferrer" title="Immoblog anzeigen">
              Immoblog anzeigen
            </a>
          </div>
        </IconListElement>
      );
    default:
      return null;
  }
}

export default IconListElementVorteilBlock;
