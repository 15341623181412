import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getNews, getNewsDetail } from '../../api/News';
import { setCachedNews } from '../../reducers/cachedNews';
import * as moment from 'moment';
import { useFetchNewsDetails } from './useFetchNewsDetails';

export const useFetchNews = () => {
  const dispatch = useDispatch();
  const cachedNews = useSelector((state) => state.cachedNews.cachedNews);
  const [selectedFilter, setSelectedFilter] = useState(0);
  const [news, setNews] = useState(cachedNews.length > 0 ? cachedNews : []);
  const [newsError, setNewsError] = useState(null);
  const [shownEntries, setShownEntries] = useState(10);
  const [didNewsLoad, setDidNewsLoad] = useState(false);

  useEffect(() => {
    getAndSetNews();
  }, []);

  const getAndSetNews = async () => {
    try {
      const news = await getNews();
      setNews(news);

      // schaut welche news schon im cache sind und überschreibt diese
      // Blocks (News Details) bleiben erhalten falls schon gecached und in news detail updated
      const updatedCache = news?.map((newsElement) => {
        const cachedNewsElement = cachedNews.find((cachedNews) => cachedNews.slug === newsElement.slug);
        if (cachedNewsElement) {
          return { ...cachedNewsElement, ...newsElement };
        }
        return newsElement;
      });

      dispatch(setCachedNews(updatedCache));
      setNewsError(null);
    } catch (error) {
      setNewsError(error);
    } finally {
      setDidNewsLoad(true);
    }
  };

  const showMoreEntries = () => {
    setShownEntries((shownEntries) => shownEntries + 10);
  };

  const handleSetActiveFilterIndex = (event) => {
    let index = event.target.dataset.index;
    setShownEntries(10);
    setSelectedFilter(parseInt(index));
  };

  return {
    selectedFilter,
    news,
    newsError,
    shownEntries,
    setShownEntries,
    didNewsLoad,
    handleSetActiveFilterIndex,
    showMoreEntries,
    getAndSetNews,
  };
};
