import IconSuccess from '../../Icons/IconSuccess';

function RegisterFormSuccess({ handleClose }) {
  return (
    <>
      <div className="d-flex w-100 justify-content-center pt-40 pb-40">
        <IconSuccess color="#1E3163" />
      </div>
      <h2 className="pt-200rem">Registrierung erfolgreich</h2>
      <p className="pt-200rem pb-200rem">
        Ihre Registrierung wurde erfolgreich abgeschlossen. Herzlich willkommen und viel Freude bei
        der Nutzung von my-sreal.at
      </p>
      <div className="button-panel pt-100rem">
        <button className="button button--blue-outline" onClick={handleClose}>
          Jetzt starten
        </button>
      </div>
    </>
  );
}

export default RegisterFormSuccess;
