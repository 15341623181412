function IconVorteilDigitalPersoenlich({ color, cssClasses }) {
  return (
    <svg
      className={cssClasses}
      xmlns="http://www.w3.org/2000/svg"
      width="221.026"
      height="200.877"
      viewBox="0 0 221.026 200.877"
    >
      <g id="Gruppe_13376" data-name="Gruppe 13376" transform="translate(0 0)">
        <g id="Gruppe_13373" data-name="Gruppe 13373" transform="translate(105.466 85.683)">
          <path
            id="Pfad_8428"
            data-name="Pfad 8428"
            d="M582.559,2272.3a98.6,98.6,0,0,0,4.4-24.094H548.769a98.364,98.364,0,0,0,4.409,24.094Z"
            transform="translate(-510.074 -2188.436)"
            fill={color}
          />
          <path
            id="Pfad_8429"
            data-name="Pfad 8429"
            d="M584.01,2242.209H551.974a88.063,88.063,0,0,0-3.238,24.094h38.512A88.082,88.082,0,0,0,584.01,2242.209Z"
            transform="translate(-510.2 -2211.349)"
            fill={color}
          />
          <path
            id="Pfad_8430"
            data-name="Pfad 8430"
            d="M550.024,2254.209A107.383,107.383,0,0,0,563.078,2279a107.718,107.718,0,0,0,13.049-24.788Z"
            transform="translate(-505.281 -2165.522)"
            fill={color}
          />
          <path
            id="Pfad_8431"
            data-name="Pfad 8431"
            d="M578.714,2261.228a78.045,78.045,0,0,0-14.49-25.261,77.892,77.892,0,0,0-14.5,25.261Z"
            transform="translate(-506.427 -2235.186)"
            fill={color}
          />
          <path
            id="Pfad_8432"
            data-name="Pfad 8432"
            d="M596.777,2261.83a57.861,57.861,0,0,0-42.878-26.021,83.249,83.249,0,0,1,13.965,26.021Z"
            transform="translate(-490.483 -2235.789)"
            fill={color}
          />
          <path
            id="Pfad_8433"
            data-name="Pfad 8433"
            d="M577.516,2242.209H546.647a56.61,56.61,0,0,0-5.908,24.094h33.727A92.978,92.978,0,0,1,577.516,2242.209Z"
            transform="translate(-540.739 -2211.349)"
            fill={color}
          />
          <path
            id="Pfad_8434"
            data-name="Pfad 8434"
            d="M586.091,2235.805c-18.716,1.685-34.406,11.574-43.562,26.04h29.578A83.308,83.308,0,0,1,586.091,2235.805Z"
            transform="translate(-533.903 -2235.805)"
            fill={color}
          />
          <path
            id="Pfad_8435"
            data-name="Pfad 8435"
            d="M556.829,2272.3h31.66a57.7,57.7,0,0,0,6.366-24.094H561.007A103.393,103.393,0,0,1,556.829,2272.3Z"
            transform="translate(-479.294 -2188.436)"
            fill={color}
          />
          <path
            id="Pfad_8436"
            data-name="Pfad 8436"
            d="M560.143,2266.3h33.7a57.122,57.122,0,0,0-6.443-24.094h-30.31A93.178,93.178,0,0,1,560.143,2266.3Z"
            transform="translate(-478.286 -2211.349)"
            fill={color}
          />
          <path
            id="Pfad_8437"
            data-name="Pfad 8437"
            d="M574.558,2248.209h-33.8a59.6,59.6,0,0,0,6.558,24.094h31.424A103.161,103.161,0,0,1,574.558,2248.209Z"
            transform="translate(-540.682 -2188.436)"
            fill={color}
          />
          <path
            id="Pfad_8438"
            data-name="Pfad 8438"
            d="M542.692,2254.209a57.486,57.486,0,0,0,43.692,26.508,111.919,111.919,0,0,1-13.483-26.508Z"
            transform="translate(-533.281 -2165.522)"
            fill={color}
          />
          <path
            id="Pfad_8439"
            data-name="Pfad 8439"
            d="M553.708,2280.7a58.036,58.036,0,0,0,43.914-26.5H567.176A112.2,112.2,0,0,1,553.708,2280.7Z"
            transform="translate(-491.213 -2165.522)"
            fill={color}
          />
        </g>
        <g id="Gruppe_13375" data-name="Gruppe 13375" transform="translate(0 0)">
          <path
            id="Pfad_8440"
            data-name="Pfad 8440"
            d="M361.64,1809.809l-4.027-1.433v-17.261c11.653-9.059,19.372-24.631,19.372-42.32,0-.482-.047-.949-.063-1.433a33.417,33.417,0,0,1-7.727,1.433c-12.884,0-21.8-2.064-29.3-12.1-6.636,7-18.4,12.1-29.124,12.1-6.006,0-13.351-3.6-18.975-7.306a59.339,59.339,0,0,0-.5,7.306c0,17.776,7.813,33.417,19.575,42.452v17.129l-36.439,13.008a27.306,27.306,0,0,0-18.087,25.667v22.479h101.5a50.4,50.4,0,0,1,3.786-59.721Z"
            transform="translate(-256.351 -1698.164)"
            fill={color}
          />
          <path
            id="Pfad_8441"
            data-name="Pfad 8441"
            d="M304.335,1761.656l3.295-6.325,3.536,6.186c6.388,11.186,13.1,13.07,25.705,13.07a35.76,35.76,0,0,0,7.034-1.517c-3.715-23.486-21.156-41.324-42.086-41.324-18.991,0-35.107,14.69-40.707,34.936,4.837,3.606,12.533,7.905,17.339,7.905C288.647,1774.586,300.737,1768.55,304.335,1761.656Z"
            transform="translate(-224.027 -1731.745)"
            fill={color}
          />
        </g>
      </g>
    </svg>
  );
}

export default IconVorteilDigitalPersoenlich;
