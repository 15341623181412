import { useEffect } from 'react';
import HeaderSmallWithIcon from '../components/Header/HeaderSmallWithIcon';
import MainContent from '../components/MainContent';
import MetaTags from '../components/MetaTags';

// ICONS
import BesichtigungenChart from '../components/Charts/BesichtigungenChart';
import InseratKlicksChart from '../components/Charts/InseratKlicksChart';
import VerschickteAngeboteChart from '../components/Charts/VerschickteAngeboteChart';
import IconBarchartWithRoof from '../components/Icons/IconBarchartWithRoof';

import { useFetchStats } from '../hooks/stats/useFetchStats';

function Statistiken() {
  const { getData, inseratKlicksData, verschickteAngeboteData, besichtigungenData } = useFetchStats('Statistiken');

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <MetaTags title={'Statistiken | Die Online-Plattform für Ihre Immobilie'} />
      <HeaderSmallWithIcon icon={<IconBarchartWithRoof />} iconActive={true} headlineDesktop="Statistiken" cssClasses="header--purplebg" />
      <MainContent cssClasses="w-desktop--center">
        <h1 className="headline--mobile">Statistiken</h1>
        <InseratKlicksChart chartData={inseratKlicksData} />
        <VerschickteAngeboteChart chartData={verschickteAngeboteData} />
        <BesichtigungenChart chartData={besichtigungenData} />
      </MainContent>
    </>
  );
}

export default Statistiken;
