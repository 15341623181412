import IconEmailSent from '../../Icons/IconEmailSent';

function FormEmailSent({ handleClose }) {
  return (
    <>
      <div className="d-flex w-100 justify-content-center pt-40 pb-40">
        <IconEmailSent color="#1E3163" />
      </div>

      <h2 className="mb-200rem">Sie haben eine E-Mail erhalten</h2>
      <p className="lh-140">
        Bitte klicken Sie auf den Bestätigungslink in der E-Mail, um den Registrierungsprozess
        abzuschließen. Bitte überprüfen Sie auch Ihren Spam-Ordner.
      </p>

      <div className="button-panel pt-100rem">
        <button className="button button--blue-outline" onClick={handleClose}>
          Zurück
        </button>
      </div>
    </>
  );
}

export default FormEmailSent;
