import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { immoSearchErrorText, immoSearchNoResultsText } from '../../components/FormFields/util/formTexts';

//STORE
import { setFormIsLoading } from '../../reducers/app';
import { setCachedImmoList, setCachedImmoListCount, setSearchFormExpanded, setResetFieldsSearchForm } from '../../reducers/searchForm';

// API
import { getRealtySearch } from '../../api/Sreal';

import { validateFieldsMinMax } from '../../components/FormFields/util/validateFields';

export const useFetchImmoSearch = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const app = useSelector((state) => state.app);
  let currentSearchForm = useSelector((state) => state.searchForm);

  const [error, setError] = useState(false);
  const [page, setPage] = useState(1);

  useEffect(() => {
    if (page > 1) {
      if (currentSearchForm.cachedImmoList.length <= currentSearchForm.cachedImmoListCount) {
        setRealtySearchList(false);
      }
    }
    // eslint-disable-next-line
  }, [page]);

  const handleShowMoreImmos = () => {
    setPage(page + 1);
    dispatch(setFormIsLoading(true));
  };

  const handleScrollToResults = () => {
    const resultListEl = document.querySelector('#immoresult-list');
    window.scrollTo({
      top: resultListEl.offsetTop,
      behavior: 'smooth',
    });
  };

  const clearSearch = () => {
    let expandedVal = currentSearchForm.searchFormExpanded;
    dispatch(setResetFieldsSearchForm());
    dispatch(setSearchFormExpanded(expandedVal));

    const formEl = document.querySelector('#searchForm');
    window.scrollTo({
      top: formEl.offsetTop,
      behavior: 'smooth',
    });

    setRealtySearchList(true, false, true);
  };

  async function setRealtySearchList(resetPage = false, scrollToResultList = false, clearSearch = false, navigateToImmoSearch = false) {
    setError(false);

    if (resetPage) {
      setPage(1);
    }

    if (navigateToImmoSearch) {
      navigate(app.root + '/immobiliensuche');
    }

    let requestBody = currentSearchForm.requestBody;
    if (clearSearch) {
      requestBody = {};
    }

    // TODO: add validation min Max
    // let validateArea = validateFieldsMinMax(requestBody.areaFrom, requestBody.areaTo);
    // let validatePrice = validateFieldsMinMax(requestBody.priceFrom, requestBody.priceTo);
    // let validateSurfaceArea = validateFieldsMinMax(requestBody.surfaceAreaFrom, requestBody.surfaceAreaTo);
    // let validateRooms = validateFieldsMinMax(requestBody.roomsFrom, requestBody.roomsTo);

    // let validationResult = false;
    // if(validateArea && validatePrice && validateSurfaceArea && validateRooms) {
    //   validationResult = true;
    // }

    let validationResult = true;
    if (validationResult) {
      const res = await getRealtySearch(requestBody, resetPage ? 1 : page);

      if (res.realties) {
        // only overwrite immolist if extend = false
        if (resetPage) {
          dispatch(setCachedImmoList(res.realties));
        } else {
          let list = currentSearchForm.cachedImmoList.concat(res.realties);
          dispatch(setCachedImmoList(list));
        }

        dispatch(setCachedImmoListCount(res.resultCount));
        dispatch(setFormIsLoading(false));

        if (res.resultCount === 0) {
          setError(immoSearchNoResultsText);
        }

        // scroll to resultslist
        if (scrollToResultList) {
          handleScrollToResults();
        }
      } else {
        dispatch(setCachedImmoList([]));
        dispatch(setCachedImmoListCount(0));
        dispatch(setFormIsLoading(false));
        setError(immoSearchErrorText);

        // scroll to resultslist
        if (scrollToResultList) {
          handleScrollToResults();
        }
      }
    } else {
      dispatch(setFormIsLoading(false));
    }
  }
  return {
    handleScrollToResults,
    clearSearch,
    setRealtySearchList,
    handleShowMoreImmos,
    error,
  };
};
