//*Components
import NewsCards from '../components/Cards/NewsCards';
import FilterMenu from '../components/FilterMenu';
import HeaderSmallWithIcon from '../components/Header/HeaderSmallWithIcon';
import MainContent from '../components/MainContent';
import MetaTags from '../components/MetaTags';
import NewsCardsSkeleton from '../components/Skeletons/NewsCardsSkeleton';
//*Utils
//*Icons
import { useEffect, useState } from 'react';
import IconNews from '../components/Icons/IconNews';
import { useHandleScreenResize } from '../hooks/app/useHandleScreenResize';
import { useFetchNews } from '../hooks/news/useFetchNews';

function News() {
  const { selectedFilter, news, newsError, shownEntries, didNewsLoad, handleSetActiveFilterIndex, showMoreEntries } = useFetchNews();
  const { isMobile, isTablet } = useHandleScreenResize();
  const [filterOptions, setFilterOptions] = useState([]);
  const [filteredNews, setFilteredNews] = useState([]);

  useEffect(() => {
    if (didNewsLoad && news.length > 0) {
      // calculate filterOptions based on news-topics
      // remove main-cards (first 2 news)
      let topics = news
        .filter((n, i) => i > 1)
        .filter((item) => item.topics.length > 0)
        .map((item) => {
          return item.topics;
        })
        .flat();

      // remove duplicates
      topics = topics.filter((value, index, self) => index === self.findIndex((t) => t.slug === value.slug));

      if (topics.length > 0) {
        // change "name" to "label"
        topics = topics.map((t) => {
          return {
            label: t.name,
            slug: t.slug,
          };
        });

        topics.unshift({ label: 'Alle', slug: 'alle' });
        setFilterOptions(topics);
      }
    }
  }, [didNewsLoad]);

  useEffect(() => {
    // filter news based on selectedFilter
    const filteredNews = news.filter((item) => {
      if (selectedFilter === 0) return item;
      let filterSlug = filterOptions[selectedFilter].slug;
      let itemTopicsSlugs = item.topics.map((t) => t.slug);
      if (itemTopicsSlugs.includes(filterSlug)) {
        return item;
      }
    });
    setFilteredNews(filteredNews);
  }, [selectedFilter, news]);

  if (!didNewsLoad) {
    return <NewsCardsSkeleton />;
  } else {
    return (
      <>
        <MetaTags
          title={'my-sreal News | Nachrichten, Einblicke und Hintergründe aus der Immobilienwelt'}
          description={'my-sreal News bietet Nachrichten, Einblicke und Hintergründe aus der Immobilienwelt. Erhalten Sie mehr Einblick auf my-sreal.at – die Online-Plattform für Ihre Immobilie.'}
          imageName={'mysreal_hero_news.jpg'}
        />
        <HeaderSmallWithIcon icon={<IconNews />} headlineDesktop="News" cssClasses="header--news header--pinkbg" />

        <MainContent cssClasses="news">
          {news?.length > 0 && !newsError ? (
            <>
              <h1 className="headline--mobile w-desktop--center">News</h1>
              <div className="gray-background mobile-hidden tablet-hidden">
                <h1 className="w-desktop--center">Aktuelle Artikel</h1>
                <NewsCards news={news.slice(0, 3)} topic={true} cssClasses="news__maincards" />
              </div>

              <div className="w-desktop--center">
                {news && news.length > 0 ? (
                  <>
                    <div className={!isMobile ? 'd-flex justify-content-between align-items-center w-desktop--center' : ''}>
                      <h2 className="mobile-hidden tablet-hidden mt-200rem mb-200rem">Alle Artikel</h2>
                      {filterOptions.length > 0 && <FilterMenu activeIndex={selectedFilter} handleClick={handleSetActiveFilterIndex} categories={filterOptions} cutside="right" />}
                    </div>
                    <NewsCards cssClasses={`news__other ${isTablet ? 'mt-200rem' : ''}`} news={filteredNews.slice(0, shownEntries)} selectedFilter={selectedFilter} filterOptions={filterOptions} />

                    {filteredNews.length > shownEntries && (
                      <span onClick={showMoreEntries} className="textlink w-100 d-flex flex-col align-items-center">
                        Weitere laden
                      </span>
                    )}
                  </>
                ) : null}
              </div>
            </>
          ) : (
            <div className="w-desktop--center">
              <p className="page__emptymessage">Es konnten leider keine News geladen werden. Bitte Seite aktualisieren, um News neu zu laden.</p>
            </div>
          )}
        </MainContent>
      </>
    );
  }
}

export default News;
