import '../../styles/immofeatures.scss';
import { getKaufpreis } from '../../util/immoObjectParser';

function ImmoFeatures({ immo, showKaufpreis = true }) {
  return (
    <div className="immodetail__features">
      {/* show kaufpreis if one of the other featues is not available */}
      {showKaufpreis && ((parseInt(immo.areas.living_area) === 0 && parseInt(immo.areas.floor_area) === 0) || parseInt(immo.rooms) === 0 || parseInt(immo.areas.site_area) === 0) && (
        <>
          <div className="immodetail__features-wrapper">
            <span className="immodetail__features--val no-break">{getKaufpreis(immo)}</span>
            <span className="immodetail__features--label">Kaufpreis</span>
          </div>
        </>
      )}

      {parseInt(immo.areas.living_area) > 0 ? (
        <div className="immodetail__features-wrapper">
          <span className="immodetail__features--val">{immo.areas.living_area.toLocaleString('de-DE')} m²</span>
          <span className="immodetail__features--label">Wohnfläche</span>
        </div>
      ) : parseInt(immo.areas.floor_area) > 0 ? (
        <div className="immodetail__features-wrapper">
          <span className="immodetail__features--val">
            {immo.areas.floor_area.toLocaleString('de-DE')} m²
            {parseInt(immo.areas.floor_area_to) > 0 && (
              <>
                {' - '}
                {immo.areas.floor_area_to.toLocaleString('de-DE')} m²
              </>
            )}
          </span>
          <span className="immodetail__features--label">Nutzfläche</span>
        </div>
      ) : null}

      {parseInt(immo.rooms) > 0 && (
        <div className="immodetail__features-wrapper">
          <span className="immodetail__features--val">{immo.rooms?.toString().replace('.', ',')}</span>
          <span className="immodetail__features--label">Zimmer</span>
        </div>
      )}

      {parseInt(immo.areas.site_area) > 0 && (
        <div className="immodetail__features-wrapper">
          <span className="immodetail__features--val">{immo.areas.site_area.toLocaleString('de-DE')} m²</span>
          <span className="immodetail__features--label">Grundfläche</span>
        </div>
      )}
    </div>
  );
}

export default ImmoFeatures;
