function IconDotsRightLeft({ cssClasses, color }) {
  return (
    <svg
      className={cssClasses}
      xmlns="http://www.w3.org/2000/svg"
      width="74.531"
      height="82.756"
      viewBox="0 0 74.531 82.756"
    >
      <path
        data-name="Pfad 7469"
        d="M-16525.469-16640.756v54.246H-16597v28.51"
        transform="translate(16598.5 16640.756)"
        fill="none"
        stroke={color}
        strokeWidth="3"
        strokeDasharray="3"
      />
    </svg>
  );
}

export default IconDotsRightLeft;
