function IconHandDocument({ cssClasses, color }) {
  return (
    <svg
      className={cssClasses}
      data-name="Gruppe 11536"
      xmlns="http://www.w3.org/2000/svg"
      width="26.403"
      height="26.402"
      viewBox="0 0 26.403 26.402"
    >
      <g id="" data-name="Gruppe 11533">
        <path
          id=""
          data-name="Pfad 8056"
          d="M25.241,5.661l-5.5-5.5A.556.556,0,0,0,19.351,0h-8.8A.55.55,0,0,0,10,.55v15.4a.545.545,0,0,0,.245.457l4.951,3.3a.538.538,0,0,0,.3.094h9.351a.55.55,0,0,0,.55-.55V6.051a.554.554,0,0,0-.161-.389m-5.89.389V.55l5.5,5.5Z"
          transform="translate(1.001)"
          fill={color}
        />
        <path
          id=""
          data-name="Pfad 8057"
          d="M4.951,16H.55a.55.55,0,0,0-.55.55v7.7a.55.55,0,0,0,.55.55h4.4a.55.55,0,0,0,.55-.55v-7.7a.55.55,0,0,0-.55-.55"
          transform="translate(0 1.602)"
          fill={color}
        />
        <path
          id=""
          data-name="Pfad 8058"
          d="M16.5,19.2H14.535a.242.242,0,0,1-.022-.025A6.024,6.024,0,0,0,9.9,17H6.05a.55.55,0,0,0-.55.55v5.5a.55.55,0,0,0,.55.55h18.7a.55.55,0,0,0,.55-.55c0-2.27-4.638-3.85-8.8-3.85"
          transform="translate(0.551 1.702)"
          fill={color}
        />
      </g>
    </svg>
  );
}

export default IconHandDocument;
