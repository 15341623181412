function IconKaufvertrag({ color, cssClasses }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="235.114"
      height="72.707"
      viewBox="0 0 235.114 72.707"
      className={cssClasses}
    >
      <g
        data-name="Gruppe 11491"
        transform="translate(0.001 0)"
      >
        <g
          data-name="Gruppe 11442"
          transform="translate(-0.001 0)"
        >
          <path
            data-name="Pfad 8000"
            d="M4.742,57.74V4H1.581A1.581,1.581,0,0,0,0,5.581v56.9a1.581,1.581,0,0,0,1.581,1.581H45.837a1.581,1.581,0,0,0,1.581-1.581V59.321H6.322A1.581,1.581,0,0,1,4.742,57.74"
            transform="translate(0 8.645)"
            fill="#fff"
          />
          <path
            data-name="Pfad 8001"
            d="M8.822,55.74a1.581,1.581,0,0,1-1.581-1.581V2H4.081A1.581,1.581,0,0,0,2.5,3.581v56.9H46.756A1.581,1.581,0,0,0,48.337,58.9l0-3.161Z"
            transform="translate(5.403 4.322)"
            fill="#fff"
          />
          <path
            data-name="Pfad 8002"
            d="M48.795,16.267,43.124,10.6,39.728,7.2l-.882-.882L35.685,3.161l-2.7-2.7A1.6,1.6,0,0,0,31.87,0H6.581A1.581,1.581,0,0,0,5,1.581V56.9H47.676a1.581,1.581,0,0,0,1.581-1.581V17.386a1.592,1.592,0,0,0-.462-1.119M31.87,17.386V1.581L47.676,17.386Z"
            transform="translate(10.806)"
            fill="#fff"
          />
        </g>
      </g>
      <g
        data-name="Gruppe 11492"
        transform="translate(173.599 5.597)"
      >
        <g
          data-name="Gruppe 11438"
          transform="translate(0.001)"
        >
          <path
            data-name="Pfad 7996"
            d="M43.573,25.7V14.1a1.291,1.291,0,0,0-.374-.907L30.383.374A1.3,1.3,0,0,0,29.476,0H1.282A1.282,1.282,0,0,0,0,1.282V55.107a1.282,1.282,0,0,0,1.282,1.282h28.23A19.167,19.167,0,0,1,43.573,25.7M29.476,1.282,42.291,14.1H29.476Z"
            fill="#fff"
          />
          <path
            data-name="Pfad 7997"
            d="M27.66,11a16.66,16.66,0,1,0,16.66,16.66A16.676,16.676,0,0,0,27.66,11M37,24.328,26.3,36.047l-7.938-7.938a1.28,1.28,0,0,1,1.81-1.81l6.044,6.036L35.1,22.6A1.282,1.282,0,0,1,37,24.328"
            transform="translate(17.194 17.194)"
            fill="#fff"
          />
        </g>
      </g>
      <g
        data-name="Gruppe 11493"
        transform="translate(-17548.029 17357.502)"
      >
        <g
          data-name="Gruppe 11448"
          transform="translate(355.999)"
        >
          <g
            data-name="Gruppe 11447"
            transform="translate(16865.863 -18298.693)"
          >
            <path
              data-name="Pfad 8007"
              d="M456.128,986.4l10.06-8.853-10.06-8.853"
              transform="translate(-2.049 0)"
              fill="none"
              stroke="#fff"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="4"
            />
          </g>
        </g>
        <g
          data-name="Gruppe 11450"
          transform="translate(338.999)"
        >
          <g
            data-name="Gruppe 11449"
            transform="translate(16865.863 -18298.693)"
          >
            <path
              data-name="Pfad 8008"
              d="M456.128,986.4l10.06-8.853-10.06-8.853"
              transform="translate(-2.049 0)"
              fill="none"
              stroke="#fff"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="4"
            />
          </g>
        </g>
        <g
          data-name="Gruppe 11452"
          transform="translate(321.999)"
        >
          <g
            data-name="Gruppe 11451"
            transform="translate(16865.863 -18298.693)"
          >
            <path
              data-name="Pfad 8009"
              d="M456.128,986.4l10.06-8.853-10.06-8.853"
              transform="translate(-2.049 0)"
              fill="none"
              stroke="#fff"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="4"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default IconKaufvertrag;
