function IconHouseSearch({ color, cssClasses }) {
  return (
    <svg className={cssClasses} data-name="Gruppe 8939" xmlns="http://www.w3.org/2000/svg" width="37.417" height="37.418" viewBox="0 0 37.417 37.418">
      <g data-name="Gruppe 8930" transform="translate(6.282 5.095)">
        <g data-name="Gruppe 8927" transform="translate(1.815 1.785)">
          <g data-name="Gruppe 8929" transform="translate(0 0)">
            <path data-name="Pfad 7345" d="M17.619,3.438a.228.228,0,0,0,.389-.161V1.225A.228.228,0,0,0,17.781,1H15.729a.228.228,0,0,0-.161.389Z" transform="translate(-6.488 -0.997)" fill={color} />
            <path data-name="Pfad 7346" d="M3,8.439V15.8H7.527V11.27h2.587V15.8H14.64V8.439l-5.82-5.82Z" transform="translate(-3.001 -2.619)" fill={color} />
          </g>
        </g>
        <path
          data-name="Pfad 7348"
          d="M9.12,10.089a1,1,0,0,0,.695.275.964.964,0,0,0,.981-.942V.942A.963.963,0,0,0,9.815,0H.982A.982.982,0,0,0,.075.584.911.911,0,0,0,.287,1.609Z"
          transform="translate(0 7.634) rotate(-45)"
          fill={color}
        />
      </g>
      <g data-name="Gruppe 8931">
        <path
          data-name="Pfad 7344"
          d="M14.032,28.063a13.962,13.962,0,0,0,8.782-3.106l11.939,12a1.56,1.56,0,0,0,2.211-2.2L25.009,22.744a14.017,14.017,0,1,0-10.977,5.32m0-24.945A10.913,10.913,0,1,1,3.118,14.032,10.926,10.926,0,0,1,14.032,3.118"
          fill={color}
        />
      </g>
    </svg>
  );
}

export default IconHouseSearch;
