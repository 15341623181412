import { useNavigate } from 'react-router-dom';
import HeaderSmallWithIcon from '../components/Header/HeaderSmallWithIcon';
import IconPageNotFound from '../components/Icons/IconPageNotFound';
import MainContent from '../components/MainContent';

import MetaTags from '../components/MetaTags';

function PageNotFound() {
  const navigate = useNavigate();

  return (
    <>
      <MetaTags title={'Seite nicht gefunden | Die Online-Plattform für Ihre Immobilie'} />
      <HeaderSmallWithIcon
        icon={<IconPageNotFound color="#3679ec" cssClasses="p-10" />}
        iconActive={false}
        headlineDesktop="Seite nicht gefunden"
      />

      <MainContent cssClasses="w-desktop--center">
        <h1 className="desktop-hidden">Seite nicht gefunden</h1>
        <p className="pb-20">Oje, das hätte nicht passieren dürfen. Leider konnte diese Seite nicht oder nicht mehr gefunden werden.</p>

        <span
          className="textlink"
          onClick={() => {
            navigate(-1);
          }}
        >
          Vorherige Seite aufrufen
        </span>
      </MainContent>
    </>
  );
}

export default PageNotFound;
