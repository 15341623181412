import { useState } from 'react';
import Card from '../Cards/Card';
import InfoDialog from '../Dialogs/InfoDialog';
import FilterMenu from '../FilterMenu';
import InfoMark from '../InfoMark';
import LineChart from './LineChart';

import { Link, useNavigate } from 'react-router-dom';
import { STATS_FILTER_AKTUELLEWOCHE, STATS_FILTER_GESAMT, STATS_FILTER_LETZTE3WOCHEN } from '../../constants';
import StatsSkeleton from '../Skeletons/StatsSkeleton';
import { useSelector } from 'react-redux';
import { useHandleScreenResize } from '../../hooks/app/useHandleScreenResize';

function InseratKlicksChart({ chartData, isDashboardVersion, chartConfig, filterIndex, isMobile }) {
  const app = useSelector((state) => state.app);
  const navigate = useNavigate();

  const filterMenuStats = [STATS_FILTER_AKTUELLEWOCHE, STATS_FILTER_LETZTE3WOCHEN, STATS_FILTER_GESAMT];
  const [activeFilterIndexStats, setActiveFilterIndexStats] = useState(STATS_FILTER_GESAMT.key);
  const handleSetActiveFilterIndexStats = (event) => {
    let index = event.target.dataset.index;
    setActiveFilterIndexStats(index);
  };

  const [infoModalQuellenOpen, setInfoModalQuellenOpen] = useState(false);
  const handleInfoModalQuellen = () => {
    setInfoModalQuellenOpen(!infoModalQuellenOpen);
  };

  const lineChartConfig = {
    type: 'line',
    plugins: {
      legend: {
        display: false,
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: {
          color: '#5d5d5d',
          borderColor: '#fff',
          display: false,
        },
        ticks: {
          color: '#5d5d5d',
        },
      },
      y: {
        position: 'right',
        grid: {
          color: '#5d5d5d',
          borderColor: '#fff',
          borderDash: [2],
        },
        ticks: {
          color: '#5d5d5d',
        },
      },
    },
  };

  const headline = 'Klicks auf Inserat';

  // const { isMobile } = useHandleScreenResize();

  if (!chartData) {
    // return <StatsSkeleton headline={headline} isDashboardVersion={isDashboardVersion} />;
    return null;
  } else {
    return (
      <>
        {isMobile ? (
          <div className="statistic-card--wrapper clickchart">
            {!isDashboardVersion && (
              <div className="statistic-card__header">
                <h2>{headline} gesamt</h2>
                <FilterMenu categories={filterMenuStats} activeIndex={activeFilterIndexStats} handleClick={handleSetActiveFilterIndexStats} cutside="right" />
              </div>
            )}

            {chartData.data && chartData.data[filterIndex || activeFilterIndexStats] ? (
              !isDashboardVersion ? (
                <div className="statistic-card__data">
                  <div className="statistic-card__chart">
                    <p className="statistic-card__linechart--count">{chartData.count[filterIndex || activeFilterIndexStats]}</p>
                    <LineChart height={100} chartData={chartData.data[filterIndex || activeFilterIndexStats]} config={chartConfig || lineChartConfig} />
                  </div>
                  <div className="statistic-card__legend">
                    <h3 className="headline--withinfomark">
                      {headline} gesamt nach Quellen
                      <InfoMark handleClick={handleInfoModalQuellen} character={'i'} />
                    </h3>

                    {chartData.legend?.length > 0 && (
                      <div className="mt-200rem">
                        {chartData.legend.map((l) => {
                          return (
                            <p key={l.source}>
                              <span className="font-weight-700 font-120 mr-50rem">{l.clicks[filterIndex || activeFilterIndexStats]}</span>
                              <span>{l.source}</span>
                            </p>
                          );
                        })}
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <>
                  <h3 className="headline--withinfomark">{headline} gesamt</h3>
                  <div className="statistic-card__chart-dashboard clickchart">
                    <p className="statistic-card__linechart--count">{chartData.count[filterIndex || activeFilterIndexStats]}</p>
                    <LineChart height={150} chartData={chartData.data[filterIndex || activeFilterIndexStats]} config={chartConfig || lineChartConfig} />
                    <button className="button button--glass" onClick={() => navigate(app.root + '/statistik')}>
                      <span>Details ansehen</span>
                    </button>
                  </div>
                </>
              )
            ) : (
              <>
                {isDashboardVersion && <h2>{headline}</h2>}
                <p className="statistic-card__emptymsg">Es sind noch keine Daten vorhanden.</p>
              </>
            )}
          </div>
        ) : (
          <Card cardStyle="customContent" cssClasses={`${isDashboardVersion ? 'w-75' : ''} statistic-card`}>
            {!isDashboardVersion && (
              <div className="statistic-card__header">
                <h2>{headline} gesamt</h2>
                <FilterMenu categories={filterMenuStats} activeIndex={activeFilterIndexStats} handleClick={handleSetActiveFilterIndexStats} cutside="right" />
              </div>
            )}

            {chartData.data && chartData.data[filterIndex || activeFilterIndexStats] ? (
              !isDashboardVersion ? (
                <div className="statistic-card__data">
                  <div className="statistic-card__chart">
                    <p className="statistic-card__linechart--count">{chartData.count[filterIndex || activeFilterIndexStats]}</p>
                    <LineChart height={200} chartData={chartData.data[filterIndex || activeFilterIndexStats]} config={chartConfig || lineChartConfig} />
                  </div>
                  <div className="statistic-card__legend">
                    <h3 className="headline--withinfomark">
                      {headline} gesamt nach Quellen
                      <InfoMark handleClick={handleInfoModalQuellen} character={'i'} />
                    </h3>

                    {chartData.legend?.length > 0 && (
                      <div className="mt-200rem">
                        {chartData.legend.map((l) => {
                          return (
                            <p key={l.source}>
                              <span className="font-weight-700 font-120 mr-50rem">{l.clicks[filterIndex || activeFilterIndexStats]}</span>
                              <span>{l.source}</span>
                            </p>
                          );
                        })}
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <>
                  <h3 className="headline--withinfomark">{headline} gesamt</h3>
                  <div className="statistic-card__chart-dashboard">
                    <p className="statistic-card__linechart--count">{chartData.count[filterIndex || activeFilterIndexStats]}</p>
                    <LineChart height={100} chartData={chartData.data[filterIndex || activeFilterIndexStats]} config={chartConfig || lineChartConfig} />
                    <button className="button button--glass" onClick={() => navigate(app.root + '/statistik')}>
                      <span>Details ansehen</span>
                    </button>
                  </div>
                </>
              )
            ) : (
              <>
                {isDashboardVersion && <h2>{headline}</h2>}
                <p className="statistic-card__emptymsg">Es sind noch keine Daten vorhanden.</p>
              </>
            )}
          </Card>
        )}

        <InfoDialog handleClose={handleInfoModalQuellen} open={infoModalQuellenOpen} headline={'Liste aller Quellen'}>
          <p>Die Basis der angezeigten Statistikdaten stammen von verschiedenen Websites und Immobilienportalen. Derzeit verwenden wir Daten von:</p>
          <ul className="mt-100rem mb-200rem ml-200rem">
            <li>sreal.at</li>
            <li>my-sreal.at </li>
            <li>willhaben.at</li>
          </ul>
          <p>Wir arbeiten laufend daran, die Quellen zu erweitern und Ihnen ein noch breiteres Angebot zu bieten.</p>
        </InfoDialog>
      </>
    );
  }
}

export default InseratKlicksChart;
