import { useState } from 'react';
import { useSelector } from 'react-redux';
import SuchprofilCard from '../Cards/SuchprofilCard';
import Carousel from '../Carousel/Carousel';
import CarouselNew from '../Carousel/CarouselNew';
import FilterMenu from '../FilterMenu';

import { SEARCHPROFILE_STATE_ACTIVE, SEARCHPROFILE_STATE_INACTIVE, SP_ACTIVE_INDEX, SP_INACTIVE_INDEX } from '../../constants';
import { useHandleScreenResize } from '../../hooks/app/useHandleScreenResize';

function ProfileSuchprofile({ cssClasses = '' }) {
  const user = useSelector((state) => state.user);
  const [filterIndex, setFilterIndex] = useState(SP_ACTIVE_INDEX);

  const { isMobile } = useHandleScreenResize();

  let suchProfilMenu = [];
  suchProfilMenu[SP_ACTIVE_INDEX] = {
    label: 'Aktive Suchprofile',
    state: SEARCHPROFILE_STATE_ACTIVE,
  };
  suchProfilMenu[SP_INACTIVE_INDEX] = {
    label: 'Inaktive Suchprofile',
    state: SEARCHPROFILE_STATE_INACTIVE,
  };

  const handleSetSPFilter = (event) => {
    let index = event.target.dataset.index;
    setFilterIndex(index);
  };

  return (
    <>
      <div id="searchprofiles">
        <div className="w-desktop--center headline--withfiltermenu">
          <h2 className="m-0">Suchprofile</h2>
          <FilterMenu activeIndex={filterIndex} categories={suchProfilMenu} handleClick={handleSetSPFilter} />
        </div>

        {user.searchProfiles.filter((sp) => sp.state === suchProfilMenu[filterIndex].state).length === 0 ? (
          <div className="w-desktop--center">
            <p>Keine Suchprofile vorhanden.</p>
          </div>
        ) : (
          <div>
            {!isMobile ? (
              <div className="w-desktop--slider mt-300rem">
                <CarouselNew cssClasses="searchprofiles">
                  {user.searchProfiles
                    .filter((sp) => sp.state === suchProfilMenu[filterIndex].state)
                    .map((sp) => (
                      <SuchprofilCard key={sp.created_at} suchProfilData={sp} />
                    ))}
                </CarouselNew>
              </div>
            ) : (
              <div className="w-desktop--center">
                {user.searchProfiles
                  .filter((sp) => sp.state === suchProfilMenu[filterIndex].state)
                  .map((sp) => (
                    <SuchprofilCard key={sp.created_at} suchProfilData={sp} />
                  ))}
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
}

export default ProfileSuchprofile;
