import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';
import { useMemo, memo } from 'react';
import { useSelector } from 'react-redux';
import IconLocation from '../Icons/IconLocation';
import markerImg from '../../assets/images/map-marker.png';

function Map({ coordinates }) {
  const app = useSelector((state) => state.app);

  let { isLoaded } = useLoadScript({ googleMapsApiKey: app.googleMapsKey });

  const center = useMemo(() => ({ lat: coordinates.latitude, lng: coordinates.longitude }), []);

  return (
    <div className="maps-container-wrapper">
      {isLoaded ? (
        <GoogleMap mapContainerClassName="maps-container" center={center} zoom={13}>
          <Marker position={center} icon={markerImg} />
        </GoogleMap>
      ) : (
        <IconLocation color={'#3679ec'} cssClasses={'position-center'} />
      )}
    </div>
  );
}

export default memo(Map);
