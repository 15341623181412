function IconDocumentAccept({color, cssClasses}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="125.232"
      height="125.232"
      viewBox="0 0 125.232 125.232"
      className={cssClasses}
    >
      <path
        data-name="Pfad 8066"
        d="M29.654,5.609A2.61,2.61,0,0,0,27.045,3H14V44.076a38.719,38.719,0,0,1,15.654-4.414Z"
        transform="translate(59.052 12.654)"
        fill={color}
      />
      <path
        data-name="Pfad 8067"
        d="M15.654,86.488V3H2.609A2.61,2.61,0,0,0,0,5.609V89.1a13.063,13.063,0,0,0,13.045,13.045H60.08a38.946,38.946,0,0,1-7.112-15.654Z"
        transform="translate(0 12.654)"
        fill={color}
      />
      <path
        data-name="Pfad 8068"
        d="M33.7,9H7.609a2.609,2.609,0,1,0,0,5.218H33.7A2.609,2.609,0,1,0,33.7,9"
        transform="translate(21.09 37.962)"
        fill={color}
      />
      <path
        data-name="Pfad 8069"
        d="M33.7,11H7.609a2.609,2.609,0,0,0,0,5.218H33.7A2.609,2.609,0,0,0,33.7,11"
        transform="translate(21.09 46.398)"
        fill={color}
      />
      <path
        data-name="Pfad 8070"
        d="M36.308,15.609A2.61,2.61,0,0,0,33.7,13H7.609a2.609,2.609,0,1,0,0,5.218H33.7a2.61,2.61,0,0,0,2.609-2.609"
        transform="translate(21.09 54.834)"
        fill={color}
      />
      <path
        data-name="Pfad 8071"
        d="M7.609,15a2.609,2.609,0,1,0,0,5.218H23.263a2.609,2.609,0,1,0,0-5.218Z"
        transform="translate(21.09 63.27)"
        fill={color}
      />
      <path
        data-name="Pfad 8072"
        d="M6.609,36.526H48.353a2.61,2.61,0,0,0,2.609-2.609V13.045a2.61,2.61,0,0,0-2.609-2.609H40.291C39.148,4.028,34.019,0,27.481,0c-6.444,0-11.626,3.919-12.81,10.436H6.609A2.61,2.61,0,0,0,4,13.045V33.917a2.61,2.61,0,0,0,2.609,2.609"
        transform="translate(16.872 0)"
        fill={color}
      />
      <path
        data-name="Pfad 8073"
        d="M44.917,11A33.917,33.917,0,1,0,78.834,44.917,33.949,33.949,0,0,0,44.917,11m19,27.134L42.157,61.985,26,45.83a2.6,2.6,0,0,1,3.684-3.684l12.3,12.288,18.08-19.818a2.61,2.61,0,0,1,3.856,3.517"
        transform="translate(46.398 46.398)"
        fill={color}
      />
    </svg>
  );
}

export default IconDocumentAccept;
