function IconMail({ cssClasses, color }) {
  return (
    <svg
      className={cssClasses}
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="11"
      viewBox="0 0 16 11"
    >
      <defs>
        <clipPath id="IconMail">
          <path data-name="Rechteck 3980" fill={color} d="M0 0h16v11H0z" />
        </clipPath>
      </defs>
      <g data-name="Gruppe 11828">
        <g data-name="Gruppe 11689" clipPath="url(#a)" fill={color}>
          <path
            data-name="Pfad 8095"
            d="M15.94.717 8.308 6.894a.367.367 0 0 1-.461 0L.214.717A1.452 1.452 0 0 0 0 1.469v8.072a1.47 1.47 0 0 0 1.469 1.469h13.217a1.47 1.47 0 0 0 1.468-1.469V1.469a1.452 1.452 0 0 0-.214-.752"
          />
          <path
            data-name="Pfad 8096"
            d="M15.411.2a1.459 1.459 0 0 0-.725-.2H1.468a1.459 1.459 0 0 0-.725.2l7.334 5.936Z"
          />
        </g>
      </g>
    </svg>
  );
}

export default IconMail;
