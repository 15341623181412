function IconSend({ color, cssClasses }) {
  return (
    <svg
      className={cssClasses}
      data-name="Gruppe 10525"
      xmlns="http://www.w3.org/2000/svg"
      width="18.033"
      height="16.476"
      viewBox="0 0 18.033 16.476"
    >
      <path
        id="Pfad_7783"
        data-name="Pfad 7783"
        d="M.232,7.1a.376.376,0,0,0,.008.7l6.246,2.43L17.418,0Z"
        transform="translate(0)"
        fill={color}
      />
      <path
        id="Pfad_7784"
        data-name="Pfad 7784"
        d="M9.008,11.133,9,11.126V16.25a.376.376,0,0,0,.7.19L12.175,12.2l4.545,2.5a.376.376,0,0,0,.549-.25L20.261.607Z"
        transform="translate(-2.228 -0.15)"
        fill={color}
      />
    </svg>
  );
}

export default IconSend;
