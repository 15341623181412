import { createSlice } from '@reduxjs/toolkit';

import { sortOptions } from '../components/FormFields/util/formOptions';

const initialState = {
  siteIndex: 0,
  headline: 'Immobiliensuche',
  searchFormExpanded: false,
  address: '',
  realtySubtypeOptions: [],
  cachedImmoList: [],
  cachedImmoListCount: 0,
  cachedSorting: sortOptions[0],
  requestBody: {
    addressObject: [],
    estateType: [],
    priceFrom: '',
    priceTo: '',
    areaFrom: '',
    areaTo: '',
    subEstateType: [],
    surfaceAreaFrom: '',
    surfaceAreaTo: '',
    roomsFrom: '',
    roomsTo: '',
    features: [],
    fullText: '',
    sorting: sortOptions[0],
  },
};

export const searchFormSlice = createSlice({
  name: 'searchForm',
  initialState,
  reducers: {
    setSiteIndexSearchForm: (state, action) => {
      state.siteIndex = action.payload;
    },
    setSearchFormExpanded: (state, action) => {
      state.searchFormExpanded = action.payload;
    },
    setAddressSearchForm: (state, action) => {
      state.address = action.payload;
    },
    setCachedImmoList: (state, action) => {
      state.cachedImmoList = action.payload;
    },
    setCachedImmoListCount: (state, action) => {
      state.cachedImmoListCount = action.payload;
    },
    setCachedSorting: (state, action) => {
      state.cachedSorting = action.payload;
    },
    setAddressObjectSearchForm: (state, action) => {
      state.requestBody.addressObject = action.payload;
    },
    clearAddressObjectSearchForm: (state) => {
      state.requestBody.addressObject = [];
    },
    setEstateTypeSearchForm: (state, action) => {
      state.requestBody.estateType = action.payload;
    },
    setPriceFromSearchForm: (state, action) => {
      state.requestBody.priceFrom = action.payload;
    },
    setPriceToSearchForm: (state, action) => {
      state.requestBody.priceTo = action.payload;
    },
    setAreaFromSearchForm: (state, action) => {
      state.requestBody.areaFrom = action.payload;
    },
    setAreaToSearchForm: (state, action) => {
      state.requestBody.areaTo = action.payload;
    },
    setSubEstateTypeSearchForm: (state, action) => {
      state.requestBody.subEstateType = action.payload;
    },
    setSurfaceAreaFromSearchForm: (state, action) => {
      state.requestBody.surfaceAreaFrom = action.payload;
    },
    setSurfaceAreaToSearchForm: (state, action) => {
      state.requestBody.surfaceAreaTo = action.payload;
    },
    setRoomsFromSearchForm: (state, action) => {
      state.requestBody.roomsFrom = action.payload;
    },
    setRoomsToSearchForm: (state, action) => {
      state.requestBody.roomsTo = action.payload;
    },
    setFeaturesSearchForm: (state, action) => {
      state.requestBody.features = action.payload;
    },
    setFullTextSearchForm: (state, action) => {
      state.requestBody.fullText = action.payload;
    },
    setSortingSearchForm: (state, action) => {
      state.requestBody.sorting = action.payload;
    },
    setRealtySubTypeOptions: (state, action) => {
      state.realtySubtypeOptions = action.payload;
    },
    setResetFieldsSearchForm: (state, action) => {
      return initialState;
    },
  },
});

export const {
  setSiteIndexSearchForm,
  setSearchFormExpanded,
  setAddressSearchForm,
  setCachedImmoList,
  setCachedImmoListCount,
  setCachedSorting,
  setAddressObjectSearchForm,
  clearAddressObjectSearchForm,
  setEstateTypeSearchForm,
  setPriceFromSearchForm,
  setPriceToSearchForm,
  setAreaFromSearchForm,
  setAreaToSearchForm,
  setSubEstateTypeSearchForm,
  setSurfaceAreaFromSearchForm,
  setSurfaceAreaToSearchForm,
  setRoomsFromSearchForm,
  setRoomsToSearchForm,
  setFeaturesSearchForm,
  setFullTextSearchForm,
  setSortingSearchForm,
  setRealtySubTypeOptions,
  setResetFieldsSearchForm,
} = searchFormSlice.actions;

export default searchFormSlice.reducer;
