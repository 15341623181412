//*React
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useSearchParams } from 'react-router-dom';

//*Icons
import IconEmailSent from '../../Icons/IconEmailSent';

//*Components
import ButtonForwardAction from '../../Buttons/ButtonForwardAction';
import LoadingSpinner from '../../LoadingSpinner';

//*API
import { confirm } from '../../../api/Onboarding';

//*Reducers
import { setFormIsLoading } from '../../../reducers/app';
import { setPhoneNumberRegisterForm, setSiteIndexRegisterForm } from '../../../reducers/registerForm';

//*Constants
import {
  REGFORM_SITEINDEX_LINKEXPIRED,
  REGFORM_SITEINDEX_SUCCESSTOKEN,
  REGFORM_SITEINDEX_USEREXISTS,
} from '../../../constants';

function RegisterFormFastFertig() {
  let registerForm = useSelector((state) => state.registerForm);
  let app = useSelector((state) => state.app);
  
  let location = useLocation();
  let dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (location.pathname.indexOf('/confirm') >= 0) {
      searchParams.delete('UserId');
      searchParams.delete('Code');
      setSearchParams(searchParams);
    }
  }, []);

  const handleSendToken = async () => {
    if (app.formIsLoading) {
      return;
    }

    const userId = registerForm.userId;
    const code = registerForm.code;

    if (userId && code) {
      dispatch(setFormIsLoading(true));
      try {
        const res = await confirm({
          userId: userId,
          code: code,
        });
		const [phoneNumber, verificationCode] = res.split(',');

        localStorage.removeItem('tokenTimerStart');
        dispatch(setFormIsLoading(false));
		dispatch(setPhoneNumberRegisterForm({text: phoneNumber}));
        dispatch(setSiteIndexRegisterForm(REGFORM_SITEINDEX_SUCCESSTOKEN));
      } catch (e) {
        dispatch(setFormIsLoading(false));
        let errDetail = e.response?.data?.detail;
        if (errDetail === 'user-already-registered') {
          dispatch(setSiteIndexRegisterForm(REGFORM_SITEINDEX_USEREXISTS));
        }
        else if(errDetail === 'SMS_EXCEEDED') {
          dispatch(setSiteIndexRegisterForm(REGFORM_SITEINDEX_SUCCESSTOKEN));
        } else {
          dispatch(setSiteIndexRegisterForm(REGFORM_SITEINDEX_LINKEXPIRED));
        }
      }
    } else {
      dispatch(setSiteIndexRegisterForm(REGFORM_SITEINDEX_LINKEXPIRED));
    }
  };

  const formType = 'registerForm';
  return (
    <>
      <div className="d-flex w-100 justify-content-center pt-40 pb-40">
        <IconEmailSent color="#1E3163" />
      </div>
      <h2 className="pt-200rem">Fast fertig!</h2>
      <p className="pt-200rem pb-200rem">
        Nur noch ein Schritt, bis zum Abschluss Ihrer Registrierung. Bitte fordern Sie den SMS-Code
        an und bestätigen Sie damit Ihre Mobilnummer.
      </p>

      <LoadingSpinner />
      <div className="button-panel mb-10">
        <ButtonForwardAction
          formType={formType}
          buttonText="SMS-Code anfordern"
          forwardAction={handleSendToken}
        />
      </div>
    </>
  );
}

export default RegisterFormFastFertig;
