import Card from './Card';

function IconContentCard({ cssClasses = '', iconBox, children }) {
  if (!cssClasses) cssClasses = 'icon-content-card';
  else cssClasses += ' icon-content-card';

  let cardStyle = 'customContent';

  return (
    <>
      <Card
        cssClasses={cssClasses + ' text-center'}
        cardStyle={cardStyle}
        //icon={<IconChat cssClasses={cardStyle == 'topImage2Cols' ? 'scale-1-7' : ''} color="#fff" />}
        //dropdownHeadline="Hotline & Kontakt"
        //dropdownChildren={contactInformation}
        //handleInfoModalDesktop={handleInfoModalDesktop}
      >
        <div className="card__content">
          <div>
            <h2>Von Anfang bis Ende bestens betreut</h2>
            <p className="icon-content-card__subheadline">
              Sie suchen eine Immobilie oder möchten ein Objekt verkaufen? Dann ist jetzt der richtige Zeitpunkt sich zu registrieren. s REAL unterstützt Sie ab Ihrem ersten Suchprofil oder
              Beratungsgespräch zu einem Immobilienverkauf bis zur erfolgreichen Übergabe und darüber hinaus. Dabei ist das Portal ihr ständiger Begleiter.{' '}
            </p>
            <div className="icon-box-wrapper">
              {iconBox.map((iconBox) => {
                return (
                  <div className="icon-box" key={iconBox.headline}>
                    <div className={'icon-box__icon ' + iconBox.color}>{iconBox.icon}</div>
                    <h5>{iconBox.headline}</h5>
                    <p>{iconBox.text}</p>
                  </div>
                );
              })}
            </div>
          </div>
          {children}
        </div>
      </Card>
    </>
  );
}

export default IconContentCard;
