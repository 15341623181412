import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Linkitem from '../Linkitems/Linkitem';

// ICONS
import IconHouse from '../Icons/IconHouse';

import { getThumbnail } from '../../util/immoObjectParser';
import { anliegenOptions } from '../FormFields/util/formOptions';

import { CONTACTFORM_ANLIEGEN_VERKAUF } from '../../constants';

// STORE
import {
  setPageDialogOpenContactForm,
  setReasonContactForm,
} from '../../reducers/contactInformation';

function ProfileImmobilien() {
  const user = useSelector((state) => state.user);
  const app = useSelector((state) => state.app);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleWeitereImmos = () => {
    dispatch(setReasonContactForm(anliegenOptions[CONTACTFORM_ANLIEGEN_VERKAUF]));
    dispatch(setPageDialogOpenContactForm(true));
  };

  return (
    <>
      {user && user.userRealties && user.userRealties.length > 0 ? (
        <>
          { user.userRealties.length > 1 ? (
            <h2 className="mt-300rem">Meine Immobilien</h2>
          ) : (
            <h2 className="mt-300rem">Meine Immobilie</h2>
          )}

          <div className="elements2cols">
            {user.userRealties.map((immo) => {
              let cover_image = getThumbnail(immo.imagesPublic[0], 'small');

              return (
                <Linkitem
                  handleLink={() =>
                    navigate(app.root + '/immobilie/' + immo.url)
                  }
                  imageSrc={cover_image}
                  imageAlt={immo.title}
                  headline={immo.title}
                  text={immo.address.zip + ' ' + immo.address.city}
                  cssClasses={'elements2cols__col text-nodecoration'}
                  key={immo.id}
                />
              );
            })}

            <Linkitem
              cssClasses={'elements2cols__col linkitem--blue'}
              handleLink={handleWeitereImmos}
              icon={<IconHouse />}
              headline="Weitere Immobilie verkaufen"
              text="Unsere Immobilienexpert:innen beraten Sie gerne"
            />
          </div>
        </>
      ) : null}
    </>
  );
}

export default ProfileImmobilien;
