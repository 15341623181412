function IconKeySuccess({ cssClasses, color }) {
  return (
    <svg width="65.531" height="79.075" viewBox="0 0 65.531 79.075">
      <defs>
        <clipPath id="clip-path">
          <rect id="Rechteck_3423" data-name="Rechteck 3423" width="52.531" height="51.042" fill={color} />
        </clipPath>
      </defs>
      <g id="Gruppe_13239" data-name="Gruppe 13239" transform="translate(-16918.469 15980.075)">
        <g id="Gruppe_10847" data-name="Gruppe 10847" transform="translate(16864 -17559)">
          <g id="Gruppe_8970" data-name="Gruppe 8970" transform="translate(54.469 1578.925)">
            <g id="Gruppe_8969" data-name="Gruppe 8969" clip-path="url(#clip-path)">
              <path id="Pfad_7351" data-name="Pfad 7351" d="M13.3,51.043A13.37,13.37,0,0,0,25.2,31.728l10.258-10.32,4.432,4.461,3.782-3.8L39.236,17.6,42.1,14.72l5.542,5.574,4.888-4.917L46.99,9.8l2.541-2.555a4.29,4.29,0,0,0,0-6.034,4.321,4.321,0,0,0-6,0L19.2,25.694a13.187,13.187,0,0,0-5.9-1.4,13.376,13.376,0,0,0,0,26.751m0-20.063a6.688,6.688,0,1,1-6.648,6.688A6.676,6.676,0,0,1,13.3,30.98" transform="translate(0 -0.003)" fill={color} />
            </g>
          </g>
        </g>
        <g id="Gruppe_10852" data-name="Gruppe 10852" transform="translate(3 1)">
          <circle id="Ellipse_1499" data-name="Ellipse 1499" cx="24" cy="24" r="24" transform="translate(16933 -15950)" fill="#3679ec" />
          <path id="Pfad_7862" data-name="Pfad 7862" d="M31.328,11A20.328,20.328,0,1,0,51.656,31.328,20.348,20.348,0,0,0,31.328,11m11.39,16.263-13.044,14.3-9.686-9.686A1.561,1.561,0,0,1,22.2,29.668l7.374,7.365L40.407,25.155a1.564,1.564,0,0,1,2.311,2.108" transform="translate(16925.781 -15957.223)" fill={color} />
        </g>
      </g>
    </svg>
  );
}

export default IconKeySuccess;
