//*React
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

//*API
import { current } from '../../api/Onboarding';
import { getValuationLight } from '../../api/PriceHubble';

//*Components

import ButtonForwardAction from '../Buttons/ButtonForwardAction';
import InfoDialogBerechnungsbasis from '../Dialogs/InfoDialogBerechnungsbasis';
import InfoDialogZustandImmo from '../Dialogs/InfoDialogZustandImmo';
import FormInput from '../FormFields/FormInput';
import FormSelect from '../FormFields/FormSelect';
import SatisfactionScale from '../FormFields/SatisfactionScale';
import InfoMark from '../InfoMark';
import LoadingSpinner from '../LoadingSpinner';
import WohnwertLightFormResultWrapper from './WohnwertLightFormResultWrapper';
import FormSelectWohnwertLastResults from '../FormFields/customFields/FormSelectWohnwertLastResults';

import {
  anliegenOptions,
  bitteWaehlenOption,
  immobilienartOptions,
} from '../FormFields/util/formOptions';
import { fetchErrorText } from '../FormFields/util/formTexts';
import { validateFields } from '../FormFields/util/validateFields';

//*Icons
import '../../styles/wohnwert.scss';
import IconListElement from '../IconList/IconListElement';
import IconListWrapper from '../IconList/IconListWrapper';
import IconBuildings from '../Icons/IconBuildings';
import IconCheckmark from '../Icons/IconCheckmark';
import IconLocationWithPin from '../Icons/IconLocationWithPin';
import IconLock from '../Icons/IconLock';

//*Constants
import {
  CONTACTFORM_ANLIEGEN_VERKAUF,
  WOHNWERTLIGHT_SITEINDEX_BASIS,
  WOHNWERTLIGHT_SITEINDEX_FORM,
  WOHNWERTLIGHT_SITEINDEX_RESULT,
  WOHNWERTLIGHT_SITEINDEX_ZUSTAND,
} from '../../constants';

//*Reducers
import { setFormIsLoading } from '../../reducers/app';
import '../../styles/wohnwert.scss';

// STORE
import {
  setPageDialogOpenContactForm,
  setReasonContactForm,
} from '../../reducers/contactInformation';
import { setPageDialogOpenLoginForm } from '../../reducers/loginForm';
import {
  setPageDialogOpenRegisterForm,
  setResetFieldsRegisterForm,
} from '../../reducers/registerForm';
import {
  setUserObject,
  setWohnwertLastResults,
  setWohnwertResultsActive,
} from '../../reducers/user';

import {
  setPageDialogOpenWohnwertForm,
  setResetFieldsWohnwertForm,
  setSiteIndexWohnwertForm,
} from '../../reducers/wohnwertLight';

//*Styles
import '../../styles/wohnwert.scss';

// API
import statesWithZips from '../FormFields/util/statesWithZips';
import { useHandleWohnwert } from '../../hooks/user/useHandleWohnwert';

function WohnwertLightForm() {
  const [infoDialogShown, setInfoDialogShown] = useState(false);
  const [infoModalBerechnungsBasisShown, setInfoModalBerechnungsBasisShown] = useState(false);
  const [error, setError] = useState('');
  let currentWohnwertLight = useSelector((state) => state.wohnwertLight);
  let currentFormValidators = useSelector((state) => state.formValidators);
  const app = useSelector((state) => state.app);
  let user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const { getWohnwertResults, getAndSetWohnwertResultActive, handleSetImmoForContactForm, getWohnwertLabel } = useHandleWohnwert();

  const handleInfoModal = () => {
    if (!infoDialogShown) {
      window.location.hash = WOHNWERTLIGHT_SITEINDEX_ZUSTAND;
    } else {
      window.location.hash = WOHNWERTLIGHT_SITEINDEX_FORM;
    }
    setInfoDialogShown(!infoDialogShown);
  };

  const handleInfoModalBerechnungsBasis = () => {
    if (!infoModalBerechnungsBasisShown) {
      window.location.hash = WOHNWERTLIGHT_SITEINDEX_BASIS;
    } else {
      window.location.hash = WOHNWERTLIGHT_SITEINDEX_RESULT;
    }

    setInfoModalBerechnungsBasisShown(!infoModalBerechnungsBasisShown);
  };

  const handleOpenRegisterForm = () => {
    dispatch(setPageDialogOpenWohnwertForm(false));
    dispatch(setResetFieldsWohnwertForm());

    dispatch(setResetFieldsRegisterForm());
    dispatch(setPageDialogOpenRegisterForm(true));
  };

  const handleOpenLoginForm = () => {
    dispatch(setPageDialogOpenWohnwertForm(false));
    dispatch(setResetFieldsWohnwertForm());

    dispatch(setResetFieldsRegisterForm());
    dispatch(setPageDialogOpenLoginForm(true));
  };

  const handleOpenContactForm = () => {
    // dispatch(setPageDialogOpenWohnwertForm(false));
    // dispatch(setResetFieldsWohnwertForm());
    handleSetImmoForContactForm();

    dispatch(setReasonContactForm(anliegenOptions[CONTACTFORM_ANLIEGEN_VERKAUF]));
    dispatch(setPageDialogOpenContactForm(true));
  };

  const handleNewCalc = () => {
    dispatch(setResetFieldsWohnwertForm());
    dispatch(setPageDialogOpenWohnwertForm(true));
    dispatch(setWohnwertResultsActive(bitteWaehlenOption));
  };

  const forwardAction = async () => {
    setError('');
    if (app.formIsLoading) {
      return;
    }

    if (user.wohnwertLastResults.length === 3) {
      setError('Sie dürfen maximal 3 Berechnungen durchführen.');
      return;
    }

    let validationResult = validateFields(
      {
        federalState: currentWohnwertLight.federalState.id,
        district: currentWohnwertLight.typedPostCode,
        estateType: currentWohnwertLight.estateType.id,
        estateSizeWohnwert: currentWohnwertLight.estateSize,
        estateCondition: currentWohnwertLight.estateCondition,
      },
      dispatch,
    );

    if (validationResult) {
      dispatch(setFormIsLoading(true));
      try {
        const wohnwertLight = await getValuationLight(
          currentWohnwertLight.federalState.id,
          currentWohnwertLight.district.id,
          currentWohnwertLight.estateType.text,
          currentWohnwertLight.estateSize,
          currentWohnwertLight.estateCondition,
        );

        if (wohnwertLight) {
          if (user.isLoggedIn) {
            try {
              const currentUser = await current();
              dispatch(setFormIsLoading(false));

              if (currentUser.results?.length > 0) {
                dispatch(setUserObject(currentUser));

                // get+set results
                const wohnwertResults = getWohnwertResults(currentUser);
                dispatch(setWohnwertLastResults(wohnwertResults));

                // set active result
                let lastIndex = wohnwertResults.length - 1;
                const label = getWohnwertLabel(wohnwertResults, lastIndex);
                dispatch(setWohnwertResultsActive({id: lastIndex, text: label}));

                dispatch(setSiteIndexWohnwertForm(WOHNWERTLIGHT_SITEINDEX_RESULT));
              } else {
                setError('Die Bewertung konnte leider nicht gespeichert werden.');
              }
            } catch (e) {
              dispatch(setFormIsLoading(false));
              console.error('e', e);
              setError('Die Bewertung konnte leider nicht gespeichert werden.');
            }
          } else {
            dispatch(setFormIsLoading(false));
            const wohnwertResult = {
              von: wohnwertLight.valueRange.lower,
              bis: wohnwertLight.valueRange.upper,
              federalState: currentWohnwertLight.federalState.text,
              district: currentWohnwertLight.district.text,
              estateType: currentWohnwertLight.estateType.text,
              estateSize: currentWohnwertLight.estateSize,
              estateCondition: currentWohnwertLight.estateCondition,
            };

            // get+set results
            dispatch(setWohnwertLastResults([wohnwertResult]));

            const label = getWohnwertLabel([wohnwertResult], 0);
            dispatch(setWohnwertResultsActive({id: 0, text: label}));

            dispatch(setSiteIndexWohnwertForm(WOHNWERTLIGHT_SITEINDEX_RESULT));
          }
        } else {
          dispatch(setFormIsLoading(false));
          setError(fetchErrorText);
        }
      } catch (e) {
        console.log(e);
        dispatch(setFormIsLoading(false));
        setError(fetchErrorText);
      }
    }
  };

  const formType = 'wohnwertLight';

  return (
    <>
      {currentWohnwertLight.siteIndex === WOHNWERTLIGHT_SITEINDEX_FORM ? (
        <div className="wohnwertlight-form">
          <div className="wohnwertlight-form__content">
            <h3 className="form__title font-150">Ihre Immobilie</h3>
            <FormSelectWohnwertLastResults />

            {!user.isLoggedIn ? (
              <span className="form--info mt-50rem">
                <span className="textlink" onClick={handleOpenRegisterForm}>
                  Jetzt registrieren,
                </span>{' '}
                um Zugriff auf vergangene Berechnung zu erhalten
              </span>
            ) : (
              <span className="form--info pt-50rem">
                Greifen sie bequem mit einem Klick auf vergangene Berechnung zu.
              </span>
            )}

            <FormSelect
              label={'Bundesland'}
              placeholder="Bitte wählen"
              value={
                currentWohnwertLight.federalState.id > -1 ? currentWohnwertLight.federalState : ''
              }
              formType={formType}
              id={'inputFederalState'}
              options={statesWithZips.map((state) => {
                return {
                  id: state.priceHubbleId,
                  text: state.label,
                };
              })}
              error={currentFormValidators.federalStateError}
            />

            <FormInput
              label={'Postleitzahl'}
              placeholder={
                currentWohnwertLight.federalState.id > -1
                  ? 'Postleitzahl'
                  : 'Bitte erst Bundesland auswählen'
              }
              id={'inputDistrict'}
              formType={formType}
              type="number"
              error={currentFormValidators.districtError}
              value={currentWohnwertLight.typedPostCode}
              disabled={currentWohnwertLight.federalState.id > -1 ? false : true}
              hasLockIcon={currentWohnwertLight.federalState.id > -1 ? false : true}
              hasList={true}
            />

            <FormSelect
              label={'Immobilienart'}
              placeholder="Bitte wählen"
              value={currentWohnwertLight.estateType}
              formType={formType}
              id={'realEstateType'}
              options={immobilienartOptions}
              error={currentFormValidators.estateTypeError}
            />

            <FormInput
              label={'Fläche in m²'}
              placeholder={'Wert eingeben'}
              id={'realEstateSize'}
              formType={formType}
              type={'number'}
              minValue={0}
              error={currentFormValidators.estateSizeError}
              value={currentWohnwertLight.estateSize}
            />

            <div className="mt-40 mb-20 d-flex flex-row">
              <span>Zustand der Immobilie</span>
              <InfoMark color="#fff" handleClick={handleInfoModal} character={'?'} />
            </div>

            <SatisfactionScale
              color="#fff"
              detailsColor="blue"
              selectedValue={currentWohnwertLight.estateCondition}
              formType={formType}
              error={currentFormValidators.estateConditionError}
            />

            <LoadingSpinner iconColor={'#fff'} />
            <div className="button-panel">
              <ButtonForwardAction
                formType={formType}
                buttonText="Immobilie bewerten"
                forwardAction={forwardAction}
              />
            </div>
            {error && <p className="form__fielderror">{error}</p>}
          </div>
        </div>
      ) : (
        <div className="wohnwertlight-form">
          <div className="wohnwertlight-form__content">
            {user.isLoggedIn && (
              <>
                <FormSelectWohnwertLastResults />

                <span className="form--info pt-50rem">
                  Greifen sie bequem mit einem Klick auf vergangene Berechnung zu.
                </span>
              </>
            )}

            <h3 className="headline--withinfomark form__title font-170">
              Ihr Ergebnis
              <InfoMark
                handleClick={handleInfoModalBerechnungsBasis}
                character={'i'}
                color="#fff"
              />
            </h3>

            <WohnwertLightFormResultWrapper />
          </div>

          <div className="pl-100rem button-panel">
            <button className="button button--orange mb-10 mr-10" onClick={handleOpenContactForm}>
              Diese Immobilie verkaufen?
            </button>

            {user.wohnwertLastResults.length === 3 ||
            (user.wohnwertLastResults.length === 1 && !user.isLoggedIn) ? (
              <button className="button button--glass mb-10 button--withicon" disabled={true}>
                <IconLock color={'#c1c4d4'} />
                Neue Berechnung
              </button>
            ) : (
              <button className="button button--glass mb-10" onClick={handleNewCalc}>
                Neue Berechnung
              </button>
            )}
          </div>

          {!user.isLoggedIn ? (
            <div className="wohnwertlight-form__footer__registrationInformation">
              <div>
                <h2 className="mt-0 font-140">
                  Sie wollen Ihr Ergebnis speichern und viele weitere Vorteile nutzen?
                </h2>

                <p>
                  Registrieren Sie sich jetzt für my-sreal.at und profitieren Sie von vielen
                  Vorteilen.
                </p>

                <IconListWrapper cssClasses="mt-200rem">
                  <IconListElement
                    iconColor={'#1E3163'}
                    icon={<IconLocationWithPin />}
                    whiteCircle={true}
                  >
                    <>
                      <h3 className="m-0">Digital und persönlich</h3>
                      <p className="m-0">
                        Nutzen Sie den Vorteil eines digitalen Immobilienportals und die Erfahrung
                        von Immobilienexpert:innen an einem Ort.
                      </p>
                    </>
                  </IconListElement>

                  <IconListElement
                    iconColor={'#1E3163'}
                    icon={<IconBuildings />}
                    whiteCircle={true}
                  >
                    <>
                      <h3 className="m-0">Transparenz in Echtzeit</h3>
                      <p className="m-0">
                        Beim Verkauf einer Immobilie erhalten Sie alle Statistiken zu Klickzahlen,
                        Aufrufen und Besichtigungen in Echtzeit.
                      </p>
                    </>
                  </IconListElement>
                </IconListWrapper>
              </div>

              <div className="button-panel">
                <button
                  className="button button--orange mb-10 mr-10"
                  onClick={handleOpenRegisterForm}
                >
                  Jetzt registrieren
                </button>
                <button className="button button--blue-outline mb-10" onClick={handleOpenLoginForm}>
                  Anmelden
                </button>
              </div>
            </div>
          ) : (
            <div className="wohnwertlight-form__footer__registrationInformation pt-0 d-flex flex-row">
              <div className="d-flex justify-content-center" style={{ width: '30%' }}>
                <IconCheckmark color="#1e3163" cssClasses="mr-20" />
              </div>

              <div>
                <h3 className="mt-0">Wertermittlung gespeichert.</h3>
                <p>
                  Als Nutzer:in von my-sreal.at genießen Sie viele Vorteile. Für mehr Bequemlichkeit
                  speichern wir Ihre bisherigen Wertermittlungen für Sie ab.
                </p>
              </div>
            </div>
          )}
        </div>
      )}

      <InfoDialogZustandImmo handleClose={handleInfoModal} open={infoDialogShown} />

      <InfoDialogBerechnungsbasis
        handleClose={handleInfoModalBerechnungsBasis}
        open={infoModalBerechnungsBasisShown}
      />
    </>
  );
}

export default WohnwertLightForm;
