import React from 'react';
import Card from './Card';

import '../../styles/card.scss';

// Icons
import IconPhone from '../Icons/IconPhone';
import IconMail from '../Icons/IconMail';
import IconContact2 from '../Icons/IconContact2';

function ContactAgentCard({ cssClasses, agent, urlBtnAnfrage }) {
  const {
    gender,
    first_name,
    prefix,
    last_name,
    role,
    phone,
    email,
    profile_picture,
    suffix,
  } = agent;

  if (!cssClasses) cssClasses = 'contactagent-card';
  else cssClasses += ' contactagent-card';

  let telLink = '';
  if (phone) {
    telLink = 'tel:' + phone.replace(/ /g, '').replace(/-/g, '');
  }

  return (
    <Card cardStyle="customContent" cssClasses={cssClasses}>
      <div className="card__content">
        <div className="maklerInfos d-flex justify-content-between mb-20">
          <div>
            <h3 className="font-100 font-weight-700">
              {gender} {prefix} {first_name} {last_name}
              {suffix !== '' ? `, ${suffix}` : ''}
            </h3>
            {role && ( <p>{role}</p> )}

            <ul className="list-style-none mt-20 icon-list">
              {phone && (
                <li className="font-weight-400 d-flex align-items-center">
                  <IconPhone color="#1E3163" /> <a href={telLink} title={`${first_name} ${last_name} anrufen`}>{phone}</a>
                </li>
              )}
              {email && (
                <li className="font-weight-400 d-flex align-items-center">
                  <IconMail color="#1E3163" /> <a href={'mailto:' + email} title={`E-Mail an ${first_name} ${last_name}`}>{email}</a>
                </li>
              )}
            </ul>
          </div>

          {(profile_picture && profile_picture.storage_key !== '') ? (
            <img
              className="contactagent-card__image"
              src={`https://storage.justimmo.at/file/${profile_picture.storage_key}`}
              alt={`${gender} ${prefix} ${first_name} ${last_name}`}
            />
          ) : (
            <div className="contactagent-card__image">
              <IconContact2 color="#fff" />
            </div>
          )}
        </div>


        <div className='button-panel'>
          {phone && (
            <a href={telLink} className="button button--gray-outline desktop-hidden" title={`${first_name} ${last_name} anrufen`}>
              Anrufen
            </a>
          )}
          {urlBtnAnfrage && (
            <a className="button button--orange" href={'mailto:' + email} title={`E-Mail an ${first_name} ${last_name}`}>
                {gender === 'Herr' ? 'Makler kontaktieren' : 'Maklerin kontaktieren'}
            </a>
          )}
        </div>
      </div>
    </Card>
  );
}

export default ContactAgentCard;
