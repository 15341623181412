import { useState } from 'react';
import { STATS_FILTER_AKTUELLEWOCHE, STATS_FILTER_GESAMT, STATS_FILTER_LETZTE3WOCHEN } from '../../constants';
import { useHandleScreenResize } from '../../hooks/app/useHandleScreenResize';
import BesichtigungenChart from '../Charts/BesichtigungenChart';
import InseratKlicksChart from '../Charts/InseratKlicksChart';
import VerschickteAngeboteChart from '../Charts/VerschickteAngeboteChart';
import InfoDialog from '../Dialogs/InfoDialog';
import PageDialogBesichtigungen from '../Dialogs/PageDialogBesichtigungen';
import headerImageMobile from '../../assets/images/mysreal_HerotemplateMobil_593x950px_Neu2024-_0002_s-REAL-Sujets-2023-Webhero-16zu9-1.14.jpg';
import headerImage from '../../assets/images/sREAL_Sujets_2023_Webhero_16zu9_1.4.jpg';
import FilterMenu from '../FilterMenu';
import Header from '../Header/Header';
import SimpleSlider from '../Sliders/SimpleSlider';

function DashboardHeaderStats({ stats, cssClasses, children }) {
  const [openBesichtigungen, setOpenBesichtigungen] = useState(false);
  const [infoModalQuellenOpen, setInfoModalQuellenOpen] = useState(false);
  const handleInfoModalQuellen = () => {
    setInfoModalQuellenOpen(!infoModalQuellenOpen);
  };

  if (!cssClasses) cssClasses = 'header--slider header--slider--purplebg header--stats header--purplebg header--fitcontent';
  else cssClasses += ' header--slider header--slider--purplebg header--stats header--purplebg header--fitcontent';

  const { isMobile } = useHandleScreenResize();

  const sliderSettings = {
    appendDots: (dots) => <ul>{dots}</ul>,
    dots: true,
    arrows: false,
    swipe: true,
    infinite: true,
  };

  const lineChartConfig = {
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          color: '#fff',
          borderColor: '#fff',
          display: false,
        },
        ticks: {
          color: '#fff',
        },
      },
      y: {
        position: 'right',
        grid: {
          color: '#fff',
          borderColor: '#fff',
          borderDash: [2],
        },
        ticks: {
          color: '#fff',
        },
      },
    },
  };

  const filterMenuStats = [STATS_FILTER_AKTUELLEWOCHE, STATS_FILTER_LETZTE3WOCHEN, STATS_FILTER_GESAMT];
  const [activeFilterIndexStats, setActiveFilterIndexStats] = useState(STATS_FILTER_GESAMT.key);

  const handleSetActiveFilterIndexStats = (event) => {
    let index = event.target.dataset.index;
    setActiveFilterIndexStats(index);
  };

  return (
    <Header cssClasses={cssClasses}>
      <img src={!isMobile ? headerImage : headerImageMobile} alt="Foto: Der beste Partner für Ihre Immobilie" className="header__image" />
      {isMobile ? (
        <>
          <SimpleSlider settings={sliderSettings} isDashboard={true}>
            {Object.entries(stats)
              .slice(0, 2)
              .map(([key, val]) => {
                if (key === 'clicks') {
                  return (
                    <div className="header__content-wrapper statsheader-container statsheader-mobile" key={key}>
                      <div className="header__content">
                        <InseratKlicksChart chartData={val} isDashboardVersion={true} chartConfig={lineChartConfig} filterIndex={2} isMobile={true} />
                      </div>
                    </div>
                  );
                } else if (key === 'offers' || key === 'sightings') {
                  return (
                    <div className="header__content-wrapper statsheader-container statsheader-mobile" key={key}>
                      <div className="header__content">
                        <VerschickteAngeboteChart chartData={val} isDashboardVersion={true} isMobile={true} filterIndex={2} />
                      </div>
                      <div className="header__content">
                        <BesichtigungenChart chartData={val} isDashboardVersion={true} isMobile={true} filterIndex={2} />
                      </div>
                    </div>
                  );
                }
                return null;
              })}
          </SimpleSlider>
        </>
      ) : (
        <>
          <div className="w-desktop--center d-flex justify-content-between align-items-center">
            <h1 className="w-desktop--center z-index-1">Verkäuferdashboard</h1>
            <FilterMenu categories={filterMenuStats} activeIndex={activeFilterIndexStats} handleClick={handleSetActiveFilterIndexStats} cssClasses="filtermenu--inverted" />
          </div>

          <div className="statsheader-container w-desktop--center">
            {Object.entries(stats).map(([key, val]) => {
              if (key === 'clicks') {
                return <InseratKlicksChart key={key} chartData={val} isDashboardVersion={true} chartConfig={lineChartConfig} filterIndex={activeFilterIndexStats} />;
              }
              return null;
            })}
            <div className="chart-wrapper--vertical desktop">
              {Object.entries(stats).map(([key, val]) => {
                if (key === 'offers') {
                  return <VerschickteAngeboteChart key={key} chartData={val} isDashboardVersion={true} filterIndex={activeFilterIndexStats} />;
                } else if (key === 'sightings') {
                  return <BesichtigungenChart key={key} chartData={val} isDashboardVersion={true} filterIndex={activeFilterIndexStats} />;
                }
                return null;
              })}
            </div>
          </div>
        </>
      )}
      <PageDialogBesichtigungen open={openBesichtigungen} handleClose={() => setOpenBesichtigungen(false)} />
      <InfoDialog handleClose={handleInfoModalQuellen} open={infoModalQuellenOpen} headline={'Liste aller Quellen'}>
        <p>
          Quellen:
          <br />
          Dolor sit met, metra et consetetur sadipscin invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd
          gubergren, no sea takimata sanctus est.
        </p>

        <p className="font-weight-700 mt-200rem">Die Quellen werden laufend erweitert.</p>
      </InfoDialog>

      {children}
    </Header>
  );
}

export default DashboardHeaderStats;
