import httpClient from './index';

const END_POINT = '/News';

const getNews = async () => {
  try {
    const resp = await httpClient.get(`${END_POINT}`);
    // News liegen in resp.data.items
    return resp.data.items;
  } catch (e) {
    throw e;
  }
};

const getNewsDetail = async (slug) => {
  try {
    const resp = await httpClient.get(`${END_POINT}/Detail`, {
      params: { slug: slug },
    });
    return resp.data;
  } catch (e) {
    throw e;
  }
};

export { getNews, getNewsDetail };
