import IconListWrapper from '../IconList/IconListWrapper';
import IconListElement from '../IconList/IconListElement';
import IconDocument from '../Icons/IconDocument';
import InfoDialog from './InfoDialog';

function InfoDialogUebergabe({handleClose, open, cssClasses}) {
    return (
        <InfoDialog handleClose={handleClose} headline="Übergabestatus" open={open}>
            <IconListWrapper cssClasses={cssClasses}>
                <IconListElement icon={<IconDocument />}>
                    <h3>Lorem Ipsum  dolor sit</h3>
                    <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod aliquyam erat, sed diam voluptua.</p>
                </IconListElement>
        
                <IconListElement icon={<IconDocument />}>
                    <h3>Lorem Ipsum  dolor sit</h3>
                    <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod aliquyam erat, sed diam voluptua.</p>
                </IconListElement>

                <IconListElement icon={<IconDocument />}>
                    <h3>Lorem Ipsum  dolor sit</h3>
                    <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod aliquyam erat, sed diam voluptua.</p>
                </IconListElement>

                <IconListElement icon={<IconDocument />}>
                    <h3>Lorem Ipsum  dolor sit</h3>
                    <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod aliquyam erat, sed diam voluptua.</p>
                </IconListElement>

                <IconListElement icon={<IconDocument />}>
                    <h3>Lorem Ipsum  dolor sit</h3>
                    <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod aliquyam erat, sed diam voluptua.</p>
                </IconListElement>
            </IconListWrapper>
        </InfoDialog>
    );
}

export default InfoDialogUebergabe;