import React from 'react';

function IconMagnifyingGlassWithHouse({ color, cssClasses }) {
  return (
    <svg
      data-name="Gruppe 12180"
      xmlns="http://www.w3.org/2000/svg"
      width="25.463"
      height="25.463"
      viewBox="0 0 25.463 25.463"
    >
      <g data-name="Gruppe 8930">
        <g data-name="Gruppe 8927">
          <g data-name="Gruppe 8929" fill={color ? color : '#fff'}>
            <path
              data-name="Pfad 7345"
              d="M13.084 6.343a.155.155 0 0 0 .265-.11v-1.4a.155.155 0 0 0-.155-.148h-1.4a.155.155 0 0 0-.11.265Z"
            />
            <path
              data-name="Pfad 7346"
              d="M5.509 8.642v5.007h3.08v-3.08h1.76v3.08h3.08V8.642l-3.96-3.96Z"
            />
          </g>
        </g>
        <path
          data-name="Pfad 7348"
          d="M13.518 9.127a.682.682 0 0 0 .467-.202.656.656 0 0 0 .02-.925L9.922 3.919a.655.655 0 0 0-.926.02l-4.25 4.25a.668.668 0 0 0-.154.72.62.62 0 0 0 .6.389Z"
          fill={color ? color : '#fff'}
        />
      </g>
      <g data-name="Gruppe 8931">
        <path
          data-name="Pfad 7344"
          d="M9.549 19.1a9.5 9.5 0 0 0 5.976-2.113l8.125 8.167a1.062 1.062 0 0 0 1.5-1.5l-8.135-8.176a9.539 9.539 0 1 0-7.47 3.62m0-16.975a7.427 7.427 0 1 1-7.423 7.426 7.436 7.436 0 0 1 7.427-7.427"
          fill={color ? color : '#fff'}
        />
      </g>
    </svg>
  );
}

export default IconMagnifyingGlassWithHouse;
