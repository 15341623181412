import React from 'react';

function IconNews({ color, cssClasses }) {
  return (
    <svg
      className={cssClasses}
      xmlns="http://www.w3.org/2000/svg"
      width="92.313"
      height="92.313"
      viewBox="0 0 92.313 92.313"
    >
      <g id="Gruppe_10571" data-name="Gruppe 10571" transform="translate(1.5 1.5)">
        <line
          id="Linie_4425"
          data-name="Linie 4425"
          x2="42.236"
          transform="translate(12.019 12.019)"
          fill="none"
          stroke={color ? color : '#fff'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
        />
        <rect
          id="Rechteck_3739"
          data-name="Rechteck 3739"
          width="19.198"
          height="23.038"
          transform="translate(12.019 23.538)"
          fill="none"
          stroke={color ? color : '#fff'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
        />
        <line
          id="Linie_4426"
          data-name="Linie 4426"
          x2="11.519"
          transform="translate(42.736 31.217)"
          fill="none"
          stroke={color ? color : '#fff'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
        />
        <line
          id="Linie_4427"
          data-name="Linie 4427"
          x2="15.359"
          transform="translate(38.897 38.897)"
          fill="none"
          stroke={color ? color : '#fff'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
        />
        <line
          id="Linie_4428"
          data-name="Linie 4428"
          x2="15.359"
          transform="translate(38.897 46.576)"
          fill="none"
          stroke={color ? color : '#fff'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
        />
        <line
          id="Linie_4429"
          data-name="Linie 4429"
          x2="42.236"
          transform="translate(12.019 54.255)"
          fill="none"
          stroke={color ? color : '#fff'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
        />
        <line
          id="Linie_4430"
          data-name="Linie 4430"
          x2="42.236"
          transform="translate(12.019 61.935)"
          fill="none"
          stroke={color ? color : '#fff'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
        />
        <line
          id="Linie_4431"
          data-name="Linie 4431"
          x2="42.236"
          transform="translate(12.019 69.614)"
          fill="none"
          stroke={color ? color : '#fff'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
        />
        <g id="Gruppe_10572" data-name="Gruppe 10572" transform="translate(0.5 0.5)">
          <g id="Gruppe_10574" data-name="Gruppe 10574">
            <path
              id="Pfad_7803"
              data-name="Pfad 7803"
              d="M25.179,15.439h7.679v57.6a3.84,3.84,0,0,1-7.679,0V2H17.5V9.679"
              transform="translate(47.774 3.76)"
              fill="none"
              stroke={color ? color : '#fff'}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="4"
            />
            <path
              id="Pfad_7804"
              data-name="Pfad 7804"
              d="M77.293,88.813H12.019A11.523,11.523,0,0,1,.5,77.293V.5H65.774V77.293a11.519,11.519,0,0,0,23.038,0V12.019H73.454"
              transform="translate(-0.5 -0.5)"
              fill="none"
              stroke={color ? color : '#fff'}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="4"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default IconNews;
