function IconPersonManager({ color, cssClasses, outline }) {
  return outline ? (
    <svg
      className={cssClasses}
      xmlns="http://www.w3.org/2000/svg"
      width="78.221"
      height="84.081"
      viewBox="0 0 78.221 84.081"
    >
      <g id="Gruppe_10298" data-name="Gruppe 10298" transform="translate(1.5 1.5)">
        <g id="Gruppe_10299" data-name="Gruppe 10299" transform="translate(0.5 0.5)">
          <path
            id="Pfad_7618"
            data-name="Pfad 7618"
            d="M27.845,12.324V22.946L8.278,29.935A11.716,11.716,0,0,0,.5,40.97v7.367H74.721V40.97a11.716,11.716,0,0,0-7.778-11.036L47.377,22.946V11.594"
            transform="translate(-0.5 31.744)"
            fill="none"
            stroke="#fff"
            strokeLinejoin="round"
            strokeWidth="4"
          />
          <ellipse
            id="Ellipse_1467"
            data-name="Ellipse 1467"
            cx="19.532"
            cy="23.438"
            rx="19.532"
            ry="23.438"
            transform="translate(17.09)"
            fill="none"
            stroke="#fff"
            strokeLinejoin="round"
            strokeWidth="4"
          />
          <path
            id="Pfad_7619"
            data-name="Pfad 7619"
            d="M43.569,11.426c-.656.078-1.109.055-1.77.184C35.147,12.891,30.874,10.48,27.213,4.07c-2.2,4.211-9.071,7.539-14.711,7.539a15.7,15.7,0,0,1-7.563-1.82"
            transform="translate(12.401 9.876)"
            fill="none"
            stroke="#fff"
            strokeLinejoin="round"
            strokeWidth="4"
          />
        </g>
      </g>
    </svg>
  ) : (
    <svg
      className={cssClasses}
      data-name="Gruppe 9603"
      xmlns="http://www.w3.org/2000/svg"
      width="59.158"
      height="63.594"
      viewBox="0 0 59.158 63.594"
    >
      <defs>
        <clipPath>
          <rect data-name="Rechteck 3562" width="59.158" height="63.595" fill={color} />
        </clipPath>
      </defs>
      <g data-name="Gruppe 9602" transform="translate(0)" clipPath="url(#clipPath)">
        <path
          data-name="Pfad 7502"
          d="M52.287,37.1l-13.834-4.94V25.344a20.383,20.383,0,0,0,7.025-15.8c0-.213-.024-.42-.03-.627a8.828,8.828,0,0,1-2.928.627c-4.9,0-8.282-.784-11.128-4.6a16.249,16.249,0,0,1-11.057,4.6c-1.92,0-5.093-1.228-7.279-2.21-.068.725-.115,1.461-.115,2.21,0,6.916,3.115,12.97,7.764,16.357v6.265L6.868,37.1A10.374,10.374,0,0,0,0,46.853v7.058H59.158V46.853A10.372,10.372,0,0,0,52.287,37.1"
          transform="translate(0 9.684)"
          fill={color}
        />
        <path
          data-name="Pfad 7503"
          d="M21.178,11.358l1.254-2.4,1.343,2.349c2.425,4.248,4.975,4.963,9.764,4.963a8.032,8.032,0,0,0,2.647-.731C34.725,6.7,28.129,0,20.228,0,12.792,0,6.518,5.934,4.587,14c2.275,1.077,5.44,2.269,6.768,2.269,3.866,0,8.46-2.292,9.823-4.91"
          transform="translate(8.981)"
          fill={color}
        />
      </g>
    </svg>
  );
}

export default IconPersonManager;
