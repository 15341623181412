function IconInformationBubble({ color, cssClasses }) {
  return (
    <svg
      className={cssClasses}
      xmlns="http://www.w3.org/2000/svg"
      width="99.599"
      height="92.312"
      viewBox="0 0 99.599 92.312"
    >
      <g data-name="Gruppe 10278" transform="translate(-0.025 -0.025)">
        <g data-name="Gruppe 10280" transform="translate(2.025 2.025)">
          <path
            data-name="Pfad 7613"
            d="M96.1,39.5c0,21.539-21.4,39-47.8,39a57.337,57.337,0,0,1-16.746-2.465L4.083,88.812,14.84,67.344C5.987,60.266.5,50.4.5,39.5.5,17.953,21.9.5,48.3.5S96.1,17.953,96.1,39.5Z"
            transform="translate(-0.5 -0.5)"
            fill="none"
            stroke={color}
            strokeLinejoin="round"
            strokeWidth="4"
          />
          <path
            data-name="Pfad 7614"
            d="M18.829,32.562V7.623H10.516"
            transform="translate(31.114 21.983)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="4"
          />
          <line
            data-name="Linie 4394"
            x2="16.626"
            transform="translate(41.63 54.545)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="4"
          />
          <circle
            data-name="Ellipse 1464"
            cx="2.078"
            cy="2.078"
            r="2.078"
            transform="translate(45.721 15.059)"
            fill="none"
            stroke={color}
            strokeLinejoin="round"
            strokeWidth="4"
          />
        </g>
      </g>
    </svg>
  );
}

export default IconInformationBubble;
