import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { parseDate } from '../../util/datetimeParser';

import LogoutButton from './LogoutButton';
import Menu from './Menu';
import SelectIhreImmobilie from './SelectIhreImmobilie';
import useOutsideAlerter from '../useOutsideAlerter';
import MenuButtonSwitcher from './MenuButtonSwitcher';

import { toggleDesktopMenu } from '../../reducers/app';
import PropTypes from 'prop-types';

function Flyout({ children }) {
  let user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  
  const handleOpenFlyout = () => {
    setTimeout(() => {
      dispatch(toggleDesktopMenu());
    }, 50);
  };

  const flyoutRef = useRef(null);
  useOutsideAlerter(flyoutRef, handleOpenFlyout);

  return (
    <div className="flyout" ref={flyoutRef}>
      {children}

      <div className="mb-100rem">
        {user && user.userObject && user.userObject.firstName && user.userObject.lastName && (
          <span className="font-weight-600">
            Hallo,
            <br />
            {user.userObject.firstName} {user.userObject.lastName}!
          </span>
        )}


        {user.lastLoggedIn !== '' && (
          <>
            <br />
            <span className="font-90">
              Letzter Login: {parseDate(user.lastLoggedIn, 'DD.MM.YYYY HH:mm')}
            </span>
          </>
        )}

        <MenuButtonSwitcher cssClasses={'mt-200rem mb-200rem'} />
      </div>

      <SelectIhreImmobilie />

      <Menu flyout={true} />

      <LogoutButton />

      <a
        href="https://www.sreal.at"
        target="_blank"
        className="text-link d-flex mt-200rem"
        rel="noreferrer"
      >
        sREAL.at aufrufen
      </a>
    </div>
  );
}

export default Flyout;
