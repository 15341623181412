import { parseDate } from './datetimeParser';
import { isImage } from './generalUtils';

function getDefaultNewsObject() {
  let defaultObject = {
    title: '',
    slug: '',
    summary: '',
    topics: '',
    topicsArray: [],
    blocks: [],
    author_name: '',
    header_picture: '',
    header_picture_small: '',
    updated_at: '',
  };

  return defaultObject;
}

const newsParser = (data) => {
  if (!data) return null;
  let newsObject = getDefaultNewsObject();

  if (data.title) {
    newsObject.title = data.title;
  }

  if (data.slug) {
    newsObject.slug = data.slug;
  }

  if (data.summary) {
    newsObject.summary = data.summary;
  }

  if (data.topics?.length > 0) {
    let topics = data.topics
      .map((t) => {
        // put topic string together
        return t.name.toUpperCase().replace(',', '');
      })
      .join(', ');

    newsObject.topics = topics;
    newsObject.topicsArray = data.topics;
  }

  if (data.author?.first_name && data.author?.last_name) {
    newsObject.author_name = `${data.author.first_name} ${data.author.last_name}`;
  }

  if (data.header_picture?.file) {
    let fileString = data.header_picture.file;
    if (isImage(fileString)) {
      newsObject.header_picture = fileString;
    }
  }

  if (data.header_picture?.file_375x400) {
    let fileString = data.header_picture.file_375x400;
    if (isImage(fileString)) {
      newsObject.header_picture_small = fileString;
    }
  }

  if (data.updated_at) {
    newsObject.updated_at = parseDate(data.updated_at);
  }

  if (data.blocks?.blocks) {
    newsObject.blocks = data.blocks.blocks;
  }

  return newsObject;
};

export { newsParser, getDefaultNewsObject };
