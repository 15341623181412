import { useSelector } from 'react-redux';
import FormSelect from '../FormFields/FormSelect';
import { MENU_VERKAUF } from '../../constants';
import { useEffect, useState } from 'react';

function SelectIhreImmobilie() {
  const user = useSelector((state) => state.user);
  const app = useSelector((state) => state.app);

  const [ihreImmobilien, setIhreImmobilien] = useState([]);

  useEffect(() => {
    if(user.userRealties.length > 0) {
      let immos = user.userRealties.filter((r) => r !== null).map((r) => {
        if(!r.id || !r.title) return null;

        return {
          id: r.id,
          text: r.title,
          immo: r,
        };
      }).filter((r) => r !== null);

      setIhreImmobilien(immos);
    }
  }, [user.userRealties])

  if (app.menuType === MENU_VERKAUF && user && user.isLoggedIn && ihreImmobilien.length > 0) {
    return (
      <FormSelect
        showBitteWaehlen={false}
        label={ihreImmobilien.length > 1 ? 'Ihre Immobilien' : 'Ihre Immobilie'}
        options={ihreImmobilien}
        id="userRealties"
        formType="userRealties"
        value={user.userRealtyActive?.id ? { id: user.userRealtyActive.id, text: user.userRealtyActive.title } : ''}
        showResetIcon={false}
      />
    );
  } else {
    return null;
  }
}

export default SelectIhreImmobilie;
