import React, { useState, useEffect, useRef } from 'react';
import '../styles/table.scss';
import IconHakerl from './Icons/IconHakerl';
import IconX from './Icons/IconX';
import { useHandleScreenResize } from '../hooks/app/useHandleScreenResize';

function TableComparison({ list, headlines, cssClasses = '', htmlParser = false, children }) {
  const [scrolledRight, setScrolledRight] = useState(false);
  const tableRef = useRef(null);
  const parseHTML = require('html-react-parser');
  const { isMobile } = useHandleScreenResize();

  const smoothScroll = (element, target, duration) => {
    const start = element.scrollLeft;
    const distance = target - start;
    const startTime = performance.now();

    const easeInOutQuad = (t) => {
      return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
    };

    const scrollStep = (timestamp) => {
      const currentTime = timestamp - startTime;
      const scrollAmount = easeInOutQuad(Math.min(1, currentTime / duration)) * distance;

      element.scrollLeft = start + scrollAmount;

      if (currentTime < duration) {
        requestAnimationFrame(scrollStep);
      }
    };

    requestAnimationFrame(scrollStep);
  };

  const scroll = () => {
    const table = document.querySelector('.horizontal-scroll-except-first-column');
    if (scrolledRight) {
      smoothScroll(table, 0, 1000);
      setScrolledRight(false);
    } else {
      smoothScroll(table, table.scrollWidth, 1000);
      setScrolledRight(true);
    }
  };

  useEffect(() => {
    if (tableRef.current) {
      tableRef.current.addEventListener('scroll', () => {
        if (tableRef.current.scrollLeft > 0) {
          setScrolledRight(true);
        } else {
          setScrolledRight(false);
        }
      });
    }
  }, [tableRef]);

  return (
    <div className={cssClasses}>
      {isMobile ? (
        <>
          <div className="tableWrapper">
            <div class="horizontal-scroll-except-first-column" ref={tableRef}>
              <table>
                <tbody>
                  <tr>
                    <td className='tableHeader_blank' style={{ height: '149px', border: 'none', backgroundColor: '#f4f4f4', boxShadow: 'none' }}></td>
                    {headlines.map((item, index) => (
                      <td className="tableColumn" style={{ height: '149px', textAlign: 'center', border: 'none' }}>
                        <div className="tableHeader">
                          {item.icon}
                          <span className="tablerow__label">{item.headline}</span>
                        </div>
                      </td>
                    ))}
                  </tr>
                  {list
                    .filter((item) => !item.isSubline)
                    .map((item, index) => (
                      <tr>
                        <td>
                          <span className="tablerow__label">{item.label}</span>
                        </td>
                        {Object.entries(item).map(([key, value], subIndex) => {
                          if (key !== 'label') {
                            return (
                              <td key={subIndex} className="tableColumn">
                                {index === Math.floor(list.filter((item) => !item.isSubline).length / 2) && value && (
                                  <button className={`tableButton ${scrolledRight ? 'tableButton--scrolled' : ''}`} onClick={() => scroll()}>
                                    {scrolledRight ? (
                                      <svg xmlns="http://www.w3.org/2000/svg" width="29.099" height="16.6" viewBox="0 0 29.099 16.6" style={{ marginLeft: '-6px' }}>
                                        <path
                                          data-name="Pfad 8568"
                                          d="M28.127,7.305H3.584L9.112,1.777a.992.992,0,0,0,0-1.458.992.992,0,0,0-1.458,0L.364,7.609a.752.752,0,0,0-.182.122l-.061.061c0,.061-.061.061-.061.121s0,.061-.061.122v.547c0,.061,0,.061.061.121,0,.061.061.061.061.122a.059.059,0,0,0,.061.061c.061.061.061.122.121.122l7.29,7.29a1.027,1.027,0,0,0,1.458,0,.992.992,0,0,0,0-1.458L3.523,9.31H28.066A1.065,1.065,0,0,0,29.1,8.277a.96.96,0,0,0-.972-.972"
                                          fill="#2970ed"
                                        />
                                      </svg>
                                    ) : (
                                      <svg xmlns="http://www.w3.org/2000/svg" width="29.099" height="16.6" viewBox="0 0 29.099 16.6" style={{ marginLeft: '-6px' }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="29.099" height="16.6" viewBox="0 0 29.099 16.6" style={{ marginLeft: '-12px', marginTop: '4px' }}>
                                          <path
                                            data-name="Pfad 8568"
                                            d="M28.127,7.305H3.584L9.112,1.777a.992.992,0,0,0,0-1.458.992.992,0,0,0-1.458,0L.364,7.609a.752.752,0,0,0-.182.122l-.061.061c0,.061-.061.061-.061.121s0,.061-.061.122v.547c0,.061,0,.061.061.121,0,.061.061.061.061.122a.059.059,0,0,0,.061.061c.061.061.061.122.121.122l7.29,7.29a1.027,1.027,0,0,0,1.458,0,.992.992,0,0,0,0-1.458L3.523,9.31H28.066A1.065,1.065,0,0,0,29.1,8.277a.96.96,0,0,0-.972-.972"
                                            transform="translate(29.099 16.6) rotate(180)"
                                            fill="#2970ed"
                                          />
                                        </svg>
                                      </svg>
                                    )}
                                  </button>
                                )}
                                {typeof value === 'string' && <span className="tablerow__value">{htmlParser ? parseHTML(value) : value}</span>}
                                {value === true && (
                                  <span className="tablerow__icon">
                                    <IconHakerl color="#0eb43f" />
                                  </span>
                                )}
                                {value === false && (
                                  <span className="tablerow__icon">
                                    <IconX color="#e72222" />
                                  </span>
                                )}
                              </td>
                            );
                          }
                          return null;
                        })}
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="tableRowContent tableRowContent--comparison">
            <div className="tableColumn"></div>
            {headlines.map((item, index) => (
              <div key={index} className="tableColumn tableColumn--headline">
                {item.icon}
                <span className="tablerow__label">{item.headline}</span>
              </div>
            ))}
          </div>
          <div className={'table table--comparison'}>
            {list.map((item, index) => (
              <React.Fragment key={index}>
                {item.isSubline ? (
                  <div className="tableRowContent tableRowContent--comparison">
                    <div className="tableColumn">
                      <span className="tablerow__label tablerow__label--subheadline">{item.label}</span>
                    </div>
                  </div>
                ) : (
                  <div className="tableRowContent tableRowContent--comparison">
                    <div className="tableColumn">
                      <span className="tablerow__label">{item.label}</span>
                    </div>
                    {Object.entries(item).map(([key, value], subIndex) => {
                      if (key !== 'label') {
                        return (
                          <div key={subIndex} className="tableColumn">
                            {typeof value === 'string' && <span className="tablerow__value">{htmlParser ? parseHTML(value) : value}</span>}
                            {value === true && (
                              <span className="tablerow__icon">
                                <IconHakerl color="#0eb43f" />
                              </span>
                            )}
                            {value === false && (
                              <span className="tablerow__icon">
                                <IconX color="#e72222" />
                              </span>
                            )}
                          </div>
                        );
                      }
                      return null;
                    })}
                  </div>
                )}
              </React.Fragment>
            ))}
          </div>
        </>
      )}
      {children}
    </div>
  );
}

export default TableComparison;
