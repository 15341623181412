function IconVKImmoDrive({ color, cssClasses }) {
  return (
    <svg
      className={cssClasses}
      xmlns="http://www.w3.org/2000/svg"
      width="40.787"
      height="33.988"
      viewBox="0 0 40.787 33.988"
    >
      <path
        id="Pfad_8453"
        data-name="Pfad 8453"
        d="M1216.933,497.408h-23.44l-3.149-3.149a.85.85,0,0,0-.6-.25h-11.9a.85.85,0,0,0-.85.85v32.289a.849.849,0,0,0,.85.85h39.086a.848.848,0,0,0,.85-.85v-28.89A.849.849,0,0,0,1216.933,497.408ZM1206.8,514.319h-3.382v6.155a1.272,1.272,0,0,1-1.326,1.212h-8.6a1.272,1.272,0,0,1-1.325-1.212v-6.155h-3.382a.746.746,0,0,1-.594-1.271l9.007-8.613a.877.877,0,0,1,1.188,0l9.007,8.613A.746.746,0,0,1,1206.8,514.319Z"
        transform="translate(-1176.996 -494.01)"
        fill={color}
      />
    </svg>
  );
}

export default IconVKImmoDrive;
