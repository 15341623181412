//*React
import { useDispatch, useSelector } from 'react-redux';
//*Components
import Card from '../components/Cards/Card';
import ProfilAnalysenCard from '../components/Cards/ProfilAnalysenCard';
import ProfileForm from '../components/Forms/ProfileForm';
import HeaderSmallWithIcon from '../components/Header/HeaderSmallWithIcon';
import IconPersonManager from '../components/Icons/IconPersonManager';
import MainContent from '../components/MainContent';
import MetaTags from '../components/MetaTags';
import ProfileImmobilien from '../components/Profile/ProfileImmobilien';
import ProfileSuchprofile from '../components/Profile/ProfileSuchprofile';
import ProfileEmailSettingsForm from '../components/Forms/ProfileEmailSettingsForm';

import { parseDate } from '../util/datetimeParser';

import { CONTACTFORM_ANLIEGEN_PROFIL_DEAKTIVIEREN, CONTACTFORM_SITEINDEX_MAIN, MENU_KAUF, MENU_VERKAUF } from '../constants';
import { setPageDialogOpenContactForm, setReasonContactForm, setSiteIndexContactForm } from '../reducers/contactInformation';

import { anliegenOptions } from '../components/FormFields/util/formOptions';
import { useHandleScreenResize } from '../hooks/app/useHandleScreenResize';

function Profil() {
  const user = useSelector((state) => state.user);
  const app = useSelector((state) => state.app);
  const dispatch = useDispatch();

  const { isMobile } = useHandleScreenResize();

  const handleDeactivateProfile = () => {
    dispatch(setSiteIndexContactForm(CONTACTFORM_SITEINDEX_MAIN));
    dispatch(setReasonContactForm(anliegenOptions[CONTACTFORM_ANLIEGEN_PROFIL_DEAKTIVIEREN]));
    localStorage.setItem('profildeaktivieren-clicked', true);
    dispatch(setPageDialogOpenContactForm(true));
  };

  return (
    <>
      <MetaTags title={'Profil | Die Online-Plattform für Ihre Immobilie'} />
      <HeaderSmallWithIcon
        icon={<IconPersonManager />}
        iconActive={true}
        headlineDesktop="Profil"
        headlineDesktopRight={user.userObject.firstName + ' ' + user.userObject.lastName}
        subheadlineDesktopRight={'Registriert seit ' + parseDate(user.userObject.created)}
      />

      <MainContent>
        <div className="w-desktop--center ">
          <h1 className="headline--mobile">Profil</h1>
          {user.userObject && Object.keys(user.userObject).length > 0 ? (
            <>
              <h2 className="headline--mobile font-weight-600 font-170">
                {user.userObject.firstName}
                <br />
                {user.userObject.lastName}
              </h2>
              <p className="headline--mobile pb-200rem">Registriert seit {parseDate(user.userObject.created)}</p>
            </>
          ) : null}
          {!isMobile ? (
            <>
              <Card cardStyle="customContent" cssClasses="p-150rem bg-grey">
                <ProfileForm />
              </Card>
              <Card cardStyle="customContent" cssClasses="p-150rem bg-grey">
                <ProfileEmailSettingsForm />
              </Card>
            </>
          ) : (
            <>
              <ProfileForm />
              <ProfileEmailSettingsForm />
            </>
          )}

          {app.menuType === MENU_VERKAUF && <ProfileImmobilien />}
        </div>

        {app.menuType === MENU_KAUF && (
          <div className="gray-background">
            <ProfileSuchprofile />
          </div>
        )}

        <div className={!isMobile ? 'w-desktop--center mt-200rem' : 'w-desktop--center'}>
          <ProfilAnalysenCard />

          <div className="mt-200rem mb-200rem">
            <span className="textlink textlink--dark-blue d-flex w-100" onClick={() => handleDeactivateProfile()}>
              Profil deaktivieren
            </span>
          </div>
        </div>
      </MainContent>
    </>
  );
}

export default Profil;
