import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import headerImageMobile from '../assets/images/mysreal_HerotemplateMobil_593x950px_Neu2024-_0005_s-REAL-Sujets-2023-Webhero-16zu9-1.1.jpg';
import ProductHighlightsKaufImg1 from '../assets/images/producthighlights-img-kauf-1-Suchprofile.png';
import ProductHighlightsKaufImg2 from '../assets/images/producthighlights-img-kauf-2.png';
import ProductHighlightsKaufImg3 from '../assets/images/producthighlights-img-kauf-3-Suche.png';
import ProductHighlightsKaufImg4 from '../assets/images/producthighlights-img-kauf-4-abwickeln.png';
import headerImage from '../assets/images/sREAL_Sujets_2023_Webhero_16zu9_1.1.jpg';
import ContactFormCard from '../components/Cards/ContactFormCard';
import MarktinfoLightCard from '../components/Cards/MarktinfoLightCard';
import Header from '../components/Header/Header';
import HeaderCtaButtons from '../components/Header/HeaderCtaButtons';
import MainContent from '../components/MainContent';
import MetaTags from '../components/MetaTags';
import TableComparison from '../components/TableComparison';
import { setPageDialogOpenRegisterForm } from '../reducers/registerForm';
import { setSearchFormExpanded } from '../reducers/searchForm';
//HOOKS
import { useFetchImmoSearch } from '../hooks/immo/useFetchImmoSearch';
//*Constants
import ProductHighlights from '../components/ContentBlocks/ProductHighlights';
import IconWithRegistration from '../components/Icons/IconWithRegistration';
import IconWithoutRegistration from '../components/Icons/IconWithoutRegistration';
import Quicklinks from '../components/Quicklinks';
import { useHandleScreenResize } from '../hooks/app/useHandleScreenResize';

function Kaeufer() {
  const dispatch = useDispatch();
  const { setRealtySearchList } = useFetchImmoSearch();
  const user = useSelector((state) => state.user);

  const { isMobile } = useHandleScreenResize();

  useEffect(() => {
    dispatch(setSearchFormExpanded(false));
  }, []);

  const headerCtabuttons = [
    <HeaderCtaButtons key="headerCtabuttons">
      {!user.isLoggedIn && (
        <button className="button button--orange" onClick={() => dispatch(setPageDialogOpenRegisterForm(true))}>
          Jetzt registrieren
        </button>
      )}
    </HeaderCtaButtons>,
  ];

  return (
    <>
      <MetaTags
        title={'Immobilie kaufen auf my-sreal.at | Die Online-Plattform für Ihre Immobilie'}
        description={'Sie wollen eine Wohnung kaufen oder ein Haus kaufen? my-sreal.at ist die Online-Plattform für Ihren Immobilienkauf. Einfach, transparent und digital kaufen.'}
        imageName={'mysreal_hero_kauf.jpg'}
      />
      <Header cssClasses="header--default header--withimage header--immosuche">
        {!isMobile ? (
          <img src={headerImage} alt="Foto: Der beste Partner für Ihre Immobilie" className="header__image" />
        ) : (
          <img src={headerImageMobile} alt="Foto: Der beste Partner für Ihre Immobilie" className="header__image" />
        )}
        <div className="header__content-wrapper header__content-wrapper--bottom">
          <div className="header__content">
            <h1 className="mt-0 mb-0 text-white">
              Das moderne
              <br />
              Kundenportal für Ihren
              <br />
              Immobilienkauf.
            </h1>
          </div>
          <div className="header__ctabuttons-wrapper">{headerCtabuttons}</div>
        </div>

        {/* cta buttons mobile-version fixed */}
        {headerCtabuttons}
      </Header>
      <Quicklinks
        list={[
          { label: 'Immobilie – gesucht gefunden', id: 'immobilie' },
          { label: 'Die Vorteile von my-sreal', id: 'vorteile' },
          { label: 'Marktanalyse', id: 'marktanalyse' },
          { label: 'Kontakt', id: 'kontakt' },
        ]}
      />
      <MainContent>
        <div className="w-desktop--center">
          <div id="immobilie">
            <ProductHighlights
              mainHeadline="Immobilie - gesucht gefunden!"
              mainSubline="Die passende Immobilie zu finden, war noch nie so bequem. Favoriten setzen, Suchprofile flexibel anpassen, mit Marktanalyse die Lage überprüfen oder mit wenigen Klicks eine Finanzierung checken. Das und noch viel mehr können Sie als Sucher:in auf my-sreal."
              isMobile={isMobile}
              imagePath1={ProductHighlightsKaufImg1}
              imagePath2={ProductHighlightsKaufImg2}
              imagePath3={ProductHighlightsKaufImg3}
              imagePath4={ProductHighlightsKaufImg4}
              headline1="Praktisches Sucherdashboard"
              headline2="Marktanalyse"
              headline3="Immobiliensuche"
              headline4="Kauf digital abwickeln"
              button1="Mehr anzeigen"
              button2="Mehr anzeigen"
              button3="Mehr anzeigen"
              button4="Mehr anzeigen"
              buttonlink1="/immobilie-kaufen#such-profile"
              buttonlink2="/immobilie-kaufen#markt-analyse"
              buttonlink3="/immobiliensuche"
              buttonlink4="/immobilie-kaufen#fDigital"
            />
          </div>
        </div>

        <div className="gray-background">
          <div className="w-desktop--center">
            <div id="vorteile">
              <h2 className="text-center">Immobilien suchen wie ein Profi</h2>
              <p className="mb-200rem text-center">
                Finden Sie Ihre Traumimmobilie mit s REAL. Als Nutzer:in von my-sreal profitieren Sie dabei von vielen nützlichen Profi-Funktionen. Das Beste: die Registrierung ist völlig kostenlos.{' '}
              </p>
            </div>
          </div>
          <TableComparison
            cssClasses={`${isMobile ? 'table-mobile' : 'w-desktop--center'}`}
            headlines={[
              { headline: 'my-sreal.at ohne Registrierung', icon: <IconWithoutRegistration color={'#7598ab'} /> },
              { headline: 'my-sreal.at mit kostenloser Registrierung', icon: <IconWithRegistration color={'#2870ed'} /> },
            ]}
            list={[
              { label: 'Ihre Immobiliensuche', isSubline: true },
              { label: 's REAL Vormerkservice', value1: true, value2: true },
              { label: 'Beratung und Besichtigungen', value1: true, value2: true },
              { label: 'Kooperation Kaufvertrag und Notariat', value1: true, value2: true },
              { label: 'my-sreal Sucherdashboard', value1: false, value2: true },
              { label: 'Angebotsfavoriten', value1: false, value2: true },
              { label: 'Marktanalyse', value1: false, value2: true },
              { label: 'digitale Kaufanbotsanfrage', value1: false, value2: true },
              { label: 'integrierter Finanzierungsrechner', value1: false, value2: true },
              { label: 'Ihr Immobilienkauf', isSubline: true },
              { label: 'Betreuung durch s REAL Makler:in', value1: true, value2: true },
              { label: 'Bereitstellung kaufrelevanter Unterlagen', value1: true, value2: true },
              { label: 'Begleitung bei Kaufvertrag und Übergabe', value1: true, value2: true },
              { label: 'Dokumentenbibliothek immo-drive', value1: false, value2: true },
              { label: 'Status Kaufprozess digital einsehbar', value1: false, value2: true },
              { label: 'Nachbetreuung mit s REAL Vorteilswelt', value1: false, value2: true },
            ]}
          >
            <p className="mt-200rem mb-200rem">
              Kostenhinweis: Erst im Falle der erfolgreichen Vermittlung einer Immobilie fällt ein vorab im Vermittlungsauftrag vereinbartes Vermittlungshonorar entsprechend des österreichischen
              Maklergesetzes an. Die Nutzung des my-sreal Kundenportals führt zu keinen zusätzlichen Kosten für die Kund:in
            </p>
            {isMobile && !user.isLoggedIn && (
              <button className="button button--orange button--tablecomparison mt-100rem mb-200rem" onClick={() => dispatch(setPageDialogOpenRegisterForm(true))}>
                Jetzt kostenlos registrieren
              </button>
            )}
            {!isMobile && !user.isLoggedIn && (
              <div className="tableRowContent tableRowContent--comparison">
                <div className="tableColumn"></div>
                <div className="tableColumn"></div>
                <div className="tableColumn">
                  <button className="button button--orange button--tablecomparison mt-100rem mb-200rem" onClick={() => dispatch(setPageDialogOpenRegisterForm(true))}>
                    Jetzt kostenlos registrieren
                  </button>
                </div>
              </div>
            )}
          </TableComparison>
        </div>
        <div className="w-desktop--center">
          <h2 className="text-center">Gute Lage oder sehr gute Lage?</h2>
          <p className="text-center">
            Nutzen Sie auf my-sreal die praktische Marktanalyse um zu einer Adresse den Lage-Score abzurufen. Wie steht es um Gastronomie, Bildung und Freizeit? So wissen Sie bei Ihrer Immobiliensuche
            sofort, ob die Umgebung Ihren Ansprüchen entspricht.{' '}
          </p>
        </div>
        <div className="w-desktop--center p-100rem">
          <div id="marktanalyse">
            <MarktinfoLightCard cssClasses={'bg-white'} />
          </div>
        </div>

        <div className="w-desktop--center mt-400rem mb-400rem">
          <h2 className="mt-500rem desktop-hidden">Kontaktieren & beauftragen</h2>
          <div id="kontakt">
            <ContactFormCard />
          </div>
        </div>
      </MainContent>
    </>
  );
}

export default Kaeufer;
