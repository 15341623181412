import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Card from '../Cards/Card';
import FilterMenu from '../FilterMenu';

import { useSelector } from 'react-redux';
import { STATS_FILTER_AKTUELLEWOCHE, STATS_FILTER_GESAMT, STATS_FILTER_LETZTE3WOCHEN } from '../../constants';
import { useHandleScreenResize } from '../../hooks/app/useHandleScreenResize';
import PageDialogBesichtigungen from '../Dialogs/PageDialogBesichtigungen';
import StatsSkeleton from '../Skeletons/StatsSkeleton';

function BesichtigungenChart({ chartData, isDashboardVersion, filterIndex, isMobile }) {
  const app = useSelector((state) => state.app);
  const navigate = useNavigate();

  const filterMenuStats = [STATS_FILTER_AKTUELLEWOCHE, STATS_FILTER_LETZTE3WOCHEN, STATS_FILTER_GESAMT];
  const [activeFilterIndexStats, setActiveFilterIndexStats] = useState(STATS_FILTER_GESAMT.key);
  const handleSetActiveFilterIndexStats = (event) => {
    let index = event.target.dataset.index;
    setActiveFilterIndexStats(index);
  };

  const [openBesichtigungen, setOpenBesichtigungen] = useState(false);

  // const { isMobile } = useHandleScreenResize();

  const headline = 'Besichtigungen';
  if (!chartData) {
    // return <StatsSkeleton headline={headline} isDashboardVersion={isDashboardVersion} />;
    return null;
  } else {
    return (
      <>
        {isMobile ? (
          <div className="statistic-card--wrapper mt-100rem">
            {!isDashboardVersion && (
              <div className="statistic-card__header">
                <h2>{headline}</h2>
                <FilterMenu categories={filterMenuStats} activeIndex={activeFilterIndexStats} handleClick={handleSetActiveFilterIndexStats} cutside="right" />
              </div>
            )}

            {chartData.count ? (
              <div className="statistic-card__data">
                {!isDashboardVersion ? (
                  <>
                    <div className="statistic-card__chart statistic-card__numberchart">
                      <p>
                        <span className="font-500 font-weight-700"> {chartData.count[filterIndex || activeFilterIndexStats]}</span>
                        <span>{headline}</span>
                      </p>
                      <span>{headline}</span>
                    </div>
                    <div className="statistic-card__legend">
                      <h3>{headline}</h3>

                      <div className="mt-200rem">
                        <p>
                          <Link to={'../besichtigungen'} title="Ihre Besichtigungen">
                            Hier finden Sie die Detailseite
                          </Link>{' '}
                          mit allen Besichtigungen Ihrer Immobilie.
                        </p>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="statistic-card__chart-dashboard statistic-card__numberchart h-8rem">
                      <div className="chart-wrapper--vertical w-75">
                        <h3>{headline}</h3>
                        <button className="button button--glass" onClick={() => navigate(app.root + '/statistik')}>
                          <span>Details ansehen</span>
                        </button>
                      </div>
                      <div className="flex-center h-100p">
                        <span className="font-500 font-weight-700"> {chartData.count[filterIndex || activeFilterIndexStats]}</span>
                      </div>
                    </div>
                  </>
                )}
              </div>
            ) : (
              <>
                {isDashboardVersion && <h2>{headline}</h2>}
                <p className="statistic-card__emptymsg">Es sind noch keine Daten vorhanden.</p>
              </>
            )}
          </div>
        ) : (
          <Card cardStyle="customContent" cssClasses={`${isDashboardVersion ? 'vertical' : ''} statistic-card`}>
            {!isDashboardVersion && (
              <div className="statistic-card__header">
                <h2>{headline}</h2>
                <FilterMenu categories={filterMenuStats} activeIndex={activeFilterIndexStats} handleClick={handleSetActiveFilterIndexStats} cutside="right" />
              </div>
            )}

            {chartData.count ? (
              <div className="statistic-card__data">
                {!isDashboardVersion ? (
                  <>
                    <div className="statistic-card__chart statistic-card__numberchart">
                      <p>
                        <span className="font-500 font-weight-700">{chartData.count[activeFilterIndexStats]}</span>
                        <span>{headline}</span>
                      </p>
                    </div>
                    <div className="statistic-card__legend">
                      <h3>{headline}</h3>

                      <div className="mt-200rem">
                        <p>
                          <span onClick={() => setOpenBesichtigungen(true)} className="textlink">
                            Hier finden Sie die Detailseite
                          </span>{' '}
                          mit allen Besichtigungen Ihrer Immobilie.
                        </p>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="statistic-card__chart-dashboard statistic-card__numberchart">
                      <h3>{headline}</h3>

                      <button className="button button--glass mt-200rem" onClick={() => navigate(app.root + '/statistik')}>
                        <span>Details ansehen</span>
                      </button>
                    </div>
                    <span className="font-500 font-weight-700"> {chartData.count[filterIndex || activeFilterIndexStats]}</span>
                  </>
                )}
              </div>
            ) : (
              <>
                {isDashboardVersion && <h2>{headline}</h2>}
                <p className="statistic-card__emptymsg">Es sind noch keine Daten vorhanden.</p>
              </>
            )}
          </Card>
        )}

        <PageDialogBesichtigungen open={openBesichtigungen} handleClose={() => setOpenBesichtigungen(false)} />
      </>
    );
  }
}

export default BesichtigungenChart;
