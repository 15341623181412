import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { MENU_KAUF, MENU_VERKAUF, ROLE_INTERESSENT_NAME } from '../../constants';

import { getMessageStats } from '../../api/Contact';
import {
  PHASE0,
  PHASE1_VERKAUF,
  PHASE1_KAUF,
  PHASE2_KAUF_SP_AKTIV,
  PHASE2_KAUF_SP_BEARBEITUNG,
  PHASE2_VERKAUF_MIT_AKTIVITAET,
  PHASE2_VERKAUF_OHNE_AKTIVITAET,
  PHASE3_KAUF,
  PHASE3_VERKAUF,
  PHASE4_KAUF,
  PHASE4_VERKAUF,
} from '../../constants';
import { setMenuId } from '../../reducers/app';
import { useHandleScreenResize } from '../app/useHandleScreenResize';
import { useHandleUserRealtyActive } from '../user/useHandleUserRealtyActive';
import { getJustImmoContactsWithCategory } from '../../util/generalUtils';

export const useHandleMenu = () => {
  const user = useSelector((state) => state.user);
  const app = useSelector((state) => state.app);

  const { isMobile } = useHandleScreenResize();

  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { getKaeuferRealties, getKaeuferImmoPhase3, files } = useHandleUserRealtyActive();

  // Links
  const linkHome = {
    link: app.root + '/',
    name: 'Übersicht',
  };
  const linkVerkaufen = {
    link: app.root + '/verkaufen',
    name: 'Immobilie verkaufen',
  };
  const linkKaufen = {
    link: app.root + '/kaufen',
    name: 'Immobilie kaufen',
  };
  const linkImmosuche = {
    link: app.root + '/immobiliensuche',
    name: 'Immobiliensuche',
  };
  const linkNews = {
    link: app.root + '/news',
    name: 'News',
  };

  const linkFaqs = { link: app.root + '/faqs', name: 'FAQs' };
  const linkNachrichten = {
    link: app.root + '/nachrichten',
    name: 'Nachrichten',
  };
  const linkBesichtigungen = {
    link: app.root + '/besichtigungen',
    name: 'Besichtigungen',
  };
  const linkMarketing = {
    link: app.root + '/aktivitaeten',
    name: 'Weitere Aktivitäten',
  };
  const linkDashboard = {
    link: app.root + '/dashboard',
    name: 'Dashboard',
  };
  const linkStatistiken = {
    link: app.root + '/statistik',
    name: 'Statistik',
  };
  const linkImmoDrive = {
    link: app.root + '/immo-drive',
    name: 'immo-drive',
  };
  const linkProfil = {
    link: app.root + '/profil',
    name: 'Profil',
  };

  const submenuAktuelles = [linkNachrichten, linkBesichtigungen, linkMarketing];
  const linkAktuelles = {
    link: '',
    name: 'Aktuelles',
    submenu: submenuAktuelles,    
  };

  const linkAlleAngebote = {
    link: app.root + '/angebote',
    name: 'Alle Angebote',
    
  };
  const linkFavoriten = {
    link: app.root + '/favoriten',
    name: 'Favoriten',
    
  };

  const linkMeineImmobilie = getLinkMeineImmobilie();

  // Menus
  const menuPhase0 = [
    linkHome,
    linkVerkaufen,
    linkKaufen,
    linkImmosuche,
    linkNews,
    linkFaqs,
  ];

  const menuPhase1Verkauf = [
    linkDashboard,
    linkNachrichten,
    linkVerkaufen,
    linkKaufen,
    linkImmosuche,
    linkNews,
    linkFaqs,
  ];

  const menuPhase1Kauf = [
    linkDashboard,
    linkVerkaufen,
    linkKaufen,
    linkImmosuche,
    linkNews,
    linkFaqs,
  ];

  // menu phases
  // VERkAUF
  const menuPhase2VerkaufOhneAktivitaeten = [
    linkDashboard,
    linkNachrichten,
    linkMeineImmobilie,
    linkImmoDrive,
    linkNews,
    linkFaqs,
  ];
  const menuPhase2VerkaufMitAktivitaeten = [
    linkDashboard,
    linkStatistiken,
    linkAktuelles,
    linkMeineImmobilie,
    linkImmoDrive,
    linkNews,
    linkFaqs,
  ];
  const menuPhase3Verkauf = [
    linkDashboard,
    linkStatistiken,
    linkAktuelles,
    linkMeineImmobilie,
    linkImmoDrive,
    linkNews,
    linkFaqs,
  ];
  const menuPhase4Verkauf = [
    linkDashboard,
    linkNachrichten,
    linkImmoDrive,
    linkNews,
    linkFaqs,
  ];

  const menuPhase2KaufSuchprofilInBearbeitung = [
    linkDashboard,
		linkFavoriten,
    linkImmosuche,
    linkNews,
    linkFaqs,
  ];
  const menuPhase2KaufSuchprofilAktiv = [
    linkDashboard,
    linkAlleAngebote,
    linkFavoriten,
    linkImmosuche,
    linkNews,
    linkFaqs,
  ];
  const menuPhase3Kauf = [
    linkDashboard,
    linkMeineImmobilie,
    linkImmoDrive,
    linkNews,
    linkFaqs,
  ];
  const menuPhase4Kauf = [
    linkDashboard,
    linkNachrichten,
    linkImmoDrive,
    linkNews,
    linkFaqs,
  ];

  const menus = {
    [PHASE0]: menuPhase0,
    [PHASE1_VERKAUF]: menuPhase1Verkauf,
    [PHASE1_KAUF]: menuPhase1Kauf,
    [PHASE2_VERKAUF_OHNE_AKTIVITAET]: menuPhase2VerkaufOhneAktivitaeten,
    [PHASE2_VERKAUF_MIT_AKTIVITAET]: menuPhase2VerkaufMitAktivitaeten,
    [PHASE3_VERKAUF]: menuPhase3Verkauf,
    [PHASE4_VERKAUF]: menuPhase4Verkauf,
    [PHASE2_KAUF_SP_BEARBEITUNG]: menuPhase2KaufSuchprofilInBearbeitung,
    [PHASE2_KAUF_SP_AKTIV]: menuPhase2KaufSuchprofilAktiv,
    [PHASE3_KAUF]: menuPhase3Kauf,
    [PHASE4_KAUF]: menuPhase4Kauf,
  };

  const [menu, setMenu] = useState(menus[app.menuId]);
  // const [menu, setMenu] = useState(menuPhase1Verkauf)

  const getRealtyPhases = () => {
    let realtyPhases = [];
    if(user.userObject?.realtyPhases) {
      realtyPhases = [...user.userObject?.realtyPhases];
      realtyPhases = realtyPhases.filter((p) => p !== null && p !== undefined);
      
      realtyPhases = realtyPhases.sort((a, b) => {
        return b.phase - a.phase;
      })
    }
    return realtyPhases;
  }

  /**
   * set correct menu to display depending of phase user is in
   */
  const setMenuByPhase = () => {
    let menuId = null;

    if (!user.isLoggedIn) menuId = PHASE0;
    else {
      // sort realtyPhases Array by highest phase
      let realtyPhases = getRealtyPhases();

      switch (app.menuType) {
        case '':
          menuId = PHASE0;
          break;
        case MENU_VERKAUF:
          // manual phase-change
          if (user.userRealtyActive?.id && user.userRealtyActive?.id > -1) {
            let realtyIdPhase = realtyPhases.find((p) => p?.realtyId === user?.userRealtyActive?.id);

            if(realtyIdPhase && realtyIdPhase.phase) {
              switch (realtyIdPhase.phase) {
                case 4:
                  menuId = PHASE4_VERKAUF;
                  break;
                case 3:
                  menuId = PHASE3_VERKAUF;
                  break;
                case 2:
                  if (user.userRealtyActive?.activities?.length === 0) {
                    menuId = PHASE2_VERKAUF_OHNE_AKTIVITAET;
                  } else {
                    menuId = PHASE2_VERKAUF_MIT_AKTIVITAET;
                  }
                  break;
                case 1:
                default:
                  menuId = PHASE1_VERKAUF;
                  break;
              }
            } else {
              menuId = PHASE1_VERKAUF;
            }
          } else {
            menuId = PHASE1_VERKAUF;
          }         

          break;
        case MENU_KAUF:
          // manual phase-change
          const kaeuferImmos = getKaeuferRealties();
          
          if (kaeuferImmos?.length > 0) {
            let kaeuferImmosIds = kaeuferImmos.map((k) => k.id);
            let realtyIdPhase = realtyPhases.find((p) => kaeuferImmosIds.includes(p?.realtyId));
            
            if(realtyIdPhase?.phase) {
              switch (realtyIdPhase.phase) {
                case 4:
                  menuId = PHASE4_KAUF;
                  break;
                case 3:
                  menuId = PHASE3_KAUF;
                  break;
                case 2:
                case 1:
                default:
                  menuId = PHASE2_KAUF_SP_AKTIV;
                  break;
              }
            } else {
              // if any kaeuferImmos do exist
              menuId = PHASE2_KAUF_SP_AKTIV;
            }
          } else {
            if(user.searchProfiles?.length > 0) {
              menuId = PHASE2_KAUF_SP_BEARBEITUNG;
            }
            else {
              menuId = PHASE1_KAUF; 
            }
          }

          break;
        default:
          menuId = PHASE1_KAUF;
          break;
      }
    }

    if (menuId && menus.hasOwnProperty(menuId)) {
      // check if menuId has changed
      if (app.menuId !== menuId) {
        dispatch(setMenuId(menuId));

        let currentMenu = menus[menuId];
        setMenu(currentMenu);

				navigate(location.pathname + location.hash);
      }
    }
  };

  /**
   * get link structure for "Meine Immobilie"
   * @returns Object
   */
  function getLinkMeineImmobilie() {
    let linkMeineImmobilie = {};
    if (app.menuType === MENU_VERKAUF && user.userRealtyActive?.id > 0) {
      linkMeineImmobilie = {
        name: 'Meine Immobilie',
        link: app.root + '/immobilie/' + user.userRealtyActive.url,
        
      };
    } else if (app.menuType === MENU_KAUF) {
      let kaeuferPhase3Immo = getKaeuferImmoPhase3();
      if (kaeuferPhase3Immo) {
        linkMeineImmobilie = {
          name: 'Meine Immobilie',
          link: app.root + '/immobilie/' + kaeuferPhase3Immo.url,
          
        };
      }
    }

    return linkMeineImmobilie;
  }

  const getLinkName = (li) => {
    if (!li || !li.link) return '';

    let linkname = li.link.replace(app.root + '/', '');
    linkname = linkname.split('/')[0];
    return linkname;
  };

  /**
   * check which page is active and set css-class
   * @param {Object} li
   * @returns
   */
  const getLinkCssClass = (li) => {
    if (!li) return;

    let linkname = getLinkName(li);
    let pagename = location.pathname.split('/')[2];
    let active = false;
    if (linkname === pagename) {
      active = true;
    }

    if (li.submenu) {
      li.submenu.map((sub) => {
        let linknameSub = getLinkName(sub);
        if (linknameSub === pagename) {
          active = true;
        } else if (pagename === 'immobilie' && linkname === 'dashboard') {
          active = true;
        }
      });
    }

    let cssClasses = '';
    if (active) {
      cssClasses = 'active';
    }

    if (linkname === 'profil' || linkname === 'faqs') {
      cssClasses += ' header__nav--desktophidden';
    }
	if(linkname === 'nachrichten' && user.hasMessages === false) {
		cssClasses += ' is-hidden';
	}

    return cssClasses;
  };

  const getPageDialogSetting = (li) => {
    let openAsPageDialog = false;
    // if (!isMobile) {
      let linkname = getLinkName(li);
      if (linkname === 'besichtigungen' || linkname === 'aktivitaeten') {
        openAsPageDialog = true;
      }
    // }

    return openAsPageDialog;
  };

  /**
   * checks if menu has nachrichten and checks for unread-messages
   * @param {Array} menuToCheck
   * @returns
   */
  const findLinkInMenu = async (menuToCheck, linkname) => {
    // MESSAGES UNREAD HANDLING
    let foundInMenu = false;
    menuToCheck.map((li) => {
      if (getLinkName(li) === linkname) {
        foundInMenu = true;
      }

      if (li.submenu) {
        li.submenu.map((sub) => {
          if (getLinkName(sub) === linkname) {
            foundInMenu = true;
          }
        });
      }
    });

    return foundInMenu;
  };

  /**
   * show unread messages count in menu
   * @param {Array} menuToCheck
   * @returns
   */
  const getNachrichtenCount = async (li) => {
    if (getLinkName(li) === 'nachrichten') {
      try {
        const messageStats = await getMessageStats();
        return messageStats.unreadMessagesCount;
      } catch (error) {
        console.error(error);
        return 0;
      }
    }
  };

  /**
   * show files count in menu
   * @param {Array} menuToCheck
   * @returns
   */
  const getImmoDriveCount = (li) => {
    let count = 0;

    if (getLinkName(li) === 'immo-drive') {
      count = files.length;
    }

    return count;
  };

  return {
    menu,
    setMenuByPhase,
    getLinkCssClass,
    getPageDialogSetting,
    getLinkName,
    linkProfil,
    linkFaqs,
    getImmoDriveCount,
    getNachrichtenCount,
    getRealtyPhases
  };
};
