//*React
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

//*Components
import Aktuelles from '../components/Aktuelles/Aktuelles';
import Card from '../components/Cards/Card';
import ImmoDriveEmptyCard from '../components/Cards/ImmoDriveEmptyCard';
import DashboardHeaderStats from '../components/Dashboard/DashboardHeaderStats';
import HeaderCtaButtons from '../components/Header/HeaderCtaButtons';
import RecommendationLinkitem from '../components/Linkitems/RecommondationLinkitem';
import MainContent from '../components/MainContent';
import MetaTags from '../components/MetaTags';
import NewsTeaserCards from '../components/News/NewsTeaserCards';

//*Images
import IconHouseTree from '../components/Icons/IconHouseTree';

//*Utils
import { anliegenOptions } from '../components/FormFields/util/formOptions';
import { CONTACTFORM_ANLIEGEN_VERKAUF } from '../constants';
import { useHandleScreenResize } from '../hooks/app/useHandleScreenResize';
import { useFetchStats } from '../hooks/stats/useFetchStats';
import { useHandleUserRealtyActive } from '../hooks/user/useHandleUserRealtyActive';
import { setPageDialogOpenContactForm, setReasonContactForm } from '../reducers/contactInformation';
import ImmoDriveCard from '../components/Cards/ImmoDriveCard';

function DashboardVKPhase1() {
	const user = useSelector((state) => state.user);

  const { getData, inseratKlicksData, verschickteAngeboteData, besichtigungenData } = useFetchStats('VKPhase1');
  const { files } = useHandleUserRealtyActive();

  const dispatch = useDispatch();

  useEffect(() => {
    const dummy = true;
    getData(dummy);
  }, []);

  const { isMobile } = useHandleScreenResize();

  const hanldeSRealBeauftragen = () => {
    dispatch(setReasonContactForm(anliegenOptions[CONTACTFORM_ANLIEGEN_VERKAUF]));
    dispatch(setPageDialogOpenContactForm(true));
  };

  const headerCtabuttons = [
    <HeaderCtaButtons key="headerCtabuttons">
      <button className="button button--orange" onClick={hanldeSRealBeauftragen}>
        Jetzt Termin vereinbaren
      </button>
    </HeaderCtaButtons>,
  ];

  return (
    <>
      <MetaTags title={'Dashboard | Die Online-Plattform für Ihre Immobilie'} imageName={'sREAL_Sujets_2023_Webhero_16zu9_1.4.jpg'} />

      <DashboardHeaderStats
        stats={
          inseratKlicksData && verschickteAngeboteData && besichtigungenData
            ? {
                clicks: inseratKlicksData,
                offers: verschickteAngeboteData,
                sightings: besichtigungenData,
              }
            : {
                clicks: null,
                offers: null,
                sightings: null,
              }
        }
        cssClasses={'header--statsdummy'}
      >
        <div className="header__content-overlay"></div>
        <div className="header__content-wrapper header__content-wrapper--bottom">
          <div className="header__content">
            <h1 className="mt-0 mb-0 text-white">Hier finden Sie künftig Ihre Vermarktungsdetails</h1>
          </div>
          <div className="header__ctabuttons-wrapper">{headerCtabuttons}</div>
        </div>

        {/* cta buttons mobile-version fixed */}
        {headerCtabuttons}
      </DashboardHeaderStats>

      <MainContent>
	   	{ user.userObject?.directRegistrationCompleted === null && (
			<div className="gray-background--no-margin">
				<Aktuelles />
			</div>
	   	)}

        <div className={!isMobile ? 'w-desktop--center' : 'pt-200rem pb-0 mb-0 w-desktop--center'}>
          <h2 className="mb-200rem">Meine Immobilie</h2>
          <Card cssClasses={'immoabgeber-card--empty with-shadow'} cardStyle={'topImage2Cols'} icon={<IconHouseTree cssClasses="w-50" color="#fff" />}>
            <div>
              <h3 className="mb-100rem">Noch keine Immobilie in Vermarktung</h3>
              <p className="pt-50rem">Sobald wir Ihre Immobilie für die Vermarktung aufbereitet haben, finden Sie hier immer Ihr aktuelles Immobilieninserat.</p>
            </div>
          </Card>

          <div className="mt-400rem">
            <h2>immo-drive</h2>

            {files.length > 0 ? <ImmoDriveCard files={files} /> : <ImmoDriveEmptyCard />}
          </div>

          <RecommendationLinkitem cssClasses="mb-400rem" />
        </div>

        <NewsTeaserCards cssClasses="mt-400rem w-desktop--slider" />
      </MainContent>
    </>
  );
}

export default DashboardVKPhase1;
