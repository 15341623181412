function IconSmiley({ color, detailsColor, cssClasses, level, active }) {
  if (color === 'blue') {
    color = '#3679ec';
  }

  if (detailsColor === 'blue') {
    detailsColor = '#3679ec';
  }

  if (!detailsColor) detailsColor = '#fff';

  switch (level) {
    case 0:
      if (!!active) {
        return (
          <svg className={cssClasses} xmlns="http://www.w3.org/2000/svg" width="43.766" height="43.766" viewBox="0 0 43.766 43.766">
            <g data-name="Gruppe 6250" transform="translate(1.043 1.044)">
              <path data-name="Pfad 1158" d="M.86,1.72A20.839,20.839,0,0,0,21.7-19.12,20.839,20.839,0,0,0,.86-39.96,20.839,20.839,0,0,0-19.98-19.12,20.839,20.839,0,0,0,.86,1.72Z" transform="translate(19.98 39.96)" fill={color} stroke={color} strokeWidth="2" />
            </g>
            <g data-name="Gruppe 6251" transform="translate(8.66 25.826)">
              <path data-name="Pfad 1159" d="M0,.24C1.455-3.1,6.821-5.58,13.217-5.58c6.415,0,11.792,2.493,13.229,5.847" transform="translate(0 5.58)" fill="none" stroke={detailsColor} strokeLinecap="round" strokeWidth="2" />
            </g>
            <g data-name="Gruppe 6252" transform="translate(25.176 10.542)">
              <path data-name="Pfad 1161" d="M.147.295a3.57,3.57,0,0,0,3.57-3.57A3.571,3.571,0,0,0,.147-6.847a3.571,3.571,0,0,0-3.57,3.571A3.57,3.57,0,0,0,.147.295" transform="translate(3.423 6.847)" fill={detailsColor} />
            </g>
            <g data-name="Gruppe 6260" transform="translate(11.451 10.542)">
              <path data-name="Pfad 1161" d="M.147.295a3.57,3.57,0,0,0,3.57-3.57A3.571,3.571,0,0,0,.147-6.847a3.571,3.571,0,0,0-3.57,3.571A3.57,3.57,0,0,0,.147.295" transform="translate(3.423 6.847)" fill={detailsColor} />
            </g>
            <path data-name="Pfad 6232" d="M0,1.806H43.766V-41.96H0Z" transform="translate(0 41.96)" fill="none" />
          </svg>
        );
      } else {
        return (
          <svg className={cssClasses} xmlns="http://www.w3.org/2000/svg" width="43.766" height="43.766" viewBox="0 0 43.766 43.766">
            <g data-name="Gruppe 6250" transform="translate(1.043 1.044)">
              <path data-name="Pfad 1158" d="M.86,1.72A20.839,20.839,0,0,0,21.7-19.12,20.839,20.839,0,0,0,.86-39.96,20.839,20.839,0,0,0-19.98-19.12,20.839,20.839,0,0,0,.86,1.72Z" transform="translate(19.98 39.96)" fill="none" stroke={color} strokeWidth="2" />
            </g>
            <g data-name="Gruppe 6251" transform="translate(8.66 25.826)">
              <path data-name="Pfad 1159" d="M0,.24C1.455-3.1,6.821-5.58,13.217-5.58c6.415,0,11.792,2.493,13.229,5.847" transform="translate(0 5.58)" fill="none" stroke={color} strokeLinecap="round" strokeWidth="2" />
            </g>
            <g data-name="Gruppe 6252" transform="translate(25.176 10.542)">
              <path data-name="Pfad 1161" d="M.147.295a3.57,3.57,0,0,0,3.57-3.57A3.571,3.571,0,0,0,.147-6.847a3.571,3.571,0,0,0-3.57,3.571A3.57,3.57,0,0,0,.147.295" transform="translate(3.423 6.847)" fill={color} />
            </g>
            <g data-name="Gruppe 6260" transform="translate(11.451 10.542)">
              <path data-name="Pfad 1161" d="M.147.295a3.57,3.57,0,0,0,3.57-3.57A3.571,3.571,0,0,0,.147-6.847a3.571,3.571,0,0,0-3.57,3.571A3.57,3.57,0,0,0,.147.295" transform="translate(3.423 6.847)" fill={color} />
            </g>
            <path data-name="Pfad 6232" d="M0,1.806H43.766V-41.96H0Z" transform="translate(0 41.96)" fill="none" />
          </svg>
        );
      }
    case 1:
      if (!!active) {
        return (
          <svg className={cssClasses} xmlns="http://www.w3.org/2000/svg" width="43.766" height="43.766" viewBox="0 0 43.766 43.766">
            <g data-name="Gruppe 6257" transform="translate(1.043 1.044)">
              <path data-name="Pfad 1153" d="M.86,1.72A20.839,20.839,0,0,0,21.7-19.12,20.839,20.839,0,0,0,.86-39.96,20.839,20.839,0,0,0-19.98-19.12,20.839,20.839,0,0,0,.86,1.72Z" transform="translate(19.98 39.96)" fill={color} stroke={color} strokeWidth="2" />
            </g>
            <g data-name="Gruppe 6258" transform="translate(11.451 10.542)">
              <path data-name="Pfad 1154" d="M.147.295a3.57,3.57,0,0,0,3.57-3.57A3.571,3.571,0,0,0,.147-6.847a3.571,3.571,0,0,0-3.57,3.571A3.57,3.57,0,0,0,.147.295" transform="translate(3.423 6.847)" fill={detailsColor} />
            </g>
            <g data-name="Gruppe 6259" transform="translate(25.176 10.542)">
              <path data-name="Pfad 1155" d="M.147.295a3.57,3.57,0,0,0,3.57-3.57A3.571,3.571,0,0,0,.147-6.847a3.571,3.571,0,0,0-3.57,3.571A3.57,3.57,0,0,0,.147.295" transform="translate(3.423 6.847)" fill={detailsColor} />
            </g>
            <path data-name="Pfad 6234" d="M0,1.806H43.766V-41.96H0Z" transform="translate(0 41.96)" fill="none" />
            <path data-name="Pfad 6235" d="M0,0H25.164" transform="translate(9.301 27.119)" fill="none" stroke={detailsColor} strokeLinecap="round" strokeWidth="2" />
          </svg>
        );
      } else {
        return (
          <svg className={cssClasses} xmlns="http://www.w3.org/2000/svg" width="43.766" height="43.766" viewBox="0 0 43.766 43.766">
            <g data-name="Gruppe 6257" transform="translate(1.043 1.044)">
              <path data-name="Pfad 1153" d="M.86,1.72A20.839,20.839,0,0,0,21.7-19.12,20.839,20.839,0,0,0,.86-39.96,20.839,20.839,0,0,0-19.98-19.12,20.839,20.839,0,0,0,.86,1.72Z" transform="translate(19.98 39.96)" fill="none" stroke={color} strokeWidth="2" />
            </g>
            <g data-name="Gruppe 6258" transform="translate(11.451 10.542)">
              <path data-name="Pfad 1154" d="M.147.295a3.57,3.57,0,0,0,3.57-3.57A3.571,3.571,0,0,0,.147-6.847a3.571,3.571,0,0,0-3.57,3.571A3.57,3.57,0,0,0,.147.295" transform="translate(3.423 6.847)" fill={color} />
            </g>
            <g data-name="Gruppe 6259" transform="translate(25.176 10.542)">
              <path data-name="Pfad 1155" d="M.147.295a3.57,3.57,0,0,0,3.57-3.57A3.571,3.571,0,0,0,.147-6.847a3.571,3.571,0,0,0-3.57,3.571A3.57,3.57,0,0,0,.147.295" transform="translate(3.423 6.847)" fill={color} />
            </g>
            <path data-name="Pfad 6234" d="M0,1.806H43.766V-41.96H0Z" transform="translate(0 41.96)" fill="none" />
            <path data-name="Pfad 6235" d="M0,0H25.164" transform="translate(9.301 27.119)" fill="none" stroke={color} strokeLinecap="round" strokeWidth="2" />
          </svg>
        );
      }
    case 2:
      if (!!active) {
        return (
          <svg className={cssClasses} xmlns="http://www.w3.org/2000/svg" width="43.766" height="43.766" viewBox="0 0 43.766 43.766">
            <g data-name="Gruppe 6246" transform="translate(1.043 1.044)">
              <path data-name="Pfad 1148" d="M.86,1.72A20.839,20.839,0,0,0,21.7-19.12,20.839,20.839,0,0,0,.86-39.96,20.839,20.839,0,0,0-19.98-19.12,20.839,20.839,0,0,0,.86,1.72Z" transform="translate(19.98 39.96)" fill={color} stroke={color} strokeWidth="2" />
            </g>
            <g data-name="Gruppe 6247" transform="translate(8.715 25.031)">
              <path data-name="Pfad 1149" d="M0,0A13.527,13.527,0,0,0,13.161,10.216,13.525,13.525,0,0,0,26.334-.047" transform="translate(0 0.047)" fill="none" stroke={detailsColor} strokeLinecap="round" strokeWidth="2" />
            </g>
            <g data-name="Gruppe 6248" transform="translate(11.449 10.542)">
              <path data-name="Pfad 1150" d="M.147.295a3.57,3.57,0,0,0,3.57-3.57A3.571,3.571,0,0,0,.147-6.847a3.571,3.571,0,0,0-3.57,3.571A3.57,3.57,0,0,0,.147.295" transform="translate(3.423 6.847)" fill={detailsColor} />
            </g>
            <g data-name="Gruppe 6249" transform="translate(25.174 10.542)">
              <path data-name="Pfad 1151" d="M.147.295a3.57,3.57,0,0,0,3.57-3.57A3.571,3.571,0,0,0,.147-6.847a3.571,3.571,0,0,0-3.57,3.571A3.57,3.57,0,0,0,.147.295" transform="translate(3.423 6.847)" fill={detailsColor} />
            </g>
            <path data-name="Pfad 6231" d="M0,1.806H43.766V-41.96H0Z" transform="translate(0 41.96)" fill="none" />
          </svg>
        );
      } else {
        return (
          <svg className={cssClasses} xmlns="http://www.w3.org/2000/svg" width="43.766" height="43.766" viewBox="0 0 43.766 43.766">
            <g data-name="Gruppe 6246" transform="translate(1.043 1.044)">
              <path data-name="Pfad 1148" d="M.86,1.72A20.839,20.839,0,0,0,21.7-19.12,20.839,20.839,0,0,0,.86-39.96,20.839,20.839,0,0,0-19.98-19.12,20.839,20.839,0,0,0,.86,1.72Z" transform="translate(19.98 39.96)" fill="none" stroke={color} strokeWidth="2" />
            </g>
            <g data-name="Gruppe 6247" transform="translate(8.715 25.031)">
              <path data-name="Pfad 1149" d="M0,0A13.527,13.527,0,0,0,13.161,10.216,13.525,13.525,0,0,0,26.334-.047" transform="translate(0 0.047)" fill="none" stroke={color} strokeLinecap="round" strokeWidth="2" />
            </g>
            <g data-name="Gruppe 6248" transform="translate(11.449 10.542)">
              <path data-name="Pfad 1150" d="M.147.295a3.57,3.57,0,0,0,3.57-3.57A3.571,3.571,0,0,0,.147-6.847a3.571,3.571,0,0,0-3.57,3.571A3.57,3.57,0,0,0,.147.295" transform="translate(3.423 6.847)" fill={color} />
            </g>
            <g data-name="Gruppe 6249" transform="translate(25.174 10.542)">
              <path data-name="Pfad 1151" d="M.147.295a3.57,3.57,0,0,0,3.57-3.57A3.571,3.571,0,0,0,.147-6.847a3.571,3.571,0,0,0-3.57,3.571A3.57,3.57,0,0,0,.147.295" transform="translate(3.423 6.847)" fill={color} />
            </g>
            <path data-name="Pfad 6231" d="M0,1.806H43.766V-41.96H0Z" transform="translate(0 41.96)" fill="none" />
          </svg>
        );
      }
    case 3:
      if (!!active) {
        return (
          <svg className={cssClasses} xmlns="http://www.w3.org/2000/svg" width="43.766" height="43.766" viewBox="0 0 43.766 43.766">
            <g data-name="Gruppe 6253" transform="translate(1.045 1.044)">
              <path data-name="Pfad 1143" d="M.86,1.72A20.839,20.839,0,0,0,21.7-19.12,20.839,20.839,0,0,0,.86-39.96,20.839,20.839,0,0,0-19.98-19.12,20.839,20.839,0,0,0,.86,1.72Z" transform="translate(19.98 39.96)" fill={color} stroke={color} strokeWidth="2" />
            </g>
            <g data-name="Gruppe 6254" transform="translate(8.336 21.885)">
              <path data-name="Pfad 1144" d="M.559.554a13.479,13.479,0,0,0,13.548-13.41h-27.1A13.479,13.479,0,0,0,.559.554Z" transform="translate(12.989 12.856)" fill={detailsColor} />
            </g>
            <g data-name="Gruppe 6255" transform="translate(11.451 10.542)">
              <path data-name="Pfad 1145" d="M.147.295a3.57,3.57,0,0,0,3.57-3.57A3.571,3.571,0,0,0,.147-6.847a3.571,3.571,0,0,0-3.57,3.571A3.57,3.57,0,0,0,.147.295" transform="translate(3.423 6.847)" fill={detailsColor} />
            </g>
            <g data-name="Gruppe 6256" transform="translate(25.176 10.542)">
              <path data-name="Pfad 1146" d="M.147.295a3.57,3.57,0,0,0,3.57-3.57A3.571,3.571,0,0,0,.147-6.847a3.571,3.571,0,0,0-3.57,3.571A3.57,3.57,0,0,0,.147.295" transform="translate(3.423 6.847)" fill={detailsColor} />
            </g>
            <path data-name="Pfad 6233" d="M0,1.806H43.766V-41.96H0Z" transform="translate(0 41.96)" fill="none" />
          </svg>
        );
      } else {
        return (
          <svg className={cssClasses} xmlns="http://www.w3.org/2000/svg" width="43.766" height="43.766" viewBox="0 0 43.766 43.766">
            <g data-name="Gruppe 6253" transform="translate(1.045 1.044)">
              <path data-name="Pfad 1143" d="M.86,1.72A20.839,20.839,0,0,0,21.7-19.12,20.839,20.839,0,0,0,.86-39.96,20.839,20.839,0,0,0-19.98-19.12,20.839,20.839,0,0,0,.86,1.72Z" transform="translate(19.98 39.96)" fill="none" stroke={color} strokeWidth="2" />
            </g>
            <g data-name="Gruppe 6254" transform="translate(8.336 21.885)">
              <path data-name="Pfad 1144" d="M.559.554a13.479,13.479,0,0,0,13.548-13.41h-27.1A13.479,13.479,0,0,0,.559.554Z" transform="translate(12.989 12.856)" fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
            </g>
            <g data-name="Gruppe 6255" transform="translate(11.451 10.542)">
              <path data-name="Pfad 1145" d="M.147.295a3.57,3.57,0,0,0,3.57-3.57A3.571,3.571,0,0,0,.147-6.847a3.571,3.571,0,0,0-3.57,3.571A3.57,3.57,0,0,0,.147.295" transform="translate(3.423 6.847)" fill={color} />
            </g>
            <g data-name="Gruppe 6256" transform="translate(25.176 10.542)">
              <path data-name="Pfad 1146" d="M.147.295a3.57,3.57,0,0,0,3.57-3.57A3.571,3.571,0,0,0,.147-6.847a3.571,3.571,0,0,0-3.57,3.571A3.57,3.57,0,0,0,.147.295" transform="translate(3.423 6.847)" fill={color} />
            </g>
            <path data-name="Pfad 6233" d="M0,1.806H43.766V-41.96H0Z" transform="translate(0 41.96)" fill="none" />
          </svg>
        );
      }
    default:
      break;
  }
}

export default IconSmiley;
