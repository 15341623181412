import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { useHandleMenu } from '../../hooks/menu/useHandleMenu';
import IconArrowDown from '../Icons/IconArrowDown';
import MenuCount from './MenuCount';
import Submenu from './Submenu';

import { useEffect } from 'react';

function Menu({ flyout = false, cssClasses = '' }) {
  const app = useSelector((state) => state.app);
  const user = useSelector((state) => state.user);
  const kaufanbote = useSelector((state) => state.kaufAnbote);
  const location = useLocation();

  const { setMenuByPhase, menu, linkProfil, linkFaqs, getLinkCssClass } = useHandleMenu(true);

  useEffect(() => {
    setMenuByPhase();
  }, [location, user.isLoggedIn, app.menuType, user.userRealtyActive, user.searchProfiles, kaufanbote.kaufanboteList]);

  return (
    <ul className={'header__nav ' + cssClasses}>
      {flyout ? (
        <>
          <li className={getLinkCssClass(linkFaqs)} key={linkFaqs.name + '-flyout'}>
            <Link to={linkFaqs.link} title={linkFaqs.name} className="header__nav__link">
              <span className="header__nav__span">{linkFaqs.name}</span>
            </Link>
          </li>
          <li className={getLinkCssClass(linkProfil)} key={linkProfil.name + '-flyout'}>
            <Link to={linkProfil.link} title={linkProfil.name} className="header__nav__link">
              <span className="header__nav__span">{linkProfil.name}</span>
            </Link>
          </li>
        </>
      ) : menu && menu.length > 0 ? (
        <>
          {menu.map((li, index) => {
			let key = index;
			if(li.name) {
				key = li.name.toLowerCase().replace(/[^A-Z0-9]/ig, "");
			}

            return (
              <li className={getLinkCssClass(li)} key={key + '-' + index} data-id={`linkitem--${key}`}>
                {li.link ? (
                  <Link to={li.link} title={li.name} className="header__nav__link">
                    <span className="header__nav__span">
                      {li.name}
                      <MenuCount li={li} />
                    </span>
                  </Link>
                ) : li.submenu ? (
                  <span className="header__nav__link header__nav__link--withsubmenu">
                    <span className="header__nav__span">{li.name}</span>

                    <span className="header__nav__span">
                      <IconArrowDown cssClasses="header__nav__arrow" color="#fff"></IconArrowDown>
                    </span>

                    <Submenu submenu={li.submenu} />
                  </span>
                ) : null}
              </li>
            );
          })}

          {user.isLoggedIn && (
            <li className={getLinkCssClass(linkProfil)} key={linkProfil.name + '-mobile'}>
              <Link to={linkProfil.link} title={linkProfil.name} className="header__nav__link">
                <span className="header__nav__span">{linkProfil.name}</span>
              </Link>
            </li>
          )}
        </>
      ) : null}
    </ul>
  );
}

export default Menu;
