import InfoDialogUebergabe from '../Dialogs/InfoDialogUebergabe';
import IconKey from '../Icons/IconKey';
import IconKeySuccess from '../Icons/IconKeySuccess';
import Card from './Card';

import { useState } from 'react';
import {
  UEBERGABE_CARDTYPE_CLOSED,
  UEBERGABE_CARDTYPE_PENDING,
  UEBERGABE_CARDTYPE_SAMPLE,
} from '../../constants';

function UebergabeCard({ cardType, cssClasses }) {
  cssClasses += ' uebergabe-card';
  let status;
  let link;

  const [openInfoDialog, setOpenInfoDialog] = useState(false);
  const handleOpenInfoDialog = () => {
    let newVal = !openInfoDialog;
    setOpenInfoDialog(newVal);
  };

  switch (cardType) {
    case UEBERGABE_CARDTYPE_SAMPLE:
    default:
      status = 'So funktioniert die Übergabe';
      link = (
        <a href={require('../../assets/documents/muster_uebergabeprotokoll.pdf')} target="_blank" rel="noopener noreferrer" className="textlink">
          Musterübergabeprotokoll
        </a>
      );
      break;
    case UEBERGABE_CARDTYPE_PENDING:
      status = 'Übergabe ausständig';
      link = (
        <span className="textlink" onClick={handleOpenInfoDialog}>
          So funktioniert die Übergabe
        </span>
      );
      break;
    // obsolete
    // case UEBERGABE_CARDTYPE_CLOSED:
    //   status = 'Übergabe erledigt';
    //   link = (
    //     <span className="textlink" onClick={() => downloadProtokoll(uebergabeprotokoll)}>
    //       Zum Übergabe Protokoll
    //     </span>
    //   );

    //   cssClasses += ' uebergabe-card__finished';
    //   break;
  }

  return (
    <>
      <Card cardStyle={'customContent'} cssClasses={cssClasses}>
        <div className="card__content">
          <h2>Übergabestatus</h2>
          <div className="uebergabe-card__status">
            {(cardType === UEBERGABE_CARDTYPE_PENDING ||
              cardType === UEBERGABE_CARDTYPE_SAMPLE) && <IconKey color={'#1e3163'} />}
            {cardType === UEBERGABE_CARDTYPE_CLOSED && <IconKeySuccess color="#fff" />}

            <h3>{status}</h3>
          </div>
          {link}
        </div>
      </Card>

      <InfoDialogUebergabe handleClose={() => handleOpenInfoDialog()} open={openInfoDialog} />
    </>
  );
}
export default UebergabeCard;
