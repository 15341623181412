import IconMessageSent from '../../Icons/IconMessageSent';

function ContactEmailSent({ handleClose }) {
  return (
    <>
      <div className="d-flex w-100 justify-content-center pt-40 pb-40">
        <IconMessageSent color="#1E3163" />
      </div>

      <h2 className="font-weight-600 font-170">Anfrage erfolgreich verschickt.</h2>

      <p>Vielen Dank für Ihre Anfrage! Eine Mitarbeiter:in wird sich in Kürze bei Ihnen melden.</p>

      <div className="button-panel pt-200rem">
        <button className="button button--blue-outline" onClick={handleClose}>
          Schließen
        </button>
      </div>
    </>
  );
}

export default ContactEmailSent;
