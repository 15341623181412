import React, { useEffect, useState } from 'react';
import Card from './Card';
import IconEye from '../Icons/IconEye';
import DotUnread from '../DotUnread';
import { parseTime, parseDate } from '../../util/datetimeParser';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

function BesichtigungCard({ cssClasses, nameClient, nameAgent, dateFrom, dateTo, immo, comment, id, unread = false }) {
  if (!cssClasses) cssClasses = 'besichtigung-card';
  else cssClasses += ' besichtigung-card';

  const user = useSelector((state) => state.user);
  const app = useSelector((state) => state.app);

  const [date, setDate] = useState(null);
  const [timeFrom, setTimeFrom] = useState(null);
  const [timeTo, setTimeTo] = useState(null);
  useEffect(() => {
    if (dateFrom) {
      setDate(parseDate(dateFrom));

      let timeFromParsed = parseTime(dateFrom);
      if (timeFromParsed !== '00:00') {
        setTimeFrom(timeFromParsed);
      }
    }

    if (dateTo) {
      let timeToParsed = parseTime(dateTo);
      if (timeToParsed !== '00:00') {
        setTimeTo(timeToParsed);
      }
    }
  }, [user.userRealtyActive]);

  return (
    <Card cardStyle="customContent" cssClasses={cssClasses}>
      <span className="js-dataid is-hidden" data-id={id}></span>
      <div className="card__content">
        <div className="d-flex flex-row justify-content-between align-items-center mb-20 mt-100rem">
          <div className="besichtigung-card__title">
            <IconEye color="#ffffff" />
            <h3 className="font-100 font-weight-700">Besichtigung</h3>
          </div>

          <div className="besichtigung-card__date">{date}</div>
        </div>

        <ul className="list-style-none lh-150">
          {nameClient && (
            <li>
              <span className="font-weight-600">Von:</span> {nameClient}
            </li>
          )}

          {nameAgent && (
            <li>
              <span className="font-weight-600">Makler:in:</span> {nameAgent}
            </li>
          )}

          {timeFrom && timeFrom !== '00:00' && (
            <li>
              <span className="font-weight-600">Uhrzeit:</span> {timeFrom}
              {timeTo && <> - {timeTo}</>} Uhr
            </li>
          )}

          {immo && (
            <li>
              <span className="font-weight-600">Immobilie:</span>{' '}
              <Link to={app.root + '/immobilie/' + immo.url} title={immo.title}>
                {immo.title}
              </Link>
            </li>
          )}
        </ul>

        {comment && (
          <div className="mt-40">
            <h4 className="font-weight-700">Kommentar:</h4>
            <p>{comment}</p>
          </div>
        )}
      </div>

      {unread !== false && <DotUnread unreadAmount={unread} />}
    </Card>
  );
}

export default BesichtigungCard;
