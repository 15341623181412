import React from 'react';

function IconHouse({ color, cssClasses }) {
  return (
    <svg
      className={cssClasses}
      data-name="Gruppe 10228"
      xmlns="http://www.w3.org/2000/svg"
      width="30.744"
      height="26.259"
      viewBox="0 0 30.744 26.259"
    >
      <g data-name="Gruppe 10131" transform="translate(0 0)">
        <path
          data-name="Pfad 7576"
          d="M30.1,22.029H26.9V11.14a.642.642,0,0,0-.64-.64H4.484a.64.64,0,0,0-.64.64V22.029H.641a.64.64,0,0,0,0,1.281H30.1a.64.64,0,0,0,0-1.281M7.687,12.421a.64.64,0,0,1,.64-.64H12.17a.642.642,0,0,1,.64.64v6.4a.642.642,0,0,1-.64.64H8.327a.64.64,0,0,1-.64-.64Zm6.4,9.607a.642.642,0,0,1-.64.64h-6.4a.64.64,0,0,1-.64-.64v-.64a.64.64,0,0,1,.64-.64h6.4a.642.642,0,0,1,.64.64Zm8.967-4.483a.642.642,0,0,1-.64.64h-6.4a.64.64,0,0,1-.64-.64V13.7a.64.64,0,0,1,.64-.64h6.4a.642.642,0,0,1,.64.64Z"
          transform="translate(0 2.95)"
          fill={color}
        />
        <path
          data-name="Pfad 7577"
          d="M.642,12.169H30.1a.64.64,0,0,0,.38-1.155L26.9,8.365V3.2a.642.642,0,0,0-.64-.64H22.418a.64.64,0,0,0-.64.64V4.577L15.753.125a.646.646,0,0,0-.763,0L.259,11.014a.641.641,0,0,0,.383,1.155"
          transform="translate(0 0)"
          fill={color}
        />
      </g>
    </svg>
  );
}

export default IconHouse;
