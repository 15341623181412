function IconPhone({ color, cssClasses, call }) {
  if (!!call) {
    return (
      <svg
        className={cssClasses}
        xmlns="http://www.w3.org/2000/svg"
        width="23.994"
        height="23.998"
        viewBox="0 0 23.994 23.998"
      >
        <g data-name="Gruppe 10543" transform="translate(-0.001)">
          <path
            data-name="Pfad 7788"
            d="M17.166,16.154a2.5,2.5,0,0,0-3.536,0l-.385.385A46.9,46.9,0,0,1,7.456,10.75l.385-.385a2.5,2.5,0,0,0,0-3.536L5.72,4.708a2.5,2.5,0,0,0-3.536,0L1.02,5.872a3.512,3.512,0,0,0-.442,4.4A46.962,46.962,0,0,0,13.72,23.418a3.5,3.5,0,0,0,4.4-.444l1.164-1.164a2.5,2.5,0,0,0,0-3.535Z"
            fill={color}
          />
          <path
            data-name="Pfad 7789"
            d="M11.995,0a.5.5,0,0,0,0,1A11.012,11.012,0,0,1,23,12a.5.5,0,0,0,1,0,12.014,12.014,0,0,0-12-12"
            fill={color}
          />
          <path
            data-name="Pfad 7790"
            d="M11.995,5a7.008,7.008,0,0,1,7,7,.5.5,0,0,0,1,0,8.009,8.009,0,0,0-8-8,.5.5,0,0,0,0,1"
            fill={color}
          />
          <path
            data-name="Pfad 7791"
            d="M11.995,9a3,3,0,0,1,3,3,.5.5,0,0,0,1,0,4,4,0,0,0-4-4,.5.5,0,0,0,0,1"
            fill={color}
          />
        </g>
      </svg>
    );
  } else {
    return (
      <svg
        className={cssClasses}
        xmlns="http://www.w3.org/2000/svg"
        width="23.994"
        height="23.998"
        viewBox="0 0 23.994 23.998"
      >
        <g data-name="Gruppe 10543" transform="translate(-0.001)">
          <path
            data-name="Pfad 7788"
            d="M17.166,16.154a2.5,2.5,0,0,0-3.536,0l-.385.385A46.9,46.9,0,0,1,7.456,10.75l.385-.385a2.5,2.5,0,0,0,0-3.536L5.72,4.708a2.5,2.5,0,0,0-3.536,0L1.02,5.872a3.512,3.512,0,0,0-.442,4.4A46.962,46.962,0,0,0,13.72,23.418a3.5,3.5,0,0,0,4.4-.444l1.164-1.164a2.5,2.5,0,0,0,0-3.535Z"
            fill={color}
          />
        </g>
      </svg>
    );
  }
}

export default IconPhone;
