function IconCalendar({ color, cssClasses }) {
  return (
    <svg
      className={cssClasses}
      xmlns="http://www.w3.org/2000/svg"
      width="53.076"
      height="42.019"
      viewBox="0 0 53.076 42.019"
    >
      <g data-name="Gruppe 10739" transform="translate(-1.119 -0.737)">
        <g data-name="Gruppe 10731" transform="translate(1.119 0.737)">
          <path
            data-name="Pfad 6348"
            d="M0,29.221A5.534,5.534,0,0,0,5.529,34.75H47.547a5.535,5.535,0,0,0,5.529-5.529V6H0ZM35.384,11.529a1.108,1.108,0,0,1,1.106-1.106h6.635a1.108,1.108,0,0,1,1.106,1.106v6.635a1.1,1.1,0,0,1-1.106,1.106H36.49a1.1,1.1,0,0,1-1.106-1.106Zm0,11.058a1.108,1.108,0,0,1,1.106-1.106h6.635a1.108,1.108,0,0,1,1.106,1.106v6.635a1.1,1.1,0,0,1-1.106,1.106H36.49a1.1,1.1,0,0,1-1.106-1.106ZM22.115,11.529a1.108,1.108,0,0,1,1.106-1.106h6.635a1.108,1.108,0,0,1,1.106,1.106v6.635a1.1,1.1,0,0,1-1.106,1.106H23.221a1.1,1.1,0,0,1-1.106-1.106Zm0,11.058a1.108,1.108,0,0,1,1.106-1.106h6.635a1.108,1.108,0,0,1,1.106,1.106v6.635a1.1,1.1,0,0,1-1.106,1.106H23.221a1.1,1.1,0,0,1-1.106-1.106ZM8.846,11.529a1.108,1.108,0,0,1,1.106-1.106h6.635a1.108,1.108,0,0,1,1.106,1.106v6.635a1.1,1.1,0,0,1-1.106,1.106H9.952a1.1,1.1,0,0,1-1.106-1.106Zm0,11.058a1.108,1.108,0,0,1,1.106-1.106h6.635a1.108,1.108,0,0,1,1.106,1.106v6.635a1.1,1.1,0,0,1-1.106,1.106H9.952a1.1,1.1,0,0,1-1.106-1.106Z"
            transform="translate(0 7.269)"
            fill={color}
          />
          <path
            data-name="Pfad 6349"
            d="M47.547,0H5.529A5.534,5.534,0,0,0,0,5.529v5.529H53.076V5.529A5.535,5.535,0,0,0,47.547,0M8.846,8.846a2.212,2.212,0,1,1,2.212-2.212A2.211,2.211,0,0,1,8.846,8.846m6.635,0a2.212,2.212,0,1,1,2.212-2.212,2.211,2.211,0,0,1-2.212,2.212m6.635,0a2.212,2.212,0,1,1,2.212-2.212,2.211,2.211,0,0,1-2.212,2.212"
            transform="translate(0 0)"
            fill={color}
          />
        </g>
      </g>
    </svg>
  );
}

export default IconCalendar;
