//*React
import { memo, useEffect, useMemo, useState } from 'react';
import Countdown, { zeroPad } from 'react-countdown';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

//*Components
import ButtonForwardAction from '../../Buttons/ButtonForwardAction';
import FormInput from '../../FormFields/FormInput';
import LoadingSpinner from '../../LoadingSpinner';

//*API
import { confirm, current, login, token, directRegisterConfirm } from '../../../api/Onboarding';

//*Reducers
import { setFormIsLoading } from '../../../reducers/app';
import { setSiteIndexLoginForm, setTokenLoginForm } from '../../../reducers/loginForm';
import { setSiteIndexRegisterForm, setTokenRegisterForm } from '../../../reducers/registerForm';

//*Utils
import { fetchErrorText } from '../../FormFields/util/formTexts';
import { checkTokenToVerificationCode, validateFields } from '../../FormFields/util/validateFields';

//*Icons
import IconMessageSent1 from '../../Icons/IconMessageSent1';

//*Constants
import { LOGINFORM_SITEINDEX_ERROR, LOGINFORM_SITEINDEX_LOGINSUCCESS, REGFORM_SITEINDEX_ERROR, REGFORM_SITEINDEX_LOGINSUCCESS, REGFORM_SITEINDEX_CHANGEPHONENUMBER, DIREKTREGFORM_SITEINDEX_ERROR, DIREKTREGFORM_SITEINDEX_LOGINSUCCESS, DIREKTREGFORM_SITEINDEX_SUCCESSTOKEN } from '../../../constants';
import { useHandleUser } from '../../../hooks/user/useHandleUser';

function FormToken({ formType }) {
  let registerForm = useSelector((state) => state.registerForm);
  let loginForm = useSelector((state) => state.loginForm);

  let app = useSelector((state) => state.app);
  let currentFormValidators = useSelector((state) => state.formValidators);
  let location = useLocation();

  const { setupUser } = useHandleUser(true);

  const dispatch = useDispatch();
  const [error, setError] = useState('');

  const [isDirektReg, setIsDirektReg] = useState(false);
  const [mobilnummer, setMobilnummer] = useState(null);
  useEffect(() => {
    if (registerForm.siteIndex === DIREKTREGFORM_SITEINDEX_SUCCESSTOKEN) {
      setIsDirektReg(true);
    }

	if (formType === 'registerForm') {
		setMobilnummer(registerForm.requestBody.phoneNumber);
	} else if (formType === 'loginForm') {
		setMobilnummer(loginForm.phoneNumber);
	}
  }, [])

  const [timerRunning, setTimerRunning] = useState(true);
  let seconds = useMemo(() => {
    if (timerRunning) {
      let minutesCnt = 60000 * 3; // 3 mins
      let maxTimerCount = Date.now() + minutesCnt;

      const now = new Date().getTime();

      const lastSessionStorageTime = localStorage.getItem('tokenTimerStart');
      if (!lastSessionStorageTime) {
        localStorage.setItem('tokenTimerStart', now);
      } else {
        const diff = Math.floor(now - parseInt(lastSessionStorageTime));

        if (diff >= minutesCnt) {
          localStorage.removeItem('tokenTimerStart');
          maxTimerCount = Date.now();
        }

        maxTimerCount -= diff;
      }

      return maxTimerCount;
    } else {
      return -1;
    }
  }, [timerRunning]);

  const renderer = ({ minutes, seconds, completed }) => {
    if (completed) {
      return (
        <span className="font-80 textlink" onClick={resendConfirmationCode}>
          {' '}
          Code erneut anfordern
        </span>
      );
    } else {
      return (
        <span className="font-80">
          SMS-Code gültig für{' '}
          <span>
            {minutes}:{zeroPad(seconds)}
          </span>{' '}
          Minuten.
        </span>
      );
    }
  };

  const forwardAction = async () => {
    setError('');
    if (app.formIsLoading) {
      return;
    }

    if (formType === 'registerForm') {
      await sendToken(registerForm.token, '', registerForm.rememberMe, true);
    } else if (formType === 'loginForm') {
      await sendToken(loginForm.token, loginForm.verificationCode, loginForm.requestBody.rememberMe, false);
    }
  };

  const getCurrentUserObject = async () => {
    try {
      const currentUser = await current();
      setupUser(currentUser);

      if (formType === 'registerForm' || location.pathname.indexOf('/confirm') >= 0) {
        dispatch(setSiteIndexRegisterForm(REGFORM_SITEINDEX_LOGINSUCCESS));
      }
      else if (formType === 'registerForm' || isDirektReg) {
        dispatch(setSiteIndexRegisterForm(DIREKTREGFORM_SITEINDEX_LOGINSUCCESS));
      } else if (formType === 'loginForm') {
        dispatch(setSiteIndexLoginForm(LOGINFORM_SITEINDEX_LOGINSUCCESS));
      }
    } catch (error) {
      // Handle user error
      dispatch(setFormIsLoading(false));
      setError(fetchErrorText);
      window.location.hash = LOGINFORM_SITEINDEX_ERROR;
    }
  };

  const sendToken = async (formToken, verificationCode, rememberMe, initial) => {
    let validationResult = validateFields({ token: formToken }, dispatch);

    if (validationResult && verificationCode) {
      validationResult = checkTokenToVerificationCode(formToken, verificationCode, dispatch);
    }
    if (validationResult) {
      dispatch(setFormIsLoading(true));

      try {
        await token({
          token: formToken,
          rememberMe: rememberMe,
          initial: initial,
        });

        getCurrentUserObject();
      } catch (e) {
        dispatch(setFormIsLoading(false));
        setError('Der SMS-Code ist leider falsch.');

        if (formType === 'registerForm') {
          if (isDirektReg) {
            window.location.hash = DIREKTREGFORM_SITEINDEX_ERROR;
          }
          else {
            window.location.hash = REGFORM_SITEINDEX_ERROR;
          }
        } else if (formType === 'loginForm') {
          window.location.hash = LOGINFORM_SITEINDEX_ERROR;
        }
      }
    }
  };

  const resendConfirmationCode = () => {
    setError('');
    if (app.formIsLoading) {
      return;
    }

    if (location.pathname.indexOf('/confirm') >= 0) {
      formType = 'registerForm';
    }

    if (formType === 'registerForm') {
      dispatch(setTokenRegisterForm(''));
      resendConfirmationCodeRegForm();
    } else if (formType === 'loginForm') {
      dispatch(setTokenLoginForm(''));
      resendConfirmationCodeLoginForm();
    }
  };

  const resendConfirmationCodeLoginForm = async () => {
    try {
      dispatch(setFormIsLoading(true));
      const res = await login(loginForm.requestBody);
      dispatch(setFormIsLoading(false));

      if (typeof res !== 'string') {
        setError(fetchErrorText);
        setTimerRunning(false);
      } else {
        setTimerRunning(true);

        const now = new Date().getTime();
        localStorage.setItem('tokenTimerStart', now);
      }
    } catch (error) {
      setError(fetchErrorText);
      dispatch(setFormIsLoading(false));
    }
  };

  const resendConfirmationCodeRegForm = async () => {
    const { userId, code } = registerForm;

    if (!!userId && !!code) {
      try {
        dispatch(setFormIsLoading(true));
        let res = false;
        if(!isDirektReg) {
          res = await confirm({ userId, code });
        }
        else {
          res = await directRegisterConfirm({
            userId: userId,
            code: code,
            mode: 0,
            registerDto: registerForm.requestBody
          });
        }
        
        dispatch(setFormIsLoading(false));

        if (typeof res !== 'string') {
          setError(fetchErrorText);
          setTimerRunning(false);
        } else {
          setTimerRunning(true);

          const now = new Date().getTime();
          localStorage.setItem('tokenTimerStart', now);
        }
      } catch (error) {
        dispatch(setFormIsLoading(false));
        setError(fetchErrorText);
        setTimerRunning(false);
      }
    } else {
      setError(fetchErrorText);
      setTimerRunning(false);
    }
  };

  const CountdownWrapper = () => <Countdown date={seconds} renderer={renderer} onComplete={() => setTimerRunning(false)} />;

  const MemoCountdown = memo(CountdownWrapper);

  return (
    <>
      <div className="d-flex w-100 justify-content-center pt-40 pb-40">
        <IconMessageSent1 color="#1E3163" />
      </div>

      <h2>Sie haben eine SMS erhalten</h2>

		{ mobilnummer && (
			<>
				<label htmlFor="mobilnummer">Mobilnummer</label>
				<br />
				<span className="d-flex font-120 font-weight-600 lh-200">{mobilnummer}</span>
			</>
		)}

      {(formType === 'registerForm' && !isDirektReg) && (
        <div className="font-80 mb-200rem">
          <span className="textlink" onClick={() => { dispatch(setSiteIndexRegisterForm(REGFORM_SITEINDEX_CHANGEPHONENUMBER)) }}>
            Mobilnummer ändern
          </span>
        </div>
      )}

      <FormInput type={'text'} id={'inputToken'} label={'SMS-Code'} error={currentFormValidators.tokenError} required={true} formType={formType} cssClasses={'mt-100rem'} />

      <MemoCountdown />

      <LoadingSpinner />

      <div className="button-panel pt-100rem">
        <ButtonForwardAction formType={formType} buttonText="Bestätigen" forwardAction={forwardAction} />
      </div>

      {error && <p className="form__fielderror">{error}</p>}
    </>
  );
}

export default FormToken;
