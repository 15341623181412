import * as React from 'react';

import '../styles/table.scss';

function Table({ list, cssClasses = '', htmlParser = false }) {
  const parseHTML = require('html-react-parser');

  return (
    <div className={'table' + cssClasses}>
      {list.map((item, index) => {
        return (
          <div className="tableRowContent" key={index}>
            <span className="tablerow__label font-weight-700 ">{item.label}</span>
            <span className="tablerow__value">
              { htmlParser ? (
                parseHTML(String(item.value))
              ) : (
                item.value
              )}
            </span>
          </div>
        );
      })}
    </div>
  );
}

export default Table;
