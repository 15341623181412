function IconThumbUpDown({ color, cssClasses }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="181.603"
      height="103.82"
      viewBox="0 0 181.603 103.82"
      className={cssClasses}
    >
      <g data-name="Gruppe 9011">
        <g data-name="Gruppe 9130" transform="translate(0 0)">
          <path
            data-name="Pfad 7360"
            d="M8.011,67.712a39.5,39.5,0,0,1,11.4,3.307A25.619,25.619,0,0,0,31.1,73.648H50.427a7.026,7.026,0,0,0,5.837-10.94,7.023,7.023,0,0,0,3.514-10.543,7.023,7.023,0,0,0,3.514-10.543,7.027,7.027,0,0,0-2.323-13.66H38.834c1.483-4.533,4.213-14.12,2.745-19.356C39.336.623,34.68,0,33.306,0c-3.261,0-5.721,2.681-5.721,6.238,0,11.094-10.367,28.113-19.328,28.113A1.755,1.755,0,0,0,6.5,36.109V65.98a1.749,1.749,0,0,0,1.511,1.732"
            transform="translate(16.342 -0.001)"
            fill={color}
          />
          <path
            data-name="Pfad 7361"
            d="M1.757,51.127H19.328a1.76,1.76,0,0,0,1.757-1.757V10.714a1.76,1.76,0,0,0-1.757-1.757H1.757A1.758,1.758,0,0,0,0,10.714V49.37a1.758,1.758,0,0,0,1.757,1.757M12.3,41.7a1.757,1.757,0,1,1-1.757,1.757A1.758,1.758,0,0,1,12.3,41.7"
            transform="translate(0 22.516)"
            fill={color}
          />
        </g>
      </g>
      <g
        data-name="Gruppe 9012"
        transform="translate(181.603 103.82) rotate(180)"
      >
        <g data-name="Gruppe 9131" transform="translate(0 0)">
          <path
            data-name="Pfad 7360"
            d="M1.511,67.711a39.5,39.5,0,0,1,11.4,3.307A25.619,25.619,0,0,0,24.6,73.647H43.927a7.026,7.026,0,0,0,5.837-10.94,7.023,7.023,0,0,0,3.514-10.543,7.023,7.023,0,0,0,3.514-10.543,7.027,7.027,0,0,0-2.323-13.66H32.334c1.483-4.533,4.213-14.12,2.745-19.356C32.836.622,28.18,0,26.806,0c-3.261,0-5.721,2.681-5.721,6.238,0,11.094-10.367,28.113-19.328,28.113A1.755,1.755,0,0,0,0,36.108v29.87a1.749,1.749,0,0,0,1.511,1.732"
            transform="translate(22.842 0)"
            fill={color}
          />
          <path
            data-name="Pfad 7361"
            d="M1.757,42.17H19.328a1.76,1.76,0,0,0,1.757-1.757V1.757A1.76,1.76,0,0,0,19.328,0H1.757A1.758,1.758,0,0,0,0,1.757V40.413A1.758,1.758,0,0,0,1.757,42.17M12.3,32.745A1.757,1.757,0,1,1,10.543,34.5,1.758,1.758,0,0,1,12.3,32.745"
            transform="translate(0 31.473)"
            fill={color}
          />
        </g>
      </g>
    </svg>
  );
}

export default IconThumbUpDown;
