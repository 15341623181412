function IconVorteilMehrwert({ color, cssClasses }) {
  return (
    <svg
      className={cssClasses}
      xmlns="http://www.w3.org/2000/svg"
      width="141.784"
      height="141.789"
      viewBox="0 0 141.784 141.789"
    >
      <path
        id="Pfad_8448"
        data-name="Pfad 8448"
        d="M330.866,2938.125a6.344,6.344,0,0,0,3.906-1.342,6.413,6.413,0,0,0,2.114-7.373l-17.736-47.282,35.163-29.271a6.452,6.452,0,0,0-4.112-11.407H303.5L291.939,2801a6.452,6.452,0,0,0-12.4,0l-11.549,40.447H221.3a6.452,6.452,0,0,0-4.138,11.407l35.15,29.271-17.723,47.282a6.444,6.444,0,0,0,9.783,7.5l41.376-29.543,41.363,29.543A6.377,6.377,0,0,0,330.866,2938.125Z"
        transform="translate(-214.85 -2796.336)"
        fill={color}
      />
    </svg>
  );
}

export default IconVorteilMehrwert;
