function IconSearchHouse({ color, cssClasses }) {
  return (
    <svg
      id="Gruppe_11174"
      data-name="Gruppe 11174"
      xmlns="http://www.w3.org/2000/svg"
      width="106.382"
      height="106.384"
      viewBox="0 0 106.382 106.384"
      className={cssClasses}
    >
      <g id="Gruppe_8939" data-name="Gruppe 8939" transform="translate(0 0)">
        <g id="Gruppe_8930" data-name="Gruppe 8930" transform="translate(17.86 14.484)">
          <g id="Gruppe_8927" data-name="Gruppe 8927" transform="translate(5.16 5.075)">
            <g id="Gruppe_8929" data-name="Gruppe 8929" transform="translate(0 0)">
              <path
                id="Pfad_7345"
                data-name="Pfad 7345"
                d="M21.524,7.938a.649.649,0,0,0,1.107-.459V1.645A.648.648,0,0,0,21.983,1H16.149A.649.649,0,0,0,15.69,2.1Z"
                transform="translate(10.123 -0.997)"
                fill={color}
              />
              <path
                id="Pfad_7346"
                data-name="Pfad 7346"
                d="M3,19.165v20.92H15.87V27.216h7.354V40.085H36.093V19.165L19.547,2.619Z"
                transform="translate(-3.001 -2.619)"
                fill={color}
              />
            </g>
          </g>
          <path
            id="Pfad_7348"
            data-name="Pfad 7348"
            d="M25.93,28.685a2.849,2.849,0,0,0,1.976.782,2.741,2.741,0,0,0,2.79-2.679V2.679A2.737,2.737,0,0,0,27.906,0H2.793A2.793,2.793,0,0,0,.215,1.661a2.591,2.591,0,0,0,.6,2.915Z"
            transform="translate(0 21.706) rotate(-45)"
            fill={color}
          />
        </g>
        <g id="Gruppe_8931" data-name="Gruppe 8931">
          <path
            id="Pfad_7344"
            data-name="Pfad 7344"
            d="M39.893,79.787a39.694,39.694,0,0,0,24.969-8.83l33.945,34.122a4.435,4.435,0,0,0,6.285-6.259L71.1,64.663a39.851,39.851,0,1,0-31.21,15.124m0-70.922A31.028,31.028,0,1,1,8.865,39.893,31.065,31.065,0,0,1,39.893,8.865"
            fill={color}
          />
        </g>
      </g>
    </svg>
  );
}

export default IconSearchHouse;
