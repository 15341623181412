function IconChatHerz({ cssClasses, color }) {
  return (
    <svg
      data-name="Gruppe 9129"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 84.18 82.428"
      className={cssClasses}
    >
      <path
        data-name="Pfad 7428"
        d="M57.874,29.814a32.384,32.384,0,0,1,4.907.4,24.408,24.408,0,0,0,.354-4.142C63.135,11.7,48.972,0,31.568,0S0,11.7,0,26.075A24.394,24.394,0,0,0,8.278,44.237L1.985,55.25a1.754,1.754,0,0,0,1.526,2.624,1.834,1.834,0,0,0,.649-.123l16.973-6.794a34.134,34.134,0,0,0,4.728.986c.077.007.224-.014.263.014.263,0,1.119-.007,2.378-.077,2.382-12.325,14.847-22.066,29.372-22.066"
        transform="translate(0 0)"
        fill={color}
      />
      <path
        data-name="Pfad 7429"
        d="M55.029,46.774A19.13,19.13,0,0,0,61.613,32.3c0-12.357-12.048-22.8-26.306-22.8S9,19.942,9,32.3C9,44.07,19.095,54.13,31.567,55.354l.021.007a26.59,26.59,0,0,0,12.529-1.884c1.1.417,12.262,4.6,13.374,5.016a1.75,1.75,0,0,0,2.182-2.42c-.968-1.943-3.75-7.5-4.644-9.3m-19.46.049a1.114,1.114,0,0,1-1.322,0c-.523-.379-12.816-9.4-12.816-16.548,0-4.974,3.683-7.945,7.24-7.945a7.134,7.134,0,0,1,6.24,4.006,7.13,7.13,0,0,1,6.236-4.006c3.557,0,7.24,2.971,7.24,7.945,0,7.148-12.294,16.17-12.816,16.548"
        transform="translate(22.568 23.821)"
        fill={color}
      />
    </svg>
  );
}
export default IconChatHerz;
