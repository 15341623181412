function IconPeopleConnect({ cssClasses, color }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="42"
      height="42"
      viewBox="0 0 42 42"
      className={cssClasses}
    >
      <defs>
        <clipPath id="clip-path-peopleconnect">
          <rect
            data-name="Rechteck 3025"
            width="42"
            height="42"
            fill={color}
          />
        </clipPath>
      </defs>
      <g data-name="Gruppe 6402" clipPath="url(#clip-path-peopleconnect)">
        <path
          data-name="Pfad 6350"
          d="M29.931,11.914l-3.806-1.088V9.494A6.754,6.754,0,0,0,28.75,4.084c-1.621.411-4.07.326-5.366-.732a7.9,7.9,0,0,1-6.856.131c-.016.2-.028.4-.028.6a6.754,6.754,0,0,0,2.625,5.409v1.332l-3.81,1.088A3.336,3.336,0,0,0,13,14.985v1.75H32.25v-1.75a3.335,3.335,0,0,0-2.319-3.071"
          transform="translate(9.75 2.515)"
          fill={color}
        />
        <path
          data-name="Pfad 6351"
          d="M21.206,4.055a.875.875,0,0,1,.745-.249.886.886,0,0,1,.644.453c.53.985,3.176.908,4.253.584A6.223,6.223,0,0,0,20.95,0a6.162,6.162,0,0,0-5.717,4.249c1.733.985,4.809.968,5.973-.194"
          transform="translate(11.425)"
          fill={color}
        />
        <path
          data-name="Pfad 6352"
          d="M8.733,17.251c1.733.984,4.809.968,5.973-.194a.873.873,0,0,1,1.389.2c.53.982,3.178.908,4.253.584A6.223,6.223,0,0,0,14.45,13a6.164,6.164,0,0,0-5.717,4.251"
          transform="translate(6.55 9.75)"
          fill={color}
        />
        <path
          data-name="Pfad 6353"
          d="M23.431,24.914l-3.806-1.089V22.494a6.754,6.754,0,0,0,2.625-5.409c-1.62.411-4.07.326-5.365-.731a7.9,7.9,0,0,1-6.857.131c-.016.2-.028.4-.028.6a6.754,6.754,0,0,0,2.625,5.409v1.332l-3.81,1.089A3.336,3.336,0,0,0,6.5,27.985v1.75H25.75v-1.75a3.335,3.335,0,0,0-2.319-3.071"
          transform="translate(4.875 12.265)"
          fill={color}
        />
        <path
          data-name="Pfad 6354"
          d="M19.25,14.984a3.335,3.335,0,0,0-2.319-3.071l-3.806-1.089V9.493A6.754,6.754,0,0,0,15.75,4.084c-1.62.411-4.071.326-5.365-.73a7.906,7.906,0,0,1-6.857.129c-.016.2-.028.4-.028.6A6.754,6.754,0,0,0,6.125,9.493v1.332l-3.81,1.089A3.338,3.338,0,0,0,0,14.984v1.75H19.25Z"
          transform="translate(0 2.516)"
          fill={color}
        />
        <path
          data-name="Pfad 6355"
          d="M8.207,4.055a.873.873,0,0,1,.744-.249.886.886,0,0,1,.644.453c.53.985,3.176.908,4.252.584A6.222,6.222,0,0,0,7.95,0,6.161,6.161,0,0,0,2.234,4.249c1.733.985,4.807.968,5.973-.194"
          transform="translate(1.675)"
          fill={color}
        />
        <path
          data-name="Pfad 6356"
          d="M9.368,17.869a.876.876,0,0,0,0-1.237L4.993,12.257a.875.875,0,1,0-1.237,1.237l4.375,4.375a.877.877,0,0,0,1.237,0"
          transform="translate(2.625 9.001)"
          fill={color}
        />
        <path
          data-name="Pfad 6357"
          d="M21.631,12.256l-4.375,4.375a.875.875,0,0,0,1.237,1.239l4.375-4.375a.875.875,0,0,0-1.237-1.239"
          transform="translate(12.75 9)"
          fill={color}
        />
      </g>
    </svg>
  );
}

export default IconPeopleConnect;
