import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CONTACTFORM_ANLIEGEN_PROFIL } from '../../constants';
import { setPageDialogOpenContactForm, setReasonContactForm } from '../../reducers/contactInformation';
import FormInput from '../FormFields/FormInput';
import FormRadio from '../FormFields/FormRadio';
import { anliegenOptions, genderOptions } from '../FormFields/util/formOptions';

function ProfileForm() {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const handleOpenContactForm = () => {
    dispatch(setReasonContactForm(anliegenOptions[CONTACTFORM_ANLIEGEN_PROFIL]));
    dispatch(setPageDialogOpenContactForm(true));
  };

  return (
    <div className="profileForm">
      <h2 className="form__title">Daten</h2>

      <div className="elements3cols">
        <div className="elements3cols__col">
          <span>Anrede</span>
          <p>{user.userObject.gender === 'f' ? 'Frau' : 'Herr'}</p>
        </div>
        <div className="elements3cols__col">
          <span>Vorname</span>
          <p>{user.userObject.firstName}</p>
        </div>
        <div className="elements3cols__col">
          <span>Nachname</span>
          <p>{user.userObject.lastName}</p>
        </div>
      </div>

      <div className="elements3cols">
        <div className="elements3cols__col">
          <span>Titel vorgestellt</span>
          <p>{user.userObject.titleBefore !== '' ? user.userObject.titleBefore : '-'}</p>
        </div>
        <div className="elements3cols__col">
          <span>Titel nachgestellt</span>
          <p>{user.userObject.titleAfter !== '' ? user.userObject.titleAfter : '-'}</p>
        </div>
        <div className="elements3cols__col">
          <span>Mobiltelefonnummer</span>
          <p>{user.userObject.phoneNumber}</p>
        </div>
      </div>

      <div className="elements3cols">
        <div className="elements3cols__col">
          <span>E-Mail-Adresse</span>
          <p>{user.userObject.userName}</p>
          <span className="form--info">
            <span
              className="textlink"
              onClick={() => {
                handleOpenContactForm();
              }}
            >
              Stellen Sie hier eine Anfrage
            </span>
            , um Ihre Daten zu ändern.
          </span>
        </div>
      </div>

      {/*<div className="button-panel">
          <button className="button button--blue" onClick={saveProfileData}>
            Änderungen speichern
          </button>
  </div>*/}
    </div>
  );
}

export default ProfileForm;
