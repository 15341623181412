function IconMessageSent({ color, cssClasses }) {
  return (
    <svg
      className={cssClasses}
      data-name="Gruppe 10755"
      xmlns="http://www.w3.org/2000/svg"
      width="108"
      height="108"
      viewBox="0 0 108 108"
    >
      <g data-name="Gruppe 10756">
        <path
          data-name="Pfad 7838"
          d="M78.75,45A33.534,33.534,0,0,1,99,51.813V2.25A2.251,2.251,0,0,0,96.75,0H2.25A2.251,2.251,0,0,0,0,2.25v63A2.251,2.251,0,0,0,2.25,67.5H18V83.25a2.25,2.25,0,0,0,1.391,2.079,2.3,2.3,0,0,0,.859.171,2.267,2.267,0,0,0,1.593-.657L39.181,67.5h7.79A33.773,33.773,0,0,1,78.75,45"
          fill={color}
        />
        <path
          data-name="Pfad 7839"
          d="M40.25,11A29.25,29.25,0,1,0,69.5,40.25,29.281,29.281,0,0,0,40.25,11M56.639,34.4,37.87,54.969,23.929,41.037a2.25,2.25,0,0,1,3.181-3.181L37.721,48.463,53.314,31.372A2.25,2.25,0,1,1,56.639,34.4"
          transform="translate(38.5 38.5)"
          fill={color}
        />
      </g>
    </svg>
  );
}

export default IconMessageSent;
