function IconGeldfluss({ color, cssClasses }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="311.086"
      height="69.256"
      viewBox="0 0 311.086 69.256"
      className={cssClasses}
    >
      <g data-name="Gruppe 13159" transform="translate(-33.916 -1371.447)">
        <g data-name="Gruppe 10839" transform="translate(33.916 1371.447)">
          <g data-name="Gruppe 9080" transform="translate(0)">
            <path
              data-name="Pfad 7391"
              d="M7.449,39.806l15-5.355V27.058A22.1,22.1,0,0,1,14.83,9.929c0-.231.026-.455.032-.68a9.57,9.57,0,0,0,3.174.68c5.307,0,8.978-.85,12.063-4.983A17.615,17.615,0,0,0,42.085,9.929c2.081,0,5.521-1.331,7.891-2.4.074.786.125,1.584.125,2.4,0,7.5-3.376,14.06-8.417,17.732v6.791l15,5.355a11.246,11.246,0,0,1,7.445,10.568v7.651H0V50.375A11.244,11.244,0,0,1,7.449,39.806"
              transform="translate(0 10.913)"
              fill={color}
            />
            <path
              data-name="Pfad 7392"
              d="M20.856,12.313l-1.36-2.6-1.456,2.546c-2.629,4.6-5.393,5.38-10.584,5.38a8.707,8.707,0,0,1-2.87-.792C6.171,7.259,13.321,0,21.886,0c8.061,0,14.862,6.432,16.956,15.176-2.466,1.167-5.9,2.459-7.336,2.459-4.191,0-9.17-2.485-10.649-5.323"
              transform="translate(10.579 0)"
              fill={color}
            />
          </g>
        </g>
        <g data-name="Gruppe 10840" transform="translate(108.675 1404.447)">
          <g data-name="Gruppe 9090" transform="translate(0 0)">
            <path
              data-name="Pfad 7425"
              d="M.9,6.083H17.679L30.8,19.207a.942.942,0,0,0,.329.206c.009,0,.014.009.023.013l5.416,1.805a.97.97,0,0,0,.285.045.9.9,0,0,0,.856-1.188l-1.805-5.416-.013-.02a.912.912,0,0,0-.206-.334L27.452,6.083H42.423a.9.9,0,0,1,.9.9V30.453a.9.9,0,0,1-.9.9H.9a.905.905,0,0,1-.9-.9V6.986a.9.9,0,0,1,.9-.9M6.318,24.135H27.981a.9.9,0,1,0,0-1.805H6.318a.9.9,0,0,0,0,1.805m0-5.416H20.76a.9.9,0,1,0,0-1.805H6.318a.9.9,0,0,0,0,1.805"
              transform="translate(0 4.899)"
              fill={color}
            />
            <rect
              data-name="Rechteck 3453"
              width="17.341"
              height="3.301"
              transform="translate(31.441 22.192) rotate(-135)"
              fill={color}
            />
            <path
              data-name="Pfad 7426"
              d="M6.69,14.243,4.374,13.47l1.543-1.545Z"
              transform="translate(28.739 9.603)"
              fill={color}
            />
            <path
              data-name="Pfad 7427"
              d="M22.248,7.219a.9.9,0,0,1-.637-.264L16.833,2.179l-.98.978L14.576,4.434l1.883,1.883L14.125,8.653,9.083,3.609l2.334-2.332L13.3,3.158l1.276-1.276L16.2.262a.907.907,0,0,1,1.276,0l5.416,5.416a.9.9,0,0,1-.639,1.542"
              transform="translate(3.778 0)"
              fill={color}
            />
          </g>
        </g>
        <g data-name="Gruppe 13114" transform="translate(280.873 1371.447)">
          <g data-name="Gruppe 9080" transform="translate(0.001)">
            <path
              data-name="Pfad 7391"
              d="M7.449,39.806l15-5.355V27.058A22.1,22.1,0,0,1,14.83,9.929c0-.231.026-.455.032-.68a9.57,9.57,0,0,0,3.174.68c5.307,0,8.978-.85,12.063-4.983A17.614,17.614,0,0,0,42.084,9.929c2.081,0,5.521-1.331,7.891-2.4.074.786.125,1.584.125,2.4,0,7.5-3.376,14.06-8.417,17.732v6.791l15,5.355a11.246,11.246,0,0,1,7.445,10.568v7.651H0V50.375A11.244,11.244,0,0,1,7.449,39.806"
              transform="translate(0 10.913)"
              fill={color}
            />
            <path
              data-name="Pfad 7392"
              d="M20.856,12.313l-1.36-2.6-1.456,2.546c-2.629,4.6-5.393,5.38-10.584,5.38a8.707,8.707,0,0,1-2.87-.792C6.171,7.259,13.321,0,21.886,0c8.061,0,14.862,6.432,16.956,15.176-2.466,1.167-5.9,2.459-7.336,2.459-4.191,0-9.17-2.485-10.649-5.323"
              transform="translate(10.579 0)"
              fill={color}
            />
          </g>
        </g>
        <g data-name="Gruppe 13115" transform="translate(222 1396.447)">
          <g data-name="Gruppe 9084" transform="translate(0.001 0)">
            <path
              data-name="Pfad 7393"
              d="M1.076,29.849H6.451V11.575A1.077,1.077,0,0,1,7.526,10.5H44.073a1.074,1.074,0,0,1,1.075,1.075V29.849h5.375a1.075,1.075,0,1,1,0,2.15H1.076a1.075,1.075,0,0,1,0-2.15M38.7,13.725a1.074,1.074,0,0,0-1.075-1.075h-6.45A1.077,1.077,0,0,0,30.1,13.725V24.474a1.077,1.077,0,0,0,1.075,1.075h6.45A1.074,1.074,0,0,0,38.7,24.474ZM27.949,29.849a1.077,1.077,0,0,0,1.075,1.075H39.774a1.074,1.074,0,0,0,1.075-1.075V28.774A1.074,1.074,0,0,0,39.774,27.7H29.024a1.077,1.077,0,0,0-1.075,1.075ZM12.9,22.324A1.077,1.077,0,0,0,13.975,23.4H24.724A1.074,1.074,0,0,0,25.8,22.324v-6.45A1.074,1.074,0,0,0,24.724,14.8H13.975A1.077,1.077,0,0,0,12.9,15.875Z"
              transform="translate(-0.001 12.073)"
              fill={color}
            />
            <path
              data-name="Pfad 7394"
              d="M50.522,20.423H1.075a1.075,1.075,0,0,1-.639-1.939L6.45,14.038V5.374A1.077,1.077,0,0,1,7.525,4.3h6.45a1.074,1.074,0,0,1,1.075,1.075V7.681L25.16.21a1.084,1.084,0,0,1,1.281,0L51.165,18.484a1.076,1.076,0,0,1-.643,1.939"
              transform="translate(0 0)"
              fill={color}
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
export default IconGeldfluss;
