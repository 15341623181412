import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import PageDialog from '../components/Dialogs/PageDialog';
import HeaderSmallWithIcon from '../components/Header/HeaderSmallWithIcon';
import Linkitem from '../components/Linkitems/Linkitem';
import MainContent from '../components/MainContent';
import MetaTags from '../components/MetaTags';

import '../styles/immodrive.scss';
import { downloadZip } from '../util/generalUtils';

// ICONS
import { getRealtyFile } from '../api/JustImmo';
import ChecklistTableSingle from '../components/ChecklistTable/ChecklistTableSingle';
import IconBearbeitung from '../components/Icons/IconBearbeitung';
import IconHerunterladen from '../components/Icons/IconHerunterladen';
import IconImmothek from '../components/Icons/IconImmothek';

import { useHandleUserRealtyActive } from '../hooks/user/useHandleUserRealtyActive';

function Immodrive() { 
  const user = useSelector((state) => state.user);

  const { files } = useHandleUserRealtyActive();

  const [downloadError, setDownloadError] = useState('');
  const [checklistTableOptions, setChecklistTableOptions] = useState([]);

  useEffect(() => {
    if (files.length > 0) {
      let childOptionsHelper = [];
      childOptionsHelper = files.map((file) => {
        return {
          label: file.headline,
          checked: true,
          item: { ...file, immo: user.userRealtyActive },
        };
      });

      setChecklistTableOptions(childOptionsHelper);

      // TODO: add categories for parentLabel (NTX-Immodrive)
      // let childOptions = [];
      // childOptions.push({
      //   parentLabel: 'Meine Doks',
      //   childList: childOptionsHelper,
      // });

      // setChecklistTableOptions(childOptions);
    }
  }, []);

  const [showAllDoksPageDialog, setShowAllDoksPageDialog] = useState(false);
  const handleSetShowAllDoksPageDialog = () => {
    setShowAllDoksPageDialog(!showAllDoksPageDialog);
    setDownloadError('');

    // set items.checked to true on closing/opening
    if (files.length > 0) {
      let childOptionsHelper = files.map((file) => {
        return {
          label: file.headline,
          checked: true,
          item: { ...file, immo: user.userRealtyActive },
        };
      });

      setChecklistTableOptions(childOptionsHelper);
    }
  };

  const handleDownloadZip = async (all = false) => {
    let docs = [];
    setDownloadError('');

    if (all) {
      docs = files.map((file) => {
        return { ...file, immo: user.userRealtyActive };
      });
    } else {
      // TODO: get items from childlist (NTX-Immodrive)
      // docs = checklistTableOptions[0].childList
      //   .filter((item) => item.checked)
      //   .map((item) => {
      //     return item.item;
      //   });

      const checkboxes = document.querySelectorAll('#immodrive-files > .MuiFormControlLabel-root');
      let checkedItemNames = [];

      checkboxes.forEach(function (label) {
        let checkitem = label.querySelector('.MuiCheckbox-root');
        let labelName = label.querySelector('.MuiFormControlLabel-label').innerHTML;
        let isChecked = checkitem.classList.contains('Mui-checked');
        if (isChecked && labelName) {
          checkedItemNames.push(labelName);
        }
      });

      // add file to docs-array
      if (checkedItemNames) {
        docs = files
          .filter((f) => checkedItemNames.includes(f.headline))
          .map((f) => {
            return {
              ...f,
              immo: user.userRealtyActive,
            };
          });
      }
    }
    console.log('docs', docs);

    if (docs.length === 0) {
      setDownloadError('Bitte wählen Sie Dokumente für den Download aus.');
    } else {
      await downloadZip(docs, 'immo-drive');
      handleSetShowAllDoksPageDialog();
    }
  };

  const downloadDocJustImmo = async (file) => {
    try {
      const realtyFile = await getRealtyFile(user.userRealtyActive.id, user.userRealtyActive.tenant_id, file.storage_key);
      const url = window.URL.createObjectURL(realtyFile);
      const a = document.createElement('a');
      a.href = url;
      a.download = file.original_filename;
      a.click();
    } catch (e) {
      //handle realty file error
      console.error('e', e);
    }
  };

  return (
    <>
      <MetaTags title={'Immodrive | Die Online-Plattform für Ihre Immobilie'} description={'Hier finden Sie alle Dokumente, die Ihre Objekte betreffen'} />
      <HeaderSmallWithIcon icon={<IconImmothek />} iconActive={true} headlineDesktop="immo-drive" cssClasses="header--turquoisebg" />

      <MainContent cssClasses="w-desktop--center">
        <h1 className="headline--mobile">immo-drive</h1>

        {files.length > 0 ? (
          <>
            <div className="desktop--firstrow">
              <div className="desktop--box">
                <Accordion className="accordion desktop--box_item mb-20" expanded={true}>
                  <AccordionSummary>
                    <h2 className="d-flex flex-row">Meine Dokumente</h2>
                  </AccordionSummary>
                  <AccordionDetails className="desktop--box_item">
                    <div className="linkitem-collection">
                      {files.map((file) => {
                        return (
                          <Linkitem
                            handleLink={async () => {
                              await downloadDocJustImmo(file);
                            }}
                            icon={<IconBearbeitung colorDetails="#1E3163" />}
                            headline={file.headline}
                            text={file.text}
                            showArrow={false}
                            key={file.url}
                          />
                        );
                      })}
                    </div>
                  </AccordionDetails>
                </Accordion>
              </div>
            </div>

            <Linkitem
              handleLink={handleSetShowAllDoksPageDialog}
              icon={<IconHerunterladen />}
              cssClasses="linkitem--blue linkitem--einzeilig-desktop"
              headline="Dokumente herunterladen"
              text="Sichern Sie ausgewählte Dokumente als ZIP."
            />

            <PageDialog open={showAllDoksPageDialog} handleClose={handleSetShowAllDoksPageDialog} headline="Dokumente herunterladen">
              <div className="d-flex flex-col w-100 justify-content-center align-items-center mt-100rem">
                <div className="image--round position-relative" style={{ backgroundColor: '#3679ec' }}>
                  <IconHerunterladen cssClasses="position-center" color="#fff" />
                </div>

                <span className="textlink mt-100rem" onClick={() => handleDownloadZip(true)}>
                  Alle herunterladen (.zip)
                </span>
              </div>
              <div className="p-200rem">
                <h2 className="font-110">Dokumente auswählen</h2>
                {/* <ChecklistTableGroup
                  label={'Alle Dokumente'}
                  childOptions={checklistTableOptions}
                /> */}

                <ChecklistTableSingle label={'Alle Dokumente'} childList={checklistTableOptions} id={'immodrive-files'} />

                <span className="textlink" onClick={() => handleDownloadZip(false)}>
                  Auswahl herunterladen (.zip)
                </span>

                {downloadError && <p className="form__fielderror">{downloadError}</p>}
              </div>
            </PageDialog>
          </>
        ) : (
          <p className="page__emptymessage">Willkommen in immo-drive! Bald finden Sie hier alle Dokumente zu Ihrer Immobilie. Wenn Sie dazu Fragen haben, wenden Sie sich an Ihre Makler:in.</p>
        )}
      </MainContent>
    </>
  );
}

export default Immodrive;
