function IconHerunterladen({ color, cssClasses }) {
  return (
    <svg className={cssClasses} xmlns="http://www.w3.org/2000/svg" width="35.474" height="35.474" viewBox="0 0 35.474 35.474" style={{ transform: 'translate(-50%, -50%) scale(0.7)' }}>
      <g id="Gruppe_9961" data-name="Gruppe 9961" transform="translate(-92 -10)">
        <g id="Gruppe_8998" data-name="Gruppe 8998" transform="translate(92 10)">
          <path
            id="Pfad_7356"
            data-name="Pfad 7356"
            d="M10.563,27.866a.737.737,0,0,0,1.045,0L21.955,17.518a.741.741,0,0,0-.522-1.262l-4.434,0V.739A.74.74,0,0,0,16.26,0H5.913a.738.738,0,0,0-.739.739V16.256H.74a.739.739,0,0,0-.523,1.262Z"
            transform="translate(6.65 0)"
            fill={color}
          />
          <path
            id="Pfad_7357"
            data-name="Pfad 7357"
            d="M34,17.5a1.477,1.477,0,0,0-1.478,1.478v5.173H2.956V18.978a1.478,1.478,0,0,0-2.956,0v6.651a1.478,1.478,0,0,0,1.478,1.478H34a1.479,1.479,0,0,0,1.478-1.478V18.978A1.478,1.478,0,0,0,34,17.5"
            transform="translate(0 8.366)"
            fill={color}
          />
        </g>
      </g>
    </svg>
  );
}

export default IconHerunterladen;
