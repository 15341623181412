function IconContact2({ color }) {
   return (
      <svg xmlns="http://www.w3.org/2000/svg" width="67.571" height="67.516" viewBox="0 0 67.571 67.516">
      <g data-name="Gruppe 14928" transform="translate(0 0)">
        <path data-name="Pfad 8744" d="M58.161,49.993a21.1,21.1,0,0,0,3.1-11.682A22.946,22.946,0,0,0,38.316,15.369a24.135,24.135,0,0,0-9.993,2.252A22.922,22.922,0,0,0,47.746,59.142l10.7,2.111h.422a2.27,2.27,0,0,0,1.83-.844,2.459,2.459,0,0,0,.422-2.393Zm-5.067.563,1.97,5.208L47.746,54.5a2.926,2.926,0,0,0-1.548.141A18.067,18.067,0,1,1,30.434,22.125a17.062,17.062,0,0,1,7.882-1.83,18.206,18.206,0,0,1,15.2,28.15,2.288,2.288,0,0,0-.422,2.111" transform="translate(6.301 6.262)" fill={color}/>
        <path data-name="Pfad 8745" d="M12.245,48.8,6.193,49.922,9.008,42.6a2.588,2.588,0,0,0-.281-2.252,22.67,22.67,0,0,1-3.8-12.667,22.953,22.953,0,0,1,25.616-22.8,22.362,22.362,0,0,1,15.2,8.586,2.407,2.407,0,1,0,3.8-2.956A27.856,27.856,0,0,0,30.965.238,27.514,27.514,0,0,0,0,27.684,28.59,28.59,0,0,0,3.941,42.04L.141,52.174a2.459,2.459,0,0,0,.422,2.393,2.27,2.27,0,0,0,1.83.844h.422l10.275-1.83a2.49,2.49,0,0,0,1.97-2.815,2.613,2.613,0,0,0-2.815-1.97" transform="translate(0 0)" fill={color}/>
      </g>
    </svg>
    
   );
 }
 
 export default IconContact2;