import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import Aktuelles from '../components/Aktuelles/Aktuelles';
import GeldflussCard from '../components/Cards/GeldflussCard';
import ProgressDokCard from '../components/Cards/ProgressDokCard';
import UebergabeCard from '../components/Cards/UebergabeCard';
import InfoDialogKaufvertrag from '../components/Dialogs/InfoDialogKaufvertrag';
import Header from '../components/Header/Header';
import RecommendationLinkitem from '../components/Linkitems/RecommondationLinkitem';
import MainContent from '../components/MainContent';
import MetaTags from '../components/MetaTags';
import NewsTeaserCards from '../components/News/NewsTeaserCards';
import ImmoDriveCard from '../components/Cards/ImmoDriveCard';
import ImmoDriveEmptyCard from '../components/Cards/ImmoDriveEmptyCard';

// ICONS
import IconKaufvertrag from '../components/Icons/IconKaufvertrag';

import ImmoCardAbgeber from '../components/Cards/ImmoCardAbgeber';
import { MENU_KAUF, MENU_VERKAUF, UEBERGABE_CARDTYPE_SAMPLE } from '../constants';

import CardGridPlaceholderElement from '../components/Cards/CardGridPlaceholderElement';
import IconLoadingSpinner from '../components/Icons/IconLoadingSpinner';
import { useHandleScreenResize } from '../hooks/app/useHandleScreenResize';
import { useHandleUserRealtyActive } from '../hooks/user/useHandleUserRealtyActive';
import { useHandleMessages } from '../hooks/messages/useHandleMessages';
import MarktinfoLightCard from '../components/Cards/MarktinfoLightCard';
function DashboardPhase3() {
  const user = useSelector((state) => state.user);
  const app = useSelector((state) => state.app);

  const [openInfoDialogKV, setOpenInfoDialogKV] = useState(false);
  const handleOpenInfoDialogKV = () => {
    let newVal = !openInfoDialogKV;
    setOpenInfoDialogKV(newVal);
  };

  const { files, getKaeuferImmoPhase3 } = useHandleUserRealtyActive();
  const { threads, threadsLoading, loadMessages } = useHandleMessages();

  const [immoCardAbgeberRealty, setImmoCardAbgeberRealty] = useState(null);

  const { isMobile } = useHandleScreenResize();

  useEffect(() => {
    if (app.menuType === MENU_VERKAUF && user.userRealtyActive?.id > 0) {
      setImmoCardAbgeberRealty(user.userRealtyActive);
    } else if (app.menuType === MENU_KAUF) {
      let kaeuferPhase3Immo = getKaeuferImmoPhase3();
      if (kaeuferPhase3Immo && kaeuferPhase3Immo.id) {
        setImmoCardAbgeberRealty(kaeuferPhase3Immo);
      }
    }
  }, [app.menuId, app.menuType]);

  useEffect(() => {
    loadMessages();
  }, []);

  return (
    <>
      <MetaTags title={'Kaufvertrag | Die Online-Plattform für Ihre Immobilie'} imageName={'mysreal_hero_dashboard-kauf.jpg'} />

      <Header cssClasses="header--default header--darkbluebg header--fit-content">
        <div className="header__content-wrapper" id="header-phase3">
          <h1>Kaufvertrag</h1>
          <IconKaufvertrag color="#fff" />

          <p className="lh-140">Informieren Sie sich, was Sie bei der Abwicklung des Kaufvertrags erwartet.</p>
          <span className="textlink mt-100rem mb-100rem d-flex" onClick={handleOpenInfoDialogKV}>
            Kaufvertrags-Infos anzeigen
          </span>
        </div>
      </Header>

      <MainContent>
        {app.menuType === MENU_VERKAUF ? <Aktuelles /> : null}

        <div className={!isMobile ? 'gray-background--no-margin' : ''}>
          <div className="w-desktop--center">
            <h2 className="mt-0 pb-100rem">Kaufabwicklung</h2>

            <div className="elements2cols pb-200rem">
              <GeldflussCard cssClasses={'elements2cols__col'} />
              <UebergabeCard cardType={UEBERGABE_CARDTYPE_SAMPLE} cssClasses={'elements2cols__col'} />
            </div>
            <div className="w-desktop--center">
              <h2>immo-drive</h2>
              {files.length > 0 ? <ImmoDriveCard files={files} /> : <ImmoDriveEmptyCard />}
            </div>

            {immoCardAbgeberRealty && (
              <div className="pt-200rem pb-100rem">
                <h2 className="mt-0">Meine Immobilie</h2>

                <ImmoCardAbgeber retailObject={immoCardAbgeberRealty} key={immoCardAbgeberRealty.id} />
              </div>
            )}

            <RecommendationLinkitem cssClasses="mb-0" />
          </div>
        </div>

        <div className={!isMobile ? 'w-desktop--center mt-400rem' : 'w-desktop--center'}>
          <MarktinfoLightCard cssClasses={'bg-white'} />
        </div>

        <NewsTeaserCards cssClasses="w-desktop--slider" />

        <InfoDialogKaufvertrag open={openInfoDialogKV} handleClose={handleOpenInfoDialogKV} />
      </MainContent>
    </>
  );
}

export default DashboardPhase3;
