import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateEmailPreferences } from '../../api/User';
import { setMailTypes } from '../../reducers/user';
import ButtonForwardAction from '../Buttons/ButtonForwardAction';
import FormCheckbox from '../FormFields/FormCheckbox';
import FormRadio from '../FormFields/FormRadio';
import { emailSettingsFrequencyOptions, emailSettingsTimeOfDayOptions } from '../FormFields/util/formOptions';
import IconInformation from '../Icons/IconInformation';
import LoadingSpinner from '../LoadingSpinner';

import { setFormIsLoading } from '../../reducers/app';

const formType = 'profileEmailSettingsForm';

function ProfileEmailSettingsForm() {
  let app = useSelector((state) => state.app);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [formError, setFormError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [mailTypesEmpty, setMailTypesEmpty] = useState(false);
  const [settingsDisabled, setSettingsDisabled] = useState(false);

  const forwardAction = async () => {
    if (app.formIsLoading || (mailTypesEmpty && !settingsDisabled)) {
      return;
    }

    dispatch(setFormIsLoading(true));

    try {
      const emailPreferences = {
        documents: user.emailSettings.wantsToReceiveMails ? user.emailSettings.mailTypes.find((mailType) => mailType.id === 'documents').value : false,
        activities: user.emailSettings.wantsToReceiveMails ? user.emailSettings.mailTypes.find((mailType) => mailType.id === 'activities').value : false,
        viewings: user.emailSettings.wantsToReceiveMails ? user.emailSettings.mailTypes.find((mailType) => mailType.id === 'viewings').value : false,
        hour: user.emailSettings.hour,
        intervalDays: user.emailSettings.intervalDays,
      };

      await updateEmailPreferences(emailPreferences);
      setFormError('');

      setSuccessMessage('Ihre Einstellungen wurden erfolgreich gespeichert!');

      setTimeout(() => {
        setSuccessMessage('');
      }, 2000);
      dispatch(setFormIsLoading(false));
    } catch (e) {
      setFormError('Fehler beim Speichern der Einstellungen. Bitte versuchen Sie es später erneut.');
      dispatch(setFormIsLoading(false));
    }
  };

  useEffect(() => {
    if (user.emailSettings.mailTypes.filter((mailType) => mailType.value).length === 0) {
      setMailTypesEmpty(true);
    } else {
      setMailTypesEmpty(false);
    }
  }, [user.emailSettings.mailTypes]);

  useEffect(() => {
    if (user.emailSettings.wantsToReceiveMails === false) {
      setSettingsDisabled(true);
    } else {
      setSettingsDisabled(false);
    }
  }, [user.emailSettings.wantsToReceiveMails]);

  return (
    <div className="profileEmailSettingsForm">
      <h2 className="form__title pt-200rem">E-Mail-Benachrichtigungen</h2>
      <p>Verändern Sie die Einstellungen für Benachrichtigungen außerhalb von my-sreal.</p>

      <p>
        <IconInformation color="#3679ec" cssClasses={'mr-50rem'} />
        Nachrichten von Ihrer Immobilienbetreuer:in lösen automatisch eine E-Mail-Benachrichtigung aus.
      </p>

      <div className="mt-200rem">
        <FormCheckbox formType={formType} id={'emailSettingsWantsToReceiveMails'} label={'E-Mail-Benachrichtigungen zu Verkaufsaktivitäten erhalten'} value={user.emailSettings.wantsToReceiveMails} />
      </div>

      <div className={`profileEmailSettingsForm__fields ${settingsDisabled ? 'form--disabled' : ''}`}>
        <div className="profileEmailSettingsForm__fields--disabled"></div>

        <div className="elements2cols">
          <div className="elements2cols__col">
            <div>
              <p className="font-weight-600">Worüber wollen Sie Benachrichtigungen erhalten? (min. 1 Kategorie wählen)</p>
              {mailTypesEmpty && !settingsDisabled && <p className="form__fielderror">Bitte wählen Sie mindestens eine Kategorie.</p>}
            </div>
          </div>

          <div className={`elements2cols__col ${mailTypesEmpty && !settingsDisabled ? 'form--error' : ''}`}>
            <FormCheckbox
              formType={formType}
              id={'emailSettingsMailTypes'}
              label={'Neue Dokumente'}
              value={user.emailSettings.mailTypes.find((mailType) => mailType.id === 'documents').value}
              callback={() => {
                dispatch(setMailTypes('documents'));
              }}
            />

            <FormCheckbox
              formType={formType}
              id={'emailSettingsMailTypes'}
              label={'Neue Immobilienaktivitäten'}
              value={user.emailSettings.mailTypes.find((mailType) => mailType.id === 'activities').value}
              callback={() => {
                dispatch(setMailTypes('activities'));
              }}
            />

            <FormCheckbox
              formType={formType}
              id={'emailSettingsMailTypes'}
              label={'Neue Besichtigungen'}
              value={user.emailSettings.mailTypes.find((mailType) => mailType.id === 'viewings').value}
              callback={() => {
                dispatch(setMailTypes('viewings'));
              }}
            />
          </div>
        </div>

        <div className="elements2cols">
          <div className="elements2cols__col">
            <p className="font-weight-600">Wie oft möchten Sie E-Mail-Benachrichtigungen zu Verkaufsaktivitäten erhalten?</p>
          </div>

          <div className="elements2cols__col">
            <FormRadio options={emailSettingsFrequencyOptions} id={'emailSettingsFrequency'} formType={formType} selectedValue={user.emailSettings.intervalDays} />
          </div>
        </div>

        <div className="elements2cols">
          <div className="elements2cols__col">
            <p className="font-weight-600">Wann möchten Sie E-Mail-Benachrichtigungen erhalten?</p>
          </div>

          <div className="elements2cols__col">
            <FormRadio options={emailSettingsTimeOfDayOptions} id={'emailSettingsTimeOfDay'} formType={formType} selectedValue={user.emailSettings.hour} />
          </div>
        </div>
      </div>

      <div className="h-100rem">
        {app.formIsLoading && <LoadingSpinner />}
        {formError && <p className="form__fielderror">{formError}</p>}
        {successMessage && <p className="form__successmessage">{successMessage}</p>}
      </div>

      <div className={`button-panel ${mailTypesEmpty && !settingsDisabled ? 'buttons--disabled' : ''}`}>
        <ButtonForwardAction formType={formType} buttonText={'Änderungen speichern'} buttonStyle="blue" forwardAction={forwardAction} />
      </div>
    </div>
  );
}

export default ProfileEmailSettingsForm;
