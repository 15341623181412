function IconImmothek({ color, cssClasses = '' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="97.92"
      height="87.379"
      viewBox="0 0 97.92 87.379"
      className={cssClasses}
    >
      <g data-name="Gruppe 10310" transform="translate(-140.039 -200.811)">
        <path
          data-name="Differenzmenge 38"
          d="M15.751,83.379h-7c-5.48,0-8.751-2.6-8.751-6.951V1.738a1.75,1.75,0,0,1,3.5,0V13.895c0,3.758,0,6.241.731,7.823a2.88,2.88,0,0,0,.171.294l.028.044a5.105,5.105,0,0,0,1.254,1.263c1.209.711,3.112,1,6.568,1s5.356-.29,6.565-1a5.166,5.166,0,0,0,1.254-1.263l.056-.091a2.613,2.613,0,0,0,.139-.241C21,20.136,21,17.653,21,13.895V1.738a1.75,1.75,0,0,1,3.5,0v74.69C24.5,80.781,21.23,83.379,15.751,83.379Zm-3.5-24.319A5.211,5.211,0,1,0,17.5,64.272,5.238,5.238,0,0,0,12.251,59.06Z"
          transform="translate(142.039 202.811)"
          fill="none"
          stroke={color}
          strokeMiterlimit="10"
          strokeWidth="4"
        />
        <path
          data-name="Differenzmenge 39"
          d="M15.751,83.379h-7c-5.48,0-8.751-2.6-8.751-6.951V1.738a1.75,1.75,0,0,1,3.5,0V13.895c0,3.758,0,6.241.731,7.823a2.88,2.88,0,0,0,.171.294l.028.044a5.105,5.105,0,0,0,1.254,1.263c1.209.711,3.112,1,6.568,1s5.356-.29,6.565-1a5.166,5.166,0,0,0,1.254-1.263l.056-.091a2.613,2.613,0,0,0,.139-.241C21,20.136,21,17.653,21,13.895V1.738a1.75,1.75,0,0,1,3.5,0v74.69C24.5,80.781,21.23,83.379,15.751,83.379Zm-3.5-24.319A5.211,5.211,0,1,0,17.5,64.272,5.238,5.238,0,0,0,12.251,59.06Z"
          transform="translate(176.749 202.811)"
          fill="none"
          stroke={color}
          strokeMiterlimit="10"
          strokeWidth="4"
        />
        <path
          id="Differenzmenge_40"
          data-name="Differenzmenge 40"
          d="M15.751,83.379h-7c-5.48,0-8.751-2.6-8.751-6.951V1.738a1.75,1.75,0,0,1,3.5,0V13.895c0,3.758,0,6.241.731,7.823a2.88,2.88,0,0,0,.171.294l.028.044a5.105,5.105,0,0,0,1.254,1.263c1.209.711,3.112,1,6.568,1s5.356-.29,6.565-1a5.166,5.166,0,0,0,1.254-1.263l.056-.091a2.613,2.613,0,0,0,.139-.241C21,20.136,21,17.653,21,13.895V1.738a1.75,1.75,0,0,1,3.5,0v74.69C24.5,80.781,21.23,83.379,15.751,83.379Zm-3.5-24.319A5.211,5.211,0,1,0,17.5,64.272,5.238,5.238,0,0,0,12.251,59.06Z"
          transform="translate(211.458 202.811)"
          fill="none"
          stroke={color}
          strokeMiterlimit="10"
          strokeWidth="4"
        />
        <path
          data-name="Pfad 7658"
          d="M11.75,18.37a1.744,1.744,0,0,0,1.75-1.737V2.737a1.75,1.75,0,0,0-3.5,0v13.9a1.744,1.744,0,0,0,1.75,1.737"
          transform="translate(177.5 202.368)"
          fill="none"
          stroke={color}
          strokeMiterlimit="10"
          strokeWidth="3"
        />
        <path
          data-name="Pfad 7661"
          d="M13.75,18.37a1.744,1.744,0,0,0,1.75-1.737V2.737a1.75,1.75,0,0,0-3.5,0v13.9a1.744,1.744,0,0,0,1.75,1.737"
          transform="translate(209.959 202.368)"
          fill="none"
          stroke={color}
          strokeMiterlimit="10"
          strokeWidth="3"
        />
        <path
          data-name="Pfad 7662"
          d="M11.75,18.37a1.744,1.744,0,0,0,1.75-1.737V2.737a1.75,1.75,0,0,0-3.5,0v13.9a1.744,1.744,0,0,0,1.75,1.737"
          transform="translate(142.54 202.368)"
          fill="none"
          stroke={color}
          strokeMiterlimit="10"
          strokeWidth="3"
        />
      </g>
    </svg>
  );
}
export default IconImmothek;
