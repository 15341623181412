import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import Card from './Card';

// ICONS
import IconHouse from '../../components/Icons/IconHouse';
import IconHeart from '../Icons/IconHeart';
import IconStar from '../Icons/IconStar';

// API

// STORE
import InfoDialogUnfavourite from '../Dialogs/InfoDialogUnfavourite';

import { MENU_KAUF, PHASE1_KAUF } from '../../constants';
import { getKaufpreis, getMainArea } from '../../util/immoObjectParser';

// HOOKS
import { useHandleFavorite } from '../../hooks/immo/useHandleFavorite';

function ImmoCard({ retailObject, cssClasses, isAbgeber }) {
  if (!cssClasses) cssClasses = 'immo-card';
  else cssClasses += ' immo-card';

  const { addToFavourites } = useHandleFavorite(retailObject);
  const user = useSelector((state) => state.user);
  const app = useSelector((state) => state.app);
  const navigate = useNavigate();
  const [kaufpreis, setKaufpreis] = useState('');
  const [areaLabel, setAreaLabel] = useState('');
  const [area, setArea] = useState(false);
  const [coverImage, setCoverImage] = useState(null);

  const [openRemoveFavDialog, setOpenRemoveFavDialog] = useState(false);

  /**
   * opens infodialog for unfavouriting
   */
  const handleOpenRemoveFavDialog = () => {
    setOpenRemoveFavDialog(!openRemoveFavDialog);
  };

  const handleFavFunc = () => {
    let favFound = user.favourites.find((f) => f?.id === (retailObject?.id).toString());

    if (!favFound) {
      addToFavourites(retailObject);
    } else {
      handleOpenRemoveFavDialog();
    }
  };

  const parseHTML = require('html-react-parser');

  useEffect(() => {
    let preis = getKaufpreis(retailObject);
    setKaufpreis(preis);

    if (retailObject.imagesPublic && retailObject.imagesPublic[0]?.url) {
      setCoverImage(retailObject.imagesPublic[0].url);
    }

    let area = getMainArea(retailObject);
    let areaLabel = getMainArea(retailObject, true);
    if (area && areaLabel) {
      setArea(area);
      setAreaLabel(areaLabel);
    }
  }, []);

  const handleLink = (e) => {
    // Checke hier, ob das Herz-Icon geklickt wurde, falls nicht, verlinke auf die Detailseite
    e.preventDefault();
    if (e.target.nodeName === 'svg' || e.target.nodeName === 'path') {
      // Favorite Icon wurde geklickt
      handleFavFunc();
    } else {
      window.location.href = e.currentTarget.querySelector('.card-link').getAttribute('href');
    }
  };

  return (
    <>
      <div className={cssClasses} onClick={(e) => handleLink(e)}>
        <Card
          cardStyle={'topImage'}
          linkOnFullCard={isAbgeber ? false : true}
          linkTo={`${app.root}/immobilie/${retailObject.url}`}
          cssClasses={'linkOnFullCard bg-white with-shadow'}
          imagePath={coverImage}
          icon={<IconHouse cssClasses={'scale-1-5'} color="#fff" />}
        >
          <div className="immo-card__headline-wrapper">
            <div className="immo-card__headline">
              <h3 className="font-110">{retailObject.title}</h3>
              {user.isLoggedIn && app.menuType === MENU_KAUF && app.menuId !== PHASE1_KAUF && (
                <div className="immo-card__favicon" id="star-wrapper">
                  <IconHeart color="#3679ec" filled={user.favourites.find((f) => f.id === retailObject.id.toString())} />
                </div>
              )}
            </div>

            <div className="immo-card__teaser">
              {retailObject.address?.zip} {retailObject.address?.city}
              {retailObject.rooms > 0 && <>, {retailObject.rooms?.toString().replace('.', ',')} Zimmer</>}
            </div>
          </div>

          <div className="immo-card__bottom">
            {area && areaLabel && (
              <div>
                <span className="immo-card__bottom--val font-110">{parseHTML(area)}</span>
                <span className="immo-card__bottom--label font-90">{areaLabel}</span>
              </div>
            )}

            {kaufpreis !== '' && (
              <div>
                <span className="immo-card__bottom--val font-110">{kaufpreis}</span>
                <span className="immo-card__bottom--label font-90">Kaufpreis</span>
              </div>
            )}
          </div>

          {isAbgeber && (
            <Link to={`../immobilie/${retailObject.url}`} title={retailObject.title}>
              <button className="button--blue mt-200rem">Immobiliendetails anzeigen</button>
            </Link>
          )}
        </Card>
      </div>

      <InfoDialogUnfavourite retailObject={retailObject} handleClose={handleOpenRemoveFavDialog} open={openRemoveFavDialog} />
    </>
  );
}

export default ImmoCard;
