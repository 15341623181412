function IconKey({ cssClasses, color }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="52.531"
      height="51.044"
      viewBox="0 0 52.531 51.044"
      className={cssClasses}
    >
      <path
        id="Pfad_7351"
        data-name="Pfad 7351"
        d="M13.3,51.043A13.37,13.37,0,0,0,25.2,31.728l10.258-10.32,4.432,4.461,3.782-3.8L39.236,17.6,42.1,14.72l5.542,5.574,4.888-4.917L46.99,9.8l2.541-2.555a4.29,4.29,0,0,0,0-6.034,4.321,4.321,0,0,0-6,0L19.2,25.694a13.187,13.187,0,0,0-5.9-1.4,13.376,13.376,0,0,0,0,26.751m0-20.063a6.688,6.688,0,1,1-6.648,6.688A6.676,6.676,0,0,1,13.3,30.98"
        transform="translate(0 0.001)"
        fill={color}
      />
    </svg>
  );
}

export default IconKey;
