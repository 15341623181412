function IconDocumentOutlined({ color, cssClasses }) {
  return (
    <svg
      className={cssClasses}
      xmlns="http://www.w3.org/2000/svg"
      width="63.857"
      height="84.982"
      viewBox="0 0 63.857 84.982"
    >
      <g data-name="Gruppe 10672" transform="translate(1.5 1.5)">
        <g data-name="Gruppe 10285" transform="translate(0.5 0.5)">
          <g data-name="Gruppe 10287">
            <path
              data-name="Pfad 7615"
              d="M60.357,81.482H.5V.5H39.231L60.357,21.626Z"
              transform="translate(-0.5 -0.5)"
              fill="none"
              stroke={color}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="4"
            />
            <path
              data-name="Pfad 7616"
              d="M11.5.5V21.626H32.626"
              transform="translate(27.231 -0.5)"
              fill="none"
              stroke={color}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="4"
            />
          </g>
        </g>
        <line
          data-name="Linie 4404"
          x2="15.844"
          transform="translate(14.584 25.147)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
        />
        <line
          data-name="Linie 4405"
          x2="31.689"
          transform="translate(14.584 35.71)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
        />
        <line
          data-name="Linie 4406"
          x2="31.689"
          transform="translate(14.584 46.273)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
        />
        <line
          data-name="Linie 4407"
          x2="31.689"
          transform="translate(14.584 56.836)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
        />
        <line
          data-name="Linie 4408"
          x2="31.689"
          transform="translate(14.584 67.399)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
        />
      </g>
    </svg>
  );
}

export default IconDocumentOutlined;
