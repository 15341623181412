function IconKBesteImmoVorab({ color, cssClasses }) {
  return (
    <svg
      className={cssClasses}
      xmlns="http://www.w3.org/2000/svg"
      width="41.434"
      height="40.053"
      viewBox="0 0 41.434 40.053"
    >
      <g id="Gruppe_13434" data-name="Gruppe 13434" transform="translate(-207.031 -4005.393)">
        <g id="Gruppe_13426" data-name="Gruppe 13426">
          <path
            id="Pfad_8454"
            data-name="Pfad 8454"
            d="M247.684,4043.884h-3.906V4030.6a.783.783,0,0,0-.781-.781H216.435a.78.78,0,0,0-.781.779v13.283h-3.909a.781.781,0,0,0,0,1.562h35.937a.781.781,0,0,0,0-1.562Zm-27.339-11.719a.78.78,0,0,1,.778-.782h4.69a.782.782,0,0,1,.781.782v7.812a.782.782,0,0,1-.781.781h-4.687a.779.779,0,0,1-.781-.777v-7.816Zm7.812,11.718a.782.782,0,0,1-.781.781h-7.812a.779.779,0,0,1-.781-.777v-.785a.78.78,0,0,1,.779-.781h7.814a.783.783,0,0,1,.781.781Zm10.937-5.469a.783.783,0,0,1-.781.781H230.5a.779.779,0,0,1-.781-.777v-4.691a.78.78,0,0,1,.778-.782h7.815a.782.782,0,0,1,.781.782Z"
            fill={color}
          />
        </g>
        <path
          id="Pfad_8461"
          data-name="Pfad 8461"
          d="M222.465,4023.482l-2.773-7.246,5.453-4.539-7.264.026-1.8-6.33-1.77,6.326h-7.275l5.461,4.514-2.72,7.255,6.321-4.555Z"
          fill={color}
        />
        <path
          id="Pfad_8462"
          data-name="Pfad 8462"
          d="M248.149,4026.85l-4.37-3.232v-6.3a.783.783,0,0,0-.781-.781h-4.687a.78.78,0,0,0-.781.779V4019l-7.348-5.43a.788.788,0,0,0-.931,0l-6.6,4.876,1.7,4.532a1.92,1.92,0,0,1-1.794,2.6,1.9,1.9,0,0,1-1.133-.373l-4.025-2.874-6.119,4.523a.783.783,0,0,0,.467,1.409l-.005,0h35.94a.782.782,0,0,0,.464-1.409Z"
          fill={color}
        />
      </g>
    </svg>
  );
}

export default IconKBesteImmoVorab;
