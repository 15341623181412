import Header from '../components/Header/Header';
import HeaderSmallWithIcon from '../components/Header/HeaderSmallWithIcon';
import MainContent from '../components/MainContent';
import MetaTags from '../components/MetaTags';
import NewsDetailSkeleton from '../components/Skeletons/NewsDetailSkeleton';

// ICONS
import IconArrowLeft from '../components/Icons/IconArrowLeft';
import IconNews from '../components/Icons/IconNews';

import { useFetchNewsDetails } from '../hooks/news/useFetchNewsDetails';
import { getDefaultNewsObject } from '../util/newsParser';

import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useHandleScreenResize } from '../hooks/app/useHandleScreenResize';
import '../styles/news.scss';

function NewsDetail() {
  const app = useSelector((state) => state.app);
  const { currentNews, navigateBack } = useFetchNewsDetails();
  const { isMobile } = useHandleScreenResize();

  if (currentNews?.slug === '') {
    return <NewsDetailSkeleton />;
  } else {
    if (JSON.stringify(currentNews) !== JSON.stringify(getDefaultNewsObject())) {
      return (
        <>
          <MetaTags
            title={'my-sreal News | ' + currentNews.title}
            description={currentNews.summary}
            imageName={currentNews.header_picture ? currentNews.header_picture : 'mysreal_hero_news.jpg'}
            imageFullPath={currentNews.header_picture ? true : false}
          />
          {currentNews.header_picture ? (
            <Header cssClasses={'header--default header--withimage header--news header--newsdetail header--pinkbg'}>
              <img src={currentNews.header_picture} alt={currentNews.title} className="header__image" />

              <div className="header--news__links">
                <span onClick={navigateBack} className="textlink">
                  <IconArrowLeft color="#fff" />
                  Zurück
                </span>
                <Link to={app.root + '/news'} className="textlink" title="Zur News-Übersicht">
                  Zur News-Übersicht
                </Link>
              </div>
            </Header>
          ) : (
            <HeaderSmallWithIcon cssClasses={'header--news header--newsdetail header--pinkbg'} icon={<IconNews />} headlineDesktop={currentNews.title} iconActive={true}>
              <div className="header--news__sublines">
                {currentNews.author_name && (
                  <>
                    <span className="font-80 font-weight-600">von {currentNews.author_name}</span>
                    <br />
                  </>
                )}
                <span className="font-80">
                  aktualisiert am {currentNews.updated_at}
                  {currentNews.topics.length > 0 ? ` in ${currentNews.topics}` : ''}
                </span>
              </div>
            </HeaderSmallWithIcon>
          )}

          <MainContent cssClasses="w-desktop--center newsdetail">
            <div className="header--news__links">
              <span onClick={navigateBack} className="textlink">
                <IconArrowLeft color="#3679ec" />
                Zurück
              </span>
              <Link to={app.root + '/news'} className="textlink" title="Zur News-Übersicht">
                Zur News-Übersicht
              </Link>
            </div>

            {((!currentNews.header_picture && isMobile) || currentNews.header_picture) && (
              <>
                <h1 className="mb-100rem">{currentNews.title}</h1>
                {currentNews.author_name && (
                  <>
                    <span className="font-80 font-weight-600">von {currentNews.author_name}</span>
                    <br />
                  </>
                )}
                <span className="font-80">
                  aktualisiert am {currentNews.updated_at}
                  {currentNews.topics.length > 0 ? ` in ${currentNews.topics}` : ''}
                </span>
              </>
            )}

            <p className="font-100 lh-140 mt-200rem">{currentNews.summary}</p>

            <div className="newsdetail__content">
              {currentNews.blocks.length > 0 && (
                <>
                  {currentNews.blocks.map((b, index) => {
                    let type = b.type;
                    let data = b.data;

                    switch (type) {
                      case 'paragraph':
                        return (
                          <div className="mt-200rem" key={index}>
                            <div dangerouslySetInnerHTML={{ __html: data.text }} />
                          </div>
                        );
                      case 'header':
                        if (data.level === 2) {
                          return <h2 key={index}>{data.text}</h2>;
                        } else if (data.level === 3) {
                          return <h3 key={index}>{data.text}</h3>;
                        } else if (data.level === 4) {
                          return <h4 key={index}>{data.text}</h4>;
                        } else if (data.level === 5) {
                          return <h5 key={index}>{data.text}</h5>;
                        } else if (data.level === 6) {
                          return <h6 key={index}>{data.text}</h6>;
                        } else {
                          return <h2 key={index}>{data.text}</h2>;
                        }
                      case 'quote':
                        return (
                          <p className="mt-200rem w-100" key={index}>
                            <cite>{data.text}</cite>
                            <br />
                            <small className="mt-100rem">{data.caption}</small>
                          </p>
                        );
                      default:
                        break;
                    }
                  })}
                </>
              )}
            </div>
          </MainContent>
        </>
      );
    } else {
      return null;
    }
  }
}

export default NewsDetail;
