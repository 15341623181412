const parseCurrency = (amount) => {
  if (!amount) {
    return '';
  }

  // check if number has decimals
  if (amount % 1 !== 0) {
    amount = +parseFloat(amount).toFixed(2);
  }

  amount = Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR',
    maximumFractionDigits: 2,
    trailingZeroDisplay: 'stripIfInteger',
  }).format(amount);

  return amount;
};

export default parseCurrency;
