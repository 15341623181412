import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PageDialogMarkinfoLight from '../Dialogs/PageDialogMarkinfoLight';
import PageDialogWohnwertLight from '../Dialogs/PageDialogWohnwertLight';
import FormSelectMarktinfoLastResults from '../FormFields/customFields/FormSelectMarktinfoLastResults';
import FormSelectWohnwertLastResults from '../FormFields/customFields/FormSelectWohnwertLastResults';
import Card from './Card';

//Icons
import IconCalculatorSimpleOutlined from '../Icons/IconCalculatorSimpleOutlined';
import IconLock from '../Icons/IconLock';

// STORE
import { MARKTINFO_SITEINDEX_RESULT, WOHNWERTLIGHT_SITEINDEX_RESULT } from '../../constants';
import {
  setPageDialogOpenMarktinfoForm,
  setResetFieldsMarktinfoLightForm,
  setSiteIndexMarktinfoForm
} from '../../reducers/marktinfoLight';
import {
  setPageDialogOpenWohnwertForm,
  setResetFieldsWohnwertForm,
  setSiteIndexWohnwertForm
} from '../../reducers/wohnwertLight';
import { useHandleScreenResize } from '../../hooks/app/useHandleScreenResize';

function ProfilAnalysenCard({ cssClasses }) {
  const dispatch = useDispatch();
  let user = useSelector((state) => state.user);

  cssClasses += ' profilAnalysen-card';

  const { isMobile } = useHandleScreenResize();

  const handleOpenWohnwertForm = () => {
    dispatch(setResetFieldsWohnwertForm());
    dispatch(setPageDialogOpenWohnwertForm(true));
    dispatch(setSiteIndexWohnwertForm(WOHNWERTLIGHT_SITEINDEX_RESULT));
  };


  const handleOpenMarktinfoForm = () => {
    dispatch(setResetFieldsMarktinfoLightForm());
    dispatch(setPageDialogOpenMarktinfoForm(true));
    dispatch(setSiteIndexMarktinfoForm(MARKTINFO_SITEINDEX_RESULT));
  };

  return (
    <div id='profilAnalysen'>
      <h2 className='mb-200rem desktop-hidden'>Bisherige Berechnungen</h2>
      
      <Card
        cssClasses={cssClasses}
        cardStyle={!isMobile ? 'topImage2Cols' : 'customContent'}
        icon={<IconCalculatorSimpleOutlined color='#fff' />}
      >
        <div className={!isMobile ? 'pt-300rem' : 'card__content pb-0'}>
          <h2 className='mb-300rem mobile-hidden'>Bisherige Berechnungen</h2>

          <FormSelectWohnwertLastResults label={'Ihre Wertermittlungen'} />

          <div className='button-panel pb-100rem'>
            {(user.wohnwertResultsActive.id === -1) ? (
              <button className="button button--blue-outline button--withicon" disabled={true}>
                <IconLock color={'#c1c4d4'} />
                Analyse ansehen
              </button>
            ) : (
              <button className='button button--blue-outline' onClick={() => handleOpenWohnwertForm()}>Analyse ansehen</button>
            )}
          </div>

          <FormSelectMarktinfoLastResults label={'Ihre Marktanalysen'} />

          <div className={!isMobile ? 'button-panel pb-100rem' : 'button-panel pb-0'}>
            {(user.marktinfoResultActive.id === -1) ? (
              <button className="button button--blue-outline button--withicon" disabled={true}>
                <IconLock color={'#c1c4d4'} />
                Analyse ansehen
              </button>
            ) : (
              <button className='button button--blue-outline' onClick={() => handleOpenMarktinfoForm()}>Analyse ansehen</button>
            )}
          </div>
        </div>
      </Card>
      <PageDialogWohnwertLight />
      <PageDialogMarkinfoLight />
    </div>
  );
}

export default ProfilAnalysenCard;
