import Card from './Card';
import IconDocument from '../Icons/IconDocument';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

function ImmoDriveCard({ cssClasses = '', files }) {
  if (!cssClasses) cssClasses = 'immodriveempty-card';
  else cssClasses += ' immodriveempty-card';
  const navigate = useNavigate();
  const app = useSelector((state) => state.app);

  let cardStyle = 'customContent';

  return (
    <Card cssClasses={cssClasses + ' text-center'} cardStyle={cardStyle}>
      <div className="card__icon">
        <IconDocument color="#fff" />
      </div>

      <div className="card__content pb-0 pt-0">
        <h2 className="mb-50rem text-white">
          Sie haben aktuell {files.length} {files.length === 1 ? 'Dokument' : 'Dokumente'} in Ihrem immo-drive
        </h2>
      </div>

      <div>
        <button className="mb-200rem mt-50rem button button--glass" onClick={() => navigate(app.root + '/immo-drive')}>
          immo-drive aufrufen
        </button>
      </div>
    </Card>
  );
}

export default ImmoDriveCard;
