import IconInquirySent from '../../Icons/IconInquirySent';

function ObjectRequestSent({ handleClose }) {
  return (
    <>
      <div className="d-flex w-100 justify-content-center pt-40 pb-40">
        <IconInquirySent color="#1E3163" />
      </div>

      <h2>Objekt-Anfrage wird bearbeitet</h2>
      <p>
        Vielen Dank für Ihre Anfrage! Eine Mitarbeiter:in wird sich in Kürze um die Beantwortung
        kümmern.
      </p>

      <div className="button-panel pt-100rem">
        <button className="button button--blue-outline" onClick={handleClose}>
          Schließen
        </button>
      </div>
    </>
  );
}

export default ObjectRequestSent;
