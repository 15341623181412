import { useState } from 'react';
import InfoDialogGeldfluss from '../Dialogs/InfoDialogGeldfluss';
import IconGeldfluss from '../Icons/IconGeldfluss';
import Card from './Card';

function GeldflussCard({ cssClasses }) {
  const [openInfoDialog, setOpenInfoDialog] = useState(false);
  const handleOpenInfoDialog = () => {
    let newVal = !openInfoDialog;
    setOpenInfoDialog(newVal);
  };

  cssClasses += ' geldfluss-card';

  return (
    <>
      <Card cardStyle={'customContent'} cssClasses={cssClasses}>
        <div className="card__content">
          <h2>Infos zum Geldfluss</h2>
          <p className="pb-100rem">
            Bis Immobilie und Geld die Besitzer:innen wechseln, sind einige
            Schritte nötig.
          </p>
          <IconGeldfluss color="#fff" />

          <span
            className="d-flex mt-200rem textlink"
            onClick={handleOpenInfoDialog}
          >
            Details anzeigen
          </span>
        </div>
      </Card>

      <InfoDialogGeldfluss
        open={openInfoDialog}
        handleClose={handleOpenInfoDialog}
      />
    </>
  );
}

export default GeldflussCard;
