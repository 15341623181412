import React from 'react';
import Header from './Header';

function HeaderSmallWithIcon({
  icon,
  iconActive,
  headlineDesktop,
  headlineDesktopRight,
  subheadlineDesktopRight,
  overlappedContent,
  cssClasses,
  children
}) {
  let headerIcon = React.cloneElement(icon, {
    color: '#fff',
    cssClasses: 'header__iconbg',
    active: iconActive
  });

  cssClasses += ' header--darkbluebg';
  if(overlappedContent) {
    cssClasses += ' header--overlapped';
  }

  return (
    <Header cssClasses={cssClasses}>
      <span className={'desktop-hidden'}>{headerIcon}</span>
      <div className="header__desktopheadlines mb-20">
        {headlineDesktop && (
          <h1 className="text-white w-desktop--center mt-200rem mb-100">{headlineDesktop}</h1>
        )}
        <div className="mb-30">
          {headlineDesktopRight && <h2 className="text-white mb-0">{headlineDesktopRight}</h2>}
          {subheadlineDesktopRight && <p>{subheadlineDesktopRight}</p>}
        </div>
        {children}
      </div>
    </Header>
  );
}

export default HeaderSmallWithIcon;
