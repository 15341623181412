import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import HeaderSmallWithIcon from '../components/Header/HeaderSmallWithIcon';
import MainContent from '../components/MainContent';
import MetaTags from '../components/MetaTags';

import '../styles/impressum-datenschutz.scss';

//STORE
import {
  setPageDialogOpenRegisterForm,
  setResetFieldsRegisterForm
} from '../reducers/registerForm';
import { setResetFormValidator } from '../reducers/formValidators';

// ICONS
import IconDocumentOutlined from '../components/Icons/IconDocumentOutlined';

function Datenschutz() {
  const dispatch = useDispatch();

  // close Register Modal Dialog on this page
  useEffect(() => {
    dispatch(setPageDialogOpenRegisterForm(false));
    dispatch(setResetFormValidator());
    dispatch(setResetFieldsRegisterForm());
  }, []);

  return (
    <>
      <MetaTags
        title={'Datenschutzinformation für KundInnen | Die Online-Plattform für Ihre Immobilie'}
        description={
          'Der Schutz Ihrer personenbezogenen Daten ist uns wichtig und ist auch gesetzlich gefordert.'
        }
      />
      <HeaderSmallWithIcon
        icon={<IconDocumentOutlined />}
        headlineDesktop="Datenschutzinformation für KundInnen"
        iconActive={true}
      />

      <MainContent cssClasses="w-desktop--center datenschutz">
        <h1 className="headline--mobile">Datenschutzinformation für KundInnen</h1>
        <div>
          <p className="mb-100rem">
            Der Schutz Ihrer personenbezogenen Daten ist uns wichtig und ist auch gesetzlich
            gefordert.
          </p>
          <p>
            Die Verarbeitung Ihrer personenbezogenen Daten erfolgt nach den datenschutzrechtlichen
            Bestimmungen. Die nachstehende Übersicht soll Sie über die wichtigsten Aspekte der
            Verarbeitung personenbezogener Daten informieren.
          </p>
        </div>
        <div className="mt-400rem">
          <h2>Für die Verarbeitung verantwortlich</h2>
          <div className="mb-200rem">
            <p>s REAL Immobilienvermittlung GmbH</p>
            <p>Landstraßer Hauptstraße 60</p>
            <p>1030 Wien</p>
            <p>datenschutz.wien (at) sreal.at</p>
          </div>
          <div className="mb-200rem">
            <p>Sparkassen Real Service für Kärnten und Osttirol</p>
            <p>Realitätenvermittlungs-Ges.m.b.H.</p>
            <p>Neuer Platz 14</p>
            <p>9020 Klagenfurt</p>
            <p>datenschutz.kaernten (at) sreal.at</p>
          </div>
          <div className="mb-200rem">
            <p>Real Service für steirische Sparkassen</p>
            <p>Realitätenvermittlungs-Ges.m.b.H.</p>
            <p>Sparkassenplatz 4</p>
            <p>8010 Graz</p>
            <p>datenschutz.steiermark (at) sreal.at</p>
          </div>
          <div className="mb-200rem">
            <p>Sparkassen Real Service Tirol</p>
            <p>Realitätenvermittlungs-Ges.m.b.H.</p>
            <p>Sparkassenplatz 5</p>
            <p>6020 Innsbruck</p>
            <p>datenschutz.tirol (at) sreal.at</p>
          </div>
          <div className="mb-200rem">
            <p>Real Service für oberösterreichische Sparkassen</p>
            <p>Realitätenvermittlungs-Ges.m.b.H.</p>
            <p>Promenade 9</p>
            <p>4020 Linz</p>
            <p>datenschutz.oberoesterreich (at) sreal.at</p>
          </div>
          <div className="mb-200rem">
            <p>Sparkassen Real Vorarlberg Immobilienvermittlung GmbH</p>
            <p>Sparkassenplatz 1</p>
            <p>6850 Dornbirn</p>
            <p>datenschutz.vorarlberg (at) sreal.at</p>
          </div>
          <p>
            Es ist kein Datenschutzbeauftragter bestellt, da keine gesetzliche Notwendigkeit
            besteht.
          </p>
        </div>
        <div className="mt-400rem">
          <h2>Welche personenbezogenen Daten werden verarbeitet?</h2>
          <p>Wir verarbeiten folgende personenbezogene Daten:</p>
          <ul className="mt-100rem mb-200rem">
            <li>Stammdaten z.B. Name, Titel</li>
            <li>Kontaktdaten z.B. Postadresse</li>
            <li>Kommunikationsdaten z.B. E-Mail</li>
            <li>Identitätsdaten</li>
            <li>Bonitätsdaten</li>
            <li>Daten über das Geschäftsverhältnis</li>
            <li>Daten über die Leistungserbringung</li>
            <li>Mit dem Immobiliengeschäft zusammenhängende Daten z.B. Hypothek</li>
            <li>Daten über die Kundenzufriedenheit</li>
            <li>Veranstaltungsteilnahme</li>
          </ul>
          <p>
            Bitte bedenken Sie: Hierbei handelt es sich bloß um eine allgemeine Aufzählung. Wir
            verfügen nicht in jedem Fall über alle der oben genannten Daten. Für eine detaillierte,
            individuelle Aufstellung haben Sie Recht auf Auskunft und können diese bei uns
            verlangen. An wen Sie sich dazu wenden müssen, finden Sie im Abschnitt „Welche Rechte
            habe ich?“.
          </p>
        </div>
        <div className="mt-400rem">
          <h2>Woher stammen die personenbezogenen Daten, die verarbeitet werden?</h2>
          <p>
            Die meisten Ihrer personenbezogenen Daten, die wir verarbeiten, haben Sie uns selbst
            bekannt gegeben, etwa bei Anfrage auf eine bestimmte Immobilie oder bei
            Auftragserteilung zur Vermittlung einer Immobilie. In manchen Fällen haben Sie einem
            unserer Partnerunternehmen schriftlich eine Zustimmung gegeben, Ihre personenbezogenen
            Daten an uns weiterzuleiten, z.B. der Erste Bank/Sparkasse, weil Sie eine Immobilie
            suchen.
          </p>
          <p className="mt-200rem">
            Darüber hinaus können die Daten aus folgenden Quellen stammen:
          </p>
          <ul className="mt-100rem mb-200rem">
            <li>
              Schuldnerverzeichnis (CRIF ww.crif.at) – bei der Vermittlung von Mietwohnungen, als
              Schutz für Vermieter vor einem möglicherweise drohendem Zahlungsausfall
            </li>
            <li>Öffentlich zugängliche Quellen, z.B. Grundund Firmenbuch</li>
          </ul>
        </div>
        <div className="mt-400rem">
          <h2>Welche personenbezogenen Daten werden verarbeitet?</h2>
          <p>
            Wir sind ein Immobilienmaklerunternehmen. Daher verarbeiten wir Ihre Daten zum Zwecke
            der Immobilienvermittlung und –bewertung, zur Gewinnung und Bindung von Kunden und
            Verbesserung unserer Dienstleistungsqualität.
          </p>
          <p className="mt-200rem">Im Detail bedeutet das:</p>
          <ul className="mt-100rem mb-200rem">
            <li>
              Verarbeitung für die Vertragserfüllung: Je nach Art des Vertrages, den wir mit Ihnen
              haben, dürfen wir bestimmte Leistungen für Sie erbringen. Bei einem Verkaufsauftrag
              beispielsweise beauftragen Sie uns, einen Käufer für Ihre Immobilie zu finden. Dazu
              müssen wir Ihre Daten verarbeiten. Weitere Verträge können beispielsweise sein:
              Vermietungsauftrag, Immobilienbewertung, Kaufoder Miete einer Immobilie
            </li>
            <li>
              Vertragsanbahnung: Wir sind mit Ihnen in Kontakt, um in Kürze einen Vertrag mit Ihnen
              abzuschließen. Mit dem Ziel des Vertragsabschlusses verarbeiten wir Ihre Daten schon
              davor.
            </li>
            <li>Berechtigtes Interesse</li>
            <li>Gesetzliche Grundlage</li>
            <li>
              Verarbeitung aufgrund Einwilligung: Wenn es keinen Vertrag, rechtliche Grundlage oder
              berechtigtes Interesse gibt, kann die Verarbeitung Ihrer Daten dennoch rechtmäßig
              sein, wenn Sie uns Ihre Einwilligung und Zustimmung gegeben haben. Selbstverständlich
              können Sie diese Einwilligung jederzeit widerrufen.
            </li>
          </ul>
        </div>
        <div className="mt-400rem">
          <h2>
            Bin ich verpflichtet, meine personenbezogenen Daten bereitzustellen? Was passiert, wenn
            ich das nicht möchte?
          </h2>
          <p>
            Für unsere Geschäftsbeziehung sind wir auf viele Ihrer personenbezogenen Daten
            angewiesen. Wenn wir Ihren Namen und Ihre Adresse nicht kennen, können wir Ihnen z. B.
            keine Immobilienangebote zusenden. Wenn wir Ihre Identität nicht prüfen können,
            verbietet uns das Gesetz die Geschäftsbeziehung. Sie sehen also: Dort, wo es für die
            Geschäftsbeziehung aufgrund eines Vertrags oder einer rechtlichen Vorschrift
            erforderlich ist, müssen wir Ihre personenbezogenen Daten verarbeiten. Möchten Sie dies
            nicht, kann es sein, dass wir bestimmte Produkte leider nicht anbieten bzw. bestimmte
            Services nicht erbringen dürfen. Dürfen wir Ihre Daten nur aufgrund Ihrer Einwilligung
            verarbeiten, sind Sie nicht verpflichtet, diese Einwilligung zu erteilen und die Daten
            bereitzustellen.
          </p>
        </div>
        <div className="mt-400rem">
          <h2>Werden meine personenbezogenen Daten von s REAL weitergegeben?</h2>
          <p>
            Wir speichern und verarbeiten Ihre personenbezogenen Daten nur soweit es mit der
            Abwicklung des Vertrages im Zusammenhang steht. Eine Weitergabe erfolgt nur im minimal
            erforderlichen Umfang und soweit es für die Vertragsabwicklung notwendig ist, auf einer
            gesetzlichen Grundlage beruht oder ein berechtigtes Interesse an der Geschäftsabwicklung
            Beteiligter (Dritter) besteht.
          </p>
          <p className="mt-200rem">Mögliche Empfänger können sein:</p>
          <ul className="mt-100rem mb-200rem">
            <li>
              Abteilungen der s REAL, die mit der Geschäftsabwicklung befasst sind (z.B. IT,
              sonstige Verwaltungseinheiten) oder Gesellschaften der Unternehmensgruppe (z.B. zur
              Abwicklung gemeinsamer Projekte);
            </li>
            <li>
              An der Geschäftsabwicklung beteiligte Dritte (z.B. weitere Makler, Hausverwaltungen,
              Finanzierungsunternehmen, private und öffentliche Stellen, die Informationen zu
              Objekten bekannt geben können oder benötigen, Versicherungen);
            </li>
            <li>Dienstleister der s REAL (z.B. Steuerberater, Rechtsvertreter)</li>
            <li>Behörden (Finanzamt, sonstige Behörden)</li>
            <li>
              Rechtsvertreter (bei der Durchsetzung von Rechten oder Abwehr von Ansprüchen oder im
              Rahmen von Behördenverfahren)
            </li>
            <li>
              Unternehmen, die als Auftragsverarbeiter tätig sind (z.B. im Rahmen der Betreuung der
              IT-Infrastruktur)
            </li>
          </ul>
          <p className="mb-100rem">
            Keinesfalls werden Ihre Daten zu Werbezwecken o.ä. weitergegeben. Unsere
            MitarbeiterInnen und unsere Dienstleistungsunternehmen sind zur Verschwiegenheit und zur
            Einhaltung der Datenschutzbestimmungen verpflichtet.
          </p>
          <p>
            Eine Übermittlung an Empfänger in einem Drittland (außerhalb der EU) oder an eine
            internationale Organisation ist nicht vorgesehen. Einzige Ausnahme ist die Auswertung
            der Kundenzufriedenheitsumfrage nach erfolgter Immobilienvermittlung an einen
            Auftragsverarbeiter in der Schweiz
          </p>
        </div>
        <div className="mt-400rem">
          <h2>Wie lange werden meine Daten gespeichert?</h2>
          <p className="mb-100rem">
            Ihre personenbezogenen Daten werden jedenfalls so lange aufbewahrt, wie es für die
            Erfüllung der jeweiligen Zwecke erforderlich ist. Darüber hinaus ist gesetzlich
            vorgeschrieben, für welchen Zeitraum wir die Daten aufbewahren müssen. Diese
            Aufbewahrungspflichten können auch noch dann bestehen, wenn Sie nicht mehr unsere Kundin
            oder unser Kunde sind.
          </p>
          <p>
            Haben Sie uns Ihre Einwilligung zur Verarbeitung gegeben (z.B. für den Newsletter)
            verarbeiten wir Ihre Daten so lange, bis Sie Ihre Einwilligung widerrufen.
          </p>
        </div>
        <div className="mt-400rem">
          <h2>Profiling?</h2>
          <p>Es besteht keine automatisierte Entscheidungsfindung (Profiling).</p>
        </div>
        <div className="mt-400rem">
          <h2>Was ist mit Cookies und Webanalysen?</h2>
          <p className="mb-100rem">
            <span className="font-weight-700">Cookies: </span>An verschiedenen Stellen unserer
            Website werden Cookies verwendet. Cookies sind kleine Textdateien, die BenutzerInnen
            wiedererkennen, wenn sie die Website erneut nutzen. Dabei werden jedoch keine
            persönlichen Angaben, wie Name oder Adresse, gespeichert. Sie können durch die
            betreffenden Informationen also nicht identifiziert werden.
          </p>
          <p className="mb-200rem">
            Wir verwenden Cookies, um unsere Angebote auf Ihre Bedürfnisse zuzuschneiden und zu
            analysieren, wie diese Angebote verwendet werden.
          </p>
          <p className="mb-200rem">
            <span className="font-weight-700">Webanalyse: </span>Für anonymisierte, statistische
            Auswertung des Besucherstroms auf unserer Webseite www.my-sreal.at und in unserer App
            verwenden wir das Analysetool „Matomo“. Es werden dabei die IP-Adresse (anonymisiert),
            die Art des Browsers, Plug-ins, der Gerätetyp und –ID, das Land des Zugriffs, besuchte
            Seiten und Landingpages, Verlauf und Dauer des Besuches und Aktivitäten auf der Seite
            verarbeitet. Diese Daten werden 1 Jahr aufbewahrt.
          </p>
          <p>
            <span className="font-weight-700">Adobe Audience Manager: </span>Unser Partner Erste
            Bank & Sparkassen verwendet Cookies, Web Beacons und verwandte Technologien, um zu
            erfahren, wie Nutzer die Webseite verwenden. So können sie erkennen, welche Teile
            unseres Internetangebots besonders populär sind. Es ermöglicht Erste Bank & Sparkassen
            dann, Sie mit zielgerichteten Angeboten zu adressieren. Die Datenerhebung erfolgt über
            eine Cookie-ID, die pseudonymisiert gespeichert wird. Diesem Pseudonym werden Infos über
            die Nutzer-Aktivitäten auf den Webseiten, Services und Anwendungen zugeordnet. Hierzu
            wird von Erste Bank & Sparkassen der "Adobe Audience Manager", ein Dienst der Adobe Inc.
            (Irland, USA), verwendet.
          </p>
        </div>
        <div className="mt-400rem">
          <h2>(Re-)Targeting</h2>
          <p className="mb-100rem">
            Damit wir Werbebotschaften auf Ihre Interessen zuschneiden und Ihnen möglichst passende
            Produkte und Services anbieten können, nutzen wir Re-Targetingund Tracking Software .
            Durch Re-Targeting und Tracking werden bei Ihrem Besuch auf Websites Cookies gesetzt und
            Ihre IP Adresse an Dritte (Werbende, Webdienste, Data Management Plattformen,
            Mediaagenturen, Publisher) weitergegeben. Das tun wir, um Ihnen maßgeschneiderte
            Werbung, Angebote und Services anbieten zu können.
          </p>
          <p className="mb-200rem">
            <span className="font-weight-700">GroupM: </span>setzt Cookies die eine eindeutige ID
            vergeben um Sie auf dem GroupM Netzwerk wiederzuerkennen und Ihnen interessensbasierte
            Werbung auszuspielen. Weitere Informationen finden Sie hier{' '}
            <a href="https://www.xaxis.com/privacy-notice/">
              https://www.xaxis.com/privacy-notice/
            </a>
          </p>
          <p className="mb-200rem">
            <span className="font-weight-700">Google: </span>setzt Cookies die eine eindeutige ID
            vergeben um Sie auf dem Google Netzwerk wiederzuerkennen und Ihnen interessensbasierte
            Werbung auszuspielen. Weitere Informationen finden Sie hier{' '}
            <a href="https://www.google.com/intl/en/policies/terms/">
              https://www.google.com/intl/en/policies/terms/
            </a>
          </p>
          <p className="mb-100rem">
            <span className="font-weight-700">Facebook: </span>setzt Cookies die eine eindeutige ID
            vergeben um Sie auf dem Facebook Netzwerk wiederzuerkennen und Ihnen interessensbasierte
            Werbung auszuspielen. Weitere Informationen finden Sie hier{' '}
            <a href="https://www.facebook.com/privacy/explanation">
              https://www.facebook.com/privacy/explanation
            </a>
          </p>
          <p>
            Sie können Ihren Browser so einstellen, dass vor dem Verwenden eines Cookies Ihre
            Zustimmung eingeholt werden muss oder das Verwenden von Cookies generell blockiert wird.
            Sie können unsere Website www.my-sreal.at grundsätzlich auch ohne Cookies verwenden.
          </p>
        </div>
        <div className="mt-400rem">
          <h2>Welche Rechte habe ich?</h2>
          <p className="mb-100rem">
            Als betroffener Person steht Ihnen grundsätzlich das Recht auf Auskunft, Berichtigung,
            Löschung, Einschränkung, Widerspruch und Übertragung zu. Zur Ausübung Ihrer Rechte
            wenden Sie sich bitte an jenes s REAL Unternehmen, mit dem Sie in Kontakt stehen oder
            standen:
          </p>
          <div className="mb-200rem">
            <p>Für Wien, Niederösterreich und Burgenland:</p>
            <p>s REAL Immobilienvermittlung GmbH</p>
            <p>Landstraßer Hauptstraße 60</p>
            <p>1030 Wien</p>
            <p>datenschutz.wien (at) sreal.at</p>
          </div>
          <div className="mb-200rem">
            <p>Für Kärnten:</p>
            <p>Sparkassen Real Service für Kärnten und Osttirol</p>
            <p>Realitätenvermittlungs-Ges.m.b.H.</p>
            <p>Neuer Platz 14</p>
            <p>9020 Klagenfurt</p>
            <p>datenschutz.kaernten (at) sreal.at</p>
          </div>
          <div className="mb-200rem">
            <p>Für die Steiermark:</p>
            <p>Real Service für steirische Sparkassen</p>
            <p>Realitätenvermittlungs-Ges.m.b.H.</p>
            <p>Sparkassenplatz 4</p>
            <p>8010 Graz</p>
            <p>datenschutz.steiermark (at) sreal.at</p>
          </div>
          <div className="mb-200rem">
            <p>Für Tirol:</p>
            <p>Sparkassen Real Service Tirol</p>
            <p>Realitätenvermittlungs-Ges.m.b.H.</p>
            <p>Sparkassenplatz 5</p>
            <p>6020 Innsbruck</p>
            <p>datenschutz.tirol (at) sreal.at</p>
          </div>
          <div className="mb-200rem">
            <p>Für Oberösterreich:</p>
            <p>Real Service für oberösterreichische Sparkassen</p>
            <p>Realitätenvermittlungs-Ges.m.b.H.</p>
            <p>Promenade 9</p>
            <p>4020 Linz</p>
            <p>datenschutz.oberoesterreich (at) sreal.at</p>
          </div>
          <div className="mb-200rem">
            <p>Für Vorarlberg:</p>
            <p>Sparkassen Real Vorarlberg Immobilienvermittlung GmbH</p>
            <p>Sparkassenplatz 1</p>
            <p>6850 Dornbirn</p>
            <p>datenschutz.vorarlberg (at) sreal.at</p>
          </div>
          <p>
            Wenn Sie der Meinung sind, dass die Verarbeitung Ihrer Daten gegen das Datenschutzrecht
            verstößt oder Ihre datenschutzrechtlichen Ansprüche sonst in irgendeiner Weise verletzt
            worden sind, können Sie sich bei der Datenschutzbehörde beschweren.
          </p>
          <p>Diese erreichen Sie unter:</p>
          <div className="mb-200rem">
            <p>Österreichische Datenschutzbehörde</p>
            <p>Barichgasse 40-42</p>
            <p>1030 Wien, Österreich</p>
            <p>Telefon: +43 1 52 152-0</p>
            <p>
              E-Mail: <a href="mailto:dsb@dsb.gv.at">dsb@dsb.gv.at</a>
            </p>
          </div>
        </div>
      </MainContent>
    </>
  );
}

export default Datenschutz;
