import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import ButtonForwardAction from '../Buttons/ButtonForwardAction';
import SatisfactionScale from '../FormFields/SatisfactionScale';
import TextArea from '../FormFields/Textarea';
import IconDocumentAccept from '../Icons/IconDocumentAccept';
import LoadingSpinner from '../LoadingSpinner';
import PageDialog from './PageDialog';
import { createSurveyZufriedenheit } from '../../api/Contact';

import { SATISFACTION_OPTIONS, SATISFACTION_SITEINDEX_MESSAGE1, SATISFACTION_SITEINDEX_MESSAGE2, SATISFACTION_SITEINDEX_Q1, SATISFACTION_SITEINDEX_Q2, SATISFACTION_SITEINDEX_Q3, SATISFACTION_SITEINDEX_Q4, SATISFACTION_SITEINDEX_Q5, SATISFACTION_SITEINDEX_SUCCESS } from '../../constants';

import { setMessage1SatisfactionForm, setMessage2SatisfactionForm, setPageDialogOpenSatisfactionForm, setQ1SatisfactionForm, setQ2SatisfactionForm, setQ3SatisfactionForm, setQ4SatisfactionForm, setQ5SatisfactionForm, setResetFieldsSatisfactionForm, setSiteIndexSatisfactionForm } from '../../reducers/satisfactionForm';
import { setFormIsLoading } from '../../reducers/app';

function PageDialogSatisfaction() {
  let currentSatisfactionForm = useSelector((state) => state.satisfactionForm);
  const app = useSelector((state) => state.app);

  const dispatch = useDispatch();
  const location = useLocation();

  const handleClose = () => {
    if (app.formIsLoading) {
      return;
    }

    dispatch(setPageDialogOpenSatisfactionForm());
    dispatch(setResetFieldsSatisfactionForm());
  };

  const [error, setError] = useState('');

  const forwardAction = () => {
    if (app.formIsLoading) {
      return;
    }

    setError('');

    let val = '';
    let nextSiteIndex = '';
    switch (currentSatisfactionForm.siteIndex) {
      case SATISFACTION_SITEINDEX_Q1:
        val = currentSatisfactionForm.requestBody.q1;
        nextSiteIndex = SATISFACTION_SITEINDEX_Q2;
        break;
      case SATISFACTION_SITEINDEX_Q2:
        val = currentSatisfactionForm.requestBody.q2;
        nextSiteIndex = SATISFACTION_SITEINDEX_Q3;
        break;
      case SATISFACTION_SITEINDEX_Q3:
        val = currentSatisfactionForm.requestBody.q3;
        nextSiteIndex = SATISFACTION_SITEINDEX_Q4;
        break;
      case SATISFACTION_SITEINDEX_Q4:
        val = currentSatisfactionForm.requestBody.q4;
        nextSiteIndex = SATISFACTION_SITEINDEX_Q5;
        break;
      case SATISFACTION_SITEINDEX_Q5:
        val = currentSatisfactionForm.requestBody.q5;
        nextSiteIndex = SATISFACTION_SITEINDEX_MESSAGE1;
        break;
      case SATISFACTION_SITEINDEX_MESSAGE1:
        val = currentSatisfactionForm.requestBody.message1;
        nextSiteIndex = SATISFACTION_SITEINDEX_MESSAGE2;
        break;
      case SATISFACTION_SITEINDEX_MESSAGE2:
        val = currentSatisfactionForm.requestBody.message2;
        nextSiteIndex = SATISFACTION_SITEINDEX_SUCCESS;
        break;

      default:
        break;
    }

    val = val.text;

    if (!val && (currentSatisfactionForm.siteIndex !== SATISFACTION_SITEINDEX_MESSAGE1 && currentSatisfactionForm.siteIndex !== SATISFACTION_SITEINDEX_MESSAGE2)) {
      setError('Bitte wählen Sie eine Option aus.');
    } else if (nextSiteIndex === SATISFACTION_SITEINDEX_SUCCESS) {
      dispatch(setFormIsLoading(true));
      createSurveyZufriedenheit(currentSatisfactionForm.requestBody);
      localStorage.setItem('mysreal-satisfaction-isset', true);
      dispatch(setFormIsLoading(false));
      dispatch(setSiteIndexSatisfactionForm(SATISFACTION_SITEINDEX_SUCCESS));
    } else {
      dispatch(setSiteIndexSatisfactionForm(nextSiteIndex));
    }
  };

  const handleBack = () => {
    if (currentSatisfactionForm.siteIndex === SATISFACTION_SITEINDEX_Q1) {
      handleClose();
    } else {
      let newSiteIndex = '';
      switch (currentSatisfactionForm.siteIndex) {
        case SATISFACTION_SITEINDEX_Q2:
          newSiteIndex = SATISFACTION_SITEINDEX_Q1;
          break;
        case SATISFACTION_SITEINDEX_Q3:
          newSiteIndex = SATISFACTION_SITEINDEX_Q2;
          break;
        case SATISFACTION_SITEINDEX_Q4:
          newSiteIndex = SATISFACTION_SITEINDEX_Q3;
          break;
        case SATISFACTION_SITEINDEX_Q5:
          newSiteIndex = SATISFACTION_SITEINDEX_Q4;
          break;
        case SATISFACTION_SITEINDEX_MESSAGE1:
          newSiteIndex = SATISFACTION_SITEINDEX_Q5;
          break;
        case SATISFACTION_SITEINDEX_MESSAGE2:
          newSiteIndex = SATISFACTION_SITEINDEX_MESSAGE1;
          break;
        case SATISFACTION_SITEINDEX_SUCCESS:
          newSiteIndex = SATISFACTION_SITEINDEX_MESSAGE2;
          break;
        default:
          break;
      }

      if (newSiteIndex !== '') {
        dispatch(setSiteIndexSatisfactionForm(newSiteIndex));
      }
    }
  };

  useEffect(() => {
    if (location.hash && location.hash !== '') {
      let hash = location.hash.replace('#', '');

      // check hash
      if (hash !== '') {
        if (hash === SATISFACTION_SITEINDEX_Q1) {
          dispatch(setSiteIndexSatisfactionForm(SATISFACTION_SITEINDEX_Q1));
          dispatch(setPageDialogOpenSatisfactionForm(true));
        }
      }
    }
  }, []);

  const handleGetValue = (index) => {
    let val = {};
    if (SATISFACTION_OPTIONS[index]) {
      val = { index: index, text: SATISFACTION_OPTIONS[index] };
    }
    return val;
  };
  const formType = 'satisfaction';
  return (
    <PageDialog open={currentSatisfactionForm.pageDialogOpen} headline={currentSatisfactionForm.headline} handleClose={handleClose} handleBack={handleBack} showBackArrow={currentSatisfactionForm.siteIndex !== SATISFACTION_SITEINDEX_Q1 && currentSatisfactionForm.siteIndex !== SATISFACTION_SITEINDEX_SUCCESS ? true : false}>
      <div className="p-100rem">
        {currentSatisfactionForm.siteIndex === SATISFACTION_SITEINDEX_Q1 ? (
          <>
            <h2>Frage 1</h2>
            <p className="mb-300rem lh-140">{currentSatisfactionForm.requestBody.q1.q}</p>

            <SatisfactionScale detailsColor="#fff" selectedValue={currentSatisfactionForm.requestBody.q1.index} formType={formType} callback={(index) => dispatch(setQ1SatisfactionForm(handleGetValue(index)))} />

            <div className="button-panel">
              <ButtonForwardAction formType={formType} forwardAction={forwardAction} buttonStyle={'blue'} buttonText={'Weiter'} />
            </div>
            {error && <p className="form__fielderror">{error}</p>}
          </>
        ) : currentSatisfactionForm.siteIndex === SATISFACTION_SITEINDEX_Q2 ? (
          <>
            <h2>Frage 2</h2>
            <p className="mb-300rem lh-140">{currentSatisfactionForm.requestBody.q2.q}</p>

            <SatisfactionScale detailsColor="#fff" selectedValue={currentSatisfactionForm.requestBody.q2.index} formType={formType} callback={(index) => dispatch(setQ2SatisfactionForm(handleGetValue(index)))} />

            <div className="button-panel align-items-center">
              <ButtonForwardAction formType={formType} forwardAction={forwardAction} buttonStyle={'blue'} buttonText={'Weiter'} />
              <span className="textlink mb-10 ml-100rem" onClick={handleBack}>
                Zurück
              </span>
            </div>
            {error && <p className="form__fielderror">{error}</p>}
          </>
        ) : currentSatisfactionForm.siteIndex === SATISFACTION_SITEINDEX_Q3 ? (
          <>
            <h2>Frage 3</h2>
            <p className="mb-300rem lh-140">{currentSatisfactionForm.requestBody.q3.q}</p>

            <SatisfactionScale detailsColor="#fff" selectedValue={currentSatisfactionForm.requestBody.q3.index} formType={formType} callback={(index) => dispatch(setQ3SatisfactionForm(handleGetValue(index)))} />

            <div className="button-panel align-items-center">
              <ButtonForwardAction formType={formType} forwardAction={forwardAction} buttonStyle={'blue'} buttonText={'Weiter'} />
              <span className="textlink mb-10 ml-100rem" onClick={handleBack}>
                Zurück
              </span>
            </div>
            {error && <p className="form__fielderror">{error}</p>}
          </>
        ) : currentSatisfactionForm.siteIndex === SATISFACTION_SITEINDEX_Q4 ? (
          <>
            <h2>Frage 4</h2>
            <p className="mb-300rem lh-140">{currentSatisfactionForm.requestBody.q4.q}</p>

            <SatisfactionScale detailsColor="#fff" selectedValue={currentSatisfactionForm.requestBody.q4.index} formType={formType} callback={(index) => dispatch(setQ4SatisfactionForm(handleGetValue(index)))} />

            <div className="button-panel align-items-center">
              <ButtonForwardAction formType={formType} forwardAction={forwardAction} buttonStyle={'blue'} buttonText={'Weiter'} />
              <span className="textlink mb-10 ml-100rem" onClick={handleBack}>
                Zurück
              </span>
            </div>
            {error && <p className="form__fielderror">{error}</p>}
          </>
        ) : currentSatisfactionForm.siteIndex === SATISFACTION_SITEINDEX_Q5 ? (
          <>
            <h2>Frage 5</h2>
            <p className="mb-300rem lh-140">{currentSatisfactionForm.requestBody.q5.q}</p>

            <SatisfactionScale detailsColor="#fff" selectedValue={currentSatisfactionForm.requestBody.q5.index} formType={formType} callback={(index) => dispatch(setQ5SatisfactionForm(handleGetValue(index)))} />

            <div className="button-panel align-items-center">
              <ButtonForwardAction formType={formType} forwardAction={forwardAction} buttonStyle={'blue'} buttonText={'Weiter'} />
              <span className="textlink mb-10 ml-100rem" onClick={handleBack}>
                Zurück
              </span>
            </div>
            {error && <p className="form__fielderror">{error}</p>}
          </>
        ) : currentSatisfactionForm.siteIndex === SATISFACTION_SITEINDEX_MESSAGE1 ? (
          <>
            <h2>Was hat Ihnen an my-sreal.at besonders gefallen?</h2>
            <h3 className="form__title pt-200rem">Ihre Nachricht (optional)</h3>
            <textarea onChange={(e) =>  dispatch(setMessage1SatisfactionForm((e.target.value)))} value={currentSatisfactionForm.requestBody.message1} />

            <LoadingSpinner />

            <div className="button-panel align-items-center">
              <ButtonForwardAction formType={formType} buttonText={'Weiter'} buttonStyle={'blue'} forwardAction={forwardAction} />
              <span className="textlink mb-10 ml-100rem" onClick={handleBack}>
                Zurück
              </span>
            </div>
            {error && <p className="form__fielderror">{error}</p>}
          </>
        ) : currentSatisfactionForm.siteIndex === SATISFACTION_SITEINDEX_MESSAGE2 ? (
          <>
            <h2>Was würden Sie gerne verbessert sehen?</h2>
            <h3 className="form__title pt-200rem">Ihre Nachricht (optional)</h3>
            <textarea onChange={(e) =>  dispatch(setMessage2SatisfactionForm((e.target.value)))} value={currentSatisfactionForm.requestBody.message2} />

            <LoadingSpinner />

            <div className="button-panel align-items-center">
              <ButtonForwardAction formType={formType} buttonText={'Abschließen'} buttonStyle={'blue'} forwardAction={forwardAction} />
              <span className="textlink mb-10 ml-100rem" onClick={handleBack}>
                Zurück
              </span>
            </div>
            {error && <p className="form__fielderror">{error}</p>}
          </>
        ) : currentSatisfactionForm.siteIndex === SATISFACTION_SITEINDEX_SUCCESS ? (
          <>
            <div className="d-flex flex-col align-items-center pt-200rem pb-300rem">
              <IconDocumentAccept color={'#1e3163'} />
            </div>

            <h2>Vielen Dank für Ihre Teilnahme an unserer Zufriedenheitsbefragung.</h2>
            <p className="lh-140 pb-100rem">Wir schätzen Ihr Feedback sehr und werden es nutzen, um unseren Service weiter zu verbessern.</p>

            <div className="button-panel pt-200rem">
              <button className="button button--blue-outline" onClick={handleClose}>
                Schließen
              </button>
            </div>
          </>
        ) : null}
      </div>
    </PageDialog>
  );
}

export default PageDialogSatisfaction;
