//*React
import { useDispatch, useSelector } from 'react-redux';
//*API
import { resendConfirm } from '../../api/Onboarding';
//*Components
import FormEmailSent from './Onboarding/FormEmailSent';
import FormSetPassword from './Onboarding/FormSetPassword';
import FormSetPasswordSuccess from './Onboarding/FormSetPasswordSuccess';
import FormToken from './Onboarding/FormToken';
import RegisterFormConfirmError from './Onboarding/RegisterFormConfirmError';
import RegisterFormEmailConfirm from './Onboarding/RegisterFormEmailConfirm';
import RegisterFormFastFertig from './Onboarding/RegisterFormFastFertig';
import RegisterFormLinkAbgelaufen from './Onboarding/RegisterFormLinkAbgelaufen';
import RegisterFormMain from './Onboarding/RegisterFormMain';
import RegisterFormSucces from './Onboarding/RegisterFormSucces';
import RegisterFormUserExists from './Onboarding/RegisterFormUserExists';
import RegisterFormChangePhonenumber from './Onboarding/RegisterFormChangePhonenumber';

//*Constants
import {
  DIREKTREGFORM_SITEINDEX_LOGINSUCCESS,
  DIREKTREGFORM_SITEINDEX_PW,
  DIREKTREGFORM_SITEINDEX_START,
  DIREKTREGFORM_SITEINDEX_SUCCESSTOKEN,
  REGFORM_SITEINDEX_ALMOSTTHERE,
  REGFORM_SITEINDEX_CHANGEPHONENUMBER,
  REGFORM_SITEINDEX_CONFIRMERROR,
  REGFORM_SITEINDEX_EMAIL,
  REGFORM_SITEINDEX_EMAIL_BESTAETIGEN,
  REGFORM_SITEINDEX_LINKEXPIRED,
  REGFORM_SITEINDEX_LOGINSUCCESS,
  REGFORM_SITEINDEX_PW,
  REGFORM_SITEINDEX_PWSUCCESS,
  REGFORM_SITEINDEX_START,
  REGFORM_SITEINDEX_SUCCESSTOKEN,
  REGFORM_SITEINDEX_USEREXISTS,
} from '../../constants';
//*Reducers
import { setFormIsLoading } from '../../reducers/app';
import { setSiteIndexRegisterForm } from '../../reducers/registerForm';


function RegisterForm({ handleClose }) {
  let registerForm = useSelector((state) => state.registerForm);
  let app = useSelector((state) => state.app);
  const dispatch = useDispatch();

  const resendConfirmationLink = async (email) => {
    if (app.formIsLoading) {
      return;
    }
    dispatch(setFormIsLoading(true));

    try {
      await resendConfirm(email);
      dispatch(setFormIsLoading(false));
      dispatch(setSiteIndexRegisterForm(REGFORM_SITEINDEX_EMAIL));
    } catch (e) {
      dispatch(setFormIsLoading(false));
      dispatch(setSiteIndexRegisterForm(REGFORM_SITEINDEX_CONFIRMERROR));
    }
  };

  return (
    <>
      <div className="registerForm p-100rem">
        {(registerForm.siteIndex === REGFORM_SITEINDEX_START || registerForm.siteIndex === DIREKTREGFORM_SITEINDEX_START) ? (
          <RegisterFormMain />
        ) : (registerForm.siteIndex === REGFORM_SITEINDEX_PW || registerForm.siteIndex === DIREKTREGFORM_SITEINDEX_PW) ? (
          <FormSetPassword handleClose={handleClose} />
        ) : registerForm.siteIndex === REGFORM_SITEINDEX_PWSUCCESS ? (
          <FormSetPasswordSuccess handleClose={handleClose} />
        ) : registerForm.siteIndex === REGFORM_SITEINDEX_EMAIL ? (
          <FormEmailSent handleClose={handleClose} />
        ) : registerForm.siteIndex === REGFORM_SITEINDEX_EMAIL_BESTAETIGEN ? (
          <RegisterFormEmailConfirm resendConfirmationLink={resendConfirmationLink} />
        ) : registerForm.siteIndex === REGFORM_SITEINDEX_ALMOSTTHERE ? (
          <RegisterFormFastFertig />
        ) : registerForm.siteIndex === REGFORM_SITEINDEX_LINKEXPIRED ? (
          <RegisterFormLinkAbgelaufen />
        ) : (registerForm.siteIndex === REGFORM_SITEINDEX_SUCCESSTOKEN || registerForm.siteIndex === DIREKTREGFORM_SITEINDEX_SUCCESSTOKEN) ? (
          <FormToken formType="registerForm" handleClose={handleClose} />
        ) : registerForm.siteIndex === REGFORM_SITEINDEX_CHANGEPHONENUMBER ? (
          <RegisterFormChangePhonenumber />
        ) : (registerForm.siteIndex === REGFORM_SITEINDEX_LOGINSUCCESS || registerForm.siteIndex === DIREKTREGFORM_SITEINDEX_LOGINSUCCESS) ? (
          <RegisterFormSucces handleClose={handleClose} />
        ) : registerForm.siteIndex === REGFORM_SITEINDEX_CONFIRMERROR ? (
          <RegisterFormConfirmError handleClose={handleClose} />
        ) : registerForm.siteIndex === REGFORM_SITEINDEX_USEREXISTS ? (
          <RegisterFormUserExists handleClose={handleClose} />
        ) : null}
      </div>
    </>
  );
}

export default RegisterForm;
