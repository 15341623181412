function IconAnnouncmentCircle({ cssClasses, color }) {
  return (
    <svg className={cssClasses} id="Gruppe_13735" data-name="Gruppe 13735" xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 45 45">
      <circle id="Ellipse_1604" data-name="Ellipse 1604" cx="22.5" cy="22.5" r="22.5" fill="#02a3a4" />
      <path
        id="Pfad_8713"
        data-name="Pfad 8713"
        d="M27.563.566A3.231,3.231,0,0,0,24.795.145L9.087,5.381H6.139a6.18,6.18,0,0,0-.963,12.277V18.8a6.19,6.19,0,0,0,6.2,6.2,1.023,1.023,0,1,0,0-2.046A4.161,4.161,0,0,1,7.222,18.8V17.778H9.148l15.707,5.236a2.726,2.726,0,0,0,.963.181,2.84,2.84,0,0,0,1.805-.6,3.221,3.221,0,0,0,1.324-2.528V3.094A3.189,3.189,0,0,0,27.563.566M2.046,11.58A4.161,4.161,0,0,1,6.2,7.427H8.245v8.245H6.2A4.11,4.11,0,0,1,2.046,11.58m24.795,8.486a1.053,1.053,0,0,1-.421.843.96.96,0,0,1-.9.12L10.351,15.973V7.186L25.517,2.131a.96.96,0,0,1,.9.12,1.053,1.053,0,0,1,.421.843Z"
        transform="translate(8 10)"
        fill={color || '#fff'}
      />
    </svg>
  );
}

export default IconAnnouncmentCircle;
