import React, { useEffect, useState } from 'react';
import '../../styles/fileupload.scss';
import IconDownload from '../Icons/IconDownload';
import IconDocumentDone from '../Icons/IconDocumentDone';

function FileUpload({ setFile, currentFile }) {
  const [dragOver, setDragOver] = useState(false);
  const [fileError, setFileError] = useState(null);

  const handleDrop = (e) => {
    e.preventDefault();
    const dt = e.dataTransfer;
    const files = dt.files;
    handleFiles(files);
    setDragOver(false);
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    setDragOver(true);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setDragOver(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    const relatedTarget = e.relatedTarget;
    if (!relatedTarget || (relatedTarget !== e.currentTarget && !e.currentTarget.contains(relatedTarget))) {
      setDragOver(false);
    }
  };

  const handleFiles = (files) => {
    setFileError(null);
    const file = files[0];
    const fileType = file.type;
    const fileSize = file.size / (1024 * 1024); // Size in MB
    if (!['image/jpeg', 'image/jpg', 'image/png', 'application/pdf'].includes(fileType)) {
      setFile(null);
      setFileError('Ungültiger Dateityp. Zulässig sind JPEG, JPG, PNG oder PDF.');
    } else if (fileSize > 10) {
      setFile(null);
      setFileError('Die Datei darf maximal 10 MB groß sein.');
    } else {
      setFile(file);
      setFileError(null);
    }
  };

  const handleFileSelect = (e) => {
    setFileError(null);
    setFile(null);
    const files = e.target.files;
    handleFiles(files);
  };

  useEffect(() => {
    if (currentFile) {
      console.log('currentFile', currentFile);
    }
  }, [currentFile]);

  return (
    <div>
      <div
        className={`fileUploadContainer ${dragOver ? 'dragOver' : ''}`}
        onDragEnter={handleDragEnter}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        onClick={() => document.getElementById('fileInput').click()}
      >
        {currentFile ? (
          <div className="fileUploadInner">
            <div className="fileUploadIconText">
              <IconDocumentDone color={'#2870ed'} />
              <span>{currentFile.name}</span>
            </div>
            <p>Zum Ersetzen neue Datei in dieses Feld ziehen oder hier klicken</p>
          </div>
        ) : (
          <div className="fileUploadInner">
            <IconDownload color={'#2870ed'} />
            <p>Datei in dieses Feld ziehen oder hier klicken</p>
          </div>
        )}

        <input type="file" id="fileInput" style={{ display: 'none' }} onChange={handleFileSelect} />
      </div>
      {fileError ? (
        <div className="fileUploadErrorContainer">
          <p className="form__fielderror mb-0">{fileError}</p>
        </div>
      ) : (
        <div className="fileUploadErrorContainer">
          <p className="form--info mt-50rem mb-0">Gültige Dateiformate: JPEG, JPG, PNG oder PDF bis 10 MB.</p>
        </div>
      )}
      {currentFile && (
        <div className="deleteFileContainer">
          <span className="cursor-pointer hover-underline" onClick={() => setFile(null)}>
            Datei löschen
          </span>
        </div>
      )}
    </div>
  );
}

export default FileUpload;
