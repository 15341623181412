function IconDocument({ color, cssClasses, scale }) {
  return (
    <svg
      id="Gruppe_15141"
      className={cssClasses}
      data-name="Gruppe 15141"
      xmlns="http://www.w3.org/2000/svg"
      width="21.465"
      height="25"
      viewBox="0 0 21.465 25"
      style={{ transform: `scale(${scale})` }}
    >
      <g id="Gruppe_15139" data-name="Gruppe 15139">
        <path
          id="Pfad_8709"
          fill={color}
          data-name="Pfad 8709"
          d="M21.242,9.186,12.265.261A.864.864,0,0,0,11.639,0H2.714A2.684,2.684,0,0,0,0,2.662V22.338A2.673,2.673,0,0,0,2.662,25H18.789a2.673,2.673,0,0,0,2.662-2.662V9.812a.767.767,0,0,0-.209-.626m-1.566,13.2a.915.915,0,0,1-.887.887H2.714a.915.915,0,0,1-.887-.887V2.662a.915.915,0,0,1,.887-.887h8.559l8.4,8.4Z"
        />
        <path
          id="Pfad_8710"
          fill={color}
          data-name="Pfad 8710"
          d="M11.187,15.475h3.6a.887.887,0,1,0,0-1.775h-3.6a.887.887,0,1,0,0,1.775M10.3,19.963a.915.915,0,0,0,.887.887h8.925a.887.887,0,0,0,0-1.775H11.187a.915.915,0,0,0-.887.887m9.864,4.489H11.187a.887.887,0,0,0,0,1.775h8.925A.915.915,0,0,0,21,25.339a.87.87,0,0,0-.835-.887"
          transform="translate(-4.924 -6.55)"
        />
      </g>
    </svg>
  );
}

export default IconDocument;
