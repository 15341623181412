import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import AktuellesNachrichten from '../components/Aktuelles/AktuellesNachrichten';
import Header from '../components/Header/Header';
import Linkitem from '../components/Linkitems/Linkitem';
import ImmoDriveCard from '../components/Cards/ImmoDriveCard';
import ImmoDriveEmptyCard from '../components/Cards/ImmoDriveEmptyCard';

import headerImageMobile from '../assets/images/mysreal_HerotemplateMobil_593x950px_immodrive_1_0.jpg';
import headerImage from '../assets/images/mysreal_Herotemplate_1920x800px_immodrive_1_0.jpg';
import ProgressDokCard from '../components/Cards/ProgressDokCard';
import RecommendationCard from '../components/Cards/RecommendationCard';
import SatisfactionCard from '../components/Cards/SatisfactionCard';
import IconListElementVorteilBlock from '../components/ContentBlocks/IconListElementVorteilBlock';
import HeaderCtaButtons from '../components/Header/HeaderCtaButtons';
import MetaTags from '../components/MetaTags';

import IconListWrapper from '../components/IconList/IconListWrapper';
import MainContent from '../components/MainContent';
import NewsTeaserCards from '../components/News/NewsTeaserCards';

// ICONS
import IconHouse from '../components/Icons/IconHouse';

import { anliegenOptions } from '../components/FormFields/util/formOptions';
import { CONTACTFORM_ANLIEGEN_VERKAUF, ICONLISTELEMENT_SREALIMMOBLOG_LABEL, ICONLISTELEMENT_SREALMAGAZIN_LABEL, ICONLISTELEMENT_SREALNEWSLETTER_LABEL } from '../constants';

// STORE
import CardGridPlaceholderElement from '../components/Cards/CardGridPlaceholderElement';
import InfoDialogKaufvertrag from '../components/Dialogs/InfoDialogKaufvertrag';
import IconLoadingSpinner from '../components/Icons/IconLoadingSpinner';
import { useHandleMessages } from '../hooks/messages/useHandleMessages';
import { useHandleScreenResize } from '../hooks/app/useHandleScreenResize';
import { useHandleUserRealtyActive } from '../hooks/user/useHandleUserRealtyActive';
import { setPageDialogOpenContactForm, setReasonContactForm } from '../reducers/contactInformation';

function KaeuferPhase4() {
  const dispatch = useDispatch();

  const app = useSelector((state) => state.app);

  const [openInfoDialogKV, setOpenInfoDialogKV] = useState(false);
  const handleOpenInfoDialogKV = () => {
    let newVal = !openInfoDialogKV;
    setOpenInfoDialogKV(newVal);
  };

  const { isMobile } = useHandleScreenResize();
  const { files } = useHandleUserRealtyActive();
  const { threads, threadsLoading, loadMessages } = useHandleMessages();

  useEffect(() => {
    loadMessages();
  }, []);

  const handleWeitereImmos = () => {
    dispatch(setReasonContactForm(anliegenOptions[CONTACTFORM_ANLIEGEN_VERKAUF]));
    dispatch(setPageDialogOpenContactForm(true));
  };

  const headerCtabuttons = (
    <HeaderCtaButtons>
      <Link to={app.root + '/immo-drive'} title="button button-blue">
        <button className="button button--blue">immo-drive anzeigen</button>
      </Link>
    </HeaderCtaButtons>
  );

  return (
    <>
      <MetaTags title={'Kaufvertrag | Die Online-Plattform für Ihre Immobilie'} imageName={'mysreal_hero_dashboard-kauf.jpg'} />

      <Header cssClasses="header--default header--withimage">
        <img src={!isMobile ? headerImage : headerImageMobile} alt="Foto: Der beste Partner für Ihre Immobilie" className="header__image" />
        <div className="header__content-wrapper header__content-wrapper--bottom" id="header-dashboard4">
          <h1>
            {files.length} {files.length === 1 ? 'Dokument' : 'Dokumente'}
          </h1>
          <p className="font-weight-600 font-120 mb-100rem">Sind für Sie in immo-drive gespeichert.</p>
          <p>Sichern Sie jetzt alle Dokumente zu Ihrer Immobilie gesammelt als ZIP.</p>

          {/* cta buttons desktop-version */}
          <div className="header__ctabuttons-wrapper">{headerCtabuttons}</div>
        </div>

        {/* cta buttons mobile-version fixed */}
        {headerCtabuttons}
      </Header>

      <MainContent>
        <div className="w-desktop--center">
          <h2 className="mb-200rem">Nachrichten</h2>
          <div className="elements3cols pb-200rem">
            {threadsLoading && threads?.length === 0 ? (
              <p>
                <IconLoadingSpinner color={'#5d5d5d'} />
                <span className="ml-10">Ihre Nachrichten werden geladen.</span>
              </p>
            ) : (
              <>
                {threads?.length === 0 ? (
                  <p>Sie haben derzeit noch keine Nachrichten empfangen. Sobald eine Makler:in mit Ihnen in Kontakt ist, finden Sie hier Ihre Konversationen.</p>
                ) : (
                  <>
                    {threads.map((item) => {
                      return <AktuellesNachrichten thread={item.chatList} cssClasses={'elements3cols__col linkitem-green '} reloadMessagesCallback={async () => loadMessages()} />;
                    })}

                    <CardGridPlaceholderElement array={threads} type="linkitem" cssClasses="elements3cols__col" />
                  </>
                )}
              </>
            )}
          </div>
        </div>

        <div className={!isMobile ? 'gray-background' : ''}>
          <div className="w-desktop--center">
            <h2 className="mt-0 pb-100rem">Nach dem Kauf</h2>

            {!localStorage.getItem('mysreal-satisfaction-isset') || localStorage.getItem('mysreal-satisfaction-isset' === false) ? (
              <>
                <div className={'elements2cols'}>
                  <SatisfactionCard cssClasses={'elements2cols__col'} />
                  <RecommendationCard cssClasses={'elements2cols__col'} />
                </div>
                <div className="w-desktop--center">
                  <h2>immo-drive</h2>
                  {files.length > 0 ? <ImmoDriveCard files={files} /> : <ImmoDriveEmptyCard />}
                </div>
              </>
            ) : (
              <>
                <div className={'elements2cols'}>
                  <RecommendationCard cssClasses={'elements2cols__col'} />
                </div>
                <div className="w-desktop--center">
                  <h2>immo-drive</h2>
                  {files.length > 0 ? <ImmoDriveCard files={files} /> : <ImmoDriveEmptyCard />}
                </div>
              </>
            )}
          </div>
        </div>

        <div className={'p-100rem w-desktop--center'}>
          <h2>Sie besitzen eine weitere Immobilie?</h2>
          <Linkitem handleLink={handleWeitereImmos} icon={<IconHouse />} headline="Weitere Immobilie verkaufen" text="Unsere Immobilienexpert:innen beraten Sie gerne" />

          <div className="pt-300rem">
            <h2 className="pb-100rem">Die s REAL Vorteilswelt</h2>
            <IconListWrapper>
              <IconListElementVorteilBlock label={ICONLISTELEMENT_SREALNEWSLETTER_LABEL} />
              <IconListElementVorteilBlock label={ICONLISTELEMENT_SREALMAGAZIN_LABEL} />
              <IconListElementVorteilBlock label={ICONLISTELEMENT_SREALIMMOBLOG_LABEL} />
            </IconListWrapper>
          </div>
        </div>

        <NewsTeaserCards cssClasses="w-desktop--slider mt-300rem" />

        <InfoDialogKaufvertrag open={openInfoDialogKV} handleClose={handleOpenInfoDialogKV} />
      </MainContent>
    </>
  );
}

export default KaeuferPhase4;
