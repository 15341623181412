function IconVKInteressenten({ color, cssClasses }) {
  return (
    <svg
      className={cssClasses}
      xmlns="http://www.w3.org/2000/svg"
      width="41"
      height="42"
      viewBox="0 0 41 42"
    >
      <defs>
        <clipPath id="clip-pathvkinteressenten">
          <rect
            id="Rechteck_3025"
            data-name="Rechteck 3025"
            width="41"
            height="42"
            transform="translate(-0.148 -0.166)"
            fill={color}
          />
        </clipPath>
      </defs>
      <g id="Gruppe_6403" data-name="Gruppe 6403" transform="translate(0.148 0.166)">
        <g
          data-name="Gruppe 6402"
          transform="translate(0 0)"
          clipPath="url(#clip-pathvkinteressenten)"
        >
          <path
            data-name="Pfad 6350"
            d="M29.69,11.792l-3.752-1.073V9.406a6.657,6.657,0,0,0,2.588-5.332c-1.6.405-4.013.321-5.289-.721a7.787,7.787,0,0,1-6.759.129c-.016.195-.028.392-.028.592a6.657,6.657,0,0,0,2.588,5.332v1.313l-3.755,1.073A3.289,3.289,0,0,0,13,14.82v1.725H31.976V14.82a3.288,3.288,0,0,0-2.286-3.027"
            transform="translate(9.05 2.009)"
            fill={color}
          />
          <path
            data-name="Pfad 6351"
            d="M21.121,4a.863.863,0,0,1,.735-.245.873.873,0,0,1,.635.447c.523.971,3.131.9,4.192.576A6.135,6.135,0,0,0,20.869,0a6.074,6.074,0,0,0-5.636,4.188c1.708.971,4.74.954,5.888-.191"
            transform="translate(10.669 -0.422)"
            fill={color}
          />
          <path
            data-name="Pfad 6352"
            d="M8.733,17.19c1.708.969,4.74.954,5.888-.191a.86.86,0,0,1,1.37.2c.523.968,3.133.9,4.192.576A6.135,6.135,0,0,0,14.369,13a6.076,6.076,0,0,0-5.636,4.19"
            transform="translate(5.956 9.004)"
            fill={color}
          />
          <path
            data-name="Pfad 6353"
            d="M23.19,24.792l-3.752-1.073V22.406a6.657,6.657,0,0,0,2.588-5.332c-1.6.405-4.013.321-5.289-.721a7.787,7.787,0,0,1-6.759.129c-.016.195-.028.392-.028.592a6.657,6.657,0,0,0,2.588,5.332v1.313L8.782,24.792A3.289,3.289,0,0,0,6.5,27.82v1.725H25.476V27.82a3.288,3.288,0,0,0-2.286-3.027"
            transform="translate(4.337 11.435)"
            fill={color}
          />
          <path
            data-name="Pfad 6354"
            d="M18.976,14.819a3.288,3.288,0,0,0-2.286-3.027l-3.752-1.073V9.406a6.657,6.657,0,0,0,2.588-5.332c-1.6.405-4.013.321-5.289-.719a7.793,7.793,0,0,1-6.759.128c-.016.195-.028.392-.028.592A6.657,6.657,0,0,0,6.038,9.406v1.313L2.282,11.791A3.29,3.29,0,0,0,0,14.819v1.725H18.976Z"
            transform="translate(-0.376 2.01)"
            fill={color}
          />
          <path
            data-name="Pfad 6355"
            d="M8.122,4a.861.861,0,0,1,.733-.245A.873.873,0,0,1,9.49,4.2c.523.971,3.131.9,4.192.576A6.133,6.133,0,0,0,7.868,0,6.074,6.074,0,0,0,2.234,4.188C3.942,5.16,6.973,5.142,8.122,4"
            transform="translate(1.244 -0.422)"
            fill={color}
          />
          <path
            data-name="Pfad 6356"
            d="M9.284,17.785a.863.863,0,0,0,0-1.22L4.972,12.253a.862.862,0,1,0-1.22,1.22l4.313,4.313a.864.864,0,0,0,1.22,0"
            transform="translate(2.162 8.28)"
            fill={color}
          />
          <path
            data-name="Pfad 6357"
            d="M21.565,12.252l-4.313,4.313a.863.863,0,0,0,1.22,1.221l4.313-4.313a.863.863,0,0,0-1.22-1.221"
            transform="translate(11.95 8.279)"
            fill={color}
          />
        </g>
      </g>
    </svg>
  );
}

export default IconVKInteressenten;
