import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import FormInput from '../../FormFields/FormInput';
import FormCheckbox from '../../FormFields/FormCheckbox';
import LoadingSpinner from '../../LoadingSpinner';
import ButtonForwardAction from '../../Buttons/ButtonForwardAction';

import { validateFields } from '../../FormFields/util/validateFields';
import { directRegPending, fetchErrorText } from '../../FormFields/util/formTexts';
import { LOGINFORM_SITEINDEX_SUCCESSTOKEN, LOGINFORM_SITEINDEX_PWFORGET } from '../../../constants';

// STORE
import { setFormIsLoading } from '../../../reducers/app';
import {
  setSiteIndexLoginForm,
  setVerificationCode,
  setEmailLoginForm,
  setPasswordLoginForm,
  setPhoneNumberLoginForm,
} from '../../../reducers/loginForm';
import { setPageDialogOpenRegisterForm } from '../../../reducers/registerForm';

// API
import { login } from '../../../api/Onboarding';

function LoginFormMain({ handleClose }) {
  let app = useSelector((state) => state.app);
  let loginForm = useSelector((state) => state.loginForm);
  let currentFormValidators = useSelector((state) => state.formValidators);

  const dispatch = useDispatch();
  const [error, setError] = useState('');

  const forwardAction = async () => {
    setError('');

    if (app.formIsLoading) {
      return;
    }

    let validationResult = validateFields(
      {
        email: loginForm.requestBody.email,
        passwordLogin: loginForm.requestBody.password,
      },
      dispatch,
    );

    if (validationResult) {
      dispatch(setFormIsLoading(true));
      try {
        const res = await login(loginForm.requestBody);
				const [phoneNumber, verificationCode] = res.split(',');
        dispatch(setFormIsLoading(false));
        dispatch(setVerificationCode(verificationCode));
				dispatch(setPhoneNumberLoginForm(phoneNumber));

        dispatch(setSiteIndexLoginForm(LOGINFORM_SITEINDEX_SUCCESSTOKEN));
        localStorage.removeItem('tokenTimerStart');
      } catch (e) {
        dispatch(setFormIsLoading(false));
				let errDetail = e.response?.data?.detail;
        if (errDetail === 'Username or password wrong') {
          setError('E-mail oder Passwort stimmen nicht überein. Benutzer konnte nicht gefunden werden.',);
				} else if (errDetail === 'direct_registration_pending') {
					setError(directRegPending);
				} else {
					setError(fetchErrorText);
				}
			}
		}
	};

  const handleSwitchToPasswordForm = () => {
    dispatch(setEmailLoginForm(''));
    dispatch(setPasswordLoginForm(''));
    dispatch(setSiteIndexLoginForm(LOGINFORM_SITEINDEX_PWFORGET));
  };

  const handleSwitchToRegisterForm = () => {
    handleClose();
    dispatch(setPageDialogOpenRegisterForm(true));
  };

  const formType = 'loginForm';

  return (
    <>
      <h2 className="pb-200rem">
        Jetzt einloggen
        <br />
        bei my-sreal.at
      </h2>

      <FormInput
        type={'email'}
        id={'inputEmail'}
        label={'E-Mail-Adresse'}
        value={loginForm.requestBody.email}
        error={currentFormValidators.emailError}
        required={true}
        formType={formType}
      />

      <FormInput
        type={'password'}
        id={'inputPassword'}
        label={'Passwort'}
        value={loginForm.requestBody.password}
        error={currentFormValidators.passwordError}
        required={true}
        formType={formType}
        cssClasses={'mb-0'}
      />

      <p
        className="textlink font-80 mt-50rem mb-200rem d-flex"
        onClick={handleSwitchToPasswordForm}
      >
        Passwort vergessen?
      </p>

      <FormCheckbox
        id="inputRememberMe"
        label={'Auf diesem Gerät eingeloggt bleiben'}
        value={loginForm.requestBody.rememberMe}
        formType={formType}
      />

      <p className="font-80 m-0">Sie haben noch kein Konto?</p>
      <span className="textlink font-80" onClick={handleSwitchToRegisterForm}>
        Jetzt für my-sreal.at registrieren
      </span>

      <LoadingSpinner />

      <div className="button-panel pt-200rem">
        <button className="button button--blue-outline" onClick={handleClose}>
          Zurück
        </button>
        <ButtonForwardAction formType={formType} buttonText="Login" forwardAction={forwardAction} />
      </div>

      {error && <p className="form__fielderror">{error}</p>}
    </>
  );
}

export default LoginFormMain;
