import { Line } from 'react-chartjs-2';
import { Chart as ChartJS } from 'chart.js/auto';

// docs
// https://www.chartjs.org/docs/latest/charts/line.html

function LineChart({ chartData, width, height, config }) {
  return <Line data={chartData} width={width} height={height} options={config} />;
}

export default LineChart;
