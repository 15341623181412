import HeaderSmallWithIcon from '../components/Header/HeaderSmallWithIcon';
import MainContent from '../components/MainContent';
import '../styles/impressum-datenschutz.scss';

// ICONS
import IconDocumentOutlined from '../components/Icons/IconDocumentOutlined';
import MetaTags from '../components/MetaTags';

function ImpressumOberoesterreich() {
  return (
    <>
      <MetaTags
        title={'Impressum Oberösterreich | Die Online-Plattform für Ihre Immobilie'}
        description={'my-sreal.at ist eine Plattform der s REAL Immobilienvermittlung GmbH'}
      />
      <HeaderSmallWithIcon
        icon={<IconDocumentOutlined />}
        headlineDesktop="Impressum für Oberösterreich"
        iconActive={true}
      />

      <MainContent cssClasses="w-desktop--center impressum">
        <h1 className="headline--mobile">Impressum für Oberösterreich</h1>
        <h2 className="mb-20 font-120">
          my-sreal.at ist eine Plattform der s REAL Immobilienvermittlung GmbH
        </h2>
        <div>
          <p>
            <span className="font-weight-700">Firma: </span> Real Service für oberösterreichische
            Sparkassen
          </p>
          <p>
            <span className="font-weight-700">Straße: </span>Promenade 9
          </p>
          <p>
            <span className="font-weight-700">PLZ/Ort: </span>A-4020 Linz
          </p>
          <p>
            <span className="font-weight-700">Tel.: </span>05 0100 - 26300
          </p>
          <p>
            <span className="font-weight-700">E-Mail: </span>
            <a href="mailto:info.steiermark@sreal.at">info.oberösterreich@sreal.at</a>
          </p>
          <p>
            <span className="font-weight-700">Firmenbuchnummer: </span>87179f
          </p>
          <p>
            <span className="font-weight-700">Gerichtsstand: </span>
            Linz
          </p>
          <p>
            <span className="font-weight-700">Umsatzsteuer-Identifikationsnummer (UID): </span>
            ATU37210503
          </p>
          <p>
            <span className="font-weight-700">Berufsbezeichnung: </span>
            Beteiligungsverwaltung und Beratung
          </p>
        </div>
        <div className="mt-30">
          <p className="font-weight-700">Kammer/Berufsverband:</p>
          <p>
            Wirtschaftskammer Österreich, Sektion Information und Consulting Fachgruppe der
            Immobilien- und Vermögenstreuhänder
          </p>
          <p>1040 Wien, Wiedner Hauptstraße 63</p>
          <a href="http://www.wko.at" target="_blank">
            http://www.wko.at
          </a>
        </div>
        <div className="mt-30">
          <p className="font-weight-700">Aufsichtsbehörde:</p>
          <p>Magistrat der Landeshauptstadt Linz</p>
        </div>
        <div className="mt-30">
          <p>
            Anwendbare Rechtsvorschriften sind insbesondere das Maklergesetz (MaklerG) sowie die
            Immobilienmaklerverordnung (IMV) aus 1996
          </p>
          <a href="http://www.ris.bka.gv.at" target="_blank">
            http://www.ris.bka.gv.at
          </a>
        </div>
        <div className="mt-30">
          <p>Plattform der EU-Kommission zur Online-Streitbeilegung</p>
          <a href="www.ec.europa.eu/consumers/odr" target="_blank">
            www.ec.europa.eu/consumers/odr
          </a>
        </div>
      </MainContent>
    </>
  );
}

export default ImpressumOberoesterreich;
