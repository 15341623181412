import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import DateTimePicker from '../../FormFields/DateTimePicker';
import ButtonForwardAction from '../../Buttons/ButtonForwardAction';

// STORE
import { setSiteIndexContactForm } from '../../../reducers/contactInformation';
import {
  setAppointmentContactForm,
  setAppointment2ContactForm,
} from '../../../reducers/contactInformation';

import {
  CONTACTFORM_SITEINDEX_MAIN,
  CONTACTFORM_SITEINDEX_IHREIMMOBILIE,
} from '../../../constants';

function ContactTerminwunsch() {
  let currentContactInformation = useSelector((state) => state.contactInformation);
  const dispatch = useDispatch();

  const [error, setError] = useState('');
  const forwardAction = () => {
    setError('');

    let errmsg = '';
    if (currentContactInformation.appointmentDate) {
      if (!currentContactInformation.appointmentTime) {
        errmsg = 'Bitte wählen Sie auch eine Uhrzeit für den Wunschtermin aus.';
      }
    }

    if (currentContactInformation.appointment2Date) {
      if (!currentContactInformation.appointment2Time) {
        errmsg = 'Bitte wählen Sie auch eine Uhrzeit für den Wunschtermin aus.';
      }
    }

    if (errmsg !== '') {
      setError(errmsg);
    } else {
      if (currentContactInformation.appointmentDate && currentContactInformation.appointmentTime) {
        const dateFormatted = moment(currentContactInformation.appointmentDate).format('YYYY-MM-DD',);
        const fullDate = dateFormatted + 'T' + currentContactInformation.appointmentTime;

        dispatch(setAppointmentContactForm(fullDate));
      } else {
        dispatch(setAppointmentContactForm(null));
      }

      if (currentContactInformation.appointment2Date && currentContactInformation.appointment2Time) {
        const dateFormatted = moment(currentContactInformation.appointment2Date).format('YYYY-MM-DD',);
        const fullDate = dateFormatted + 'T' + currentContactInformation.appointment2Time;
        dispatch(setAppointment2ContactForm(fullDate));
      } else {
        dispatch(setAppointment2ContactForm(null));
      }

      dispatch(setSiteIndexContactForm(CONTACTFORM_SITEINDEX_IHREIMMOBILIE));
    }
  };

  const backAction = (e) => {
    e.preventDefault();
    dispatch(setSiteIndexContactForm(CONTACTFORM_SITEINDEX_MAIN));
  };

  const formType = 'terminwunsch';
  return (
    <>
      <h3 className="form__title">Terminwunsch (optional)</h3>
      <p>
        Wir berücksichtigen Ihren Terminwunsch soweit wie möglich. Für eine bessere Planbarkeit
        bitten wir Sie, zwei mögliche Termine auszuwählen.
      </p>

      <h3 className="form__title pt-100rem">Terminvorschlag 1</h3>
      <div className="form__row form__row--double">
        <DateTimePicker
          formType={formType}
          id={'inputAppointment'}
          dateValue={currentContactInformation.appointmentDate}
          timeValue={currentContactInformation.appointmentTime}
        />
      </div>

      <h3 className="form__title pt-100rem">Terminvorschlag 2</h3>
      <div className="form__row form__row--double">
        <DateTimePicker
          formType={formType}
          id={'inputAppointment2'}
          dateValue={currentContactInformation.appointment2Date}
          timeValue={currentContactInformation.appointment2Time}
        />
      </div>

      <div className="button-panel align-items-center">
        <button className="button button--blue-outline" onClick={backAction}>
          Zurück
        </button>
        <ButtonForwardAction
          formType={formType}
          forwardAction={forwardAction}
          buttonStyle={'blue'}
          buttonText={'Weiter'}
        />
        <span
          className="textlink mb-10"
          onClick={() => dispatch(setSiteIndexContactForm(CONTACTFORM_SITEINDEX_IHREIMMOBILIE))}
        >
          Überspringen
        </span>
      </div>
      {error && <p className="form__fielderror">{error}</p>}
    </>
  );
}

export default ContactTerminwunsch;
