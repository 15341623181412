function IconVorteilTransparentesPortal({ color, cssClasses }) {
  return (
    <svg
      className={cssClasses}
      xmlns="http://www.w3.org/2000/svg"
      width="195.762"
      height="152.541"
      viewBox="0 0 195.762 152.541"
    >
      <g id="Gruppe_13320" data-name="Gruppe 13320" transform="translate(0 0)">
        <path
          id="Pfad_8375"
          data-name="Pfad 8375"
          d="M400.032,3307.958H361.426l-15.1,22.662a4.078,4.078,0,0,1-3.393,1.808,3.253,3.253,0,0,1-.375-.014,4.044,4.044,0,0,1-3.377-2.448l-21.509-50.187-28.442,42.683a4.082,4.082,0,0,1-7.186-.749l-13.434-33.582-12.007,18.019a4.1,4.1,0,0,1-3.393,1.808H212.429a4.062,4.062,0,0,1-4.078-4.076v32.625a20.408,20.408,0,0,0,20.392,20.393H383.719a20.414,20.414,0,0,0,20.391-20.393v-32.625A4.067,4.067,0,0,1,400.032,3307.958Z"
          transform="translate(-208.351 -3204.359)"
          fill={color}
        />
        <path
          id="Pfad_8376"
          data-name="Pfad 8376"
          d="M208.351,3329.253a4.08,4.08,0,0,1,4.078-4.08h38.6l15.106-22.649a4.083,4.083,0,0,1,7.178.75l13.434,33.582,28.328-42.489a4.057,4.057,0,0,1,3.768-1.8,4.107,4.107,0,0,1,3.369,2.455l21.5,50.178,12.145-18.2a4.08,4.08,0,0,1,3.393-1.821h40.783a4.085,4.085,0,0,1,4.078,4.08v-53.019H208.351Z"
          transform="translate(-208.351 -3229.732)"
          fill={color}
        />
        <path
          id="Pfad_8427"
          data-name="Pfad 8427"
          d="M596.11,3243.539a23.332,23.332,0,0,0-23.3-23.306H423.656a23.326,23.326,0,0,0-23.3,23.306v13.98H596.11ZM432.977,3248.2a4.66,4.66,0,1,1,4.661-4.659A4.637,4.637,0,0,1,432.977,3248.2Zm18.644,0a4.66,4.66,0,1,1,4.661-4.659A4.637,4.637,0,0,1,451.621,3248.2Zm18.644,0a4.66,4.66,0,1,1,4.661-4.659A4.637,4.637,0,0,1,470.265,3248.2Z"
          transform="translate(-400.348 -3220.233)"
          fill={color}
        />
      </g>
    </svg>
  );
}

export default IconVorteilTransparentesPortal;
