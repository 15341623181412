import { useDispatch } from 'react-redux';
import IconThumbUpDown from '../Icons/IconThumbUpDown';
import Card from './Card';

import { setPageDialogOpenSatisfactionForm, setResetFieldsSatisfactionForm } from '../../reducers/satisfactionForm';

function SatisfactionCard({ cssClasses }) {
  const dispatch = useDispatch();

  const handleOpenSatisfactionForm = () => {
    dispatch(setResetFieldsSatisfactionForm());
    dispatch(setPageDialogOpenSatisfactionForm(true));
  };
  
  return (
    <Card cardStyle={'customContent'} cssClasses={cssClasses}>
      <div className="card__content">
        <h2>Zufrieden mit s REAL?</h2>
        <p className="pb-100rem">
          Wie war Ihre Erfahrung mit my-sreal at? Wir freuen uns über Ihr
          Feedback.
        </p>
        <IconThumbUpDown color={'#1e3163'} cssClasses={'d-flex w-100'} />

        <span className="textlink pt-100rem d-flex w-100" onClick={handleOpenSatisfactionForm}>
          Feedback geben
        </span>
      </div>
    </Card>
  );
}
export default SatisfactionCard;
