function IconVorteilTransparenzEchtzeit({ color, cssClasses }) {
  return (
    <svg
      className={cssClasses}
      xmlns="http://www.w3.org/2000/svg"
      width="239.047"
      height="139.444"
      viewBox="0 0 239.047 139.444"
    >
      <g id="Gruppe_13405" data-name="Gruppe 13405" transform="translate(-65.271 -113.566)">
        <g id="Gruppe_13379" data-name="Gruppe 13379" transform="translate(65.271 113.566)">
          <path
            id="Pfad_8445"
            data-name="Pfad 8445"
            d="M296.1,465.572a34.861,34.861,0,1,0-34.861-34.861A34.906,34.906,0,0,0,296.1,465.572Zm-19.921-39.841a4.976,4.976,0,0,1,4.98,4.98A14.94,14.94,0,1,0,296.1,415.77a4.98,4.98,0,1,1,0-9.96,24.9,24.9,0,1,1-24.9,24.9A4.976,4.976,0,0,1,276.184,425.731Z"
            transform="translate(-176.573 -360.989)"
            fill={color}
          />
          <path
            id="Pfad_8446"
            data-name="Pfad 8446"
            d="M253.826,465.15c2.151,2.719,53.527,66.644,118.448,66.644S488.56,467.869,490.721,465.15a5,5,0,0,0,0-6.175c-2.161-2.729-53.527-66.624-118.448-66.624s-116.3,63.9-118.448,66.624A4.942,4.942,0,0,0,253.826,465.15Zm118.448-47.9a44.821,44.821,0,1,1-44.821,44.821A44.863,44.863,0,0,1,372.274,417.25Z"
            transform="translate(-252.742 -392.35)"
            fill={color}
          />
        </g>
        <ellipse
          id="Ellipse_1615"
          data-name="Ellipse 1615"
          cx="29.5"
          cy="30.5"
          rx="29.5"
          ry="30.5"
          transform="translate(154.965 155)"
          fill={color}
        />
        <path
          id="Pfad_8447"
          data-name="Pfad 8447"
          d="M316.176,2948.748a3.941,3.941,0,0,0-3.936,3.94v25.636h25.587a3.94,3.94,0,0,0,0-7.881h-17.71v-17.755A3.944,3.944,0,0,0,316.176,2948.748Z"
          transform="translate(-132.277 -2789.068)"
          fill="#fffeff"
        />
      </g>
    </svg>
  );
}

export default IconVorteilTransparenzEchtzeit;
