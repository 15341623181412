import { useEffect } from "react";
import LoadingSpinner from "../LoadingSpinner";
import InfoDialog from "./InfoDialog";
import { useHandleFavorite } from '../../hooks/immo/useHandleFavorite';

function InfoDialogUnfavourite({retailObject, handleClose, open}) {
    const { unfavorite, deleteFavError, hideUnfavDialog } = useHandleFavorite(retailObject);

    useEffect(() => {
      if(hideUnfavDialog) {
        handleClose();
      }
    }, [hideUnfavDialog]);

    return (
      <InfoDialog
          open={open}
          handleClose={handleClose}
          headline="Favorit entfernen"
          disableESC={true}
        >
          <>
            <p>
              Sind Sie sicher, dass Sie diese Immobilie von Ihrer Favoritenlisten entfernen wollen?
            </p>

            <LoadingSpinner />
            <div className="button-panel">
              <button className="button button--blue" onClick={() => unfavorite(retailObject)}>
                Entfernen
              </button>
              <button className="button button--blue-outline" onClick={handleClose}>
                Abbrechen
              </button>
            </div>

            {deleteFavError && <p className="form__fielderror">{deleteFavError}</p>}
          </>
      </InfoDialog>
    )
}

export default InfoDialogUnfavourite;