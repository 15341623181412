function IconBearbeitung({ color, colorDetails, cssClasses }) {
  return (
    <svg className={cssClasses} xmlns="http://www.w3.org/2000/svg" width="37.684" height="37.684" viewBox="0 0 37.684 37.684" style={{ transform: 'translate(-50%, -50%) scale(0.7)' }}>
      <g data-name="Gruppe 10483" transform="translate(-51.625 -983.031)">
        <g data-name="Gruppe 10353" transform="translate(30 964)">
          <g data-name="Gruppe 10315" transform="translate(21.625 19.031)">
            <g data-name="Gruppe 10317">
              <path
                data-name="Pfad 7663"
                d="M26.693,15.742V8.636a.791.791,0,0,0-.229-.556L18.613.229A.793.793,0,0,0,18.057,0H.785A.785.785,0,0,0,0,.785V33.758a.785.785,0,0,0,.785.785H18.079a11.742,11.742,0,0,1,8.614-18.8M18.057.785l7.851,7.851H18.057Z"
                fill={color}
              />
              <path data-name="Pfad 7664" d="M21.206,11A10.206,10.206,0,1,0,31.412,21.206,10.216,10.216,0,0,0,21.206,11" transform="translate(6.272 6.272)" fill={color} />
            </g>
          </g>
        </g>
        <g data-name="Gruppe 10361" transform="translate(72.249 1003.375)">
          <line data-name="Linie 4109" y2="13.241" transform="translate(9.362 4.495) rotate(45)" fill="none" stroke={colorDetails} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.8" />
          <path
            data-name="Pfad 6385"
            d="M0,5.6,5.6,0l5.6,5.6"
            transform="translate(5.936 0) rotate(45)"
            fill="none"
            stroke={colorDetails}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2.8"
          />
        </g>
      </g>
    </svg>
  );
}

export default IconBearbeitung;
