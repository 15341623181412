function IconPageNotFound({ color, cssClasses }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="160.975"
      height="156.311"
      viewBox="0 0 160.975 156.311"
      className={cssClasses}
    >
      <g
        data-name="Gruppe 13303"
        transform="matrix(-0.799, -0.602, 0.602, -0.799, -873.564, 2105.808)"
      >
        <line
          data-name="Linie 4647"
          x2="121.13"
          transform="translate(397.768 2242.233)"
          fill={"none"}
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
        />
        <rect
          data-name="Rechteck 4302"
          width="89.531"
          height="52.665"
          transform="translate(413.567 2189.568)"
          fill={"none"}
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
        />
        <path
          data-name="Pfad 8363"
          d="M458.332,2144.8l-60.565,44.766H518.9Z"
          transform="translate(0)"
          fill={"none"}
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
        />
        <path
          data-name="Pfad 8364"
          d="M430.567,2169.4v-22.6h-15.8v10.939"
          transform="translate(72.53 8.533)"
          fill={"none"}
          stroke={color}
          strokeLinejoin="round"
          strokeWidth="4"
        />
        <rect
          data-name="Rechteck 4303"
          width="15.8"
          height="26.333"
          transform="translate(429.367 2205.367)"
          fill={"none"}
          stroke={color}
          strokeLinejoin="round"
          strokeWidth="4"
        />
        <rect
          data-name="Rechteck 4304"
          width="26.333"
          height="15.8"
          transform="translate(460.966 2205.367)"
          fill={"none"}
          stroke={color}
          strokeLinejoin="round"
          strokeWidth="4"
        />
        <rect
          data-name="Rechteck 4305"
          width="26.333"
          height="10.533"
          transform="translate(424.1 2231.7)"
          fill={"none"}
          stroke={color}
          strokeLinejoin="round"
          strokeWidth="4"
        />
      </g>
    </svg>
  );
}

export default IconPageNotFound;
