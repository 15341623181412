import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import FormInput from '../FormFields/FormInput';
import LoadingSpinner from '../LoadingSpinner';
import ButtonForwardAction from '../Buttons/ButtonForwardAction';

// API
import { postSendOffer } from '../../api/JustImmo';

// STORE
import { setFormIsLoading } from '../../reducers/app';
import { setSiteIndexKaufanbotForm } from '../../reducers/kaufAnbote';

import { KAUFANBOT_SITEINDEX_MAIN, KAUFANBOT_SITEINDEX_SUCCES } from '../../constants';
import { fetchErrorText } from '../FormFields/util/formTexts';

// ICONS
import IconSuccess from '../Icons/IconSuccess';
import { current } from '../../api/Onboarding';
import { useHandleUser } from '../../hooks/user/useHandleUser';

function KaufanbotForm({ handleClose }) {
  let currentKaufanbote = useSelector((state) => state.kaufAnbote);
  const app = useSelector((state) => state.app);
  const dispatch = useDispatch();
  const [wunschImmo, setWunschImmo] = useState({});
  const [wunschKaufpreis, setWunschKaufpreis] = useState();
  const [error, setError] = useState('');
  const { setupUser } = useHandleUser();

  useEffect(() => {
    if (currentKaufanbote.wunschimmobilie && currentKaufanbote.wunschimmobilie.wunschImmo) {
      setWunschImmo(currentKaufanbote.wunschimmobilie.wunschImmo);
    }
  }, [currentKaufanbote.wunschimmobilie]);

  const handleSetWunschKaufpreis = (value) => {
    setWunschKaufpreis(value);
  };

  const sendKaufanbot = async () => {
    if (app.formIsLoading) {
      return;
    }
    setError('');

    if (!wunschKaufpreis.match(/^[0-9]+$/) || wunschKaufpreis < 1) {
      setError('Bitte geben Sie Ihr Angebot als ganze Zahl ohne Satzzeichen ein.');
      return;
    }
    if (!(parseInt(wunschKaufpreis) > 0)) {
      setError('Bitte geben Sie Ihren Wunsch-Kaufpreis ein.');
    } else {
      dispatch(setFormIsLoading(true));

      const tenantId = currentKaufanbote.wunschimmobilie.immo.tenant_id;
      const realtyId = currentKaufanbote.wunschimmobilie.immo.id;

      try {
        await postSendOffer(tenantId, realtyId, wunschKaufpreis);
        dispatch(setFormIsLoading(false));
        dispatch(setSiteIndexKaufanbotForm(KAUFANBOT_SITEINDEX_SUCCES));
        const updatedUser = await current();
        setupUser(updatedUser);
      } catch (e) {
        dispatch(setFormIsLoading(false));
        setError(fetchErrorText);
        console.error('e', e);
      }
    }
  };

  return (
    <div className="kaufanboteForm p-100rem">
      {currentKaufanbote.siteIndex === KAUFANBOT_SITEINDEX_MAIN ? (
        <>
          <h3 className="form__title">Wunschimmobilie</h3>

          <div className="d-flex flex-col mb-200rem">
            <span className="pt-100rem">Infos zum Objekt</span>
            <span className="font-weight-700 pt-50rem">{wunschImmo.addressLine}</span>

            <span className="pt-200rem">Verkaufspreis</span>
            <span className="font-weight-600 font-170 pt-50rem">{wunschImmo.kaufpreis}</span>
          </div>

          <FormInput
            label={'Wunsch-Kaufpreis'}
            placeholder={'Preis für Anbot eingeben'}
            id={'wunschKaufpreis'}
            formType={'wunschimmobilie'}
            type={'number'}
            value={wunschKaufpreis}
            callback={(value) => handleSetWunschKaufpreis(value)}
          />

          <p>
            s REAL wird beim Abschicken dieses Formulars unverbindlich informiert, dass Sie ein Kaufanbot für Ihre Wunschimmobilie abgeben wollen.
            <br />
            <br />
            Eine Makler:in nimmt anschließend mit Ihnen für die Besprechung eines schriftlichen Kaufanbots Kontakt auf.
          </p>

          <LoadingSpinner />
          <div className="button-panel">
            <ButtonForwardAction formType={'wunschimmobilie'} buttonText={'Anfrage Kaufanbot abschicken'} buttonStyle="blue" forwardAction={() => sendKaufanbot()} />
          </div>
          {error && <p className="form__fielderror">{error}</p>}
        </>
      ) : currentKaufanbote.siteIndex === KAUFANBOT_SITEINDEX_SUCCES ? (
        <>
          <div className="d-flex w-100 justify-content-center pt-40 pb-40">
            <IconSuccess color="#1E3163" />
          </div>

          <h2 className="pt-200rem">Anfrage für die Kaufanbot-Erstellung abgeschickt.</h2>
          <p className="pt-200rem pb-200rem lh-130">Eine Makler:in meldet sich in Kürze bei Ihnen, um ein schriftliches Kaufanbot mit Ihnen zu besprechen.</p>

          <div className="button-panel">
            <button className="button button--blue-outline" onClick={handleClose}>
              Schließen
            </button>
          </div>
        </>
      ) : null}
    </div>
  );
}

export default KaufanbotForm;
