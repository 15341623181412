//*React
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
//*Icons
import userPic from '../../../../../shared/icons/profil.svg';
import Flyout from './Flyout';
import HeaderHamburger from './HeaderHamburger';
import Logo from './Logo';
import Nav from './Nav';
import MenuButtonSwitcher from './MenuButtonSwitcher';
//*Reducers
import { toggleDesktopMenu, toggleMobileMenu } from '../../reducers/app';
import { setPageDialogOpenLoginForm, setResetFieldsLoginForm } from '../../reducers/loginForm';
import { setPageDialogOpenRegisterForm, setResetFieldsRegisterForm } from '../../reducers/registerForm';
//*Styles
import '../../styles/header.scss';

function Header({ cssClasses = '', children, overlappedContent }) {
  let user = useSelector((state) => state.user);
  let app = useSelector((state) => state.app);
  const location = useLocation();
  const dispatch = useDispatch();

  const handleOpenRegisterForm = () => {
    dispatch(setResetFieldsRegisterForm());
    dispatch(setPageDialogOpenRegisterForm(true));
  };

  const handleOpenLoginForm = () => {
    dispatch(setResetFieldsLoginForm());
    dispatch(setPageDialogOpenLoginForm(true));
  };

  /*const [height, setHeight] = useState('');
  useEffect(() => {
    const headerCtabuttons = document.querySelector('.header__ctabuttons');
    if (headerCtabuttons) {
      const headerHeight = document.querySelector('#header').offsetHeight;

      const defaultButtonHeight = 45;
      const puffer = 20;
      const newHeight = headerHeight - defaultButtonHeight * headerCtabuttons.children.length - puffer;
      setHeight(newHeight);
    } else if (cssClasses.indexOf('header--default') === -1) {
      setHeight('auto');
    }
  }, []);*/

  const handleOpenFlyout = () => {
    if (!app.desktopMenuOpen) {
      dispatch(toggleDesktopMenu());
    }
  };

  useEffect(() => {
    if (app.desktopMenuOpen) {
      dispatch(toggleDesktopMenu());
    }
    if (app.mobileMenuOpen) {
      dispatch(toggleMobileMenu());
    }
    // eslint-disable-next-line
  }, [app.menuType, location]);

  return (
    <>
      {app.desktopMenuOpen && <Flyout></Flyout>}
      <div id="header" className={overlappedContent ? `header--overlapped ${cssClasses}` : cssClasses}>
        <header id="sreal-header">
          <Logo cssClasses="header__logo" />

          <MenuButtonSwitcher />

          {user.isLoggedIn ? (
            <div className="header__buttonright">
              <>
                <div className="header__buttonright--desktop header__userpic" onClick={handleOpenFlyout}>
                  <img src={userPic} alt={`${user.firstName} ${user.lastName}`} className="image--round" />
                </div>

                <HeaderHamburger />
              </>
            </div>
          ) : (
            <div className="header__buttonright header_buttonright--onboarding">
              <button className="button button--white button--login-menu" onClick={handleOpenLoginForm}>
                Login
              </button>
              <button className="ml-10 button button--white-outline button--register-menu" onClick={handleOpenRegisterForm}>
                Registrieren
              </button>
              <HeaderHamburger />
            </div>
          )}

          <Nav activeMobileMenu={app.mobileMenuOpen} />
        </header>

        {children}
      </div>
    </>
  );
}

export default Header;
