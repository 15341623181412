function IconKMarktanalyse({ color, cssClasses }) {
  return (
    <svg
      className={cssClasses}
      xmlns="http://www.w3.org/2000/svg"
      width="42.789"
      height="28.525"
      viewBox="0 0 42.789 28.525"
    >
      <path
        id="Pfad_8424"
        data-name="Pfad 8424"
        d="M439.5,2176.867a.893.893,0,0,1-.891.892H397.6a.891.891,0,1,1,0-1.783h.891v-8.023a.891.891,0,0,1,.891-.892h5.349a.893.893,0,0,1,.891.892v8.023h3.566v-16.937a.89.89,0,0,1,.891-.891h5.349a.893.893,0,0,1,.891.891v16.938h3.566V2162.6a.89.89,0,0,1,.891-.891h5.349a.893.893,0,0,1,.891.891v13.372h3.566v-25.851a.891.891,0,0,1,.891-.892h5.349a.894.894,0,0,1,.891.892v25.851h.891A.893.893,0,0,1,439.5,2176.867Z"
        transform="translate(-396.711 -2149.232)"
        fill={color}
      />
    </svg>
  );
}

export default IconKMarktanalyse;
