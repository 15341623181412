function IconVorteilOnlinePlattform({ color, cssClasses }) {
  return (
    <svg
      className={cssClasses}
      xmlns="http://www.w3.org/2000/svg"
      width="199.635"
      height="199.642"
      viewBox="0 0 199.635 199.642"
    >
      <g id="Gruppe_13317" data-name="Gruppe 13317" transform="translate(0 0)">
        <path
          id="Pfad_8371"
          data-name="Pfad 8371"
          d="M479.038,3241.029a20.821,20.821,0,0,0-20.8-20.8H325.148a20.816,20.816,0,0,0-20.8,20.8v12.479H479.038Zm-145.572,4.161a4.159,4.159,0,1,1,4.159-4.161A4.141,4.141,0,0,1,333.466,3245.19Zm16.637,0a4.159,4.159,0,1,1,4.159-4.161A4.141,4.141,0,0,1,350.1,3245.19Zm16.637,0a4.159,4.159,0,1,1,4.159-4.161A4.141,4.141,0,0,1,366.74,3245.19Z"
          transform="translate(-304.352 -3220.233)"
          fill={color}
        />
        <path
          id="Pfad_8372"
          data-name="Pfad 8372"
          d="M458.741,3270.488l20.3,20.287v-65.542H304.352v79.023a20.815,20.815,0,0,0,20.8,20.8h63.137a12.39,12.39,0,0,1,2.9-4.657l49.91-49.911A12.762,12.762,0,0,1,458.741,3270.488Z"
          transform="translate(-304.352 -3183.642)"
          fill={color}
        />
        <path
          id="Pfad_8373"
          data-name="Pfad 8373"
          d="M422.271,3282.347l-23.732-23.741-26.178-26.168a4.2,4.2,0,0,0-5.881,0s-50.4,50.25-50.825,51.292a4.156,4.156,0,0,0,.915,4.5l.042.034a4.2,4.2,0,0,0,5.79,0l.05-.034,2.945-2.945H340.31v54.069h20.8v-29.114h20.8v29.114h20.8v-54.069h10.755l2.936,2.945.05.034a4.194,4.194,0,0,0,5.781,0l.05-.034A4.16,4.16,0,0,0,422.271,3282.347Z"
          transform="translate(-223.852 -3139.708)"
          fill={color}
        />
      </g>
    </svg>
  );
}

export default IconVorteilOnlinePlattform;
