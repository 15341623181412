import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// FORM FIELDS
import ButtonForwardAction from '../../Buttons/ButtonForwardAction';
import FormCheckbox from '../../FormFields/FormCheckbox';
import FormInput from '../../FormFields/FormInput';
import FormRadio from '../../FormFields/FormRadio';
import FormSelect from '../../FormFields/FormSelect';
import FormSelectPhoneNumber from '../../FormFields/customFields/FormSelectPhoneNumber';
import Textarea from '../../FormFields/Textarea';
import FormTextDsgvo from '../../FormFields/util/formTextDsgvo';
import LoadingSpinner from '../../LoadingSpinner';

import { anliegenOptions, anliegenOptionsNotLoggedIn, genderOptions } from '../../FormFields/util/formOptions';

import { findPhonePrefix } from '../../../util/generalUtils';

import { confirmPassword, validateFields } from '../../FormFields/util/validateFields';

// STORE
import { setFormIsLoading } from '../../../reducers/app';
import {
  setCreateJustImmoContactContactForm,
  setEmailContactForm,
  setFirstNameContactForm,
  setGenderContactForm,
  setLastNameContactForm,
  setPhoneNumberPrefixContactForm,
  setParsedPhoneNumberContactForm,
  setPhoneToUserPhoneContactForm,
  setPrivacyAggrementContactForm,
  setReasonContactForm,
  setSiteIndexContactForm,
  setTitleAfterContactForm,
  setTitleBeforeContactForm,
} from '../../../reducers/contactInformation';

import {
  CONTACTFORM_ANLIEGEN_ALLG,
  CONTACTFORM_ANLIEGEN_PROFIL,
  CONTACTFORM_ANLIEGEN_PROFIL_DEAKTIVIEREN,
  CONTACTFORM_SITEINDEX_EMAILSENT,
  CONTACTFORM_SITEINDEX_INFO_BUNDESLAND,
  CONTACTFORM_SITEINDEX_TERMINWUNSCH,
  CONTACTFORM_SITEINDEX_MAIN,
  CONTACTFORM_ANLIEGEN_SUCHE,
  CONTACTFORM_ANLIEGEN_SUPPORT,
  CONTACTFORM_SITEINDEX_KONTAKTIEREN,
} from '../../../constants';

// API
import { send } from '../../../api/Contact';
import InfoDialogBundesland from '../../Dialogs/InfoDialogBundesland';
import { fetchErrorText, registerUsernameAlreadyExists } from '../../FormFields/util/formTexts';
import statesWithZips from '../../FormFields/util/statesWithZips';
import { combineReduxPhoneParts } from '../../../util/phoneNumberParser';

function ContactMain({ handleClose }) {
  let contactInformation = useSelector((state) => state.contactInformation);
  let currentFormValidators = useSelector((state) => state.formValidators);
  const app = useSelector((state) => state.app);
  const [error, setError] = useState('');
  const [openInfoBundesland, setOpenInfoBundesland] = useState(false);

  const dispatch = useDispatch();
  let user = useSelector((state) => state.user);

  useEffect(() => {
    if (user.isLoggedIn && Object.keys(user.userObject).length > 0) {
      const foundPrefix = findPhonePrefix(user.userObject.phoneNumber);
      // fill data
      dispatch(setGenderContactForm(user.userObject.gender));
      dispatch(setTitleBeforeContactForm(user.userObject.titleBefore));
      dispatch(setTitleAfterContactForm(user.userObject.titleAfter));
      dispatch(setFirstNameContactForm(user.userObject.firstName));
      dispatch(setLastNameContactForm(user.userObject.lastName));
      dispatch(setEmailContactForm(user.userObject.userName));
      dispatch(setPhoneToUserPhoneContactForm(user.userObject.phoneNumber));
      dispatch(setPhoneNumberPrefixContactForm(foundPrefix));
      dispatch(setParsedPhoneNumberContactForm(user.userObject.phoneNumber.substring(foundPrefix.text.substring(0, foundPrefix.text.indexOf(' ')).length)));
      dispatch(setPrivacyAggrementContactForm(true));
    }
  }, []);

  useEffect(() => {
    if (
      contactInformation.reasonObject.id !== CONTACTFORM_ANLIEGEN_ALLG &&
      contactInformation.reasonObject.id !== CONTACTFORM_ANLIEGEN_SUCHE &&
      contactInformation.reasonObject.id !== CONTACTFORM_ANLIEGEN_PROFIL &&
      contactInformation.reasonObject.id !== CONTACTFORM_ANLIEGEN_PROFIL_DEAKTIVIEREN
    ) {
      setShortRoute(false);
    } else {
      setShortRoute(true);
    }
  }, [contactInformation.reasonObject]);

  const handleSetOpenBundeslandInfo = () => {
    let val = !openInfoBundesland;
    if (val) {
      window.location.hash = CONTACTFORM_SITEINDEX_INFO_BUNDESLAND;
    } else {
      // window.location.hash = CONTACTFORM_SITEINDEX_MAIN;
      // setReasonContactForm() automatically sets hash
      dispatch(setReasonContactForm(contactInformation.reasonObject));
    }
    setOpenInfoBundesland(val);
  };

  const forwardAction = async () => {
    setError('');

    if (app.formIsLoading) {
      return;
    }
  
    let fieldsToValidate = {
      reason: contactInformation.requestBody.reason,
      gender: contactInformation.requestBody.register.gender,
      firstName: contactInformation.requestBody.register.firstName,
      lastName: contactInformation.requestBody.register.lastName,
      email: contactInformation.requestBody.register.email,
      phone: combineReduxPhoneParts(contactInformation.phoneNumberPrefix, contactInformation.parsedPhoneNumber),
    };

    let validatePWResult = true;
    if (shortRoute) {
      fieldsToValidate.privacyPolicy = contactInformation.requestBody.register.privacyAggrement;
      if (contactInformation.requestBody.createUser) {
        fieldsToValidate.password = contactInformation.requestBody.register.password;
        fieldsToValidate.passwordConfirm = contactInformation.passwordConfirm;

        validatePWResult = confirmPassword(contactInformation.requestBody.register.password, contactInformation.passwordConfirm, dispatch);
      }
    }

    if (contactInformation.reasonObject.id === CONTACTFORM_ANLIEGEN_ALLG || contactInformation.reasonObject.id === CONTACTFORM_ANLIEGEN_SUCHE) {
      fieldsToValidate.federalState = contactInformation.stateObject.id;
    }

    let validationResult = validateFields(fieldsToValidate, dispatch);

    if (validationResult && validatePWResult) {
      if (shortRoute) {
        let newObjectRequest = { ...contactInformation.requestBody };

        dispatch(setFormIsLoading(true));

        if (contactInformation.reasonObject.id === CONTACTFORM_ANLIEGEN_SUCHE && contactInformation.requestBody.createUser) {
          dispatch(setCreateJustImmoContactContactForm(true));
          newObjectRequest.createJustImmoContact = true;
        }

        try {
          await send(newObjectRequest);
          dispatch(setFormIsLoading(false));
          dispatch(setSiteIndexContactForm(CONTACTFORM_SITEINDEX_EMAILSENT));
        } catch (e) {
          dispatch(setFormIsLoading(false));
          if (e.response?.data?.detail?.includes('already taken')) {
            setError(registerUsernameAlreadyExists);
          } else {
            setError(fetchErrorText);
          }
        }
      } else {
        
        if(contactInformation.reasonObject.id === CONTACTFORM_ANLIEGEN_SUPPORT) {
          dispatch(setSiteIndexContactForm(CONTACTFORM_SITEINDEX_KONTAKTIEREN));
        }
        else {
          dispatch(setSiteIndexContactForm(CONTACTFORM_SITEINDEX_TERMINWUNSCH));
        }
      }
    }
  };

  const [shortRoute, setShortRoute] = useState(true);

  const formType = 'contactInformation';

  return (
    <>
      <h3 className="form__title">Anfrage</h3>
      {contactInformation.reasonObject.id === CONTACTFORM_ANLIEGEN_PROFIL_DEAKTIVIEREN ? (
        <p>Wir bedauern, dass Sie Ihr Profil deaktivieren möchten. Wenn Sie unseren Service wieder benötigen, sind wir gerne wieder für Sie da.</p>
      ) : (
        <p>Wir beraten Sie gern individuell zu Ihrem Anliegen. Bitte füllen Sie dafür das Kontaktformular aus. Wir melden uns zeitnah bei Ihnen.</p>
      )}

      {!localStorage.getItem('profildeaktivieren-clicked') && localStorage.getItem('profildeaktivieren-clicked') !== true ? (
        <>
          <h3 className="form__title pt-100rem">Ihr Anliegen</h3>
          <FormSelect
            label={'Ich möchte'}
            value={contactInformation.reasonObject}
            error={currentFormValidators.reasonError}
            formType={formType}
            showBitteWaehlen={false}
            id={'inputReason'}
            options={user.isLoggedIn ? anliegenOptions : anliegenOptionsNotLoggedIn}
          />
        </>
      ) : null}

      <h3 className="form__title">Ihre Daten</h3>
      <FormRadio options={genderOptions} id={'inputGender'} error={currentFormValidators.genderError} formType={formType} selectedValue={contactInformation.requestBody.register.gender} />

      <div className="form__row form__row--double">
        <FormInput type={'text'} id={'inputTitleBefore'} label={'Titel (vorgestellt)'} value={contactInformation.requestBody.register.titleBefore} required={false} formType={formType} />

        <FormInput type={'text'} id={'inputTitleAfter'} label={'Titel (nachgestellt)'} value={contactInformation.requestBody.register.titleAfter} required={false} formType={formType} />
      </div>

      <FormInput
        type={'text'}
        id={'inputFirstName'}
        label={'Vorname'}
        value={contactInformation.requestBody.register.firstName}
        error={currentFormValidators.firstNameError}
        required={true}
        formType={formType}
      />
      <FormInput
        type={'text'}
        id={'inputLastName'}
        label={'Nachname'}
        value={contactInformation.requestBody.register.lastName}
        error={currentFormValidators.lastNameError}
        required={true}
        formType={formType}
      />
      <FormInput
        type={'email'}
        id={'inputEmail'}
        label={'E-Mail-Adresse'}
        value={contactInformation.requestBody.register.email}
        error={currentFormValidators.emailError}
        required={true}
        formType={formType}
      />

      <FormSelectPhoneNumber formType={formType} form={contactInformation} />

      {contactInformation.reasonObject.id === CONTACTFORM_ANLIEGEN_ALLG || contactInformation.reasonObject.id === CONTACTFORM_ANLIEGEN_SUCHE ? (
        <>
          <FormSelect
            label="Bundesland"
            placeholder="Bitte wählen"
            value={contactInformation.stateObject}
            error={currentFormValidators.federalStateError}
            id={'inputState'}
            formType={formType}
            options={statesWithZips.map((state) => {
              return {
                id: state.justImmoId,
                text: state.label,
              };
            })}
          />

          <span className="textlink font-80" onClick={handleSetOpenBundeslandInfo}>
            Welches Bundesland wird benötigt?
          </span>
        </>
      ) : null}

      {!!shortRoute ? (
        <>
          {contactInformation.reasonObject.id === CONTACTFORM_ANLIEGEN_PROFIL ? (
            <h3 className="form__title pt-200rem">Bitte geben Sie Ihren Änderungswunsch bekannt.</h3>
          ) : (
            <h3 className="form__title pt-200rem">Ihre Nachricht (optional)</h3>
          )}

          <Textarea formType={formType} label="" id="ihrenachricht" />

          {!user.isLoggedIn && (
            <>
              <h3 className="form__title pt-100rem">Zustimmungen</h3>
              <FormCheckbox
                id="inputPrivacyAggrement"
                label={<FormTextDsgvo />}
                required={true}
                value={contactInformation.requestBody.register.privacyAggrement}
                error={currentFormValidators.privacyPolicyError}
                formType={formType}
              />
              <FormCheckbox
                id="inputCreateUser"
                label={'Jetzt direkt für das sREAL Kundenportal registrieren und von den vielen Vorteilen profitieren.'}
                value={contactInformation.requestBody.createUser}
                formType={formType}
              />

              {contactInformation.requestBody.createUser && (
                <>
                  <FormInput
                    type={'password'}
                    id={'inputPassword'}
                    label={'Passwort'}
                    value={contactInformation.requestBody.register.password}
                    error={currentFormValidators.passwordError}
                    required={true}
                    formType={formType}
                  />
                  <span className="form--info">Mindestens 8 Zeichen, 1 Klein- und Großbuchstabe, 1 Ziffer und 1 Sonderzeichen</span>

                  <FormInput
                    type={'password'}
                    id={'inputPasswordConfirm'}
                    label={'Passwort wiederholen'}
                    value={contactInformation.passwordConfirm}
                    error={currentFormValidators.passwordError || currentFormValidators.passwordConfirmError}
                    required={true}
                    formType={formType}
                  />
                </>
              )}
            </>
          )}

          <LoadingSpinner />

          <div className="button-panel pt-150rem">
            <button className="button button--blue-outline" onClick={handleClose}>
              Zurück
            </button>
            <ButtonForwardAction formType={formType} buttonText="Nachricht senden" forwardAction={forwardAction} buttonStyle="blue" />
          </div>

          {error && <p className="form__fielderror">{error}</p>}
        </>
      ) : (
        <>
          <div className="button-panel pt-150rem">
            <ButtonForwardAction formType={formType} forwardAction={forwardAction} buttonStyle={'blue'} buttonText={'Weiter'} />
          </div>
          {error && <p className="form__fielderror">{error}</p>}
        </>
      )}
      <InfoDialogBundesland open={openInfoBundesland} handleClose={handleSetOpenBundeslandInfo} />
    </>
  );
}

export default ContactMain;
