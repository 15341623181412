function IconVorteilAnEinemOrt({ color, cssClasses }) {
  return (
    <svg
      className={cssClasses}
      xmlns="http://www.w3.org/2000/svg"
      width="203.194"
      height="203.457"
      viewBox="0 0 203.194 203.457"
    >
      <g id="Gruppe_13343" data-name="Gruppe 13343" transform="translate(0 0)">
        <path
          id="Pfad_8405"
          data-name="Pfad 8405"
          d="M579.118,3241.516a21.308,21.308,0,0,0-21.282-21.282h-136.2a21.3,21.3,0,0,0-21.282,21.282v12.769H579.118Zm-148.972,4.255a4.256,4.256,0,1,1,4.257-4.255A4.234,4.234,0,0,1,430.145,3245.771Zm17.025,0a4.256,4.256,0,1,1,4.256-4.255A4.234,4.234,0,0,1,447.171,3245.771Zm17.026,0a4.256,4.256,0,1,1,4.256-4.255A4.234,4.234,0,0,1,464.2,3245.771Z"
          transform="translate(-400.351 -3220.233)"
          fill={color}
        />
        <path
          id="Pfad_8406"
          data-name="Pfad 8406"
          d="M528.672,3310.363l12.6-25.1a13.684,13.684,0,0,1,24.746.02l12.488,25.085h.613v-85.129H400.351v80.873a21.3,21.3,0,0,0,21.282,21.282h73.975a13.129,13.129,0,0,1,.239-8.2,13.6,13.6,0,0,1,13.042-8.828Z"
          transform="translate(-400.351 -3182.67)"
          fill={color}
        />
        <path
          id="Pfad_8407"
          data-name="Pfad 8407"
          d="M507.161,3265.3H482.117l-14.838-29.794a5.168,5.168,0,0,0-9.5-.017L442.813,3265.3H417.778a4.891,4.891,0,0,0-3.235,8.88l.017.015,21.674,18.056-11.186,33.542c-1.047,3.257.315,4.538,2.145,6.75,2.3,0,3.5.195,5.389-1.079l29.888-20.535,29.871,20.535c2.086,1.393,3.311,1.063,5.7.926,1.711-2.359,2.792-3.77,1.83-6.6l-11.169-33.542,21.673-18.056a5.55,5.55,0,0,0,2.051-4.29C512.379,3267.163,509.859,3265.3,507.161,3265.3Z"
          transform="translate(-309.24 -3129.096)"
          fill={color}
        />
      </g>
    </svg>
  );
}

export default IconVorteilAnEinemOrt;
