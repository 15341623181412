function IconCalculatorOutlined({ color, cssClasses }) {
  return (
    <svg
      className={cssClasses}
      xmlns="http://www.w3.org/2000/svg"
      width="159.127"
      height="202.218"
      viewBox="0 0 159.127 202.218"
    >
      <g id="Gruppe_12595" data-name="Gruppe 12595" transform="translate(2 2)">
        <g id="Gruppe_11124" data-name="Gruppe 11124">
          <rect
            id="Rechteck_3816"
            data-name="Rechteck 3816"
            width="155.127"
            height="198.218"
            rx="2.5"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="4"
          />
          <path
            id="Rechteck_3817"
            data-name="Rechteck 3817"
            d="M.894,0H119.76a.9.9,0,0,1,.9.9V33.579a.894.894,0,0,1-.894.894H.9a.9.9,0,0,1-.9-.9V.894A.894.894,0,0,1,.894,0Z"
            transform="translate(17.236 17.24)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="4"
          />
          <line
            id="Linie_4449"
            data-name="Linie 4449"
            x2="155.127"
            transform="translate(0 129.273)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="4"
          />
          <line
            id="Linie_4450"
            data-name="Linie 4450"
            x2="155.127"
            transform="translate(0 68.945)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="4"
          />
          <line
            id="Linie_4451"
            data-name="Linie 4451"
            y2="129.273"
            transform="translate(77.564 68.945)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="4"
          />
          <line
            id="Linie_4452"
            data-name="Linie 4452"
            x2="34.473"
            transform="translate(17.236 99.109)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="4"
          />
          <line
            id="Linie_4453"
            data-name="Linie 4453"
            x2="34.473"
            transform="translate(99.109 103.418)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="4"
          />
          <line
            id="Linie_4454"
            data-name="Linie 4454"
            x2="27.199"
            y2="27.449"
            transform="translate(25.181 149.227)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="4"
          />
          <line
            id="Linie_4455"
            data-name="Linie 4455"
            y1="27.199"
            x2="27.199"
            transform="translate(25.181 149.474)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="4"
          />
          <line
            id="Linie_4456"
            data-name="Linie 4456"
            x2="34.473"
            transform="translate(99.109 172.364)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="4"
          />
          <line
            id="Linie_4457"
            data-name="Linie 4457"
            x2="34.473"
            transform="translate(99.109 155.127)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="4"
          />
          <line
            id="Linie_4458"
            data-name="Linie 4458"
            y2="34.473"
            transform="translate(34.473 81.873)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="4"
          />
        </g>
      </g>
    </svg>
  );
}

export default IconCalculatorOutlined;
