function IconChatQuestionmark({ color, cssClasses, fillColor }) {
  return (
    <svg className={cssClasses} id="Gruppe_15235" data-name="Gruppe 15235" xmlns="http://www.w3.org/2000/svg" width="48.02" height="47.925" viewBox="0 0 48.02 47.925">
      <g id="Gruppe_15237" data-name="Gruppe 15237">
        <path
          id="Pfad_8750"
          data-name="Pfad 8750"
          d="M34.6,2.4A24.508,24.508,0,0,0,24,0,24.071,24.071,0,0,0,0,24,23.819,23.819,0,0,0,3.5,36.5L.1,45.6a1.747,1.747,0,0,0,.3,1.7,1.658,1.658,0,0,0,1.6.6l12-2.2a23.456,23.456,0,0,0,10,2.2,23.221,23.221,0,0,0,7.8-1.3A23.741,23.741,0,0,0,45.6,34.4a24.012,24.012,0,0,0-11-32M42.5,33A20.663,20.663,0,0,1,15,42.5a1.884,1.884,0,0,0-.8-.2h-.3L4.4,44l2.7-7.1a1.839,1.839,0,0,0-.2-1.6A19.623,19.623,0,0,1,3.4,24a20.094,20.094,0,0,1,6-14.5A20.454,20.454,0,0,1,32.9,5.6,20.389,20.389,0,0,1,42.5,33"
          fill={color ? color : '#fff'}
        />
        <path
          id="Pfad_8751"
          data-name="Pfad 8751"
          d="M24,12a6.914,6.914,0,0,0-6.9,6.9,1.7,1.7,0,1,0,3.4,0,3.4,3.4,0,1,1,3.4,3.4A1.752,1.752,0,0,0,22.2,24v3.4a1.7,1.7,0,1,0,3.4,0V25.5a6.843,6.843,0,0,0,5.1-6.6A6.623,6.623,0,0,0,24,12m0,20.6a2.6,2.6,0,1,0,2.6,2.6A2.582,2.582,0,0,0,24,32.6"
          fill={color ? color : '#fff'}
        />
      </g>
    </svg>
  );
}

export default IconChatQuestionmark;
